import React, { useEffect, useState } from "react";
import styles from "../styles/Table.module.css";
import { fetchTools, Tool, deleteTool, resetTool, moveTool } from "./ToolsAPI"; // Import the fetchToolsData function
import ToolEventList from "./ToolEventList"; // Import the ToolEventList component
import AddEditToolForm from "./ToolAddEditForm"; // Import the AddTool component
import MoveToolForm from "./ToolMoveForm";

// Formats time from seconds
const formatTime = (seconds: number): string => {
  if (seconds < 60) {
    seconds = Math.floor(seconds);
    return `${seconds}s`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${remainingMinutes}m`;
  }
};

const ToolList: React.FC = () => {
  const [tools, setTools] = useState<Tool[]>([]);
  const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [editTool, setEditTool] = useState<Tool | null>(null);

  // Fetch tools data when the component mounts
  async function fetchToolsHandler() {
    console.log("fetchToolsHandler1");
    try {
      const data = await fetchTools();
      setTools(data);
      console.log("fetchToolsHandler2");
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchToolsHandler();
  }, []);

  const handleToolAddClick = () => {
    setEditTool(null);
    setIsAddEditModalOpen(true);
  };

  const handleAddEditToolFormClose = () => {
    setEditTool(null);
    setIsAddEditModalOpen(false);
  };

  const handleMoveToolFormClose = () => {
    setEditTool(null);
    setIsMoveModalOpen(false);
  };

  const handleToolSelectClick = (tool: Tool) => {
    if (selectedTool && selectedTool.id == tool.id) setSelectedTool(null);
    else setSelectedTool(tool);
  };

  const handleToolEditClick = (event: React.MouseEvent, tool: Tool) => {
    event.stopPropagation();
    setEditTool(tool);
    setIsAddEditModalOpen(true);
  };

  const handleToolResetClick = async (event: React.MouseEvent, tool: Tool) => {
    event.stopPropagation();
    await resetTool(tool.id);
    fetchToolsHandler();
  };
  const handleToolMoveClick = async (event: React.MouseEvent, tool: Tool) => {
    event.stopPropagation();
    setEditTool(tool);
    setIsMoveModalOpen(true);
  };

  const handleToolDeleteClick = async (event: React.MouseEvent, tool: Tool) => {
    event.stopPropagation();
    await deleteTool(tool.id);
    fetchToolsHandler();
  };

  return (
    <div>
      <h1>Tool List</h1>
      <button onClick={handleToolAddClick}>Add Tool</button>
      <table className={styles.tooldb__table}>
        <thead>
          <tr>
            <th>ID</th>
            <th colSpan={2}>Tool</th>
            <th>Description</th>
            <th>SKU</th>
            <th colSpan={2}>Position</th>
            <th colSpan={2}>Geometry</th>
            <th colSpan={2}>Tool Life</th>
            <th>Statistics</th>
          </tr>
        </thead>
        <tbody>
          {tools.map((tool) => (
            <tr key={tool.id} onClick={() => handleToolSelectClick(tool)}>
              <td>{tool.id}</td>
              <td>
                T{tool.number}
                {tool.sister != "" ? "-" + tool.sister : ""}
              </td>
              <td>
                <button onClick={(e) => handleToolEditClick(e, tool)}>
                  Edit
                </button>
              </td>
              <td>{tool.description}</td>
              <td>{tool.sku}</td>
              <td>
                {tool.position != ""
                  ? tool.position + ": " + tool.position_id
                  : ""}
              </td>
              <td>
                <button onClick={(e) => handleToolMoveClick(e, tool)}>
                  Move
                </button>
              </td>
              <td>{tool.geom_diameter !== null ? `Ø${tool.geom_diameter.toFixed(1)}` : 'N/A'}</td>
              <td>{tool.geom_length !== null ? `L${tool.geom_length.toFixed(1)}` : 'N/A'}</td>
              <td>{`${formatTime(tool.life_time)} (${tool.life_count}) `}</td>
              <td>
                <button onClick={(e) => handleToolResetClick(e, tool)}>
                  Reset
                </button>
              </td>
              <td>{`${formatTime(tool.stats_time)} (${tool.stats_count}) (${
                tool.stats_resets
              } resets) `}</td>
              <td>
                <button onClick={(e) => handleToolDeleteClick(e, tool)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedTool && (
        <div>
          <ToolEventList tool={selectedTool} />
        </div>
      )}
      {isAddEditModalOpen && (
        <AddEditToolForm
          onClose={handleAddEditToolFormClose}
          onSubmit={fetchToolsHandler}
          toolToEdit={editTool}
        />
      )}
      {isMoveModalOpen && editTool && (
        <MoveToolForm
          onClose={handleMoveToolFormClose}
          onSubmit={fetchToolsHandler}
          toolToEdit={editTool}
        />
      )}
    </div>
  );
};

export default ToolList;
