// Author: Erik s195397
import { Table, TableBody } from "@mui/material";
import { FunctionComponent } from "react";
import { RequisitionItemType } from "types/RequisitionTypes";
import RequisitionItemRow from "./RequisitionItemRow";
import CustomRequisitionItemRow from "./CustomRequisitionItemRow";
import { ProductTypeEnum } from "types/products/ProductCommandsType";

type PropsType = {
  requisitionItems: RequisitionItemType[];
};

// Table for requisitions in outsourcing table
const RequisitionItemsTable: FunctionComponent<PropsType> = ({
  requisitionItems,
}) => {
  return (
    <Table size="small">
      <TableBody>
        {requisitionItems.map((item) =>
          item.product.productType === ProductTypeEnum.OTHER ? (
            <CustomRequisitionItemRow item={item} />
          ) : (
            <RequisitionItemRow item={item} data={item.product.details} />
          )
        )}
      </TableBody>
    </Table>
  );
};

export default RequisitionItemsTable;
