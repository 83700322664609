import { FunctionComponent, useContext, useEffect, useReducer } from "react";

import styles from "./FileResourceList.module.css";
import { FileResourceType } from "types/FileResourceTypes";
import ListReducer, { ListReducerEnum } from "util/ListReducer";
import {
  apiCreateFileResource,
  apiDownloadFileResource,
} from "util/network/FileResources";
import AppStateContext from "contexts/AppStateContext";
import FileInput from "./FileInput";

type PropsType = {
  resources: FileResourceType[];
  allowEdit?: boolean;
  onChange?: (resources: FileResourceType[]) => Promise<boolean>;
};

const FileResourceList: FunctionComponent<PropsType> = ({
  resources,
  allowEdit,
  onChange,
}) => {
  const { token } = useContext(AppStateContext);

  const [fileResources, fileResourcesDispath] = useReducer(
    ListReducer<FileResourceType>("id"),
    resources ?? []
  );

  useEffect(() => {
    fileResourcesDispath({ type: ListReducerEnum.SET, value: resources });
  }, [resources]);

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = sizes[i];
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + size;
  };

  const handleAddFileResource = (files: File[]) => {
    Promise.all(
      files.map((file) => {
        return apiCreateFileResource(token, file).then((fileResource) => {
          fileResourcesDispath({
            type: ListReducerEnum.ADD,
            value: fileResource,
          });
          return fileResource;
        });
      })
    ).then((res) => {
      onChange &&
        onChange([...fileResources, ...res]).then((success) => {
          if (!success) {
            console.log("Failed to add file resource");
            res.forEach((fileResource) => {
              fileResourcesDispath({
                type: ListReducerEnum.REMOVE,
                value: fileResource,
              });
            });
          }
        });
    });
  };

  const handleDeleteFileResource = (id: number) => {
    const fileResource = fileResources.find((x) => x.id === id);
    if (!fileResource) return;
    fileResourcesDispath({ type: ListReducerEnum.REMOVE, value: fileResource });
    onChange &&
      onChange(fileResources.filter((x) => x.id !== id)).then((success) => {
        console.log(success);
        if (!success) {
          console.log("Failed to delete file resource");
          fileResourcesDispath({
            type: ListReducerEnum.ADD,
            value: fileResource,
          });
        }
      });
  };

  return (
    <>
      {allowEdit && <FileInput handleAddFileResource={handleAddFileResource} />}
      <ul className={styles.fileResourceList}>
        {fileResources.map((resource) => (
          <li key={resource.id} className={styles.fileResourceListItem}>
            <button
              type="button"
              onClick={() =>
                token &&
                apiDownloadFileResource(token, resource.id, resource.fileName)
              }
              style={{
                background: "none",
                border: "none",
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                padding: "0",
                font: "inherit",
              }}
            >
              {resource.fileName}
            </button>

            <span className={styles.fileResourceListItemDetails}>
              {resource.fileType} ({formatFileSize(resource.fileSize)})
            </span>
            {allowEdit && (
              <button
                className={styles.fileResourceListItemRemoveButton}
                onClick={() => handleDeleteFileResource(resource.id)}
              >
                Remove
              </button>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default FileResourceList;
