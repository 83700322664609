import {
  CreateRequisitionType,
  RenderRequisitionType,
  RequisitionListType,
  RequisitionType,
  UpdateRequisitionType,
} from "types/RequisitionTypes";
import { API_URL } from "./common";

const ENDPOINT = `${API_URL}/v1/ecommerce/requisitions`;

export const apiCreateRequisition = (
  token: string,
  requisition: CreateRequisitionType,
  sendEmail: boolean = true,
  sendFrom?: string
) => {
  const body = JSON.stringify(requisition);
  return fetch(
    `${ENDPOINT}?sendEmail=${sendEmail}${
      sendFrom ? "&sendFrom=" + sendFrom : ""
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    }
  ).then((res) => {
    return res.json();
  });
};

export const apiPreviewRequisition = (
  token: string,
  requisition: RenderRequisitionType,
  type: "pdf" | "html" = "html"
) => {
  const body = JSON.stringify(requisition);
  return fetch(`${ENDPOINT}/render/${type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  });
};

export const apiPreviewRequisitionFromId = (
  token: string,
  id: number,
  type: "pdf" | "html" = "html"
) => {
  const body = JSON.stringify(id);
  return fetch(`${ENDPOINT}/${id}/render/${type}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const apiUpdateRequisition = (
  token: string,
  id: number,
  requisition: UpdateRequisitionType,
  sendEmail: boolean = true,
  sendFrom?: string
): Promise<RequisitionType> => {
  const body = JSON.stringify(requisition);
  return fetch(
    `${ENDPOINT}/${id}?sendEmail=${sendEmail}${
      sendFrom ? "&sendFrom=" + sendFrom : ""
    }`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    }
  ).then((res) => {
    return res.json();
  });
};

export const apiGetRequisitions = (
  token: string,
  page: number,
  pageSize: number,
  sortKey: string = "id",
  sortDirection: "asc" | "desc" = "desc",
  status?: string,
  search?: string,
  filter?: string
): Promise<RequisitionListType> => {
  return fetch(
    `${ENDPOINT}?page=${page}&pageSize=${pageSize}&sortKey=${sortKey}&sortDirection=${sortDirection}${
      search ? "&search=" + search : ""
    }${status ? "&status=" + status : ""}${filter ? "&filter=" + filter : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
