import React, { FunctionComponent } from "react";
import { ReactComponent as PhoneIcon } from "../../assets/svg/phone.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useToast } from "contexts/ToastContext";
import { Phone } from "@mui/icons-material";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

type PhoneProps = {
  phone: string;
  blackIcon?: boolean;
};

const CopyPhone: FunctionComponent<PhoneProps> = ({ phone, blackIcon }) => {
  const { addToast } = useToast();

  const formattedPhoneNumber = phone.replace(
    /(\d{2})(\d{2})(\d{2})(\d{2})/,
    "$1 $2 $3 $4"
  );

  const handleCopyPhone = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(formattedPhoneNumber);
    addToast({
      type: "info",
      message: "Phone number copied to the clipboard",
    });
  };

  return (
    <LightTooltip
      title={
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#028B81",
          }}
          onClick={handleCopyPhone}
        >
          {formattedPhoneNumber}
        </span>
      }
    >
      {blackIcon ? (
        <Phone onClick={handleCopyPhone} />
      ) : (
        <PhoneIcon style={{ cursor: "pointer" }} onClick={handleCopyPhone} />
      )}
    </LightTooltip>
  );
};
export default CopyPhone;
