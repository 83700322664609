import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { formatter } from "../../../util/formatter";
import { FunctionComponent } from "react";

type PropsType = {
  total: number;
  totalTax: number;
  fees?: number;
};
const AmountTables: FunctionComponent<PropsType> = ({
  total,
  totalTax,
  fees,
}) => {
  return (
    <Table sx={{ border: 0 }} size="small">
      <TableBody>
        {fees !== undefined && (
          <TableRow>
            <TableCell>Fees</TableCell>
            <TableCell align="center">{formatter.format(fees)}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Total beløb u. moms</TableCell>
          <TableCell align="center">{formatter.format(total)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Moms</TableCell>
          <TableCell align="center">
            {formatter.format(totalTax ?? 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight={"bold"}>Total beløb inkl. moms</Typography>
          </TableCell>
          <TableCell align="center">
            {formatter.format(total + totalTax)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default AmountTables;
