const fakePOMData = [
  {
    "Varenummer": "POMC-80-25",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "POM-C 80mm x 25mm",
    "Maengde": 150,
    "Stregkode": "POMC-80-25"
  },
  {
    "Varenummer": "POMC-80-50",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "POM-C 80mm x 50mm",
    "Maengde": 250,
    "Stregkode": "POMC-80-50"
  },
  {
    "Varenummer": "POMC-80-75",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "POM-C 80mm x 75mm",
    "Maengde": 350,
    "Stregkode": "POMC-80-75"
  },
  {
    "Varenummer": "POMC-80-100",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "POM-C 80mm x 100mm",
    "Maengde": 450,
    "Stregkode": "POMC-80-100"
  },
  {
    "Varenummer": "POMC-80-150",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "POM-C 80mm x 150mm",
    "Maengde": 550,
    "Stregkode": "POMC-80-150"
  },
  {
    "Varenummer": "POMC-80-200",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "POM-C 80mm x 200mm",
    "Maengde": 650,
    "Stregkode": "POMC-80-200"
  },
  {
    "Varenummer": "POMC-80-250",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "POM-C 80mm x 250mm",
    "Maengde": 750,
    "Stregkode": "POMC-80-250"
  },
  {
    "Varenummer": "POMC-80-300",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "POM-C 80mm x 300mm",
    "Maengde": 850,
    "Stregkode": "POMC-80-300"
  },
  {
    "Varenummer": "POMC-80-350",
    "Type": "POM-C",
    "Tykkelse": "80 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "POM-C 80mm x 350mm",
    "Maengde": 950,
    "Stregkode": "POMC-80-350"
  },
  {
    "Varenummer": "POMC-60-25",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "POM-C 60mm x 25mm",
    "Maengde": 1050,
    "Stregkode": "POMC-60-25"
  },
  {
    "Varenummer": "POMC-60-50",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "POM-C 60mm x 50mm",
    "Maengde": 1150,
    "Stregkode": "POMC-60-50"
  },
  {
    "Varenummer": "POMC-60-75",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "POM-C 60mm x 75mm",
    "Maengde": 1250,
    "Stregkode": "POMC-60-75"
  },
  {
    "Varenummer": "POMC-60-100",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "POM-C 60mm x 100mm",
    "Maengde": 1350,
    "Stregkode": "POMC-60-100"
  },
  {
    "Varenummer": "POMC-60-150",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "POM-C 60mm x 150mm",
    "Maengde": 1450,
    "Stregkode": "POMC-60-150"
  },
  {
    "Varenummer": "POMC-60-200",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "POM-C 60mm x 200mm",
    "Maengde": 1550,
    "Stregkode": "POMC-60-200"
  },
  {
    "Varenummer": "POMC-60-250",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "POM-C 60mm x 250mm",
    "Maengde": 1650,
    "Stregkode": "POMC-60-250"
  },
  {
    "Varenummer": "POMC-60-300",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "POM-C 60mm x 300mm",
    "Maengde": 1750,
    "Stregkode": "POMC-60-300"
  },
  {
    "Varenummer": "POMC-60-350",
    "Type": "POM-C",
    "Tykkelse": "60 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "POM-C 60mm x 350mm",
    "Maengde": 1850,
    "Stregkode": "POMC-60-350"
  },
  {
    "Varenummer": "POMC-40-25",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "POM-C 40mm x 25mm",
    "Maengde": 1950,
    "Stregkode": "POMC-40-25"
  },
  {
    "Varenummer": "POMC-40-50",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "POM-C 40mm x 50mm",
    "Maengde": 2050,
    "Stregkode": "POMC-40-50"
  },
  {
    "Varenummer": "POMC-40-75",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "POM-C 40mm x 75mm",
    "Maengde": 2150,
    "Stregkode": "POMC-40-75"
  },
  {
    "Varenummer": "POMC-40-100",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "POM-C 40mm x 100mm",
    "Maengde": 2250,
    "Stregkode": "POMC-40-100"
  },
  {
    "Varenummer": "POMC-40-150",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "POM-C 40mm x 150mm",
    "Maengde": 2350,
    "Stregkode": "POMC-40-150"
  },
  {
    "Varenummer": "POMC-40-200",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "POM-C 40mm x 200mm",
    "Maengde": 2450,
    "Stregkode": "POMC-40-200"
  },
  {
    "Varenummer": "POMC-40-250",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "POM-C 40mm x 250mm",
    "Maengde": 2550,
    "Stregkode": "POMC-40-250"
  },
  {
    "Varenummer": "POMC-40-300",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "POM-C 40mm x 300mm",
    "Maengde": 2650,
    "Stregkode": "POMC-40-300"
  },
  {
    "Varenummer": "POMC-40-350",
    "Type": "POM-C",
    "Tykkelse": "40 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "POM-C 40mm x 350mm",
    "Maengde": 2750,
    "Stregkode": "POMC-40-350"
  },
  {
    "Varenummer": "POMC-20-25",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "POM-C 20mm x 25mm",
    "Maengde": 2850,
    "Stregkode": "POMC-20-25"
  },
  {
    "Varenummer": "POMC-20-50",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "POM-C 20mm x 50mm",
    "Maengde": 2950,
    "Stregkode": "POMC-20-50"
  },
  {
    "Varenummer": "POMC-20-75",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "POM-C 20mm x 75mm",
    "Maengde": 3050,
    "Stregkode": "POMC-20-75"
  },
  {
    "Varenummer": "POMC-20-100",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "POM-C 20mm x 100mm",
    "Maengde": 3150,
    "Stregkode": "POMC-20-100"
  },
  {
    "Varenummer": "POMC-20-150",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "POM-C 20mm x 150mm",
    "Maengde": 3250,
    "Stregkode": "POMC-20-150"
  },
  {
    "Varenummer": "POMC-20-200",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "POM-C 20mm x 200mm",
    "Maengde": 3350,
    "Stregkode": "POMC-20-200"
  },
  {
    "Varenummer": "POMC-20-250",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "POM-C 20mm x 250mm",
    "Maengde": 3450,
    "Stregkode": "POMC-20-250"
  },
  {
    "Varenummer": "POMC-20-300",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "POM-C 20mm x 300mm",
    "Maengde": 3550,
    "Stregkode": "POMC-20-300"
  },
  {
    "Varenummer": "POMC-20-350",
    "Type": "POM-C",
    "Tykkelse": "20 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "POM-C 20mm x 350mm",
    "Maengde": 3650,
    "Stregkode": "POMC-20-350"
  }
];

export default fakePOMData;