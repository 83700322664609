import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppStateContext from "../../contexts/AppStateContext";
import { apiGetMe } from "../../util/network/Users";
import Talk from "talkjs";

type Props = {
  customerId: number;
  customerName: string;
  customerEmail: string;
  productId: number;
  selectedParticipant: "customer" | "employee";
  customerComment?: string | null;
};

const ChatBox: FunctionComponent<Props> = ({
  customerComment,
  customerId,
  customerName,
  customerEmail,
  productId,
  selectedParticipant,
}) => {
  const chatboxRef = useRef<HTMLDivElement>(null);
  const { token } = useContext(AppStateContext);
  const [employee, setEmployee] = useState<Talk.User | null>(null);
  const [talkSession, setTalkSession] = useState<Talk.Session | null>(null);
  const TALK_JS_ID = process.env.REACT_APP_TALK_JS_ID ?? "";

  const createTalkUser = (
    id: string,
    name: string,
    email: string,
    role: string,
    welcomeMessage?: string,
    custom?: any
  ): Talk.User => {
    return new Talk.User({
      id,
      name,
      email,
      role,
      welcomeMessage,
      custom,
    });
  };

  useEffect(() => {
    apiGetMe(token)
      .then((data) => {
        setEmployee(
          createTalkUser(
            data.id.toString(),
            data.firstName,
            data.email,
            "employee",
            undefined,
            { orderNumber: "#" + productId }
          )
        );
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  }, [token, productId]);

  useEffect(() => {
    if (employee) {
      const customer = createTalkUser(
        customerId.toString(),
        customerName,
        customerEmail,
        "customer",
        undefined,
        { orderNumber: "#" + productId }
      );

      const talkSession = new Talk.Session({
        appId: TALK_JS_ID,
        me: employee,
      });

      const conversationId =
        selectedParticipant === "customer"
          ? productId + "" + customerId
          : productId + "internal";

      const conversation = talkSession.getOrCreateConversation(conversationId);
      conversation.setParticipant(employee);
      if (selectedParticipant === "customer") {
        conversation.setParticipant(customer);
      } else if (selectedParticipant === "employee") {
        conversation.setParticipant(employee);
      }
      // If new conversation, send welcome message
      if (
        (!conversation.welcomeMessages ||
          conversation.welcomeMessages?.length === 0) &&
        customerComment
      ) {
        conversation.welcomeMessages = [
          customerComment
            ? `Customer Comment: ${customerComment}`
            : "No comment",
        ];
      }

      const inbox = talkSession.createInbox();
      inbox.select(conversation);
      inbox.mount(chatboxRef.current);

      return () => {
        talkSession.destroy();
      };
    }
  }, [
    employee,
    customerId,
    customerName,
    customerEmail,
    productId,
    selectedParticipant,
  ]);

  return (
    <div
      ref={chatboxRef}
      style={{
        height: "520px",
        width: "350px",
        position: "absolute",
      }}
    ></div>
  );
};

export default ChatBox;
