import { Grid, Typography } from "@mui/material";
import FilterButton from "components/Buttons/FilterButton";
import AppStateContext from "contexts/AppStateContext";
import React, { useContext, useEffect, useState } from "react";
import { ProductionVMType } from "types/ProductionType";
import SearchField from "components/SearchField";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import styles from "../css/tasksPages.module.css";
import { apiGetProduction } from "util/network/Productions";
import QualityControlTable from "./QualityControlTable";

const QualityControlUI = () => {
  const { token } = useContext(AppStateContext);
  const [production, setProduction] = useState<ProductionVMType>();
  const [searchValue, setSearchValue] = useStateWithSessionStorage<string>(
    "",
    "cqcSeachVal"
  );
  const [selectedBtn, setSelectedBtn] = useState<string>("all");

  useEffect(() => {
    if (production?.productId) {
      apiGetProduction(token, production?.productId).then((production) => {
        setProduction(production);
      });
    }
  }, [token, production?.productId]);

  const handleSectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setSelectedBtn(name);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={styles.header}>
          <div className={styles.headerTitleContainers}>
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              className={styles.headerTitle}
            >
              &nbsp;Quality control
            </Typography>
          </div>
          <div className={styles.filterBtnContainer}>
            <FilterButton
              title={"All"}
              checked={selectedBtn == "all"}
              onChange={handleSectionChange}
              name={"all"}
            />
            <FilterButton
              title={"Ready"}
              checked={selectedBtn == "ready"}
              onChange={handleSectionChange}
              name={"ready"}
            />
            <FilterButton
              title={"Coming"}
              checked={selectedBtn == "coming"}
              onChange={handleSectionChange}
              name={"coming"}
            />
            <div className={styles.searchContainer}>
              <SearchField onChange={setSearchValue} value={searchValue} />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={styles.tableContainer}>
        <QualityControlTable
          selection={selectedBtn}
          searchValue={searchValue}
        />
      </Grid>
    </Grid>
  );
};
export default QualityControlUI;
