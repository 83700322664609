export const formatter = new Intl.NumberFormat("da-DK", {
  style: "currency",
  currency: "DKK",
});

export const stringConverter = (string: String) => {
  if (string === "banktransfer") return "Banktransfer";
  if (string === "ean") return "EAN";
  if (string === "card") return "Card";
  if (string === "quote") return "Quote";
  if (string.includes("postnord")) return "PostNord";
  if (string.includes("tnt_before_12")) return "TNT 12:00";
  if (string.includes("tnt_next_day")) return "TNT Next Day";
  if (string.includes("pickup")) return "Pickup";
  else return " ";
};
