import {
  FunctionComponent,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ArrowDropDown, ThreeDRotation } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { fetchSTEPFile, fetchThreadFile } from "../../../util/FileDownload";
import AppStateContext from "../../../contexts/AppStateContext";

type PropsType = {
  disabled?: boolean;
  id?: number;
  name?: string;
  colorFileExist?: boolean;
  handleUpdateModel?: (id: number, file: File) => void;
};

const ModelSelector: FunctionComponent<PropsType> = ({
  disabled,
  id,
  name,
  colorFileExist,
  handleUpdateModel,
}) => {
  const { strings, token } = useContext(AppStateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const hiddenRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ padding: "0" }}>
      <Button
        onClick={handleClick}
        style={{ fontWeight: "bold" }}
        disabled={disabled}
      >
        <ThreeDRotation />
        <ArrowDropDown />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            id && name && fetchSTEPFile(token, name, id);
          }}
        >
          Download Original
        </MenuItem>
        <MenuItem
          disabled={!colorFileExist} //
          onClick={() => {
            id && name && fetchThreadFile(token, name, id);
          }}
        >
          Download Colored
        </MenuItem>

        <MenuItem
          disabled={window.location.pathname.includes("draft")}
          value={"Update_model"}
          onClick={(event) => {
            hiddenRef.current?.click();
          }}
        >
          Update Model
          <input
            ref={hiddenRef}
            style={{ display: "none" }}
            type={"file"}
            onChange={(e) => {
              e.target.files &&
                id &&
                handleUpdateModel &&
                handleUpdateModel(id, e.target.files[0]);
              handleClose();
            }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ModelSelector;
