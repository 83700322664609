import { useState, useReducer, useEffect, FunctionComponent } from "react";
import {
  Button,
  FormControlLabel,
  List,
  ListItem,
  FormGroup,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { ArrowDropDown } from "@mui/icons-material";
import { Checkbox, Typography } from "@mui/material";
import ListReducer, { ListReducerEnum } from "../../util/ListReducer";

type PropsType = {
  options: any[];
  onChange: (status: any[]) => void;
  statusFilter: string[];
};

const StatusSortMenu: FunctionComponent<PropsType> = ({
  options,
  onChange,
  statusFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, filtersDispatch] = useReducer(
    ListReducer("state"),
    statusFilter.map((status) => ({ state: status }))
  );

  useEffect(() => {
    let different = false;
    filters.forEach((filter) => {
      if (!statusFilter.includes(filter.state)) {
        different = true;
      }
    });
    if (different) {
      const filtersNew = filters.map((filter) => filter.state);
      setFilter(filtersNew);
    }
  }, [statusFilter]);

  const open = Boolean(anchorEl);

  // Open menu
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  // Close menu without selection
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setFilter = (filters: string[]) => {
    filtersDispatch({
      value: statusFilter.map((status) => ({ state: status })),
      type: ListReducerEnum.SET,
    });
  };

  // Choosing from menu updates sorting of orders in parent component via setStatusFilter
  const addFilter = (filter: string) => {
    filtersDispatch({
      value: { state: filter },
      type: ListReducerEnum.ADD,
    });
  };

  const removeFilter = (filter: string) => {
    filtersDispatch({
      value: { state: filter },
      type: ListReducerEnum.REMOVE,
    });
  };

  useEffect(() => {
    if (!open) onChange(filters.map((option) => option.state));
  }, [open]);

  return (
    <div style={{ padding: "10px 0 10px 0" }}>
      {/* @ts-ignore */}
      <Button
        variant={"contained"}
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
        sx={{
          background: "#E0A900",
        }}
      >
        <Typography variant={"body1"} fontWeight={"bold"}>
          State
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <List>
          {options.map((option, index) => (
            // <MenuItem
            //   key={option}
            //   selected={index === selectedIndex}
            //   onClick={(event) => handleMenuItemClick(event, index)}
            // >
            //   {option}
            // </MenuItem>
            <ListItem key={index}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(event: any) => {
                        if (event.target.checked) {
                          addFilter(option);
                        } else if (!event.target.checked) {
                          removeFilter(option);
                        }
                      }}
                      defaultChecked={
                        statusFilter.includes(option) ? true : false
                      }
                    />
                  }
                  label={option === "LOCKED" ? "QUOTE" : option}
                />
              </FormGroup>
            </ListItem>
          ))}
        </List>
      </Menu>
    </div>
  );
};

export default StatusSortMenu;
