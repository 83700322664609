import { FunctionComponent, useState } from "react";
import { OtherProductDetailsType } from "types/products/ProductCommandsType";
import FileResourceList from "components/FileResourceList";
import Modal from "components/Modals/Modal";
import { OrderItemType } from "types/OrderTypes";
import {
  Badge,
  IconButton,
  SvgIcon,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material";

type PropsType = {
  item: OrderItemType;
};

const OtherOrderItemRow: FunctionComponent<PropsType> = ({ item }) => {
  const { id, product, quantity, price, weight } = item;
  const { name, details, priceStandard } = product;
  const { description, fileResources } = details as OtherProductDetailsType;

  const [showModal, setShowModal] = useState(false);

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = sizes[i];
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + size;
  };

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <TableRow>
      <TableCell colSpan={2}>{truncateString(name, 50)}</TableCell>
      <TableCell colSpan={3}>
        {description ? truncateString(description, 100) : "-"}
      </TableCell>
      <TableCell colSpan={1} align="center">
        <Tooltip title={<h3>UPLOAD FILES</h3>}>
          <IconButton onClick={handleShowModal}>
            <Badge badgeContent={fileResources.length} color="primary">
              <SvgIcon>
                <FileUpload />
              </SvgIcon>
            </Badge>
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell colSpan={1} align={"right"}>
        {price.toFixed(2)} kr.
      </TableCell>

      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <div
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <FileResourceList resources={fileResources} />
        </div>
      </Modal>
    </TableRow>
  );
};

export default OtherOrderItemRow;
