import {
  FunctionComponent,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import AppStateContext from "../../../../contexts/AppStateContext";
import { Button, TableCell, TableRow, Tooltip } from "@mui/material";
import stylesImg from "../Styles/quotePageStyle.module.css";
import { Info } from "@mui/icons-material";
import { Materials } from "../../../../util/MaterialUtils";
import { ModelDataType } from "../../../../types/RenderTypes";
import { formatter } from "../../../../util/TextUtil";
import {
  apiGetModelInfo,
  apiGetPreviewImage,
} from "../../../../util/network/Products";
import { CartItemType } from "types/CartTypes";
import {
  CNCProductDetailsType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";
import styles from "../Styles/loginPageStyle.module.css";

type PropsType = {
  item: CartItemType;
  totalPrice: number;
  onSave: () => void;
  handleClose: () => void;
};

const CalculatorModal: FunctionComponent<PropsType> = ({
  item,
  totalPrice,
  onSave,
  handleClose,
}) => {
  const [previewImage, setPreviewImage] = useState<string>();
  const [modelInfo, setModelInfo] = useState<ModelDataType>();
  const [pdfExists, setPdfExists] = useState<boolean>();
  const details = {
    ...item.product.details,
    finish: (
      (item.product.details as CNCProductDetailsType)?.finish as any
    ).split(","),
  } as unknown as CNCProductDetailsType;
  const { strings, token, productId } = useContext(AppStateContext);

  useEffect(() => {
    if (productId === 0) return;
    apiGetModelInfo(token, details.modelId).then((res) => setModelInfo(res));
  }, [token, productId]);

  useEffect(() => {
    apiGetPreviewImage(token, details.modelId).then((res) => {
      if (res) {
        setPreviewImage(res);
      }
    });
    if (
      item.product.productType === ProductTypeEnum.CNC ||
      item.product.productType === ProductTypeEnum.SHEET
    ) {
      const tempDetails = details as CNCProductDetailsType;
      if (tempDetails.blueprint) {
        setPdfExists(true);
      } else {
        setPdfExists(false);
      }
    }
  }, [details, strings]);

  useEffect(() => {
    console.log("CalculatorModal re-rendered due to a change in totalPrice");
  }, [totalPrice]);

  return (
    <>
      <h5 className={styles.calcTitle}>
        {strings.ConfSummaryModel?.toUpperCase()}
      </h5>
      <div className={styles.dsplInBlock}>
        {item ? (
          <img
            className={stylesImg.previewImage}
            src={previewImage}
            alt={"Mini"}
          />
        ) : (
          <div className={stylesImg.previewImage} />
        )}
      </div>
      <div className={styles.flexClm}>
        <span className={styles.fontSize90}>Dimensions:</span>
        <span>
          {details.model
            ? details.model.dimX?.toFixed(1) +
              " x " +
              details.model.dimY?.toFixed(1) +
              " x " +
              details.model.dimZ?.toFixed(1)
            : null}
        </span>
      </div>
      <TableRow>
        <h5 className={styles.calcTitle}>Configuration</h5>
      </TableRow>
      <TableRow>
        <TableCell className={styles.borderBottomNone}>Quantity</TableCell>
        <TableCell className={styles.borderBottomNone} align="right">
          {item.quantity} {"pcs"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.borderBottomNone}>Material</TableCell>
        <TableCell className={styles.borderBottomNone} align="right">
          {details?.customMaterial ? (
            <strong>{details.customMaterial.name}</strong>
          ) : details?.standardMaterial ? (
            Materials.getById(details.standardMaterial.name)?.gb
              .materialTitle || "None"
          ) : (
            "Loading..."
          )}
          <br />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.borderBottomNone}>Finish</TableCell>
        <TableCell className={styles.borderBottomNone} align="right">
          {details?.customFinish ? (
            <strong>{details?.customFinish.name}</strong>
          ) : (
            <strong>
              {details?.customFinish
                ? details?.customFinish.name
                : details?.finish?.filter((i) => i !== "standard").join("+") ??
                  "Missing"}
            </strong>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.borderBottomNone}>PDF</TableCell>
        <TableCell align="right" className={styles.borderBottomNone}>
          {item ? (
            <Suspense fallback={<h5>Loading...</h5>}>
              {pdfExists ? (
                <h5>Yes</h5>
              ) : pdfExists === false ? (
                <h5>None</h5>
              ) : (
                <h5>Loading...</h5>
              )}
            </Suspense>
          ) : (
            <h5>No</h5>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.borderBottomNone}>Comment</TableCell>
        <TableCell align="right" className={styles.borderBottomNone}>
          {details && details.comment ? (
            <Tooltip
              title={details.comment ? details.comment : "Ingen kommentar"}
            >
              <Info color={details.comment ? "info" : "disabled"} />
            </Tooltip>
          ) : (
            <Tooltip title={"Ingen kommentar"}>
              <Info color={"disabled"} />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <h5 className={styles.calcTitle}>Prices</h5>
      </TableRow>
      <TableRow>
        <TableCell className={styles.borderBottomNone}>Auto Total</TableCell>
        <TableCell align="right" className={styles.borderBottomNone}>
          {" "}
          <div className={styles.flexClm}>
            <span className={styles.fntWeightBold}>
              {formatter.format(
                (item?.product.priceStandard ?? 0) * (item.quantity ?? 0)
              )}
            </span>
            <span className={styles.fontSize70}>
              {formatter.format(item?.product.priceStandard ?? 0)} / pcs
            </span>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.borderBottomNone}>New Total</TableCell>
        <TableCell align="right" className={styles.borderBottomNone}>
          <div className={styles.flexClm}>
            <span className={styles.fntWeightBold}>
              {formatter.format(totalPrice * (item.quantity ?? 0))}
            </span>
            <span className={styles.fontSize70}>
              {formatter.format(totalPrice)} / pcs
            </span>
          </div>
        </TableCell>
      </TableRow>
      <br />
      <TableRow>
        <TableCell className={styles.borderBottomNone}>
          <div className={styles.flexR}>
            <Button
              variant={"contained"}
              color={"error"}
              size="small"
              className={`${styles.modalCancelBtn} ${styles.width6}`}
              onClick={() => handleClose()}
            >
              {<h5 className={styles.mrgLeft}>Cancel</h5>}
            </Button>
          </div>
        </TableCell>
        <TableCell className={styles.borderBottomNone}>
          <Button
            variant={"contained"}
            color={"primary"}
            size="small"
            className={`${styles.modalAcceptBtn} ${styles.width6}`}
            onClick={onSave}
          >
            {<h5 className={styles.mrgLeft}>Save</h5>}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CalculatorModal;
