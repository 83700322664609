import { TableCell, TableRow, Tooltip } from "@mui/material";
import PreviewButton from "components/Generic/PreviewButton";
import AppStateContext from "contexts/AppStateContext";
import {
  FunctionComponent,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { DoDisturb, Person } from "@mui/icons-material";
import ThreadDisplay from "../../components/OrderView/ThreadDisplay";
import {
  checkColorStep,
  fetchPDFFile,
  fetchSTEPFile,
  fetchThreadFile,
} from "util/FileDownload";
import { dangerColor } from "assets/colors";
import { ReactComponent as PdfIcon } from "../../assets/svg/pdfFile.svg";
import { ReactComponent as StepIcon } from "../../assets/svg/stepFile.svg";
import { ReactComponent as ThreadsIcon } from "../../assets/svg/threadFile.svg";
import { CartItemType } from "types/CartTypes";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
  SheetProductDetailsType,
} from "types/products/ProductCommandsType";
import Popup from "components/ChatBox/Popup";
import { apiGetConversation } from "util/network/Messages";

type PropsType = {
  item: CartItemType;
};

const QuoteConfigRow: FunctionComponent<PropsType> = ({ item }) => {
  const { token, user } = useContext(AppStateContext);

  const [colorStepExist, setColorStepExist] = useState<boolean>();

  const [details, setDetails] = useState<
    CNCProductDetailsType | Print3DProductDetailsType | SheetProductDetailsType
  >();
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  const [isLastMessage, setIsLastMessage] = useState<boolean>(false);
  const handleButtonClickInternal = () => {
    setIsNewMessage(false);
    setShowInternalChat((prev) => !prev);
  };

  useEffect(() => {
    const checkIfNewMessage = () => {
      apiGetConversation(item.product.id + "internal", token)
        .then((res) => {
          if (res.lastMessage) setIsLastMessage(true);
          if (
            res.lastMessage &&
            res.lastMessage?.senderId !== user!.id.toString() &&
            !res.lastMessage?.readBy.includes(user!.id.toString())
          )
            setIsNewMessage(true);
          else setIsNewMessage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    checkIfNewMessage();
  }, [item.product.id, token, showInternalChat]);

  useEffect(() => {
    const det = {
      ...item.product.details,
      finish: (item.product.details as any).finish.split(","),
    } as
      | CNCProductDetailsType
      | Print3DProductDetailsType
      | SheetProductDetailsType;

    setDetails(det);

    checkColorStep(token, det.modelId).then((res) => {
      if (res === 200) {
        setColorStepExist(true);
      } else {
        setColorStepExist(false);
      }
    });
  }, [item, token]);

  const detailsCnc = {
    ...item.product.details,
    finish: (item.product.details as any).finish.split(","),
  } as CNCProductDetailsType;

  return (
    <TableRow key={item.id}>
      <TableCell
        style={{
          padding: 0,
          width: "30px",
        }}
        align={"left"}
      >
        <span style={{ display: "flex", flexDirection: "row" }}>
          <span>
            {details ? (
              <PreviewButton
                productId={item.product.id}
                modelId={details.modelId}
                size={"small"}
              />
            ) : (
              "Intet preview"
            )}
          </span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <span style={{}}>#{item.product.id}</span>
            <span
              style={{
                fontSize: "0.7rem",
              }}
            >
              {item.name}
            </span>
          </span>
        </span>
      </TableCell>
      <TableCell>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "0.7rem",
            width: "5.9rem",
          }}
        >
          {item.product.productType == ProductTypeEnum.CNC ? (
            <>
              <span>
                {detailsCnc.tolerance === 0
                  ? "ISO 2768-medium"
                  : detailsCnc.tolerance === 1
                  ? "ISO 2768-fine"
                  : ""}
              </span>
              <span>
                {detailsCnc.surfaceQuality === 0
                  ? "Standard Ra 1.6"
                  : detailsCnc.surfaceQuality === 1
                  ? "Fine Ra 0.8"
                  : ""}
              </span>
              <span>
                {detailsCnc.materialCertificate == true
                  ? "Certificate"
                  : detailsCnc.materialCertificate == false
                  ? "-"
                  : ""}
              </span>
            </>
          ) : item.product.productType == ProductTypeEnum.SHEET ? (
            detailsCnc.materialCertificate == true ? (
              "Certificate"
            ) : detailsCnc.materialCertificate == false ? (
              "-"
            ) : (
              ""
            )
          ) : null}
        </div>
      </TableCell>
      {/* <TableCell align="left">
        {data ? (
          <Tooltip
            title={<img src={preview} alt={"Preview"} width={"250px"} />}
            placement={"right-start"}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Button
              onClick={() => {
                setProductId(data.modelId);
                setShowThreeDModal(true);
              }}
            >
              {item.name} ({item.product.productType})
            </Button>
          </Tooltip>
        ) : (
          <p>
            {item.name} ({item.product.productType})
          </p>
        )}
      </TableCell> */}
      <TableCell></TableCell>
      <TableCell align="left" style={{ padding: 0 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            {details?.customMaterial?.name ??
              details?.standardMaterial?.name ??
              details?.material}
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            {details?.model
              ? details.model.dimX?.toFixed(1) +
                " x " +
                details.model.dimY?.toFixed(1) +
                " x " +
                details.model.dimZ?.toFixed(1)
              : "Loading..."}
          </span>
        </span>
      </TableCell>
      <TableCell align="left" style={{ padding: 0 }}>
        {details?.customFinish
          ? details.customFinish.name
          : details?.finish && details.finish.length > 1
          ? details.finish.filter((i: string) => i !== "standard").join("+")
          : details?.finish ?? "Loading..."}
      </TableCell>
      <TableCell align="right" style={{ padding: 0 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ paddingRight: "0.5rem" }}>
            {details ? (
              <p
                className={"downloadLink"}
                onClick={() =>
                  colorStepExist
                    ? fetchThreadFile(token, item.product.name, details.modelId)
                    : fetchSTEPFile(token, item.product.name, details.modelId)
                }
              >
                <StepIcon style={{ fill: "#474848", cursor: "pointer" }} />
              </p>
            ) : (
              <StepIcon style={{ fill: "#EBEBEB" }} />
            )}
          </span>
          <span style={{ paddingRight: "0.5rem" }}>
            {details &&
            (item.product.productType == ProductTypeEnum.CNC ||
              item.product.productType == ProductTypeEnum.SHEET) ? (
              <p
                className={"downloadLink"}
                onClick={() =>
                  fetchPDFFile(token, item.product.name, details.productId)
                }
              >
                <Suspense fallback={<p>Loading...</p>}>
                  {(details as CNCProductDetailsType).blueprint ? (
                    <PdfIcon style={{ fill: "#E0A900", cursor: "pointer" }} />
                  ) : (
                    <PdfIcon style={{ fill: "#EBEBEB" }} />
                  )}
                </Suspense>
              </p>
            ) : (
              <DoDisturb sx={{ color: dangerColor[0] }} />
            )}
          </span>
          <span style={{ paddingRight: "0.5rem" }}>
            {details && details.threads ? (
              details.threads?.length > 0 ? (
                <div>
                  <Tooltip
                    title={
                      <div style={{ backgroundColor: "white" }}>
                        <ThreadDisplay specs={details.threads} />
                      </div>
                    }
                  >
                    <ThreadsIcon style={{ fill: "#E0A900" }} />
                  </Tooltip>
                </div>
              ) : (
                <ThreadsIcon style={{ fill: "#EBEBEB" }} />
              )
            ) : (
              "-"
            )}
          </span>
          <Tooltip title={"Internal chat"} onClick={handleButtonClickInternal}>
            <span>
              <Person
                sx={{
                  color:
                    isLastMessage || details?.comment ? "#FFC01F" : "#EBEBEB",
                  cursor: "pointer",
                }}
              />
            </span>
          </Tooltip>
          <div style={{ position: "absolute" }}>
            {showInternalChat && (
              <Popup
                customerComment={details?.comment}
                productId={item.product.id}
                customer={item.product.author!}
                selectedParticipant={"employee"}
                onClose={handleButtonClickInternal}
              />
            )}
          </div>
        </span>
      </TableCell>
      <TableCell align="center" style={{ padding: 0 }}>
        {item.quantity}pcs
      </TableCell>
      <TableCell align="right" style={{ padding: 0, paddingRight: "0.5rem" }}>
        <span>{item.total}kr</span>
        <br />
        <span style={{ fontSize: "0.7rem" }}>{item.pricePerUnit}kr</span>
      </TableCell>
    </TableRow>
  );
};

export default QuoteConfigRow;
