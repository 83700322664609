import React, { CSSProperties, ReactNode, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button, IconButton, Tooltip } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./css/draggableTaskRow.module.css";
import AssignPersonMachine from "pages/project_management/AssignPersonMachine";
import {
  ProductionTaskStateEnum,
  ProductionTaskType,
  ProductionVMType,
} from "types/ProductionType";
import { MachineType } from "../../types/MachineType";
import QuantitySelector from "components/QuantitySelector/QuantitySelector";
import { DraggableProvided } from "react-beautiful-dnd";
import { TaskTemplatesType } from "./DraggableTable";

const stateColors: { [key in ProductionTaskStateEnum]: string } = {
  TODO: "#E7E7E7",
  ACTIVE: "#7FBAB9",
  DONE: "#007574",
};

type RowProps = {
  task: Partial<ProductionTaskType>;
  provided: DraggableProvided;
  index: number;
  isDisabled?: boolean;
  machines?: MachineType[];
  updateTasks: (
    taskId: string,
    updatedTasks: Partial<ProductionTaskType>
  ) => void;
  onDelete: (taskId: string) => void;
  project?: ProductionVMType;
  templates?: TaskTemplatesType;
};

const DraggableTaskRow: React.FC<RowProps> = ({
  task,
  provided,
  index,
  isDisabled,
  machines,
  updateTasks,
  onDelete,
  project,
  templates,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedState, setSelectedState] = useState<ProductionTaskStateEnum>(
    task.state || ProductionTaskStateEnum.TODO
  );
  const [showIncrementForDay, setShowIncrementForDay] = useState(false);
  const [showIncrementForDate, setShowIncrementForDate] = useState(false);
  const [name, setName] = useState<string>(task.name ?? "");
  const [description, setDescription] = useState<string>(
    task.description ?? ""
  );
  const [workTime, setWorkTime] = useState<number>(task.workTime ?? 0);
  const [leadTime, setLeadTime] = useState<number>(task.leadTime ?? 0);
  const [deadline, setDeadline] = useState<string>(task.deadline ?? "");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    if (isDisabled) return;

    const states = Object.keys(stateColors) as ProductionTaskStateEnum[];
    const nextIndex = (states.indexOf(selectedState) + 1) % states.length;
    const nextState = states[nextIndex];
    setSelectedState(nextState);
    handleSelect(nextState);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (newState: ProductionTaskStateEnum) => {
    setSelectedState(newState);
    task.state = newState;

    handleClose();
  };

  const [day, setDay] = useState(0);

  const incrementDay = () => {
    setDay((prevDay) => prevDay + 1);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const incrementDate = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  const styleBasedOnDisabled: CSSProperties = isDisabled
    ? { opacity: 0.8, pointerEvents: "none" as const }
    : {};

  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <TableCell className={styles.pd_0} sx={{ padding: 0 }}>
        <span style={{ color: "#848585" }}>
          <IconButton>
            <ReorderIcon />
          </IconButton>
          <IconButton onClick={() => onDelete(task.id!)}>
            <ClearIcon />
          </IconButton>
        </span>
      </TableCell>

      <TableCell className={styles.pd_0} sx={{ padding: 0 }}>
        <div className={styles.input__container}>
          <Tooltip title={description}>
            <input
              className={styles.input__task}
              placeholder="Name"
              value={description}
              onChange={(e: any) => {
                const newDescription = e.target.value;
                setDescription(newDescription);

                if (task.id)
                  updateTasks(task.id, { description: newDescription });
              }}
            />
          </Tooltip>
          <Tooltip title={name}>
            <select
              className={styles.input__description}
              value={name}
              onChange={(e: any) => {
                const newName = e.target.value;
                setName(newName);
                if (task.id) updateTasks(task.id, { name: newName });
              }}
            >
              {templates !== undefined &&
                Array.from(
                  new Set(
                    Object.values(templates).map((template) => template.name)
                  )
                ).map((uniqueName, index) => {
                  return (
                    <option
                      key={index}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <span>{uniqueName}</span>
                    </option>
                  );
                })}
            </select>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell className={styles.pd_0}>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{
              backgroundColor: stateColors[selectedState],
              color: "#fff",
              borderRadius: "0.125rem",
              padding: "3px 6px",
            }}
          >
            {selectedState}
          </Button>
        </div>
      </TableCell>
      <TableCell
        className={styles.pd_0}
        sx={{ padding: 0 }}
        style={styleBasedOnDisabled}
      >
        <div>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              textTransform: "uppercase",
              paddingLeft: "0.2rem",
              paddingRight: "0.2rem",
            }}
          >
            <AssignPersonMachine
              initTask={task}
              taskId={task.id}
              machines={machines}
              isHover={!isDisabled}
              isModal
            />
          </span>
        </div>
      </TableCell>
      <TableCell className={styles.pd_0} sx={{ padding: 0 }}>
        {task.allowWorkTime ? (
          <>
            {" "}
            <input
              type="number"
              className={styles.input__row}
              placeholder="0"
              value={workTime}
              onChange={(e: any) => {
                const newWorkTime = e.target.value;
                setWorkTime(newWorkTime);
                const workTimeValue =
                  newWorkTime === "" ? undefined : Number(newWorkTime);
                if (task.id) {
                  updateTasks(task.id, { workTime: workTimeValue });
                }
              }}
            />
            <span className={styles.input__row_text}>h</span>
          </>
        ) : null}
      </TableCell>
      <TableCell className={styles.pd_0} sx={{ padding: 0 }}>
        {task.allowLeadTime ? (
          <>
            {" "}
            <input
              type="number"
              className={styles.input__row}
              placeholder="0"
              value={leadTime}
              onFocus={() => setShowIncrementForDay(true)}
              onBlur={() => setShowIncrementForDay(false)}
              onChange={(e: any) => {
                const newLeadTime = e.target.value;
                setLeadTime(newLeadTime);
                const leadTimeValue =
                  newLeadTime === "" ? undefined : Number(newLeadTime);
                if (task.id) {
                  updateTasks(task.id, { leadTime: leadTimeValue });
                }
              }}
            />
            <span className={styles.input__row_text}>days</span>
          </>
        ) : null}{" "}
      </TableCell>
      <TableCell className={styles.pd_0}>
        <input
          type="date"
          value={deadline ? deadline.split("T")[0] : ""}
          onChange={(e: any) => {
            const newDeadline = e.target.value;
            setDeadline(newDeadline);
            if (task.id) {
              updateTasks(task.id, { deadline: newDeadline });
            }
          }}
          style={{ border: "0px", background: "transparent" }}
          onFocus={() => setShowIncrementForDate(true)}
          onBlur={() => setShowIncrementForDate(false)}
        />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        className={styles.pd_0}
        sx={{ padding: 0 }}
      >
        {task.allowProgress && (
          <QuantitySelector
            updateTasks={updateTasks}
            task={task}
            project={project}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default DraggableTaskRow;
