import { FunctionComponent, useEffect, useState } from "react";
import {
  ProductionTaskStateEnum,
  ProductionTaskType,
} from "types/ProductionType";
import styles from "./taskDeadline.module.css";

type PropsType = {
  task: ProductionTaskType;
  updateState?: ProductionTaskStateEnum;
  numberStyle?: boolean;
  letterStyle?: boolean;
};

const TaskDeadline: FunctionComponent<PropsType> = ({
  task,
  updateState,
  numberStyle,
  letterStyle,
}) => {
  const [message, setMessage] = useState("");
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (task.deadline) {
      const deadlineDate = new Date(task.deadline);
      const now = new Date();

      if (!isNaN(deadlineDate.getTime())) {
        const diff = deadlineDate.getTime() - now.getTime();
        const diffDays =
          diff < 0
            ? Math.floor(diff / (1000 * 60 * 60 * 24))
            : Math.ceil(diff / (1000 * 60 * 60 * 24));

        setMessage(`${diffDays}`);

        let style = {};

        if (diffDays === 1 || diffDays === 0 || diffDays < 0) {
          style = { color: "#E1A900", textTransform: "lowercase" };
        } else if (diffDays === 2) {
          style = { color: "#474949", textTransform: "lowercase" };
        }
        setStyle(style);
      }
    }
  }, [task.deadline]);

  return (
    <>
      {updateState !== ProductionTaskStateEnum.DONE && message && (
        <span style={style} className={numberStyle ? styles.number : ""}>
          &nbsp;{message}
          <span className={letterStyle ? styles.letter : ""}>d</span>
        </span>
      )}
    </>
  );
};
export default TaskDeadline;
