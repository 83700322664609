import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styles from "./CompanySearch.module.css";

export type CompanyData = {
  vat: string;
  name: string;
  address: string;
  zipcode: string;
  city: string;
  phone: string;
};

const fetchCompany = async (search: string): Promise<CompanyData> => {
  try {
    const res = await fetch(
      `https://cvrapi.dk/api?search=${search}&country=dk`
    );
    const data = await res.json();
    if (data.error) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    throw error;
  }
};

interface CompanySearchProps {
  onCompanySelect: (company: CompanyData) => void;
}

const CompanySearch: React.FC<CompanySearchProps> = ({ onCompanySelect }) => {
  const [search, setSearch] = useState("");
  const searchResultsRef = useRef<HTMLDivElement | null>(null);

  const { data, isError, error } = useQuery(
    ["company", search],
    ({ queryKey }) => fetchCompany(queryKey[1]),
    {
      enabled: search.length > 2,
    }
  );
  console.log(data, isError, error);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target as Node)
      ) {
        setSearch(""); // reset the search field
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchResultsRef, setSearch]);

  const handleCompanySelect = (company: CompanyData) => {
    onCompanySelect(company);
    setSearch("");
  };

  return (
    <div ref={searchResultsRef} style={{ position: "relative", width: "100%" }}>
      <input
        type="text"
        value={search}
        placeholder="Search for company or CVR (VAT)"
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
      />
      {isError && (
        <p className={styles.errorText}>{(error as Error).message}</p>
      )}
      {!isError && data && (
        <div className={styles.searchResults}>
          <div
            className={styles.company}
            onClick={() => handleCompanySelect(data)}
          >
            <h4>{data.name}</h4>
            <p>VAT: {data.vat}</p>
            <p>Address: {data.address}</p>
            <p>ZIP: {data.zipcode}</p>
            <p>City: {data.city}</p>
            <p>Phone: {data.phone}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanySearch;
