import Modal from "components/Modals/Modal";

type PropsType = {
  open: boolean;
  onClose: () => void;
  onSubmit: (id: number) => void;
  partnerId?: number;
};

const DeletePartnerModal: React.FC<PropsType> = ({
  open,
  onClose,
  onSubmit,
  partnerId,
}) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <div>
        <h2>Are you sure you want to delete partner {partnerId}?</h2>
        <p>This action cannot be undone.</p>
        <button onClick={(e) => onClose()}>Cancel</button>
        <button
          onClick={(e) => {
            if (partnerId) onSubmit(partnerId);
          }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeletePartnerModal;
