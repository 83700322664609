import { useFrame } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import * as stats from 'stats.js';

function Stats() {
  const [stat] = useState(() => new stats.default());
  useEffect(() => {
    stat.showPanel(0);
    document.body.appendChild(stat.dom);
    return () => {
      document.body.removeChild(stat.dom);
    };
  }, [stat]);
  return useFrame(state => {
    stat.begin();
    state.gl.render(state.scene, state.camera);
    stat.end();
  }, 1);
}

export default Stats;
