import { Button, Checkbox } from "@mui/material";
import Modal from "./Modal";
import {
  apiCreateInvoice,
  apiSendOrderEmail,
  apiUpdateOrderStatus,
} from "util/network/Orders";
import React, { useContext, useEffect, useState } from "react";
import AppStateContext from "contexts/AppStateContext";
import { useToast } from "contexts/ToastContext";
import { OrderStatusEnum } from "types/OrderTypes";
import {
  apiGetProductions,
  apiUpdateProduction,
} from "util/network/Productions";
import { ProductionStatusEnum } from "types/ProductionType";
import {
  apiGetRequisitions,
  apiUpdateRequisition,
} from "util/network/Requisitions";
import { RequisitionStatusEnum } from "types/RequisitionTypes";

enum Resources {
  PRODUCTION = 1,
  REQUISITION = 2,
  INVOICE = 3,
  EMAIL = 4,
}

type PropsType = {
  orderId: number;
  status: OrderStatusEnum;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (status: OrderStatusEnum) => void;
};

const OrderStatusModal: React.FC<PropsType> = ({
  orderId,
  status,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { token } = useContext(AppStateContext);
  const { addToast } = useToast();

  const [productionsToUpdate, setProductionsToUpdate] = useState<number[]>([]);
  const [requisitionsToUpdate, setRequisitionsToUpdate] = useState<number[]>(
    []
  );
  const [selectedResources, setSelectedResources] = useState<Resources[]>([
    Resources.PRODUCTION,
    Resources.REQUISITION,
    Resources.INVOICE,
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      apiGetProductions(token, 1, 100, `orderId=${orderId}`).then((res) => {
        setProductionsToUpdate(
          res.items
            .filter((prod) => prod.status == ProductionStatusEnum.TODO)
            .map((prod) => prod.id)
        );
      });
      apiGetRequisitions(
        token,
        1,
        100,
        undefined,
        undefined,
        undefined,
        undefined,
        `orderId=${orderId}`
      ).then((res) => {
        setRequisitionsToUpdate(
          res.items
            .filter(
              (req) =>
                req.state !== RequisitionStatusEnum.CANCELLED &&
                req.state !== RequisitionStatusEnum.RECEIVED
            )
            .map((req) => req.id)
        );
      });
    }
  }, [token, isOpen]);

  const confirmUpdate = () => {
    if (!token) {
      return;
    }
    setIsLoading(true);
    apiUpdateOrderStatus(token, orderId, status)
      .then((res) => {
        addToast({
          type: "success",
          message: (
            <>
              Order status was updated to <strong>{status}</strong>.
            </>
          ),
        });
        if (
          status == OrderStatusEnum.CANCELLED ||
          status == OrderStatusEnum.COMPLETE
        ) {
          if (selectedResources.includes(Resources.PRODUCTION)) {
            Promise.all(
              productionsToUpdate.map((productionId) =>
                apiUpdateProduction(token, productionId, {
                  status:
                    status == OrderStatusEnum.CANCELLED
                      ? ProductionStatusEnum.CANCELLED
                      : ProductionStatusEnum.DONE,
                })
              )
            ).then((res) => {
              addToast({
                type: "success",
                message: (
                  <>
                    Productions were updated to <strong>{status}</strong>.
                  </>
                ),
              });
            });
          }
          if (selectedResources.includes(Resources.REQUISITION)) {
            Promise.all(
              requisitionsToUpdate.map((requisitionId) =>
                apiUpdateRequisition(
                  token,
                  requisitionId,
                  {
                    state:
                      status == OrderStatusEnum.CANCELLED
                        ? RequisitionStatusEnum.CANCELLED
                        : RequisitionStatusEnum.RECEIVED,
                  },
                  false
                )
              )
            ).then((res) => {
              addToast({
                type: "success",
                message: (
                  <>
                    Requisitions were updated to <strong>{status}</strong>.
                  </>
                ),
              });
            });
          }
          if (selectedResources.includes(Resources.INVOICE)) {
            apiCreateInvoice(token, orderId, true).then((res) => {
              if (res.ok)
              {
                addToast({
                  type: "success",
                  message: <>Invoice was created and sent to the customer.</>,
                });
              }
              else 
              {
                addToast({
                  type: "error",
                  message: <>Error during Invoice creation.</>,
                });
              }
              
            });
          }
          if (selectedResources.includes(Resources.EMAIL)) {
            apiSendOrderEmail(token, orderId).then((res) => {
              addToast({
                type: "success",
                message: <>Order complete email was sent to the customer.</>,
              });
            });
          }
        }
        onSubmit(status as OrderStatusEnum);
      })
      .catch((err) => {
        addToast({
          type: "error",
          message: (
            <>
              Failed to update order status to <strong>{status}</strong>.
            </>
          ),
        });
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  const selectableRow = (type: Resources, text: string) => {
    return (
      <tr key={type}>
        <td>{text}</td>
        <td>
          <Checkbox
            checked={selectedResources.includes(type)}
            onChange={() => {
              if (selectedResources.includes(type)) {
                setSelectedResources(
                  selectedResources.filter((t) => t !== type)
                );
              } else {
                setSelectedResources([...selectedResources, type]);
              }
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h1>
        Update order #{orderId} to {status}
      </h1>
      {status == OrderStatusEnum.CANCELLED ||
      status == OrderStatusEnum.COMPLETE ? (
        <>
          <h2>The following resources will be affected from this update:</h2>
          <table>
            <tbody>
              {productionsToUpdate.length > 0 &&
                selectableRow(
                  Resources.PRODUCTION,
                  `Productions (${productionsToUpdate.length}) will be set to ${
                    status == OrderStatusEnum.CANCELLED
                      ? "cancelled"
                      : "complete"
                  }`
                )}
              {requisitionsToUpdate.length > 0 &&
                selectableRow(
                  Resources.REQUISITION,
                  `Requisitions (${
                    requisitionsToUpdate.length
                  }) will be set to ${
                    status == OrderStatusEnum.CANCELLED
                      ? "cancelled"
                      : "received"
                  }`
                )}
              {status == OrderStatusEnum.COMPLETE &&
                selectableRow(
                  Resources.INVOICE,
                  `Create and send invoice to the customer`
                )}
              {status == OrderStatusEnum.COMPLETE &&
                selectableRow(
                  Resources.EMAIL,
                  `Send out order complete email to customer?`
                )}
            </tbody>
          </table>
        </>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <Button variant="contained" color="error" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={confirmUpdate}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default OrderStatusModal;
