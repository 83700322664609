export class FetchError extends Error {
  statusCode: number;
  constructor(message: string, code: number) {
    super(message);
    this.name = "NetworkError";
    this.statusCode = code;
  }
}
export class StockNotAvailableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "StockNotAvailable";
  }
}

export class ModelOverdimensionalError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ModelOverdimensional";
  }
}
