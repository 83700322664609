import React, { useState } from "react";
import { ReactComponent as CAMIcon } from "../../assets/svg/cam.svg";
import { ReactComponent as ReviewIcon } from "../../assets/svg/reviewIcon.svg";
import { ReactComponent as CutIcon } from "../../assets/svg/cutIcon.svg";
import { ReactComponent as DoneIcon } from "../../assets/svg/doneIcon.svg";
import { ReactComponent as MillIcon } from "../../assets/svg/millIcon.svg";
import { ReactComponent as QCIcon } from "../../assets/svg/qcIcon.svg";
import { ReactComponent as ShipIcon } from "../../assets/svg/shipIcon.svg";
import { ReactComponent as TurnIcon } from "../../assets/svg/turnIcon.svg";
import styles from "./css/partStateButton.module.css";
import {
  ProductionTaskEnum,
  ProductionTaskStateEnum,
  ProductionTaskType,
  ProductionVMType,
} from "types/ProductionType";

const icons = {
  [ProductionTaskEnum.CAM]: <CAMIcon />,
  [ProductionTaskEnum.REVIEW]: <ReviewIcon />,
  [ProductionTaskEnum.MILLING]: <MillIcon />,
  [ProductionTaskEnum.TURNING]: <TurnIcon />,
  [ProductionTaskEnum.QUALITYCONTROL]: <QCIcon />,
  [ProductionTaskEnum.MATERIALCUT]: <CutIcon />,
  // add icon
  [ProductionTaskEnum.MAUNALFINISH]: <CAMIcon />,
  [ProductionTaskEnum.SHIPPING]: <ShipIcon />,
  [ProductionTaskEnum.OUTSOURCE]: <CAMIcon />,
  [ProductionTaskEnum.ANODIZE]: <CAMIcon />,
  [ProductionTaskEnum.ELECTROPLISH]: <CAMIcon />,
  [ProductionTaskEnum.BEADBLAST]: <CAMIcon />,
  [ProductionTaskEnum.DONE]: <DoneIcon />,

  // CUT: <CutIcon />,
};

type StatusProps = {
  type: ProductionTaskEnum | undefined;
  state: ProductionTaskStateEnum | undefined;
  task?: ProductionTaskType;
  project: ProductionVMType;
  onChange: (task: Partial<ProductionTaskType>) => void;
  title?: string;
};

const PartStateButton: React.FC<StatusProps> = ({
  type,
  state,
  task,
  project,
  onChange,
  title,
}) => {
  const [updateState, setUpdateState] = useState(task && task.state);

  const getIcon = (state: ProductionTaskStateEnum | undefined) => {
    if (state === ProductionTaskStateEnum.DONE) {
      return <DoneIcon />;
    } else if (type !== undefined) {
      return icons[type] || null;
    }
    return null;
  };

  const getBackgroundColor = (state: ProductionTaskStateEnum | undefined) => {
    if (state === ProductionTaskStateEnum.TODO) {
      return styles.todo;
    } else if (state === ProductionTaskStateEnum.ACTIVE) {
      return styles.active;
    } else if (state === ProductionTaskStateEnum.DONE) {
      return styles.done;
    } else {
      return styles.default;
    }
  };

  return (
    <button
      key={`${task && task.productionId},${task && task.id}`}
      className={`${styles.button} ${getBackgroundColor(updateState)}`}
      onClick={() => {
        let newRev =
          updateState === ProductionTaskStateEnum.DONE
            ? ProductionTaskStateEnum.TODO
            : updateState === ProductionTaskStateEnum.TODO
            ? ProductionTaskStateEnum.ACTIVE
            : ProductionTaskStateEnum.DONE;

        console.log("Button clicked. Updating state to:", newRev);
        setUpdateState(newRev);
        console.log("onChange: ", onChange);
        onChange({
          id: task && task.id,
          state: newRev,
        });
      }}
    >
      <div className={styles.buttonContent}>
        {getIcon(updateState)}
        <span className={styles.title}>{title ?? type}</span>
      </div>
    </button>
  );
};
export default PartStateButton;
