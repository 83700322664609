import { getJobs, getPlans } from "./epg-data";

export const fetchJobsResources = async (date: Date) => {
  const resources = getJobs(date);
  return new Promise((res) => setTimeout(() => res(resources), 500));
};

export const fetchPlansResources = async (date: Date) => {
  const epg = getPlans(date);

  // Check each CNC plan in the epg array and update since and till if they are missing
  (await epg).map((cncPlan) => {
    if (!cncPlan.since || !cncPlan.till || cncPlan.machine == "") {
      cncPlan.since = new Date(new Date().getTime() + 2 * 60 * 1000).toISOString(); // Set since to current time if missing
      const oneHourLater = new Date(new Date().getTime() + 62 * 60 * 1000); // Add 1 hour to current time
      cncPlan.till = oneHourLater.toISOString(); // Set till to current time + 1 hour if missing
      cncPlan.machine = "UNPLANNED"
    }
  });

  return epg;
};
