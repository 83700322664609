import { Dispatch, SetStateAction, useState } from "react";

function useStateWithSessionStorage<S>(
  initialState: S | (() => S),
  key: string
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setInnerState] = useState<S>(() => {
    const sessionStorageState = sessionStorage.getItem(key);

    if (sessionStorageState) {
      try {
        return JSON.parse(sessionStorageState);
      } catch (error) {
        console.log(error);
        sessionStorage.removeItem(key);
        return initialState instanceof Function ? initialState() : initialState;
      }
    } else {
      return initialState instanceof Function ? initialState() : initialState;
    }
  });

  const setState = (value: S | ((prevState: S) => S)) => {
    setInnerState((prevState) => {
      const newState = value instanceof Function ? value(prevState) : value;
      sessionStorage.setItem(key, JSON.stringify(newState));
      return newState;
    });
  };

  return [state, setState];
}

export default useStateWithSessionStorage;
