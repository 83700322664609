import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

type PropsType = {
  initialVal: number;
};
const Timer: FunctionComponent<PropsType> = ({ initialVal }) => {
  const [time, setTime] = useState<number>();
  const [timeDate, setTimeDate] = useState<any>();

  useEffect(() => {
    let start = initialVal;
    let now = dayjs();
    let diff = now.diff(start);

    var upCount = setInterval(counter, 1000);

    function counter() {
      diff = diff + 1000;
      //@ts-ignore
      let diffdate = dayjs.duration(diff).format("DD:HH:mm:ss");
      setTimeDate(diffdate);
    }
    return () => clearInterval(upCount);
  }, [initialVal]);

  return <div>{timeDate}</div>;
};

export default Timer;
