export const theme = {
  primary: {
    600: "#E9F5F3",
    900: "#FFFFFF",
  },
  grey: { 300: "#d1d1d1" },
  white: "#fff",
  teal: {
    100: "#5DDADB",
  },
  green: {
    200: "#389493",
    300: "#2C7A7B",
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "#e1e1e1",
    },
  },
  loader: {
    teal: "#5DDADB",
    purple: "#3437A2",
    pink: "#F78EB6",
    bg: "#171923db",
  },
  gradient: {
    blue: {
      300: "#B8ECE6",
      600: "#B8ECE6",
      900: "#B8ECE6",
    },
  },

  text: {
    grey: {
      300: "#a0aec0",
      500: "#718096",
    },
  },

  timeline: {
    divider: {
      bg: "#718096",
    },
  },
  grid: {
    item: "#7180961a",
    divider: "#7180961a",
    highlight: "#38B2AC",
  },
};
