import MagentoLogin from "./MagentoLogin";
import { Grid } from "@mui/material";

export default function LoginPage() {
  return (
    <Grid
      xs={4}
      sm={8}
      md={12}
      lg={12}
      container
      spacing={0}
      direction="column"
      margin={"20px"}
      maxWidth={"100vh"}
      justifyContent="center"
      alignContent={"center"}
      flexGrow={1}
    >
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        lg={12}
        display="flex"
        justifyContent="center"
      >
        <MagentoLogin />
      </Grid>
    </Grid>
  );
}
