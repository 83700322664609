import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "../../pages/project_management/css/assignPersonMachine.module.css";
import InitialsCircle from "components/InitalsCircle/InitialsCircle";
import AppStateContext from "contexts/AppStateContext";
import { AccessEnum } from "types/users/UserTypes";
import { Tooltip } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { apiUpdateCartInfo } from "util/network/Carts";
import { SimpleCartType } from "types/CartTypes";
import { ProductionVMType, SimpleProductionVMType } from "types/ProductionType";

type PropsType = {
  isModal?: boolean;
  bannedEmails: string[];
  quote?: SimpleCartType;
  project?: ProductionVMType;
};

const AssignPerson: FunctionComponent<PropsType> = ({
  isModal,
  quote,
  bannedEmails,
  project,
}) => {
  const { employees, token } = useContext(AppStateContext);
  const [assignedUser, setAssignedUser] = useState<number | null>(null);
  const [isHoveredPerson, setIsHoveredPerson] = useState(false);
  const operators = employees.items?.filter(
    (employee) =>
      employee.accessLevel >= AccessEnum.OPERATOR &&
      !bannedEmails.includes(employee.email)
  );
  const personButtonRef = useRef<HTMLButtonElement>(null);
  const [dropdownPersonPosition, setDropdownPersonPosition] = useState({
    top: 0,
    left: 0,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleAssign = async (userId: number | null) => {
    if (quote) {
      if (!token || !quote.id) {
        return;
      }

      apiUpdateCartInfo(token, quote.id, {
        responsibleId: userId,
      })
        .then((res) => {
          if (res) {
            setAssignedUser(res?.responsibleId ?? null);
          }
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    }
  };

  const user = operators.find((employee) => employee.id === assignedUser);

  const getInitials = (email: string) => {
    return email ? email.substring(0, 3).toUpperCase() : "";
  };

  const removeUser = (userId: number | null) => {
    if (quote) {
      if (assignedUser === userId && token && quote.id) {
        setAssignedUser(null);
        apiUpdateCartInfo(token, quote.id, {
          responsibleId: null,
        }).then((res) => {
          if (res) {
            setAssignedUser(res?.responsibleId ?? null);
          }
        });
      }
      console.log("Removing user with ID:", userId);
    }
  };

  const toggleItem = (type: "user", id: number | string) => {
    if (type === "user") {
      setAssignedUser((prev) => (prev === id ? null : (id as number)));
      handleAssign(id as number);
    }
  };

  const closeDropdown = (e: any) => {
    setDropdownOpen(false);
    e.stopPropagation();
  };

  useEffect(() => {
    if (quote) setAssignedUser(quote.responsibleId ?? null);
  }, [quote?.responsibleId]);

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.container}`}>
          <div
            className={`${assignedUser ? styles.has_users : ""} ${
              styles.container__initials
            }`}
            onMouseEnter={() => setIsHoveredPerson(true)}
            onMouseLeave={() => setIsHoveredPerson(false)}
          >
            <span>
              {user && (
                <InitialsCircle
                  key={user.id}
                  initials={getInitials(user.email)}
                  onClick={(e) => {
                    removeUser(user.id);
                    e.stopPropagation();
                  }}
                />
              )}
            </span>
          </div>{" "}
        </div>
        <div className={`${styles.container}`}>
          <button
            ref={personButtonRef}
            onClick={(e) => {
              if (personButtonRef.current) {
                const rect = personButtonRef.current.getBoundingClientRect();
                setDropdownPersonPosition({
                  top: rect.bottom + window.scrollY,
                  left: rect.left + window.scrollX,
                });
              }
              setDropdownOpen(!dropdownOpen);
              e.stopPropagation();
            }}
            onMouseEnter={() => setIsHoveredPerson(true)}
            onMouseLeave={() => setIsHoveredPerson(false)}
            className={`${styles.button_icon}`}
          >
            <Tooltip title={"Add employee"}>
              <PersonAdd
                className={isHoveredPerson ? styles.icon_hover : styles.icon}
              />
            </Tooltip>
          </button>
        </div>
      </div>
      {dropdownOpen && (
        <div
          className={styles.dropdown_container}
          style={{
            display: dropdownOpen ? "block" : "none",
            top: isModal ? "" : `${dropdownPersonPosition.top}px`,
            left: isModal ? "" : `${dropdownPersonPosition.left}px`,
          }}
        >
          {operators?.map((employee) => (
            <div
              key={employee.id}
              onClick={(e) => {
                toggleItem("user", employee.id);
                if (!e.shiftKey) {
                  setDropdownOpen(false);
                }
                e.stopPropagation();
              }}
              className={`${styles.dropdown_item}`}
            >
              <span className={`${styles.dropdown__item}`}>
                {employee.firstName} {employee.lastName}
              </span>
            </div>
          ))}
        </div>
      )}
      {dropdownOpen && (
        <div className={styles.overlay} onClick={closeDropdown} />
      )}
    </>
  );
};
export default AssignPerson;
