import * as React from "react";
import { FunctionComponent } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type PropsType = {
  isOpen: boolean;
  setIsOpen: Function;
  onAccept: Function;
  onCancel: Function;
};

const SaveBillingAddressDialog: FunctionComponent<PropsType> = ({
  isOpen,
  setIsOpen,
  onAccept,
  onCancel,
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ position: "auto", zIndex: 10000 }}
      >
        <DialogTitle>
          {"Would you like to save your billing address?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click agree, the new billing information will be updated in
            your profile and used for your next order.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose();
              onCancel();
            }}
          >
            Disagree
          </Button>
          <Button
            onClick={(e) => {
              handleClose();
              onAccept();
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SaveBillingAddressDialog;
