import { Close } from "@mui/icons-material";
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import { FunctionComponent } from "react";

type PropsType = {
  errorNames: string[];
  open: boolean;
  setErrorNames: Function;
  setOpen: Function;
};
const ErrorCollapse: FunctionComponent<PropsType> = ({
  errorNames,
  setErrorNames,
  open,
  setOpen,
}) => {
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: "5em",
        right: "1em",
        zIndex: 1000,
      }}
    >
      <Collapse in={open} timeout={"auto"}>
        {errorNames.map((err: string, index: number) => {
          return (
            <div style={{ marginBottom: "0.5em" }} key={index}>
              <Alert
                onClick={() => {
                  setOpen(false);
                  setErrorNames(errorNames.filter((_, i) => i !== index));
                }}
                sx={{
                  border: 0.2,
                  borderRadius: 4,
                  borderColor: "inherit",
                }}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setErrorNames(errorNames.filter((_, i) => i !== index));
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
              >
                <AlertTitle>Fejl</AlertTitle>
                {err}
              </Alert>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
};

export default ErrorCollapse;
