import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
//@ts-ignore
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Dialog, IconButton, Typography } from "@mui/material";
import { primaryColor } from "../../assets/colors";
import Modal from "components/Modals/Modal";
import "./quoteManagement.css";
import { formatter } from "../../util/formatter";
import { reformatDate } from "../../util/formatter";
import { AltRoute, Cancel, Check } from "@mui/icons-material";
import AppStateContext from "../../contexts/AppStateContext";
import { useHistory } from "react-router";
import { ProductTypeEnum } from "types/products/ProductCommandsType";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as OpenOrderIcon } from "../../assets/svg/openOrder.svg";
import {
  CartItemType,
  CartStatusEnum,
  CartType,
  SimpleCartType,
} from "types/CartTypes";
import QuoteConfigRow from "./QuoteConfigRow";
import OtherCartItemRow from "./OtherCartItemRow";
import { apiGetCart } from "util/network/Carts";
import RequisitionComponent from "components/Requisition/RequisitionComponent";
import { ReactComponent as PickupIcon } from "../../assets/svg/pickup.svg";
import { ReactComponent as GlsIcon } from "../../assets/svg/glslogo.svg";
import { ReactComponent as PostNordIcon } from "../../assets/svg/postnord.svg";
import CopyPhone from "components/Copy/CopyPhone";
import CopyEmail from "components/Copy/CopyEmail";
import { UserType } from "types/users/UserTypes";
import QuoteStatusButton from "./QuoteStatusButton";
import AssignPerson from "components/AssignPerson/AssignPerson";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { ReactComponent as CommentIcon } from "../../assets/svg/commentOrder.svg";
import { ReactComponent as CommentIconFilled } from "../../assets/svg/commentOrderFilled.svg";

type PropsType = {
  quote: SimpleCartType;
  refresh?: Function;
};
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));
const QuoteRow: FunctionComponent<PropsType> = ({ quote, refresh }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { token } = useContext(AppStateContext);
  const [confirmModalLabel, setConfirmModalLabel] = useState<string>("");
  const [confirmModalAction, setConfirmModalAction] = useState<Function>();
  const [author, setAuthor] = useState<UserType>();
  const [items, setItems] = useState<CartItemType[]>([]);
  const [reqProjects, setReqProjects] = useState<CartType | null>(null);
  const [status, setStatus] = useState<CartStatusEnum>(quote.status);
  const [isCellHovered, setIsCellHovered] = useState<boolean>(false);
  const [internalComment, setInternalComment] = useState<string>(
    quote.internalComment
  );
  const bannedEmails = [
    "can@easypartz.com",
    "baa@easypartz.com",
    "elb@easypartz.com",
    "spa@easypartz.com",
    "jeb@easypartz.com",
    "jma@easypartz.com",
    "kpo@easypartz.com",
    "lpn@easypartz.com",
    "mni@easypartz.com",
    "info@easypartz.com",
    "usr2@vaerks.com",
    "admin@vaerks.com",
    "machine@vaerks.com",
    "machine1@easypartz.com",
    "machine2@easypartz.com",
    "machine3@easypartz.com",
    "machine4@easypartz.com",
  ];

  useEffect(() => {
    if (open && items.length === 0) {
      apiGetCart(token, quote.id).then((res) => {
        setItems(res.items);
        setAuthor(res.author);
      });
    }
  }, [open, token, quote.id]);

  useEffect(() => {
    //if (orderRow?.total_invoiced) setInvoiceExist(true);

    if (quote?.id) {
      const alreadyOpen = sessionStorage.getItem("OpenedQuote");
      if (alreadyOpen) {
        if (parseInt(alreadyOpen) === quote.id) {
          setOpen(true);
        }
      }
    }
  }, [quote, token]);

  const cncTableContent = useMemo(() => {
    const cncItems = items.filter(
      (i) => i.product.productType == ProductTypeEnum.CNC
    );
    if (cncItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                CNC
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cncItems.map((item, index) => {
            return <QuoteConfigRow item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const print3dTableContent = useMemo(() => {
    const printItems = items.filter(
      (i) => i.product.productType == ProductTypeEnum.PRINT3D
    );
    if (printItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Print3D
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printItems.map((item, index) => {
            return <QuoteConfigRow item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const sheetTableContent = useMemo(() => {
    const sheetItems = items.filter(
      (i) => i.product.productType === ProductTypeEnum.SHEET
    );
    if (sheetItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Sheet Metal
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sheetItems.map((item, index) => {
            return <QuoteConfigRow item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const otherTableContent = useMemo(() => {
    const otherItems = items.filter(
      (i) => i.product.productType == ProductTypeEnum.OTHER
    );
    if (otherItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Other
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otherItems.map((item: CartItemType, index: number) => {
            return <OtherCartItemRow key={index} item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const renderItemCount = (count: number, label: string) => {
    if (count > 0) {
      return (
        <span style={{ marginRight: "0.5rem" }}>
          {count} {label}
        </span>
      );
    }
    return null;
  };

  const closeRequisition = () => {
    setReqProjects(null);
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        open={reqProjects ? true : false}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            closeRequisition();
          }
        }}
      >
        {reqProjects ? (
          <RequisitionComponent
            prodObjIn={reqProjects}
            closeRequisition={closeRequisition}
            prodType="cart"
          ></RequisitionComponent>
        ) : null}
      </Dialog>
      <Modal
        isOpen={confirmModalAction !== undefined}
        onClose={() => setConfirmModalAction(undefined)}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Confirm
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {confirmModalLabel}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1em",
          }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{ margin: "0 1em 0 0", height: "40px", width: "8em" }}
            onClick={() => {
              setConfirmModalAction(undefined);
            }}
          >
            <Cancel /> {<h5 style={{ marginLeft: ".5em" }}>No</h5>}
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ margin: "0 0 0 1em", height: "40px", width: "8em" }}
            onClick={() => {
              confirmModalAction && confirmModalAction();
              setConfirmModalAction(undefined);
            }}
          >
            <Check /> {<h5 style={{ marginLeft: ".5em" }}>Yes</h5>}
          </Button>
        </Box>
      </Modal>
      <TableRow
        sx={{
          background:
            open && quote.status === CartStatusEnum.LOCKED
              ? "rgba(224, 169, 0, 0.1)"
              : open && quote.status === CartStatusEnum.DRAFT
              ? "rgba(71, 72, 72, 0.1)"
              : open && quote.status === CartStatusEnum.REVIEW
              ? "rgba(0, 116, 116, 0.1)"
              : open && quote.status === CartStatusEnum.DELETED
              ? "rgba(71, 72, 72, 0.1)"
              : "#fff",
        }}
        className={"orderRow"}
      >
        <TableCell align={"left"} onClick={() => setOpen((open) => !open)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <QuoteStatusButton
              currentStatus={status}
              message={internalComment}
              entityID={quote.id}
              onChange={(cart?: CartType) => {
                if (cart) {
                  setStatus(cart.status);
                  setInternalComment(cart.internalComment);
                } else {
                  refresh && refresh();
                }
              }}
            />
            <span style={{ paddingLeft: "0.5rem" }}>
              {internalComment && (
                <LightTooltip title={internalComment}>
                  <StickyNote2Icon style={{ color: "var(--mainYellow)" }} />
                </LightTooltip>
              )}
            </span>
          </div>
        </TableCell>
        <TableCell align="right" onClick={() => setOpen((open) => !open)}>
          <span style={{ display: "flex", flexDirection: "row" }}>
            <span>
              <LightTooltip title="More..">
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("OpenedQuote", quote.id.toString());
                    history.push(`/quotes/${quote.id}`);
                  }}
                  color={"primary"}
                  size="small"
                >
                  <Typography fontWeight={"bold"}>
                    <OpenOrderIcon />
                  </Typography>
                </IconButton>
              </LightTooltip>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "0.2rem",
                paddingRight: "0.5rem",
              }}
            >
              {!quote?.message ? (
                <CommentIcon />
              ) : (
                <LightTooltip title={quote?.message}>
                  <CommentIconFilled />
                </LightTooltip>
              )}
            </span>
            <span
              style={{
                padding: "0",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span style={{ padding: 0, display: "flex" }}>
                <span>#{quote.id}</span>
              </span>
              <span style={{ fontSize: "0.7rem", padding: "0" }}>
                {reformatDate(quote.modified ?? quote.created)}
              </span>
            </span>
          </span>
        </TableCell>
        <TableCell onClick={() => setOpen((open) => !open)}>
          <span style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{ lineHeight: "0", display: "flex", alignItems: "center" }}
            >
              {quote.billingAddress?.phone ? (
                <span
                  style={{
                    paddingRight: "0.5rem",
                    lineHeight: "0",
                  }}
                  onClick={handleClick}
                >
                  <CopyPhone phone={quote.billingAddress?.phone} />
                </span>
              ) : (
                <p style={{ paddingRight: "0.9rem" }}>-</p>
              )}

              <span
                style={{ paddingRight: "0.5rem", lineHeight: "0" }}
                onClick={handleClick}
              >
                {quote.billingAddress?.email ? (
                  <CopyEmail
                    email={quote.billingAddress?.email}
                    subject={`Tilbud: #${quote.id}`}
                  />
                ) : (
                  <p style={{ paddingRight: "0.9rem" }}>-</p>
                )}
              </span>
            </span>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span>{quote.authorName}</span>
              <span style={{ fontSize: "0.7rem" }}>
                {quote.billingAddress?.company}
              </span>
            </span>
          </span>
        </TableCell>
        <TableCell onClick={() => setOpen((open) => !open)}>
          <span style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span>
                {quote.modified_by ? quote.modified_by : quote.created_by}
              </span>
            </span>
          </span>
        </TableCell>
        <TableCell align="center" onClick={() => setOpen((open) => !open)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span style={{ paddingRight: "0.5rem" }}>
              {quote && quote.shippingMethod ? (
                quote.shippingMethod === "pickup" ? (
                  <Tooltip title="Pickup">
                    <PickupIcon />
                  </Tooltip>
                ) : quote.shippingMethod === "tnt_before_12" ? (
                  <Tooltip title="GLS">
                    <GlsIcon />
                  </Tooltip>
                ) : quote.shippingMethod.startsWith("postnord") ? (
                  <Tooltip title="PostNord">
                    <PostNordIcon />
                  </Tooltip>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "0.7rem",
                }}
              >{`${quote.leadTimeOption} ${quote.leadTimeDays}wd`}</span>
            </span>
          </div>
        </TableCell>
        <TableCell onClick={() => setOpen((open) => !open)}>
          <span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ paddingRight: "0.5rem" }}>
                {formatter.format(quote?.total ?? 0)}
              </span>
              <span style={{ fontSize: "0.7rem" }}>
                {renderItemCount(quote.itemSummary.cnc, "CNC")}
                {renderItemCount(quote.itemSummary.print3d, "3D")}
                {renderItemCount(quote.itemSummary.sheet, "Sheet")}
                {renderItemCount(quote.itemSummary.other, "Other")}
              </span>
            </div>
          </span>
        </TableCell>
        <TableCell
          onMouseEnter={() => setIsCellHovered(true)}
          onMouseLeave={() => setIsCellHovered(false)}
          style={{ width: "8rem" }}
          align="right"
        >
          <AssignPerson quote={quote} bannedEmails={bannedEmails} />
        </TableCell>
        <TableCell>
          <div style={{ float: "right" }}>
            <IconButton>
              <LightTooltip
                onClick={() => {
                  if (items && author) {
                    setReqProjects({ ...quote, items: items, author: author });
                  } else {
                    apiGetCart(token, quote.id).then((res) => {
                      setItems(res.items);
                      setAuthor(res.author);
                      setReqProjects({
                        ...quote,
                        items: res.items,
                        author: res.author,
                      });
                    });
                  }
                }}
                title="Outsource"
              >
                <AltRoute color="info"></AltRoute>
              </LightTooltip>
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > *": {
            background: open ? "WhiteSmoke" : "",
            borderRadius: open ? "0 0 5px 5px" : "",
            borderBottom: open ? "2px solid" + primaryColor[0] : "",
            borderLeft: open ? "2px solid" + primaryColor[0] : "",
            borderRight: open ? "2px solid" + primaryColor[0] : "",
          },
        }}
      >
        <TableCell colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                {cncTableContent}
                {print3dTableContent}
                {sheetTableContent}
                {otherTableContent}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default QuoteRow;
