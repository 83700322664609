import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useEffect,
  useContext,
  FunctionComponent,
  useState,
  useMemo,
} from "react";
import { apiGetManufacturedProductCalculationValues } from "util/network/Products";
import AppStateContext from "contexts/AppStateContext";
import { CartItemType } from "types/CartTypes";
import { CNCProductDetailsType } from "types/products/ProductCommandsType";
import styles from "./css/calculateRow.module.css";
import { formatter } from "../../../../util/TextUtil";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";

type PropsType = {
  item: CartItemType;
  onPriceChange: (newPrice: number) => void;
};

const CalculatorRow: FunctionComponent<PropsType> = ({
  item,
  onPriceChange,
}) => {
  const { strings, token } = useContext(AppStateContext);
  const details: CNCProductDetailsType = item?.product
    .details as CNCProductDetailsType;
  const [calculationValues, setCalculationValues] = useState<any>();

  // machineRate
  const [machineRate, setMachineRate] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentMachineRate`
  );
  const [prevMachineRate, setPrevMachineRate] = useState<number>(0);

  // operatorRate
  const [operatorRate, setOperatorRate] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentOperator`
  );
  const [prevOperatorRate, setPrevOperatorRate] = useState<number>(0);

  // programerRate
  const [programmerRate, setProgrammerRate] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentProgrammerRate`
  );
  const [prevProgrammerRate, setPrevProgrammerRate] = useState<number>(0);

  // cam
  const [cam, setCam] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentCam`
  );
  const [prevCam, setPrevCam] = useState<number>(0);

  // customSetup
  const [customSetup, setCustomSetup] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentSetup`
  );
  const [prevCustomSetup, setPrevCustomSetup] = useState<number>(0);

  // tools
  const [tools, setTools] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentTools`
  );
  const [prevTools, setPrevTools] = useState<number>(0);

  // width
  const [width, setWidth] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentWidth`
  );
  const [prevWidth, setPrevWidth] = useState<number>(0);

  // length
  const [length, setLength] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentLength`
  );
  const [prevLength, setPrevLength] = useState<number>(0);

  // height
  const [height, setHeight] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentHeight`
  );
  const [prevHeight, setPrevHeight] = useState<number>(0);

  // density
  const [density, setDensity] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentDensity`
  );
  const [prevDensity, setPrevDensity] = useState<number>(0);

  // materialPrice
  const [materialPrice, setMaterialPrice] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentMaterialPrice`
  );
  const [prevMaterialPrice, setPrevMaterialPrice] = useState<number>(0);

  // prodStart
  const [prodStart, setProdStart] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentProdStart`
  );
  const [prevProdStart, setPrevProdStart] = useState<number>(0);

  // setup
  const [setup, setSetup] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentSetup`
  );
  const [prevSetup, setPrevSetup] = useState<number>(0);

  // machining
  const [machining, setMachining] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentMachining`
  );
  const [prevMachining, setPrevMachining] = useState<number>(0);

  // operation
  const [operation, setOperation] = useStateWithLocalStorage<number>(
    0,
    `${item.product.id}currentOperation`
  );
  const [prevOperation, setPrevOperation] = useState<number>(0);

  const [finishLeadtime, setFinishLeadtime] = useState<number>(0);
  const [finishPrice, setFinishPrice] = useState<number>(0);
  const [prevFinishPrice, setPrevFinishPrice] = useState<number>(0);

  const storageKeysAndSetters = [
    {
      key: `${item.product.id}prevProgrammerRate`,
      setter: setPrevProgrammerRate,
    },
    {
      key: `${item.product.id}prevOperatorRate`,
      setter: setPrevOperatorRate,
    },
    { key: `${item.product.id}prevMachineRate`, setter: setPrevMachineRate },

    { key: `${item.product.id}prevCam`, setter: setPrevCam },
    { key: `${item.product.id}prevCustomSetup`, setter: setPrevCustomSetup },
    { key: `${item.product.id}prevTools`, setter: setPrevTools },

    { key: `${item.product.id}prevWidth`, setter: setPrevWidth },
    { key: `${item.product.id}prevLength`, setter: setPrevLength },
    { key: `${item.product.id}prevHeight`, setter: setPrevHeight },
    { key: `${item.product.id}prevDensity`, setter: setPrevDensity },
    {
      key: `${item.product.id}prevMaterialPrice`,
      setter: setPrevMaterialPrice,
    },

    { key: `${item.product.id}prevProdStart`, setter: setPrevProdStart },
    { key: `${item.product.id}prevSetup`, setter: setPrevSetup },
    { key: `${item.product.id}prevMachining`, setter: setPrevMachining },
    { key: `${item.product.id}prevOperation`, setter: setPrevOperation },

    { key: `${item.product.id}prevFinishPrice`, setter: setPrevFinishPrice },
  ];

  useEffect(() => {
    storageKeysAndSetters.forEach(({ key, setter }) => {
      const prevValue = localStorage.getItem(key);
      if (prevValue !== null) {
        setter(Number(prevValue));
      }
    });
  }, []);

  const handleInputChange = (
    currentState: number,
    setPrevState: React.Dispatch<React.SetStateAction<number>>,
    setCurrentState: React.Dispatch<React.SetStateAction<number>>,
    localStorageKey: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(e.target.value);
    setPrevState(currentState);
    localStorage.setItem(localStorageKey, currentState.toString());
    setCurrentState(value);
  };

  const handleProgrammerChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      programmerRate,
      setPrevProgrammerRate,
      setProgrammerRate,
      `${item.product.id}prevProgrammerRate`,
      e
    );
  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      operatorRate,
      setPrevOperatorRate,
      setOperatorRate,
      `${item.product.id}prevOperatorRate`,
      e
    );

  const handleMachineChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      machineRate,
      setPrevMachineRate,
      setMachineRate,
      `${item.product.id}prevMachineRate`,
      e
    );

  const handleCamChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(cam, setPrevCam, setCam, `${item.product.id}prevCam`, e);

  const handleCustomSetupChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      customSetup,
      setPrevCustomSetup,
      setCustomSetup,
      `${item.product.id}prevCustomSetup`,
      e
    );

  const handleToolsChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      tools,
      setPrevTools,
      setTools,
      `${item.product.id}prevTools`,
      e
    );

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      width,
      setPrevWidth,
      setWidth,
      `${item.product.id}prevWidth`,
      e
    );

  const handleLengthChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      length,
      setPrevLength,
      setLength,
      `${item.product.id}prevLength`,
      e
    );

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      height,
      setPrevHeight,
      setHeight,
      `${item.product.id}prevHeight`,
      e
    );

  const handleDensityChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      density,
      setPrevDensity,
      setDensity,
      `${item.product.id}prevDensity`,
      e
    );

  const handleMaterialPriceChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      materialPrice,
      setPrevMaterialPrice,
      setMaterialPrice,
      `${item.product.id}prevMaterialPrice`,
      e
    );

  const handleProdStartChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      prodStart,
      setPrevProdStart,
      setProdStart,
      `${item.product.id}prevProdStart`,
      e
    );

  const handleSetupChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      setup,
      setPrevSetup,
      setSetup,
      `${item.product.id}prevSetup`,
      e
    );

  const handleMachiningChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      machining,
      setPrevMachining,
      setMachining,
      `${item.product.id}prevMachining`,
      e
    );

  const handleOperationChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      operation,
      setPrevOperation,
      setOperation,
      `${item.product.id}prevOperation`,
      e
    );

  const handleFinishChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(
      finishPrice,
      setPrevFinishPrice,
      setFinishPrice,
      `${item.product.id}prevFinishPrice`,
      e
    );

  useEffect(() => {
    apiGetManufacturedProductCalculationValues(token, item.product.id).then(
      (res) => {
        if (!res || !res.work || !res.material || !res.finish) {
          return;
        }
        setCalculationValues(res);

        setProgrammerRate((old) =>
          old != 0
            ? old
            : (res?.work.times.programmer.price /
                res?.work.times.programmer.time) *
              60
        );
        setOperatorRate((old) =>
          old != 0
            ? old
            : (res?.work.times.operator.price / res?.work.times.operator.time) *
              60
        );
        setMachineRate((old) =>
          old != 0
            ? old
            : (res?.work.times.machine3axis.price /
                res?.work.times.machine3axis.time) *
              60
        );

        setCam((old) =>
          old != 0 ? old : res.work.tasks.cam.time + res.work.tasks.review.time
        );
        setWidth((old) => (old != 0 ? old : res.material.y));
        setLength((old) => (old != 0 ? old : res.material.x));
        setHeight((old) => (old != 0 ? old : res.material.z));
        setDensity((old) => (old != 0 ? old : res.material.material.density));

        setFinishLeadtime((old) => (old != 0 ? old : res.finish.leadtime));
        setFinishPrice((old) => (old != 0 ? old : res.finish.price));
        setMaterialPrice((old) => (old != 0 ? old : res.material.price));
        setProdStart((old) =>
          old != 0 ? old : res.work.tasks.cam.time + res.work.tasks.review.time
        );
        setSetup((old) => (old != 0 ? old : res.work.tasks.setup.time));
        setMachining((old) => (old != 0 ? old : res.work.tasks.machining.time));
        setOperation((old) => (old != 0 ? old : res.work.tasks.operation.time));
      }
    );
  }, [item.product.id]);

  // console.log("value: " + calculationValues?.material.x);
  const WeightTotal =
    (width / 1000) * (length / 1000) * (height / 1000) * density;

  const MachineTotal = useMemo(() => {
    const priceProduction = prodStart * (machineRate / 60);
    const priceSetup = setup * (machineRate / 60);
    const priceMachining = machining * (machineRate / 60);
    const priceOperation = operation * (machineRate / 60);

    return priceProduction + priceMachining + priceOperation + priceSetup;
  }, [prodStart, setup, machining, operation]);

  const PreparationTotal = (programmerRate / 60) * cam + customSetup + tools;

  const MaterialPriceTotal = materialPrice * WeightTotal;

  const NewTotal = () => {
    const total = parseFloat(
      (
        PreparationTotal / item.quantity +
        MachineTotal / item.quantity +
        MaterialPriceTotal +
        // materialPrice +
        finishPrice
      ).toFixed(2)
    );
    return total;
  };

  console.log("New value: " + NewTotal());

  useEffect(() => {
    const total = NewTotal();
    onPriceChange(total);
  }, [
    MaterialPriceTotal,
    finishPrice,
    onPriceChange,
    PreparationTotal,
    MachineTotal,
  ]);

  return (
    <div>
      {/* Rates */}
      <h5 className={`${styles.title}`}>{"Rates"}</h5>
      <div
      // className={`${styles.container}`}
      >
        <div className={`${styles.container}`}>
          <div className={`${styles.container__content}`}>
            <span className={`${styles.container__title}`}>Programmer</span>
            <div className={`${styles.content}`}>
              <TextField
                sx={{ backgroundColor: "white", width: "90px" }}
                size="small"
                value={programmerRate?.toFixed(0)}
                type="number"
                onChange={handleProgrammerChange}
              />
            </div>
          </div>
          {!prevProgrammerRate ? (
            <span className={`${styles.units}`}>kr / hour</span>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ paddingLeft: "0.3rem" }}>kr / hour</span>
              <Tooltip title="Previous value">
                <span
                  style={{
                    paddingLeft: "0.3rem",
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                  }}
                >
                  {prevProgrammerRate.toFixed(0)} kr / hour
                </span>
              </Tooltip>
            </div>
          )}
        </div>

        <div className={`${styles.container}`}>
          <div className={`${styles.container__content}`}>
            <span className={`${styles.container__title}`}>Operator</span>
            <div className={`${styles.content}`}>
              <TextField
                sx={{ backgroundColor: "white", width: "90px" }}
                size="small"
                type="number"
                value={operatorRate?.toFixed(0)}
                onChange={handleOperatorChange}
              />
            </div>
          </div>
          {!prevOperatorRate ? (
            <span className={`${styles.units}`}>kr / hour</span>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ paddingLeft: "0.3rem" }}>kr / hour</span>
              <Tooltip title="Previous value">
                <span
                  style={{
                    paddingLeft: "0.3rem",
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                  }}
                >
                  {prevOperatorRate.toFixed(0)} kr / hour
                </span>
              </Tooltip>
            </div>
          )}
        </div>

        <div className={`${styles.container}`}>
          <div className={`${styles.container__content}`}>
            <span className={`${styles.container__title}`}>Machine</span>
            <div className={`${styles.content}`}>
              <TextField
                sx={{ backgroundColor: "white", width: "90px" }}
                size="small"
                value={machineRate?.toFixed(0)}
                type="number"
                onChange={handleMachineChange}
              />
            </div>
          </div>
          {!prevMachineRate ? (
            <span className={`${styles.units}`}>kr / hour</span>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ paddingLeft: "0.3rem" }}>kr / hour</span>
              <Tooltip title="Previous value">
                <span
                  style={{
                    paddingLeft: "0.3rem",
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                  }}
                >
                  {prevMachineRate.toFixed(0)} kr / hour
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      {/*Preparation*/}
      <h5 className={`${styles.title}`}>{"Preparation"}</h5>
      {/*CAM*/}
      <div>
        <div className={`${styles.container}`}>
          <div className={`${styles.container__content}`}>
            <span className={`${styles.container__title}`}>CAM</span>
            <div className={`${styles.content}`}>
              <TextField
                sx={{ backgroundColor: "white", width: "90px" }}
                size="small"
                type="number"
                value={cam.toFixed(0)}
                onChange={handleCamChange}
              />{" "}
            </div>
          </div>
          {!prevCam ? (
            <span className={`${styles.units}`}>min</span>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ paddingLeft: "0.3rem" }}>min</span>
              <Tooltip title="Previous value">
                <span
                  style={{
                    paddingLeft: "0.3rem",
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                  }}
                >
                  {prevCam.toFixed(0)} min
                </span>
              </Tooltip>
            </div>
          )}
        </div>

        {/*Custom Setup*/}

        <div className={`${styles.container}`}>
          <div className={`${styles.container__content}`}>
            <span className={`${styles.container__title}`}>Custom Setup</span>
            <div className={`${styles.content}`}>
              <TextField
                sx={{ backgroundColor: "white", width: "90px" }}
                size="small"
                value={customSetup.toFixed(0)}
                type="number"
                onChange={handleCustomSetupChange}
              />{" "}
            </div>
          </div>
          {!prevCustomSetup ? (
            <span className={`${styles.units}`}>min</span>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ paddingLeft: "0.3rem" }}>min</span>
              <Tooltip title="Previous value">
                <span
                  style={{
                    paddingLeft: "0.3rem",
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                  }}
                >
                  {prevCustomSetup} min
                </span>
              </Tooltip>
            </div>
          )}
        </div>
        {/*Tools*/}
        <div className={`${styles.container}`}>
          <div className={`${styles.container__content}`}>
            <span className={`${styles.container__title}`}>Tools</span>
            <div className={`${styles.content}`}>
              <TextField
                sx={{ backgroundColor: "white", width: "90px" }}
                size="small"
                value={tools}
                type="number"
                onChange={handleToolsChange}
              />{" "}
            </div>
          </div>
          {!prevTools ? (
            <span className={`${styles.units}`}>min</span>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ paddingLeft: "0.3rem" }}>min</span>
              <Tooltip title="Previous value">
                <span
                  style={{
                    paddingLeft: "0.3rem",
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                  }}
                >
                  {prevTools.toFixed(0)} min
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {/*Total*/}
      <div className={`${styles.total__container}`}>
        <div className={`${styles.total__title}`}>
          <h4>Total</h4>
        </div>
        <div>
          <h4 className={`${styles.total__price}`}>
            {formatter.format(PreparationTotal * item.quantity)}
          </h4>
          <span className={`${styles.total__price_pcs}`}>
            {formatter.format(PreparationTotal / item.quantity)} / pcs
          </span>
        </div>
      </div>
      {/*Material*/}
      <h5 className={`${styles.title}`}>{"Material"}</h5>
      {/*Batch Setup*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Batch</span>
          <FormControl sx={{ width: 100 }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={"Single"}
              disabled
            >
              <MenuItem value={"Single"}>Single</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ backgroundColor: "white", width: "90px" }}
            size="small"
            value={1}
            disabled
          />{" "}
        </div>
      </div>
      {/*Width*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Width</span>
          <div className={`${styles.content}`}>
            <TextField
              style={{
                backgroundColor: "white",
                width: "90px",
                fontSize: "0.8rem",
              }}
              size="small"
              type="number"
              value={width}
              onChange={handleWidthChange}
            />{" "}
          </div>
        </div>
        {!prevWidth ? (
          <span className={`${styles.units}`}>mm</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>mm</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevWidth.toFixed(0)} mm
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {/*Length*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Length</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={length}
              onChange={handleLengthChange}
            />{" "}
          </div>
        </div>
        {!prevLength ? (
          <span className={`${styles.units}`}>mm</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>mm</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevLength.toFixed(0)} mm
              </span>
            </Tooltip>
          </div>
        )}
      </div>

      {/*Height*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Height</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={height}
              onChange={handleHeightChange}
            />{" "}
          </div>
        </div>
        {!prevHeight ? (
          <span className={`${styles.units}`}>mm</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>mm</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevHeight.toFixed(0)} mm
              </span>
            </Tooltip>
          </div>
        )}
      </div>

      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Density</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={density}
              onChange={handleDensityChange}
            />{" "}
          </div>
        </div>{" "}
        {!prevDensity ? (
          <span className={`${styles.units}`}>
            {" "}
            kg/m<sup>3</sup>
          </span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>
              {" "}
              kg/m<sup>3</sup>
            </span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevDensity.toFixed(0)} kg/m<sup>3</sup>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {/*Weight*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Weight [total]</span>
          <div className={`${styles.content}`}>
            <h5 style={{ fontWeight: "bold" }}>{WeightTotal.toFixed(2)}kg</h5>
          </div>
        </div>
      </div>
      {/*Material Price*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Material Price</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              value={materialPrice.toFixed(2)}
              onChange={handleMaterialPriceChange}
              type="number"
            />{" "}
          </div>
        </div>
        {!prevMaterialPrice ? (
          <span className={`${styles.units}`}>kr</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>kr</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevMaterialPrice.toFixed(2)} kr
              </span>
            </Tooltip>
          </div>
        )}
      </div>

      {/*Total*/}
      <div className={`${styles.total__container}`}>
        <div className={`${styles.total__title}`}>
          <h4>Total</h4>
        </div>
        <div style={{ borderBottom: "none" }}>
          <h4 className={`${styles.total__price}`}>
            {formatter.format(materialPrice * item.quantity * WeightTotal)}
          </h4>
          <span className={`${styles.total__price_pcs}`}>
            {formatter.format((materialPrice * WeightTotal) / item.quantity)} /
            pcs
          </span>
        </div>
      </div>

      {/*Matching*/}
      <h5 className={`${styles.title}`}>{"Machining"}</h5>
      {/*Type*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Type</span>
          <FormControl sx={{ width: 90 }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={"5Axis"}
              disabled
            >
              <MenuItem value={"5Axis"}>5Axis</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {/*Batch Setup*/}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Batch</span>
          <FormControl style={{ width: 90, paddingRight: "0" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={"Single"}
              style={{ paddingRight: "0" }}
              disabled
            >
              <MenuItem style={{ paddingRight: "0" }} value={"Single"}>
                Single
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ backgroundColor: "white", width: "90px" }}
            size="small"
            value={1}
            disabled
          />{" "}
        </div>
      </div>
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Prod. Start</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={prodStart.toFixed(0)}
              onChange={handleProdStartChange}
            />
          </div>
        </div>
        {!prevProdStart ? (
          <span className={`${styles.units}`}>min</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>min</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevProdStart.toFixed(0)} min
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Setup</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={setup.toFixed(0)}
              onChange={handleSetupChange}
            />
          </div>
        </div>
        {!prevSetup ? (
          <span className={`${styles.units}`}>min</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>min</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevSetup.toFixed(0)} min
              </span>
            </Tooltip>
          </div>
        )}
      </div>

      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Machining</span>
          <div className={`${styles.content}`}>
            <TextField
              type="number"
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              value={machining.toFixed(0)}
              onChange={handleMachiningChange}
            />{" "}
          </div>
        </div>
        {!prevMachining ? (
          <span className={`${styles.units}`}>min</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>min</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevMachining.toFixed(0)} min
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Operation</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={operation.toFixed(0)}
              onChange={handleOperationChange}
            />
          </div>
        </div>
        {!prevOperation ? (
          <span className={`${styles.units}`}>min</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>min</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevOperation.toFixed(0)} min
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {/*Total*/}
      <div className={`${styles.total__container}`}>
        <div className={`${styles.total__title}`}>
          <h4>Total</h4>
        </div>
        <div>
          <h4 className={`${styles.total__price}`}>
            {formatter.format(MachineTotal * item.quantity)}
          </h4>
          <span className={`${styles.total__price_pcs}`}>
            {formatter.format(MachineTotal / item.quantity)} / pcs
          </span>
        </div>
      </div>

      {/*Finish*/}
      <h5 className={`${styles.title}`}>{"Finish"}</h5>
      {/*EasyPartz */}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Leadtime</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={finishLeadtime}
              disabled
            />{" "}
          </div>
        </div>
        <span className={`${styles.units}`}>days</span>
      </div>
      {/*External */}
      <div className={`${styles.container}`}>
        <div className={`${styles.container__content}`}>
          <span className={`${styles.container__title}`}>Price</span>
          <div className={`${styles.content}`}>
            <TextField
              sx={{ backgroundColor: "white", width: "90px" }}
              size="small"
              type="number"
              value={finishPrice.toFixed(2)}
              onChange={handleFinishChange}
            />{" "}
          </div>
        </div>
        {!prevFinishPrice ? (
          <span className={`${styles.units}`}>kr</span>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ paddingLeft: "0.3rem" }}>kr</span>
            <Tooltip title="Previous value">
              <span
                style={{
                  paddingLeft: "0.3rem",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                {prevFinishPrice.toFixed(0)} kr
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {/*Total*/}
      <div className={`${styles.total__container}`}>
        <div className={`${styles.total__title}`}>
          <h4 style={{ fontWeight: "bold" }}>Total</h4>
        </div>
        <div style={{ borderBottom: "none" }}>
          <h4 className={`${styles.total__price}`}>
            {formatter.format(finishPrice * item.quantity)}
          </h4>
          <span className={`${styles.total__price_pcs}`}>
            {formatter.format(finishPrice)} / pcs
          </span>
        </div>
      </div>
    </div>
  );
};

export default CalculatorRow;
