import { TableCell, TableRow, Tooltip } from "@mui/material";
import { FunctionComponent, useMemo } from "react";
import { SelectedThreadsInfoType } from "types/RenderTypes";
import { Square } from "@mui/icons-material";

type PropsType = {
  specs: SelectedThreadsInfoType[];
};

const ThreadDisplay: FunctionComponent<PropsType> = ({ specs }) => {
  const uniqueThreads = useMemo(() => {
    const res: { [key: string]: any } = {};
    specs.forEach(
      (spec) =>
        (res[spec.name] = {
          color: `rgb(${spec.color?.r}, ${spec.color?.g}, ${spec.color?.b})`,
          count: (res[spec.name]?.count ?? 0) + 1,
        })
    );
    return res;
  }, [specs]);

  return specs.length > 0 ? (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "350px",
        height: "40px",
      }}
    >
      {" "}
      {Object.keys(uniqueThreads).map((key, index) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          key={index}
        >
          {" "}
          <Tooltip title={uniqueThreads[key].color}>
            <Square
              style={{
                width: "15px",
                height: "15px",
                color: `${uniqueThreads[key].color}`,
                backgroundColor: `${uniqueThreads[key].color}`,
              }}
            />
          </Tooltip>
          &nbsp;
          <span>{key}</span>
          &nbsp;
          <span>({uniqueThreads[key].count})</span>&nbsp;
          <span style={{ marginRight: "0.5em", marginLeft: "0.5rem" }}>|</span>
        </div>
      ))}
    </div>
  ) : (
    <TableRow>
      <TableCell> {"Ingen gevind fundet"}</TableCell>
    </TableRow>
  );
};

export default ThreadDisplay;
