import { useFrame } from "@react-three/fiber";
import { FunctionComponent, Suspense, useMemo, useRef } from "react";
import { Group, Points, Vector3 } from "three";
import { staticColors } from "../../../util/ColorUtil";

type PropsType = {
  vertices: Points;
};

const DFMPoints: FunctionComponent<PropsType> = ({ vertices }) => {
  const groupRef = useRef<Group>();

  useFrame(({ camera }) => {
    if (groupRef.current) {
      const size = Math.max(0.5, 12 - camera.zoom);
      groupRef.current.children.forEach((child: any) =>
        child.scale.set(size, size, size)
      );
    }
  }, 10);

  const finalPoints = useMemo(() => {
    if (!vertices) return;
    const model = vertices;
    const points = model.geometry.getAttribute("position");
    const pointArray = points.array;
    const finalPoints = [];
    // As all the coordinate numbers are stored in a normal array, we need to iterate over them all and take them 3 at a time (x, y, z) in order to draw spheres.
    for (let i = 0; i < pointArray.length; i += points.itemSize) {
      finalPoints.push([pointArray[i], pointArray[i + 1], pointArray[i + 2]]);
      // const geo = new SphereBufferGeometry(5, 20, 20);
      // const mat = new MeshBasicMaterial({
      //   transparent: true,
      //   opacity: 0.6,
      //   color: staticColors.error,
      // });
      // const mesh = new Mesh(geo, mat);
      // mesh.position.set(pointArray[i], pointArray[i + 1], pointArray[i + 2]);

      // <mesh key={i} position={new Vector3(pointArray[i], pointArray[i+1], pointArray[i+2])}>
      //        <meshBasicMaterial
      //          transparent={true}
      //          opacity={0.6}
      //          color={new Color(0xff0000)}
      //        />
      //        <sphereBufferGeometry args={[5, 20, 20]} />
      //      </mesh>
      // spheres.add(mesh);
    }
    return finalPoints;
  }, [vertices]);

  //   const test = useMemo(() => {
  //     const res = [];
  //     for (let i = 0; i < 1000; i++) {
  //       let newPos = new Vector3(
  //         Math.random() * 1000 + 1,
  //         Math.random() * 1000 + 1,
  //         Math.random() * 1000 + 1
  //       );
  //       //   if (modelCenter)
  //       //     newPos = new Vector3(
  //       //       item[0] - modelCenter?.x,
  //       //       item[1] - modelCenter?.y,
  //       //       item[2] - modelCenter?.z
  //       //     );
  //       res.push(
  //         <mesh key={i} position={newPos}>
  //           <meshBasicMaterial
  //             transparent={true}
  //             opacity={0.5}
  //             color={staticColors.error}
  //             //side={DoubleSide}
  //             //depthTest={false}
  //           />
  //           <sphereBufferGeometry args={[1, 10, 10]} />
  //         </mesh>
  //       );
  //     }
  //     return res;
  //   }, []);

  return (
    <Suspense fallback={null}>
      <group ref={groupRef}>
        {finalPoints &&
          finalPoints.map((item, key) => {
            let newPos = new Vector3(item[0], item[1], item[2]);
            //   if (modelCenter)
            //     newPos = new Vector3(
            //       item[0] - modelCenter?.x,
            //       item[1] - modelCenter?.y,
            //       item[2] - modelCenter?.z
            //     );
            return (
              <mesh key={key} position={newPos}>
                <meshBasicMaterial
                  transparent={true}
                  opacity={0.5}
                  color={staticColors.error}
                  //side={DoubleSide}
                  //depthTest={false}
                />
                <sphereBufferGeometry args={[1, 10, 10]} />
              </mesh>
            );
          })}
      </group>
    </Suspense>
  );
};

export default DFMPoints;
