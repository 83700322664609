import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import { useState } from "react";

type PropsType = {
  options: {
    label: string;
    onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  }[];
  disabled?: boolean;
};

const BasicDropdownButton: React.FC<PropsType> = ({ options, disabled }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        ref={anchorRef}
        onClick={handleToggle}
        endIcon={<KeyboardArrowDown />}
        disabled={disabled}
        variant="contained"
        sx={{
          borderRadius: 0,
          maxHeight: 40,
          width: "10rem",
          height: "4rem",
          color: "#fff",
        }}
      >
        Convert to
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={"bottom"}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      onClick={(e) => {
                        setOpen(false);
                        option.onClick(e);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default BasicDropdownButton;
