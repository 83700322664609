import {
  CreatePartnerType,
  UpdatePartnerType,
} from "types/partners/PartnerCommandTypes";
import { API_URL } from "./common";

const ENDPOINT = `${API_URL}/v1/ecommerce/partners`;

export const apiGetPartners = (
  token: string,
  page: number,
  pageSize: number
) => {
  return fetch(`${ENDPOINT}?page=${page}&pageSize=${pageSize}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new Error(res.statusText);
  });
};

export const apiGetPartner = (token: string, id: number) => {
  return fetch(`${ENDPOINT}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new Error(res.statusText);
  });
};

export const apiCreatePartner = (token: string, command: CreatePartnerType) => {
  return fetch(ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(command),
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new Error(res.statusText);
  });
};

export const apiUpdatePartner = (
  token: string,
  id: number,
  command: UpdatePartnerType
) => {
  return fetch(`${ENDPOINT}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(command),
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new Error(res.statusText);
  });
};

export const apiDeletePartner = (token: string, id: number) => {
  return fetch(`${ENDPOINT}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.text();
    else throw new Error(res.statusText);
  });
};
