// Author: Magnus s195401 & Erik s195397
import { useState, useContext } from "react";
import { Grid, Typography, Button } from "@mui/material";
import PartnerTable from "../PartnerTable";
import AppStateContext from "../../../contexts/AppStateContext";
import {
  apiCreatePartner,
  apiDeletePartner,
  apiUpdatePartner,
} from "util/network/Partners";
import { UpdatePartnerType } from "types/partners/PartnerCommandTypes";
import CreateOrUpdatePartnerModal from "../CreateOrUpdatePartnerModal";
import { PartnerType } from "types/partners/PartnerTypes";
import DeletePartnerModal from "../DeletePartnerModal";
import { useToast } from "contexts/ToastContext";

// Partner management UI
const PartnerManagementUI = () => {
  const { token } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [existing, setExisting] = useState<PartnerType>();

  const [showDeletePartner, setShowDeletePartner] = useState<boolean>(false);
  const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false);

  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

  const handleDeletePartner = (id: number) => {
    console.log(existing?.id);
    apiDeletePartner(token, id)
      .then((res) => {
        console.log(res);
        setShowDeletePartner(false);
        addToast({
          type: "success",
          message: (
            <>
              Partner was <strong>deleted</strong> successfully.
            </>
          ),
        });
        setRefreshTrigger((trigger) => trigger + 1);
      })

      .catch((e) => {
        console.log(e);
      });
  };

  const handleCreatePartner = (partner: PartnerType) => {
    apiCreatePartner(token, partner).then((res) => {
      if (!res.message) {
        addToast({
          type: "success",
          message: (
            <>
              New partner was <strong>created</strong> successfully.
            </>
          ),
        });
        setRefreshTrigger((trigger) => trigger + 1);
      } else if (res.message) {
        window.alert("Der skete en fejl ved oprettelse: \n" + res.message);
      }
    });
  };

  const handleEditPartner = (id: number, changes: UpdatePartnerType) => {
    apiUpdatePartner(token, id, changes).then((res) => {
      if (!res.message) {
        addToast({
          type: "success",
          message: (
            <>
              Partner was <strong>edited</strong> successfully.
            </>
          ),
        });
        setRefreshTrigger((trigger) => trigger + 1);
      } else if (res.message) {
        addToast({
          type: "error",
          message: "Der skete en fejl ved redigere: \n" + res.message,
          keep: true,
        });
      }
    });
  };
  console.log(existing);
  return (
    <Grid container>
      <Grid xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Partner overview
            </Typography>
          </div>
          <div style={{ padding: "0 1rem 0 0" }}>
            <Button
              sx={{
                borderRadius: 0,
                maxHeight: 40,
                width: "10rem",
                background: "#E0A900",
                color: "#fff",
              }}
              onClick={() => setShowPartnerModal(true)}
            >
              <h5 color="white">Add New Partner</h5>
            </Button>
          </div>
        </div>
      </Grid>
      {/* <Grid
        item
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        xs={2}
      ></Grid> */}
      <Grid item xs={12} style={{ padding: "2rem 1.5rem 0 1.5rem" }}>
        <PartnerTable
          setExisting={setExisting}
          setShowPartnerModal={setShowPartnerModal}
          setShowDeleteUser={setShowDeletePartner}
          refreshTrigger={refreshTrigger}
        />
      </Grid>
      <CreateOrUpdatePartnerModal
        open={showPartnerModal}
        onClose={() => {
          setShowPartnerModal(false);
          setExisting(undefined);
        }}
        partner={existing}
        onSubmit={(partner) => {
          if (existing) {
            addToast({
              type: "success",
              message: (
                <>
                  Partner was <strong>edited</strong> successfully.
                </>
              ),
            });
          } else {
            addToast({
              type: "success",
              message: (
                <>
                  New partner was <strong>created</strong> successfully.
                </>
              ),
            });
          }
          setRefreshTrigger((trigger) => trigger + 1);
          setShowPartnerModal(false);
        }}
      />
      <DeletePartnerModal
        open={showDeletePartner}
        onClose={() => setShowDeletePartner(false)}
        onSubmit={(id) => {
          handleDeletePartner(id);
          setShowDeletePartner(false);
        }}
        partnerId={existing?.id}
      />
    </Grid>
  );
};
export default PartnerManagementUI;
