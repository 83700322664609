// ToolsAPI.ts

import { API_URL } from "../network/Common";

// Define the endpoint URL where you'll fetch the data (replace with your actual API URL)
const baseURL = `${API_URL}/v1/tooldb/tools`;

// function simulateDelay(ms: number) {
//     return new Promise((resolve) => {
//       setTimeout(resolve, ms);
//     });
//   }

// Define a type for the tool data
export interface Tool {
  id: number;
  number: number;
  sister: string;
  description: string;
  sku: string;
  position: string;
  position_id: number | null;
  position_date: Date | null;
  geom_length: number;
  geom_diameter: number;
  measure_length: boolean;
  measure_center_offset: number;
  measure_diameter: boolean;
  measured_length: number | null;
  measured_diameter: number | null;
  measured_date: Date | null;
  life_count: number;
  life_time: number;
  life_date: Date | null;
  stats_count: number;
  stats_time: number;
  stats_resets: number;
}

export const defaultTool: Tool = {
  id: 0,
  number: 0,
  sister: "",
  description: "",
  sku: "",
  position: "",
  position_id: null,
  position_date: null,
  geom_length: 0.0,
  geom_diameter: 0.0,
  measure_length: true,
  measure_center_offset: 0.0,
  measure_diameter: false,
  measured_length: null,
  measured_diameter: null,
  measured_date: null,
  life_count: 0,
  life_time: 0.0,
  life_date: null,
  stats_count: 0,
  stats_time: 0.0,
  stats_resets: 0,
};

// Function to fetch tools data from the API
export async function fetchTools(): Promise<Tool[]> {
  try {
    const response = await fetch(baseURL);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching tools data:", error);
    throw error;
  }
}

export async function addTool(tool: Tool) {
  try {
    const response = await fetch(baseURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tool),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    return data; // Return the newly created tool data
  } catch (error) {
    console.error("Error adding tool:", error);
    // throw error;
  }
}

// Maybe update to only send changed values?
export async function updateTool(
  toolId: number,
  updatedValues: { [key: string]: any }
) {
  try {
    const endpointUrl = `${baseURL}/${toolId}`; // Construct the URL with the tool's ID

    const response = await fetch(endpointUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedValues),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error updating tool:", error);
    // throw error;
  }
}

// Function to delete a tool by ID
export async function deleteTool(toolId: number) {
  try {
    const response = await fetch(`${baseURL}/${toolId}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return true;
  } catch (error) {
    console.error("Error deleting tool:", error);
    // throw error;
  }
}

// Function to reset a tool by ID
export async function resetTool(toolId: number) {
  try {
    const response = await fetch(`${baseURL}/${toolId}/reset`, {
      method: "POST",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error resetting tool:", error);
    // throw error;
  }
}

// Function to move a tool by ID
export async function moveTool(
  toolId: number,
  position: string | null,
  position_id: number | null
) {
  try {
    const response = await fetch(`${baseURL}/${toolId}/move`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ position: position, position_id: position_id }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error resetting tool:", error);
    // throw error;
  }
}

// Define a type for the tool event data
export interface ToolEvent {
  timestamp: Date;
  event: string;
  description: string;
}

// Function to fetch tool events data from the API by tool ID
export async function fetchToolEvents(toolId: number): Promise<ToolEvent[]> {
  try {
    //   return sampleToolEvents;

    const response = await fetch(`${baseURL}/${toolId}/events`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    data.forEach((event: ToolEvent) => {
      event.timestamp = new Date(event.timestamp);
    });

    return data;
  } catch (error) {
    console.error("Error fetching tool events data:", error);
    throw error;
  }
}
