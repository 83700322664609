import { FetchError } from "exceptions/exceptions";
import { Line, Mesh } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import {
  CNCProductDetailsType,
  CreateCNCProductType,
  CreateOtherProductType,
  CreatePrint3DProductType,
  EstimateProductType,
  ProductTypeEnum,
  ProductVMType,
  UpdateCNCProductType,
  UpdateOtherProductType,
  UpdatePrint3DProductType,
  UpdateSheetProductType,
} from "types/products/ProductCommandsType";
import {
  DFMInfoType,
  ModelDataType,
  ReceivedModelType,
} from "types/RenderTypes";
import { checkGltfChild, modelLoader } from "util/ModelLoader";
import { API_URL } from "./common";

const BASE_URL = `${API_URL}/v1/ecommerce/products`;
export const apiGetProduct = (
  token: string,
  id: number
): Promise<ProductVMType> => {
  return fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data: ProductVMType) => {
      if (
        data.productType === ProductTypeEnum.CNC ||
        data.productType === ProductTypeEnum.PRINT3D ||
        data.productType === ProductTypeEnum.SHEET
      ) {
        data.details = {
          ...data.details,
          finish: (data.details as any).finish?.split(","),
        } as any;
      }
      return data;
    });
};

export const apiGetManufacturedProductCalculationValues = (
  token: string,
  id: number
) => {
  return fetch(`${BASE_URL}/${id}/calculate`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
};

export const apiCreateProduct = (
  token: string,
  command:
    | CreateCNCProductType
    | CreatePrint3DProductType
    | CreateOtherProductType
): Promise<ProductVMType> => {
  let actualCommand = null;
  if ((command as UpdateCNCProductType).finish) {
    actualCommand = {
      ...command,
      finish: (command as UpdateCNCProductType).finish?.join(","),
    };
  } else {
    actualCommand = command;
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(actualCommand),
  };

  return fetch(`${BASE_URL}`, requestOptions)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data: ProductVMType) => {
      if (
        data.productType === ProductTypeEnum.CNC ||
        data.productType === ProductTypeEnum.PRINT3D ||
        data.productType === ProductTypeEnum.SHEET
      ) {
        data.details = {
          ...data.details,
          finish: (data.details as any).finish?.split(","),
        } as any;
      }
      return data;
    });
};

export const apiUpdateProduct = (
  token: string,
  productId: number,
  command:
    | UpdateCNCProductType
    | UpdatePrint3DProductType
    | UpdateSheetProductType
    | UpdateOtherProductType
): Promise<ProductVMType> => {
  let actualCommand = null;
  if ((command as UpdateCNCProductType).finish) {
    actualCommand = {
      ...command,
      finish: (command as UpdateCNCProductType).finish?.join(","),
    };
  } else {
    actualCommand = command;
  }

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(actualCommand),
  };

  return fetch(`${BASE_URL}/${productId}`, requestOptions)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data: ProductVMType) => {
      if (
        data.productType === ProductTypeEnum.CNC ||
        data.productType === ProductTypeEnum.PRINT3D ||
        data.productType === ProductTypeEnum.SHEET
      ) {
        data.details = {
          ...data.details,
          finish: (data.details as any).finish?.split(","),
        } as any;
      }
      return data;
    });
};

export const apiChangeProductType = (
  token: string,
  productId: number,
  productType: ProductTypeEnum
): Promise<ProductVMType> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ productType: productType }),
  };

  return fetch(`${BASE_URL}/${productId}/type`, requestOptions)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data: ProductVMType) => {
      if (
        data.productType === ProductTypeEnum.CNC ||
        data.productType === ProductTypeEnum.PRINT3D ||
        data.productType === ProductTypeEnum.SHEET
      ) {
        data.details = {
          ...data.details,
          finish: (data.details as any).finish?.split(","),
        } as any;
      }
      return data;
    });
};

export const apiGetBlueprint = (
  token: string,
  productId: number
): Promise<Blob> => {
  return fetch(`${BASE_URL}/${productId}/blueprint`, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.blob();
  });
};

export const apiAddBlueprint = (
  token: string,
  productId: number,
  file: File
): Promise<string> => {
  if (file.type !== "application/pdf" && !file.type.startsWith("image")) {
    throw new Error("File is not PDF");
  }
  const data = new FormData();
  data.append("file", file);
  data.append("filename", file.name);
  return fetch(`${BASE_URL}/${productId}/blueprint`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  }).then((res) => {
    return res.text();
  });
};

export const apiReplaceBlueprint = (
  token: string,
  productId: number,
  file: File
): Promise<string> => {
  // only allow pdf and images
  if (file.type !== "application/pdf" && !file.type.startsWith("image")) {
    throw new Error("File is not PDF");
  }
  const data = new FormData();
  data.append("file", file);
  data.append("filename", file.name);
  return fetch(`${BASE_URL}/${productId}/blueprint`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  }).then((res) => {
    return res.text();
  });
};

export const apiRemoveBlueprint = (token: string, productId: number) => {
  return fetch(`${BASE_URL}/${productId}/blueprint`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) {
      return res.text();
    }
  });
};

const wait = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

function fetchRetry(
  url: string,
  delay: number,
  tries: number,
  requestOptions?: RequestInit
): Promise<any> {
  return fetch(url, requestOptions)
    .then((res) => {
      if (res.ok) return res;
      else throw new FetchError(res.statusText, res.status);
    })
    .catch((err) => {
      if (tries === 0) {
        throw err;
      }
      return wait(delay).then(() =>
        fetchRetry(url, delay * 2, tries - 1, requestOptions)
      );
    });
}

export const apiGetPreviewImage = (token: string, id: number) => {
  return fetch(` ${API_URL}/v1/ecommerce/models/${id}/preview`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      return res.blob();
    })
    .then((img) => {
      const imageObjURL = URL.createObjectURL(img);
      return imageObjURL;
    });
};

export const apiGetGLTF = (
  token: string,
  id: number
): Promise<ReceivedModelType> => {
  const requestOptionss: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${API_URL}/v1/ecommerce/models/${id}/file/generated`,
    requestOptionss
  )
    .then((data) => {
      return modelLoader(token, data.url);
    })
    .then((gltf: GLTF) => {
      let res: ReceivedModelType = {
        models: [],
        edges: [],
      };
      const test = checkGltfChild(gltf.scene);
      test.forEach((item) => {
        if (item instanceof Line) {
          res.edges.push(item);
        }
        if (item instanceof Mesh) {
          res.models.push(item);
        }
      });
      // gltf.scene.children.forEach((item) => {
      //   let mod = item;
      //   if (mod.name === "COMPOUND" || mod.name === "SOLID") {
      //     if (mod.children.length === 1) {
      //       mod = mod.children[0];
      //     }
      //     mod.children.forEach((child) => {
      //       if (child.name === "EDGES") {
      //         res.edges = child.children as Line[];
      //       }
      //       if (child.name === "FACES") {
      //         res.models = child.children as Mesh[];
      //       }
      //     });
      //   }
      // });
      return res;
    });
};

export const apiGetColoredStep = (
  token: string,
  name: string,
  id: number
): Promise<void> => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${API_URL}/v1/ecommerce/models/${id}/file/colored`,
    requestOptions
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((e) => window.alert(e));
};

export const apiCheckDXFFile = (
  token: string,
  id: number
): Promise<boolean> => {
  const requestOptions: RequestInit = {
    method: "HEAD",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${API_URL}/v1/ecommerce/models/${id}/file/dxf`,
    requestOptions
  ).then((res) => {
    if (res.ok) return true;
    else return false;
  });
};

export const apiGetDXFFile = (
  token: string,
  name: string,
  id: number
): Promise<void> => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(`${API_URL}/v1/ecommerce/models/${id}/file/dxf`, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((e) => window.alert(e));
};

export const apiGetModelInfo = (
  token: string,
  id: number
): Promise<ModelDataType> => {
  return fetch(`${API_URL}/v1/ecommerce/models/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.features) {
        const shaftsData = data.features.shafts.map((shaft: any) => ({
          ...shaft,
          depth: shaft.length,
        }));
        return {
          ...data,
          features: { ...data.features, shafts: shaftsData },
        };
      } else {
        return data;
      }
    })
    .catch((e) => console.log("error", e));
};

export const apiGetErrorGLTFModel = (token: string, id: number) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return fetch(`${API_URL}/v1/ecommerce/models/${id}/file/dfm`, requestOptions)
    .then((res) => {
      if (res.ok) return res.url;
      else
        throw new Error("An error has occured while trying to load the model.");
    })
    .then((data) => modelLoader(token, data));
  // .then(async (gltf: GLTF) => {
  //   let res = {
  //     face: null,
  //     edge: null,
  //     vertex: null,
  //     thickness: null,
  //     clearance: null,
  //   } as {
  //     face: null | Mesh;
  //     edge: null | LineSegments;
  //     vertex: null | Points;
  //     thickness: null | Mesh;
  //     clearance: null | Mesh;
  //   };
  //   gltf.scene.children.forEach((mod) => {
  //     if (mod.name === "DFM_Analysis") {
  //       mod.children.forEach((child) => {
  //         if (child.name === "Face_issues") {
  //           res.face = child as Mesh;
  //           //res.rotation.set(0, 0, Math.PI / 2);
  //         }
  //         if (child.name === "Vertex_issues") {
  //           res.vertex = child as Points;
  //           //res.rotation.set(0, 0, Math.PI / 2);
  //         }
  //         if (child.name === "Edge_issues") {
  //           res.edge = child as LineSegments;
  //           //res.rotation.set(0, 0, Math.PI / 2);
  //         }
  //         if (child.name === "Thickness_mesh") {
  //           res.thickness = child as Mesh;
  //         }
  //         if (child.name === "Thickness_issues") {
  //           res.thickness = child as Mesh;
  //         }
  //         if (child.name === "Clearance_issues") {
  //           res.clearance = child as Mesh;
  //         }
  //       });
  //     }
  //   });
  //   return res;
  // })
  // .catch((err) => {
  //   window.alert("Der skete en fejl: " + err);
  // });
};
export const apiGetDfmInfo = (
  token: string,
  id: number
): Promise<DFMInfoType> => {
  return fetch(`${API_URL}/v1/ecommerce/models/${id}/dfm`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.status) {
        throw new Error(data.status);
      }
      return data;
    });
};
