import { FunctionComponent } from "react";
import styles from "./RenderControls.module.css";
import {
  OpacityOutlined,
  FilterCenterFocus,
  GridOn,
  GridOff,
} from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

type PropsType = {
  isTransparent: boolean;
  setIsTransparent: Function;
  centerCamFunc: Function | null;
  showGrid: boolean;
  setShowGrid: Function;
  /*showErrorModelsState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];*/
};

const RenderControls: FunctionComponent<PropsType> = ({
  isTransparent,
  setIsTransparent,
  centerCamFunc,
  showGrid,
  setShowGrid,
  //showErrorModelsState,
}) => {
  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        size="small"
        orientation={"vertical"}
        aria-label="toggle"
      >
        {/* {showErrorModelsState && (
            <Tooltip title={"Skjul fejl"} placement={"left"}>
              <ToggleButton
                value="hideErrors"
                aria-label="hideErrors"
                onClick={() => {
                  showErrorModelsState?.[1]((prevState) => !prevState);
                }}
              >
                <ErrorOutline
                  htmlColor={!showErrorModelsState?.[0] ? "#ff704d" : "red"}
                />
              </ToggleButton>
            </Tooltip>
          )} */}
        {setShowGrid && (
          <ToggleButton
            value="showGrid"
            aria-label="showGrid"
            onClick={() => {
              setShowGrid((prevState: boolean) => !prevState);
            }}
          >
            {showGrid ? <GridOn /> : <GridOff />}
          </ToggleButton>
        )}
        {setIsTransparent && (
          <ToggleButton
            value="transparent"
            aria-label="transparent"
            onClick={() => {
              setIsTransparent((prevState: boolean) => !prevState);
            }}
          >
            <OpacityOutlined color={isTransparent ? "disabled" : "action"} />
          </ToggleButton>
        )}
        {centerCamFunc && (
          <ToggleButton
            value="centercam"
            aria-label="centercam"
            onClick={() => centerCamFunc()}
          >
            <FilterCenterFocus color={"action"} />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </div>
  );
};

export default RenderControls;
