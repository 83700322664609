import React, { ChangeEvent } from "react";
import styles from "./checkboxStyle.module.css";

type CheckboxProps = {
  label?: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
  return (
    <label className={`${styles.label}`}>
      <div
        className={`${styles.container} ${
          checked ? styles.container__checked : styles.container__unchecked
        }`}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={`${styles.input}`}
        />
        {checked ? <span className={`${styles.check}`}>&#10003;</span> : null}
      </div>
      {label}
    </label>
  );
};

export default Checkbox;
