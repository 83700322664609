import { FunctionComponent, useContext, useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AppStateContext from "../../contexts/AppStateContext";
import { apiGetProduct, apiUpdateProduct } from "util/network/Products";
import { Badge, IconButton, SvgIcon, TextField, Tooltip } from "@mui/material";
import { CreateRequisitionItemType } from "types/RequisitionTypes";
import {
  OtherProductDetailsType,
  ProductTypeEnum,
  ProductVMType,
  UpdateOtherProductType,
} from "types/products/ProductCommandsType";
import Modal from "components/Modals/Modal";
import FileResourceList from "components/FileResourceList";
import { FileResourceType } from "types/FileResourceTypes";
import { Delete, FileUpload } from "@mui/icons-material";
import { OrderItemType } from "types/OrderTypes";
import { CartItemType } from "types/CartTypes";

import styles from "./Styles/ReqOrderCustomItemTable.module.css";

type PropsType = {
  item: OrderItemType | CartItemType;
  deleteItem: (item: OrderItemType | CartItemType) => void
  updateItem: (item: OrderItemType | CartItemType) => void;
};

// Requisition Order Item Row
const CustomReqItemRow: FunctionComponent<PropsType> = ({
  item,
  deleteItem,
  updateItem,
}) => {
  const { token } = useContext(AppStateContext);
  const [product, _setProduct] = useState<ProductVMType>();
  const [qty, setQty] = useState<number>(item.quantity || 1);
  const [showModal, setShowModal] = useState(false);
  const [fileResources, setFileResources] = useState<FileResourceType[]>([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const setProduct = (product: ProductVMType) => {
    if (product.productType === ProductTypeEnum.OTHER) {
      _setProduct(product);
      const data = product.details as OtherProductDetailsType;
      setFileResources(data.fileResources);
      setName(product.name);
      setDescription(data.description ?? "");
    }
  };

  //Inital load
  useEffect(() => {
    if (item.id) {
      apiGetProduct(token, item.id).then((res) => {
        setProduct(res);
      });
    }
  }, [item.id, token]);

  const updateProduct = (changes: UpdateOtherProductType) => {
    return apiUpdateProduct(token, product!.id, changes).then((res) => {
      if (res) {
        setProduct(res);
        updateItem(item);
      }
    });
  };

  return (
    <TableRow hover key={item.id} selected={true}>
        <TableCell className={`${styles.deleteCell}`} padding="checkbox">
            <IconButton onClick={() => deleteItem(item)}>
                <Delete />
            </IconButton>
        </TableCell>
        <TableCell className={`${styles.qtyCell}`}>
            <TextField
                value={qty}
                variant="standard"
                type="number"
                onChange={(e) => setQty(parseInt(e.target.value))}
                onBlur={(e) => {
                    const newQty = parseInt(e.target.value);
                    if (!isNaN(newQty) && newQty > 0) {
                        item.quantity = newQty;
                        updateItem(item); // Call the prop function to update the item's quantity
                    } else {
                        setQty(item.quantity || 1); // Reset to the previous value if invalid
                    }
                }}
            />
        </TableCell>
        <TableCell className={`${styles.nameCell}`}>
            <TextField
                value={name}
                variant="standard"
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => {
                  if (product) {
                    updateProduct({ name: e.target.value });
                  }
                }}
            />
        </TableCell>
        <TableCell className={`${styles.descriptionCell}`}>
            <TextField
                variant="standard"
                fullWidth
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={(e) => {
                  if (product) {
                    updateProduct({ description: e.target.value });
                  }
                }}
            />
        </TableCell>
        <TableCell className={`${styles.filesCell}`}>
            <Tooltip title={<h3>UPLOAD FILES</h3>}>
                <IconButton onClick={handleShowModal}>
                    <Badge badgeContent={fileResources.length} color="primary">
                        <SvgIcon>
                            <FileUpload />
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Modal isOpen={showModal} onClose={handleCloseModal}>
                <div style={{ height: "100%", overflow: "auto" }}>
                    <FileResourceList
                        resources={fileResources}
                        allowEdit
                        onChange={(res) => {
                          return updateProduct({
                            fileResources: res.map((r) => r.id),
                          }).then(() => {
                            return true;
                          });
                        }}
                    />
                </div>
            </Modal>
        </TableCell>
    </TableRow>
);
};

export default CustomReqItemRow;
