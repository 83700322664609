import { API_URL } from "./common";
import { FetchError } from "../exceptions";
import { MachineStatusType, MachineType } from "../../types/MachineType";

const ENDPOINT = `${API_URL}/v1/machine`;

export const apiGetMachines = (token: string): Promise<MachineType[]> => {
  return fetch(`${API_URL}/v1/ecommerce/machines`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetMachine = (
  token: string,
  id: string
): Promise<MachineType> => {
  return fetch(`${API_URL}/v1/ecommerce/machines/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateMachine = (
  token: string,
  name: string,
  label: string
): Promise<MachineType> => {
  return fetch(`${API_URL}/v1/ecommerce/machines`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      name,
      label,
    }),
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetMachineStatus = (
  token: string,
  id: string
): Promise<MachineStatusType> => {
  return fetch(`${ENDPOINT}/${id}/status`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
export const apiGetMachineHistory = (
  token: string,
  id: string
): Promise<MachineStatusType[]> => {
  return fetch(`${ENDPOINT}/${id}/history`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
