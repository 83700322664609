import React, { useState, useEffect, ChangeEvent } from 'react';
import { CNCPlan, addCNCPlan, updateCNCPlan } from './CNCPlansAPI'; // Import the CNCPlan interface
import './CNCPlanAddEditForm.css'; // Add your CSS styling for CNC plan add/edit form
import { format } from 'date-fns-tz';

interface AddEditCNCPlanProps {
  onClose: () => void;
  onSubmit: () => void;
  cncPlanToEdit: CNCPlan | null; // Accept a CNCPlan or null for editing
}

const AddEditCNCPlanForm: React.FC<AddEditCNCPlanProps> = ({ onClose, onSubmit: onSubmitCallback, cncPlanToEdit }) => {
  const [formData, setFormData] = useState<CNCPlan>({
    id: cncPlanToEdit == null ? 0 : cncPlanToEdit.id,
    program: cncPlanToEdit == null ? '' : cncPlanToEdit.program,
    start: cncPlanToEdit == null ? new Date() : cncPlanToEdit.start,
    end: cncPlanToEdit == null ? new Date() : cncPlanToEdit.start,
    machine: cncPlanToEdit == null ? '' : cncPlanToEdit.machine,
    tools: [1, 2, 3],
    expected_work_min: 60.0,
    item: 0,
    task: 0,
    status: "open",
    description: "sample",
  });
  formData.start?.setSeconds(0);
  formData.start?.setMilliseconds(0);
  
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    // If a cncPlanToEdit is provided, populate the form fields for editing
    if (cncPlanToEdit) {
      setFormData(cncPlanToEdit);
    }
  }, [cncPlanToEdit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: Date) => {
    console.log(date);
    setFormData({ ...formData, start: date });
  };

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    if (cncPlanToEdit == null) {
      await addCNCPlan(formData);
    } else {
      await updateCNCPlan(cncPlanToEdit.id, formData);
    }
    setIsLoading(false);
    onSubmitCallback();
    onClose();
  }

  // File handling
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [toolNumbers, setToolNumbers] = useState<number[]>([]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target?.result as string;
        parseTextFile(content);
      };

      reader.readAsText(file);
    } else {
      // Handle invalid file type (not ending with '.nc') here
      alert('Please select a valid .nc file.');
    }
  };

  const parseTextFile = (content: string) => {
    const truncatedContent = content.slice(0, 3000); // Limit to the first 3000 characters
    const lines = truncatedContent.split('\n');
    const parsedToolNumbers: number[] = [];
    
    for (const line of lines) {
      if (line.startsWith('(T')) {
        const match = line.match(/\(T(\d+)/);
        if (match) {
          const toolNumber = parseInt(match[1], 10);
          parsedToolNumbers.push(toolNumber);
        }
      }
    }
    setToolNumbers(parsedToolNumbers);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{cncPlanToEdit == null ? 'Add CNC Plan' : `Edit CNC Plan ${cncPlanToEdit.program}`}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Program *:</label>
            <input
              type="text"
              name="program"
              value={formData.program}
              onChange={handleChange}
              disabled={isLoading}
              required
            />
          </div>
          <div>
            <label>Time *:</label>
            <input
              type="datetime-local"
              name="time"
              value={formData.start ? format(formData.start, "yyyy-MM-dd'T'HH:mm", { timeZone: 'auto' }) : undefined} // Format the date as "YYYY-MM-DDTHH:mm"
              onChange={(e) => handleDateChange(new Date(Date.parse(e.target.value)))}
              disabled={isLoading}
              required
            />
          </div>
          <div>
            <label>Machine *:</label>
            <input
              type="text"
              name="machine"
              value={formData.machine}
              onChange={handleChange}
              disabled={isLoading}
              required
            />
          </div>
          <div>
            <input type="file" accept=".nc" onChange={handleFileChange} />
            {selectedFile && <p>Selected file: {selectedFile.name}</p>}
            <div>
            <h3>Parsed Tool Numbers:</h3>
            <ul>
              {toolNumbers.map((toolNumber, index) => (
                <li key={index}>{toolNumber}</li>
              ))}
            </ul>
          </div>
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Saving...' : cncPlanToEdit == null ? 'Create' : 'Save'}
          </button>
          <button type="button" onClick={onClose} disabled={isLoading}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEditCNCPlanForm;
