import React, { useState } from "react";
import styles from "./Modal.module.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  productionTasks?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  productionTasks,
}) => {
  return (
    // The onClick is just used in case the parent to this has a click event that should be stopped.
    <div
      className={`${styles.modal} ${isOpen ? styles.modalOpen : ""}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={styles.modalBackdrop}
        onClick={() => {
          onClose();
        }}
      ></div>
      <div
        className={`${styles.modalContent}   ${
          productionTasks ? styles.modalContentTasks : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
