import { FunctionComponent, useContext, useRef, useState } from "react";
import { ArrowDropDown, DoDisturb, PictureAsPdf } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AppStateContext from "../../../contexts/AppStateContext";
import {
  apiAddBlueprint,
  apiRemoveBlueprint,
  apiReplaceBlueprint,
} from "util/network/Products";
import { fetchPDFFile } from "util/FileDownload";
import { dangerColor } from "assets/colors";
import { ReactComponent as PdfIcon } from "../../../assets/svg/pdfFile.svg";
type PropsType = {
  disabled?: boolean;
  id?: number;
  name?: string | null;
  pdfExists?: boolean;
  isOrder?: boolean;
  onUpdated?: (newName: string | null) => void;
};

const BlueprintSelector: FunctionComponent<PropsType> = ({
  disabled,
  id,
  name,
  pdfExists,
  isOrder,
  onUpdated,
}) => {
  const { strings, token } = useContext(AppStateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [loading, setLoading] = useState<boolean>(false);

  const hiddenRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const replaceBlueprint = (file: File) => {
    setLoading(true);
    id &&
      apiReplaceBlueprint(token, id, file).then((res) => {
        setTimeout(() => {
          console.log("res", res);
          onUpdated && onUpdated(res ?? null);
          setLoading(false);
        }, 1000);
      });
  };

  const uploadBlueprint = (file: File) => {
    setLoading(true);
    id &&
      apiAddBlueprint(token, id, file).then((res) => {
        setTimeout(() => {
          onUpdated && onUpdated(res ?? null);
          setLoading(false);
        }, 1000);
      });
  };

  const removeBlueprint = () => {
    setLoading(true);
    id &&
      apiRemoveBlueprint(token, id).then((res) => {
        setTimeout(() => {
          onUpdated && onUpdated(null);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <Tooltip title={name ?? "Not added"}>
      <div style={{ padding: 0 }}>
        <Button
          onClick={handleClick}
          style={{ fontWeight: "bold" }}
          disabled={disabled}
        >
          {loading ? (
            <CircularProgress size={"2em"} style={{ marginRight: "1em" }} />
          ) : pdfExists ? (
            <PdfIcon style={{ fill: "#E0A900", cursor: "pointer" }} />
          ) : (
            <PdfIcon style={{ fill: "#EBEBEB" }} />
          )}
          <ArrowDropDown />
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ zIndex: 10000 }}
        >
          {pdfExists && (
            <MenuItem
              disabled={!pdfExists}
              onClick={() => {
                handleClose();
                id && name && fetchPDFFile(token, name, id);
              }}
            >
              Download
            </MenuItem>
          )}

          <MenuItem
            disabled={disabled}
            value={"Update_pdf"}
            onClick={(event) => {
              hiddenRef.current?.click();
            }}
          >
            {pdfExists ? "Replace" : "Add"}
            <input
              ref={hiddenRef}
              style={{ display: "none" }}
              type={"file"}
              accept={
                ".pdf,.PDF,.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.gif,.GIF,.tiff,.TIFF,.tif,.TIF"
              }
              onChange={(e) => {
                handleClose();
                if (e.target.files && id) {
                  if (pdfExists) {
                    replaceBlueprint(e.target.files[0]);
                  } else {
                    uploadBlueprint(e.target.files[0]);
                  }
                }
              }}
            />
          </MenuItem>
          {pdfExists && (
            <MenuItem
              disabled={disabled}
              onClick={() => {
                handleClose();
                id && name && removeBlueprint();
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </div>
    </Tooltip>
  );
};

export default BlueprintSelector;
