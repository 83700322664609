import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { primaryColor } from "../../assets/colors";
import { useHistory } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AppStateContext from "../../contexts/AppStateContext";
import { apiGetUserCart, apiGetUserEvents } from "../../util/network/Users";
import { UserType } from "types/users/UserTypes";
import { reformatDate } from "../../util/formatter";
import { CartType } from "types/CartTypes";
import ListReducer, { ListReducerEnum } from "util/ListReducer";
import { apiCreateCart, apiDeleteQuote } from "util/network/Carts";
import Modal from "components/Modals/Modal";
import { formatter } from "util/TextUtil";
import { Add, Delete } from "@mui/icons-material";
import { SimpleOrderType } from "types/OrderTypes";
import { apiGetOrders } from "util/network/Orders";
import { EventLogType } from "types/EventLogTypes";
import styles from "../order_management/orderedit_components/Styles/loginPageStyle.module.css";

type PropsType = {
  customer: UserType;
  setExisting: Function;
};

const CustomerRow: FunctionComponent<PropsType> = ({
  customer,
  setExisting,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const { token, setShowUserModal } = useContext(AppStateContext);
  const [orders, setOrders] = useState<SimpleOrderType[]>([]);

  const [showCartModal, setShowCartModal] = useState<boolean>(false);

  const [eventLogs, setEventLogs] = useState<EventLogType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortKey, setSortKey] = useState<string>("created");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout>();

  const refreshEventLogs = useCallback(() => {
    if (customer.id && open) {
      apiGetUserEvents(
        token,
        customer.id,
        page,
        pageSize,
        sortKey,
        sortDirection,
        startDate,
        endDate
      )
        .then((res) => {
          setEventLogs(res.items);
          setTotal(res.total);
        })
        .catch((err) => console.error(err));
    }
  }, [
    customer.id,
    startDate,
    endDate,
    page,
    pageSize,
    sortKey,
    sortDirection,
    token,
    open,
  ]);

  useEffect(() => {
    refreshEventLogs();
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
    if (open) {
      const timer = setInterval(() => {
        refreshEventLogs();
      }, 1000);
      setRefreshTimer(timer);
    }
    return () => {
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
    };
  }, [
    customer.id,
    startDate,
    endDate,
    page,
    pageSize,
    sortKey,
    sortDirection,
    token,
    open,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  // Function to handle sorting change
  const handleSortChange = (newSortKey: string) => {
    if (sortKey === newSortKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortKey(newSortKey);
      setSortDirection("asc");
    }
  };

  const customerData =
    customer.defaultBillingAddress ?? customer.defaultBillingAddress;

  const handleEdit = () => {
    setExisting(customer);
    setShowUserModal(true);
  };

  const [carts, cartsDispatch] = useReducer(
    ListReducer<CartType>("id"),
    [] as CartType[]
  );

  const setCarts = useCallback((items: CartType[]) => {
    cartsDispatch({ type: ListReducerEnum.SET, value: items });
  }, []);

  const clearCarts = useCallback(() => {
    cartsDispatch({ type: ListReducerEnum.CLEAR, value: [] });
  }, []);

  const addCart = useCallback((item: CartType) => {
    cartsDispatch({ type: ListReducerEnum.ADD, value: item });
  }, []);

  const removeCart = useCallback(
    (item: CartType) => {
      return apiDeleteQuote(token, item.id).then((success) => {
        if (success) {
          cartsDispatch({ type: ListReducerEnum.REMOVE, value: item });
        }
        return success;
      });
    },
    [token]
  );

  const refreshCarts = useCallback(
    (customerId: number) => {
      return apiGetUserCart(token, customerId)
        .then((res) => {
          if (res.length === 0) return;
          else if (res.length > 0) {
            setCarts(
              res.filter(
                (cart) => cart.status === "OPEN" || cart.status === "LOCKED"
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [token]
  );

  useEffect(() => {
    if (customer.id) {
      refreshCarts(customer.id);
      apiGetOrders(
        token,
        1,
        5,
        "created",
        "desc",
        undefined,
        `${customer.email}`
      ).then((res) => setOrders(res.items));
    }
  }, [customer, token]);

  const customerCartList = useMemo(() => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Quote ID</TableCell>
            <TableCell align="left">Created</TableCell>
            <TableCell align="left">Number of items</TableCell>
            <TableCell align="left">Total</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left" colSpan={2}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {carts.map((cart) => {
            return (
              <TableRow key={cart.id}>
                <TableCell align="left">{cart.id}</TableCell>
                <TableCell align="left">{reformatDate(cart.created)}</TableCell>
                <TableCell align="center">{cart.items.length}</TableCell>
                <TableCell align="left">
                  {formatter.format(cart.total)}
                </TableCell>
                <TableCell align="left">{cart.status}</TableCell>
                <TableCell align="left">
                  <Tooltip title="Open basket">
                    <IconButton
                      title="Basket"
                      color={"primary"}
                      size="small"
                      onClick={() => handleGoCart(cart.id)}
                    >
                      <ShoppingCartIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">
                  <Tooltip title="Delete basket">
                    <IconButton
                      title="Delete basket"
                      color={"primary"}
                      size="small"
                      onClick={() => removeCart(cart)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align="center" colSpan={8}>
              <Tooltip title="Create new basket">
                <IconButton
                  title="Create new basket"
                  color={"primary"}
                  size="small"
                  onClick={() =>
                    apiCreateCart(token, customer.id).then(addCart)
                  }
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }, [carts, customer, token]);

  const handleGoCart = (cartId: number) => {
    history.push(`/quotes/${cartId}`);
  };

  const updatedView = (values: {
    [key: string]: {
      old: [string | number | boolean | object | null];
      new: [string | number | boolean | object | null];
    };
  }) => {
    return Object.keys(values).map((key) => {
      console.log(key);
      return (
        <div>
          <b>{key}</b>
          <br />
          <div style={{ marginLeft: "20px", display: "flex" }} key={key}>
            <b>{JSON.stringify(values[key].old, null, 4)}</b>
            <b style={{ marginLeft: "10px", marginRight: "10px" }}>{"->"}</b>
            <b>{JSON.stringify(values[key].new, null, 4)}</b>
          </div>
        </div>
      );
    });
  };

  return customer?.email ? (
    <>
      <Modal isOpen={showCartModal} onClose={() => setShowCartModal(false)}>
        {customerCartList}
      </Modal>
      <TableRow
        sx={{
          "& > *": {
            background: open ? "GainsBoro" : "",
          },
        }}
      >
        <TableCell className={styles.pdRmv}>
          {customer.lastActionDate
            ? reformatDate(customer.lastActionDate)
            : " "}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          {customer?.firstName ? customer?.firstName : "Intet Navn"}{" "}
          {customer.lastName ? customer.lastName : " "}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} className={styles.pdRmv}>
          {reformatDate(customer.created)}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} className={styles.pdRmv}>
          {customerData?.phone ? customerData.phone : "Intet tlf nr"}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} className={styles.pdRmv}>
          {<a href={"mailto: " + customer?.email}>{customer.email}</a>}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} className={styles.pdRmv}>
          {customerData?.company ? customerData.company : "Intet firma"}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} className={styles.pdRmv}>
          {customerData?.street ? customerData.street : "Intet vejnavn"}
        </TableCell>

        <TableCell className={styles.pdRmv}>
          <Tooltip title="Rediger">
            <IconButton
              title="Rediger"
              color={"primary"}
              size="small"
              onClick={() => handleEdit()}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <div style={{ float: "right" }}>
            <Tooltip title="Se indkøbskurv..">
              <IconButton
                title="Indkoebskurv"
                color={"primary"}
                size="small"
                onClick={() => setShowCartModal(true)}
              >
                <ShoppingCartIcon />
              </IconButton>
            </Tooltip>
            {/* {cartButton} */}
          </div>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > *": {
            background: open ? "WhiteSmoke" : "",
            borderRadius: open ? "0 0 5px 5px" : "",
            borderBottom: open ? "2px solid" + primaryColor[0] : "",
            borderLeft: open ? "2px solid" + primaryColor[0] : "",
            borderRight: open ? "2px solid" + primaryColor[0] : "",
          },
        }}
      >
        <TableCell style={{ paddingBottom: 1, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open}>
            <Box sx={{ margin: 3 }}>
              <Typography variant="h6" gutterBottom component="div">
                Kundedetaljer
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography fontWeight={"bold"} variant={"body2"}>
                        By
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontWeight={"bold"} variant={"body2"}>
                        Firma
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontWeight={"bold"} variant={"body2"}>
                        CVR
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"} variant={"body2"}>
                        Vej
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"} variant={"body2"}>
                        Bruger ID
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography fontWeight={"bold"} variant={"body2"}>
                        Seneste ordre
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerData ? (
                    <TableRow>
                      <TableCell>
                        {customerData?.city ? customerData.city : "Ingen by"}
                      </TableCell>
                      <TableCell>
                        {customerData?.company
                          ? customerData.company
                          : "Intet firma"}
                      </TableCell>
                      <TableCell>
                        {customerData?.vat ? customerData.vat : "Intet CVR"}
                      </TableCell>
                      <TableCell>
                        {customerData?.street
                          ? customerData.street
                          : "Intet vejnavn"}
                      </TableCell>
                      <TableCell>{customer.id}</TableCell>

                      <TableCell>
                        {orders && orders?.length > 0
                          ? orders.map((order) => (
                              <Link
                                onClick={() =>
                                  history.push(`/ordrestyring/${order?.id}`)
                                }
                              >
                                {order?.orderNo + ", "}
                              </Link>
                            ))
                          : "Ingen ordre fundet"}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>{"Ingen information"}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* Date Range Selector */}
              {/* <div>
                <label>Start Date:</label>
                <input
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <label>End Date:</label>
                <input
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div> */}

              {/* Event Logs Table */}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {/* <TableSortLabel
                        active={sortKey === "created"}
                        direction={
                          sortKey === "created" ? sortDirection : "asc"
                        }
                        onClick={() => handleSortChange("created")}
                      >
                        Created
                      </TableSortLabel> */}
                      Created
                    </TableCell>
                    <TableCell>
                      <b>Event Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Entity Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Entity ID</b>
                    </TableCell>
                    <TableCell>
                      <b>User Id</b>
                    </TableCell>
                    <TableCell>
                      <b>Data</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventLogs.map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>{reformatDate(log.created)}</TableCell>
                      <TableCell>{log.eventType}</TableCell>
                      <TableCell>{log.entityType}</TableCell>
                      <TableCell>{log.entityId}</TableCell>
                      <TableCell>{log.userId}</TableCell>
                      <TableCell>
                        <pre style={{ margin: 0 }}>
                          {log.diff
                            ? updatedView(log.diff)
                            : JSON.stringify(log.data, null, 4)}
                        </pre>
                      </TableCell>
                      {/* ... other cells if needed */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* Pagination Controls */}
              {/* <TablePagination
                component="div"
                count={total}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
              /> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  ) : (
    <TableRow>
      <TableCell>Intet data</TableCell>
    </TableRow>
  );
};
export default CustomerRow;
