import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "components/Modals/Modal";
import AppStateContext from "contexts/AppStateContext";
import { useEffect, useState } from "react";
import {
  CreatePartnerType,
  UpdatePartnerType,
} from "types/partners/PartnerCommandTypes";
import { PartnerType } from "types/partners/PartnerTypes";
import { apiCreatePartner, apiUpdatePartner } from "util/network/Partners";
import { useContext } from "react";
import { RequisitionTypeEnum } from "types/RequisitionTypes";

type PropsType = {
  open: boolean;
  onClose: () => void;
  onSubmit: (partner: PartnerType) => void;
  partner?: PartnerType;
};

const CreateOrUpdatePartnerModal: React.FC<PropsType> = ({
  open,
  onClose,
  onSubmit,
  partner,
}) => {
  const { token } = useContext(AppStateContext);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [vat, setVat] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [templateDescription, setTemplateDescription] = useState<string>("");
  const [templateDelivery, setTemplateDelivery] = useState<string>("Pickup");
  const [templateType, setTemplateType] = useState<RequisitionTypeEnum>(
    RequisitionTypeEnum.CNC
  );

  const clearForm = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setCompany("");
    setVat("");
    setPhone("");
    setStreet("");
    setZip("");
    setCity("");
    setTemplateDescription("");
    setTemplateDelivery("Pickup");
    setTemplateType(RequisitionTypeEnum.CNC);
  };

  useEffect(() => {
    if (partner) {
      setFirstname(partner.info.firstName);
      setLastname(partner.info.lastName);
      setEmail(partner.info.email);
      setCompany(partner.info.company);
      setVat(partner.info.vat);
      setPhone(partner.info.phone);
      setStreet(partner.info.street);
      setZip(partner.info.postalCode);
      setCity(partner.info.city);
      setTemplateDescription(partner.templateDescription);
      setTemplateDelivery(partner.templateDelivery ?? "Pickup");
      setTemplateType(partner.templateType);
    }
  }, [partner]);

  const onSubmitForm = () => {
    if (partner) {
      const updatedPartner: UpdatePartnerType = {
        info: {
          firstName: firstname,
          lastName: lastname,
          email: email,
          company: company,
          vat: vat,
          phone: phone,
          street: street,
          postalCode: zip,
          city: city,
          countryId: "DK",
        },
        templateDescription: templateDescription,
        templateDelivery: templateDelivery,
        templateType: templateType,
      };
      return apiUpdatePartner(token, partner.id, updatedPartner).then((res) => {
        onSubmit(res);
      });
    } else {
      const newPartner: CreatePartnerType = {
        info: {
          firstName: firstname,
          lastName: lastname,
          email: email,
          company: company,
          vat: vat,
          phone: phone,
          street: street,
          postalCode: zip,
          city: city,
          countryId: "DK",
        },
        templateDescription: templateDescription,
        templateDelivery: templateDelivery,
        templateType: templateType,
      };
      return apiCreatePartner(token, newPartner).then((res) => {
        onSubmit(res);
      });
    }
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <div
        style={{
          width: "20em",
        }}
      >
        <Typography variant="h4">
          {partner ? "Update Partner" : "Create Partner"}
        </Typography>

        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
          }}
        >
          <TextField
            label="First Name"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            margin="normal"
            tabIndex={1}
          />
          <TextField
            label="Last Name"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            margin="normal"
            tabIndex={2}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
          }}
        >
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            tabIndex={3}
          />
          <TextField
            label="CVR"
            value={vat}
            onChange={(e) => setVat(e.target.value)}
            margin="normal"
            tabIndex={4}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
          }}
        >
          <TextField
            label="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            margin="normal"
            tabIndex={5}
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="normal"
            tabIndex={6}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
          }}
        >
          <TextField
            label="Street"
            value={street}
            fullWidth
            onChange={(e) => setStreet(e.target.value)}
            margin="normal"
            tabIndex={7}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
          }}
        >
          <TextField
            label="Zip"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            margin="normal"
            tabIndex={8}
          />
          <TextField
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            margin="normal"
            tabIndex={9}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="default-type-select-label">Type</InputLabel>
            <Select
              labelId="default-type-select-label"
              value={templateType}
              onChange={(e) =>
                setTemplateType(e.target.value as RequisitionTypeEnum)
              }
              label="Type"
              tabIndex={10}
            >
              {Object.values(RequisitionTypeEnum).map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="default-delivery-select-label">Delivery</InputLabel>
            <Select
              labelId="default-delivery-select-label"
              value={templateDelivery}
              onChange={(e) => setTemplateDelivery(e.target.value)}
              label="Delivery"
              tabIndex={11}
            >
              <MenuItem value={"Delivery"}>Delivery</MenuItem>
              <MenuItem value={"Pickup"}>Pickup</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
          }}
        >
          <TextField
            label="Description"
            value={templateDescription}
            fullWidth
            onChange={(e) => setTemplateDescription(e.target.value)}
            margin="normal"
            multiline
            tabIndex={12}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => onClose()} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() =>
              onSubmitForm().then((res) => {
                // Clear form
                clearForm();
              })
            }
            variant="contained"
          >
            {partner ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateOrUpdatePartnerModal;
