import { Table, TableCell, TableRow } from "@mui/material";
import { FunctionComponent, useMemo } from "react";
import { SelectedThreadsInfoType } from "types/RenderTypes";
import { Square } from "@mui/icons-material";

type PropsType = {
  specs: SelectedThreadsInfoType[];
};

const ThreadDisplay: FunctionComponent<PropsType> = ({ specs }) => {
  function HSLToRGB(h: number, s: number, l: number) {
    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return "RGB: (" + r + "," + g + "," + b + ")";
  }

  const uniqueThreads = useMemo(() => {
    const res: { [key: string]: any } = {};
    specs.forEach(
      (spec) =>
        (res[spec.name] = {
          color: `rgb(${spec.color?.r}, ${spec.color?.g}, ${spec.color?.b})`,
          count: (res[spec.name]?.count ?? 0) + 1,
        })
    );
    return res;
  }, [specs]);

  return specs.length > 0 ? (
    <Table size="small" sx={{ overflowY: "scroll" }}>
      {Object.keys(uniqueThreads).map((key, index) => (
        <TableRow key={index}>
          <TableCell sx={{ width: "8em" }}>{key}</TableCell>
          <TableCell>
            <Square
              style={{
                width: "15px",
                height: "15px",
                color: `${uniqueThreads[key].color}`,
                backgroundColor: `${uniqueThreads[key].color}`,
                display: "flex",
                alignItems: "center",
              }}
            />
          </TableCell>
          <TableCell align="right">({uniqueThreads[key].count})</TableCell>
        </TableRow>
      ))}
    </Table>
  ) : (
    <TableRow>
      <TableCell> {"Ingen gevind fundet"}</TableCell>
    </TableRow>
  );
};

export default ThreadDisplay;
