import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ArrowDropDown } from "@mui/icons-material";
import { OrderStatusEnum } from "types/OrderTypes";
import OrderStatusModal from "components/Modals/OrderStatusModal";
import React, { useMemo, useState } from "react";

type PropsType = {
  currentStatus: OrderStatusEnum;
  entityID: number;
  onChange: (val: OrderStatusEnum) => void;
};

const OrderStatusButton: React.FC<PropsType> = ({
  currentStatus,
  entityID,
  onChange,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<OrderStatusEnum>(currentStatus);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempStatus, setTempStatus] = useState<OrderStatusEnum>(currentStatus);

  const override = Boolean(sessionStorage.getItem("debug") === "true");

  const open = Boolean(anchorEl);

  // Open menu
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  // Close menu without selection
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (status: OrderStatusEnum) => {
    setStatus(tempStatus);
    onChange(status);
    setShowModal(false);
  };

  // Open confirmation dialog
  const handleMenuItemClick = (status: string) => {
    const newStatus = status as OrderStatusEnum;
    setAnchorEl(null);

    setTempStatus(newStatus);
    setShowModal(true);
  };

  const statusOptions = (currStatus: string, ovride?: boolean) => {
    if (ovride) {
      return ["REVIEW", "PROCESSING", "COMPLETE", "CANCELLED"];
    }
    switch (currStatus) {
      case "UNPAID":
        return ["CANCELLED"];
      case "REVIEW":
        return ["PROCESSING", "CANCELLED"];
      case "PROCESSING":
        return ["COMPLETE", "CANCELLED"];
      default:
        return [];
    }
  };

  const menuItems = useMemo(() => {
    const possibleStatus = statusOptions(status, override);
    if (possibleStatus.length === 0) {
      return null;
    }
    return possibleStatus.map((option, index) =>
      option !== "UNPAID" ? (
        <MenuItem
          key={index}
          selected={status === option}
          onClick={(event: any) => handleMenuItemClick(option)}
        >
          {option}
        </MenuItem>
      ) : null
    );
  }, [override, status]);

  return (
    <div>
      <OrderStatusModal
        orderId={entityID}
        status={tempStatus}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmit}
      />
      <Button
        disabled={status === "CANCELLED" || status === "COMPLETE"}
        endIcon={
          status !== "CANCELLED" && status !== "COMPLETE" ? (
            <ArrowDropDown />
          ) : null
        }
        color={
          status === "CANCELLED"
            ? "secondary"
            : status === "PROCESSING" || status === "UNPAID"
            ? "info"
            : status === "REVIEW"
            ? "warning"
            : "primary"
        }
        onClick={handleClick}
        style={{
          fontWeight: "bold",
          border:
            status === "CANCELLED"
              ? "1px solid #474848"
              : status === "COMPLETE"
              ? "1px solid #075E5B"
              : status === "REVIEW"
              ? "1px solid #E0A900"
              : status === "PROCESSING"
              ? "1px solid #007474"
              : status === "UNPAID"
              ? "1px solid #007474"
              : undefined,
          color:
            status === "CANCELLED"
              ? "#474848"
              : status === "COMPLETE"
              ? "#075E5B"
              : status === "REVIEW"
              ? "#E0A900"
              : status === "PROCESSING"
              ? "#007474"
              : status === "UNPAID"
              ? "#007474"
              : undefined,
          background:
            status === "CANCELLED"
              ? " rgba(71, 72, 72, 0.1)"
              : status === "COMPLETE"
              ? "rgba(7, 94, 91, 0.1)"
              : status === "REVIEW"
              ? "rgba(224, 169, 0, 0.1)"
              : status === "PROCESSING"
              ? "rgba(0, 116, 116, 0.1)"
              : status === "UNPAID"
              ? "rgba(0, 116, 116, 0.1)"
              : undefined,
          // marginTop: "3px",
          // marginBottom: "3px",
          borderRadius: "5px",
        }}
      >
        {status}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems}
      </Menu>
    </div>
  );
};

export default OrderStatusButton;
