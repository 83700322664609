import { FetchError } from "util/exceptions";
import { API_URL } from "./common";

const BASE_URL = `${API_URL}/v1/ecommerce/fileresources`;

export const apiCreateFileResource = (token: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return fetch(`${BASE_URL}`, {
    method: "POST",
    headers: headers,
    body: formData,
  }).then((res) => res.json());
};

export const apiDeleteFileResource = (token: string, id: number) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return fetch(`${BASE_URL}/${id}`, {
    method: "DELETE",
    headers: headers,
  }).then((res) => {
    if (res.ok) {
      return true;
    } else {
      throw new FetchError(res.statusText, res.status);
    }
  });
};

export const apiDownloadFileResource = (
  token: string,
  id: number,
  name: string
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return fetch(`${BASE_URL}/${id}/download`, {
    method: "GET",
    headers: headers,
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
