import { format } from "date-fns";
import { epgChannels } from "./epg-channels";
import { epgs } from "./epgs";
import { CNCPlan, fetchCNCPlans } from "../../../plans/CNCPlansAPI";
import { Job, fetchJobs } from "../../../jobs/JobsAPI";

export const getToday = ({
  date = "2023-12-16",
  formatType = "yyyy-MM-dd",
} = {}) => format(new Date(), formatType);


export const getJobs = async (date: Date) => {
  const channels = epgChannels;
  
  try {
    // Calculate the start and end of the specified date
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1); // Next day

    // Fetch jobs data from the beginning to the end of the specified date
    const jobs = await fetchJobs(undefined, startOfDay.toISOString(), endOfDay.toISOString());
    
    // You can transform the CNC plan data into the desired format if needed.
    // For example, mapping it to match your existing EPG format.
    const epg = jobs.map((job: Job) => ({
      id: job.id.toString(),
      title: job.item,
      since: job.start?.toISOString(), // Convert Date to ISO string
      till: job.end?.toISOString(), // Convert to Date ISO string
      subtitle: job.file,
      machine: job.machine,
      image: "https://dashboard.vaerks.com/api/v1/ecommerce/models/" + job.item + "/preview",  // Add logo URL if available
    }));

    return { channels, epg };
  } catch (error) {
    console.error('Error fetching CNC plans data:', error);
    throw error;
  }
};

export const getPlans = async (date: Date) => {
  try {
    // Fetch CNC plans data from the API
    // const cncPlans: CNCPlan[] = await fetchCNCPlans();
    const cncPlans: CNCPlan[] = await fetchCNCPlans();
    
    // You can transform the CNC plan data into the desired format if needed.
    // For example, mapping it to match your existing EPG format.
    console.log(cncPlans);
    const epg = cncPlans.map((cncPlan) => ({
      id: cncPlan.id.toString(),
      title: cncPlan.program,
      since: cncPlan.start?.toISOString(), // Convert Date to ISO string
      till: cncPlan.end?.toISOString(), // Convert to Date ISO string
      machine: cncPlan.machine,
      image: "", // Add image URL if available
      logo: "",  // Add logo URL if available
    }));

    return epg;
  } catch (error) {
    console.error('Error fetching CNC plans data:', error);
    throw error;
  }
};
