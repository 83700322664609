export const formatter = new Intl.NumberFormat("da-DK", {
  style: "currency",
  currency: "DKK",
});

export const formatterNoDec = new Intl.NumberFormat("da-DK", {
  style: "currency",
  currency: "DKK",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const reformatDate = (date) => {
  // console.log(date);
  let reformat = new Date(date);
  return reformat.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const reformatDate2 = (date) => {
  let reformat = new Date(date);
  return (
    reformat.toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    }) +
    ", " +
    reformat.toLocaleString("en-GB", {
      month: "short",
      day: "2-digit",
    })
  );
};

export const timeFormat = (date) => {
  let reformat = new Date(date);
  return reformat.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
};
