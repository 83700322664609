// Author: Magnus s195401 & Erik s195397
import { Clear, LockReset } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { Props } from "@react-three/fiber";
import { FunctionComponent, useContext } from "react";
import { UserType } from "types/users/UserTypes";
import EditIcon from "@mui/icons-material/Edit";
import { apiRequestPasswordReset } from "util/network/Auth";
import AppStateContext from "contexts/AppStateContext";
import { PartnerType } from "types/partners/PartnerTypes";

type PropsType = {
  partner: PartnerType;
  setExisting: Function;
  setShowPartnerModal: (show: boolean) => void;
  setShowDeleteUser: Function;
};
// Partner Row
const PartnerRow: FunctionComponent<PropsType> = ({
  partner,
  setExisting,
  setShowPartnerModal,
  setShowDeleteUser,
}) => {
  // Edit user
  const handleEdit = () => {
    setExisting(partner);
    setShowPartnerModal(true);
  };

  // Delete user
  const handleDelete = () => {
    setExisting(partner);
    setShowDeleteUser(true);
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ paddingLeft: "20px" }}>
          {partner?.info.firstName ? partner?.info.firstName : "Intet Navn"}{" "}
          {partner.info.lastName ? partner.info.lastName : " "}
        </TableCell>
        <TableCell>{partner.info?.phone || " No phone "}</TableCell>
        <TableCell>
          {<a href={"mailto: " + partner?.info.email}>{partner.info.email}</a>}
        </TableCell>
        <TableCell>{partner.info?.company || "Not defined"}</TableCell>
        <TableCell>{partner.info?.street || "Not defined"}</TableCell>
        <TableCell>
          <IconButton
            disabled={false}
            title="Rediger"
            color={"primary"}
            size="small"
            onClick={() => handleEdit()}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            title="Slet"
            color={"primary"}
            size="small"
            onClick={() => handleDelete()}
          >
            <Clear />
          </IconButton>
        </TableCell>

        <TableCell></TableCell>
      </TableRow>
    </>
  );
};

export default PartnerRow;
