import React, { useState } from "react";
import MachineForm from "./CreateMachineForm";
import styles from "./MachineModal.module.css";
import { Button } from "@mui/material";

const MachineModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <button onClick={handleModalOpen} className={styles.openModalButton}>
        Add Machine +
      </button>
      {modalOpen && (
        <div className={styles.modalOverlay} onClick={handleModalClose}>
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button onClick={handleModalClose} className={styles.closeButton}>
              &times;
            </button>
            <MachineForm />
          </div>
        </div>
      )}
    </>
  );
};

export default MachineModal;
