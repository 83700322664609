import { Color, HSL } from "three";

export const fadedColor = (color: Color | string | number, amount?: number) => {
  let hsl: HSL = { h: 0, s: 0, l: 0 };
  new Color(color).getHSL(hsl);
  const newColor = new Color().setHSL(hsl.h, hsl.s, hsl.l + (amount ?? 0.1));
  return newColor.getHex();
};

export const staticColors = {
  default: 0xeaeaea,
  select: 0x00b28d, //0x3590ff,
  hover: fadedColor(0x00b28d, 0.1),
  hoverAlt: 0xf83a1d,
  edge: 0x111111,
  black: 0x000000,
  error: 0xff0f0f,
};
