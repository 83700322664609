import { FunctionComponent } from "react";
import { Card, CardActionArea } from "@mui/material";

const styles = {
  cardSelected: {
    backgroundColor: "#E1A900",
    color: "white",
    marginTop: "1%",
    marginBottom: "1%",
    borderLeft: "3px solid " + "#E1A900",
    borderTop: "3px solid " + "#E1A900",
    borderRight: "3px solid " + "#E1A900",
    transition: "transform .2s",
    width: "7vw",
    height: "7vh",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  cardNotSelected: {
    backgroundColor: "#fff",
    color: "black",
    marginTop: "1%",
    marginBottom: "1%",
    transition: "transform .2s",
    width: "7vw",
    height: "7vh",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  cardDisabled: {
    width: "7vw",
    height: "7vh",
    backgroundColor: "#eeeeee",
    color: "black",
    marginTop: "1%",
    marginBottom: "1%",
    transition: "transform .2s",
  },
};

type PropsType = {
  title: string;
  picture: string;
  active?: boolean;
  onClick?: Function;
  disabled?: boolean;
};

const PaymentCard: FunctionComponent<PropsType> = ({
  title,
  picture,
  active,
  onClick,
  disabled,
}) => {
  return (
    <Card
      sx={
        !disabled
          ? active
            ? styles.cardSelected
            : styles.cardNotSelected
          : styles.cardDisabled
      }
      onClick={() => !disabled && onClick && onClick()}
    >
      <CardActionArea
        style={{
          display: "flex",
          padding: "1em",
        }}
      >
        <img
          style={{
            alignSelf: "center",
            width: "auto",
            height: "2em",
            margin: "0.5em",
          }}
          src={picture}
          title={title}
          alt="SHIPPING_COMPANY"
        />
      </CardActionArea>
    </Card>
  );
};

export default PaymentCard;
