import { TableCell, TableRow, IconButton, LinearProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Clear, LockReset } from "@mui/icons-material";
import React, { FunctionComponent, useContext } from "react";
import AppStateContext from "../../contexts/AppStateContext";
import { AccessEnum, UserType } from "../../types/users/UserTypes";
import { apiRequestPasswordReset } from "util/network/Auth";

type PropsType = {
  user: UserType;
  setExisting: Function;
  setEditEmployeeModal: Function;
  setShowDeleteUser: Function;
};

const EmployeeRow: FunctionComponent<PropsType> = ({
  user,
  setExisting,
  setEditEmployeeModal,
  setShowDeleteUser,
}) => {
  const { setShowResetPassword, token } = useContext(AppStateContext);
  const handleEdit = () => {
    setExisting(user);
    setEditEmployeeModal(true);
  };

  const handleDelete = () => {
    setExisting(user);
    setShowDeleteUser(true);
    //   const choice = window.confirm(
    //     "Are you sure you want to delete this user? This cannot be undone."
    //   );
    //   if (!choice) return;
    //   apiDeleteUser(token, user.id)
    //     .then((res) => {
    //       if (res) window.alert("User was successfully deleted");
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
  };

  const handleReset = () => {
    const choice = window.confirm(
      "Are you sure you want to reset the users password? An email with a password reset link will be sent to the user."
    );
    if (!choice) return;
    apiRequestPasswordReset(user.email).catch((e) => {
      console.log(e);
    });
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ paddingLeft: "20px" }}>
          {user?.firstName ? user?.firstName : "Intet Navn"}{" "}
          {user.lastName ? user.lastName : " "}
        </TableCell>
        <TableCell>
          {<a href={"mailto: " + user?.email}>{user.email}</a>}
        </TableCell>
        <TableCell>
          {user?.accessLevel ? AccessEnum[user?.accessLevel] : "N/A"}
        </TableCell>
        <TableCell>
          {user?.accessLevel === 4 ? (
            <IconButton disabled={true}>
              <EditIcon />
            </IconButton>
          ) : (
            <IconButton
              disabled={false}
              title="Rediger"
              color={"primary"}
              size="small"
              onClick={() => handleEdit()}
            >
              <EditIcon />
            </IconButton>
          )}
          {user?.accessLevel === 4 ? (
            <IconButton disabled={true}>
              <Clear />
            </IconButton>
          ) : (
            <IconButton
              title="Slet"
              color={"primary"}
              size="small"
              onClick={() => handleDelete()}
            >
              <Clear />
            </IconButton>
          )}
        </TableCell>

        <TableCell>
          <IconButton
            title="Reset Password"
            color={"primary"}
            size="small"
            onClick={() => handleReset()}
          >
            <LockReset />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmployeeRow;
