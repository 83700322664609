import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppStateContext from "contexts/AppStateContext";
import {
  ProductionTaskStateEnum,
  ProductionTaskType,
} from "types/ProductionType";
import { apiUpdateProductionTask } from "util/network/Productions";
import { MachineType } from "types/MachineType";
import InitialsCircle from "components/InitalsCircle";
import { PersonAdd } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import styles from "./css/assignPersonMachine.module.css";
import MachineModal from "components/MachineModal";
import { AccessEnum } from "types/users/UserTypes";

type PropsType = {
  initTask: Partial<ProductionTaskType>;
  taskState?: ProductionTaskStateEnum;
  assignShow?: boolean;
  taskId?: string;
  machines?: MachineType[];
  isHover?: boolean;
  isModal?: boolean;
};

const AssignPersonMachine: FunctionComponent<PropsType> = ({
  initTask,
  assignShow,
  taskState,
  taskId,
  machines,
  isHover,
  isModal,
}) => {
  const bannedEmails = [
    "can@easypartz.com",
    "rna@easypartz.com",
    "spa@easypartz.com",
    "info@easypartz.com",
    "usr2@vaerks.com",
    "admin@vaerks.com",
    "machine@vaerks.com",
    "machine1@easypartz.com",
    "machine2@easypartz.com",
    "machine3@easypartz.com",
    "machine4@easypartz.com",
  ];
  const { employees, token } = useContext(AppStateContext);
  const operators = employees.items?.filter(
    (employee) =>
      employee.accessLevel >= AccessEnum.OPERATOR &&
      !bannedEmails.includes(employee.email)
  );
  const [assignedUsers, setAssignedUsers] = useState<number[]>([]);
  const [assignedMachines, setAssignedMachines] = useState<string[]>([]);
  // const [task, setTask] = useState<ProductionTaskType | undefined>(initTask);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [machineDropdownOpen, setMachineDropdownOpen] = useState(false);
  const [isHoveredPerson, setIsHoveredPerson] = useState(false);
  const [isHoveredMachine, setIsHoveredMachine] = useState(false);
  const machineButtonRef = useRef<HTMLButtonElement>(null);
  const personButtonRef = useRef<HTMLButtonElement>(null);
  const [dropdownMachinePosition, setDropdownMachinePosition] = useState({
    top: 0,
    left: 0,
  });
  const [dropdownPersonPosition, setDropdownPersonPosition] = useState({
    top: 0,
    left: 0,
  });

  const handleAssign = (selected: any) => {
    const machineLabels: string[] = [];
    const users: number[] = [];
    selected.forEach((val: any) => {
      if (typeof val === "string") {
        machineLabels.push(val);
      }
      if (typeof val === "number") {
        users.push(val);
      }
    });
    setAssignedMachines(machineLabels);
    setAssignedUsers(users);
    if (taskId && machines) {
      const machIds: number[] = [];
      machineLabels.forEach((machLabel) => {
        const machObject = machines.find((searched) => {
          // console.log(typeof searched.label, typeof machLabel);
          return searched.label === machLabel;
        });
        // console.log(machObject);
        machObject !== undefined && machIds.push(machObject.id);
      });
      // console.log(machIds);
      const machine = machineLabels.map((machine) => {
        return Number(machine.replace("M", ""));
      });
      console.log("Updating production task:", { taskId, users, machine });
      apiUpdateProductionTask(token, Number(taskId), {
        id: Number(taskId),
        users: users,
        machines: machine,
      }).then((res) => {
        // setTask(
        //   res.tasks?.find(
        //     (newTask: ProductionTaskType) => newTask.id === taskId
        //   )
        // );
      });
    }
  };

  // console.log(machines);

  useEffect(() => {
    if (initTask.users) setAssignedUsers(initTask.users.map((user) => user.id));
    if (initTask.machines)
      setAssignedMachines(initTask.machines.map((mach) => mach.label));
  }, [initTask]);

  const toggleItem = (type: "user" | "machine", id: number | string) => {
    if (type === "user") {
      setAssignedUsers((prev) => {
        const updated = prev.includes(id as number)
          ? prev.filter((uid) => uid !== id)
          : [...prev, id as number];
        handleAssign([...updated, ...assignedMachines]);
        return updated;
      });
    } else if (type === "machine") {
      setAssignedMachines((prev) => {
        const updated = prev.includes(id.toString())
          ? prev.filter((mid) => mid !== id.toString())
          : [...prev, id.toString()];
        console.log("Toggle machine", updated);
        handleAssign([...updated, ...assignedUsers]);
        return updated;
      });
    }
  };

  const removeUser = (userId: number) => {
    setAssignedUsers((prev) => {
      const updated = prev.filter((id) => id !== userId);
      handleAssign([...updated, ...assignedMachines]);
      return updated;
    });
    console.log("Removing user with ID:", userId);
  };

  const removeMachine = (machineId: string) => {
    setAssignedMachines((prev) => {
      const updated = prev.filter((id) => id !== machineId);
      handleAssign([...updated, ...assignedUsers]);
      return updated;
    });
    console.log("Removing machine:", machineId);
  };

  useEffect(() => {
    const handleWindowClick = (event: any) => {
      if (
        dropdownOpen &&
        personButtonRef.current &&
        !personButtonRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      window.addEventListener("click", handleWindowClick);
    }

    return () => window.removeEventListener("click", handleWindowClick);
  }, [dropdownOpen]);

  const closeDropdown = (e: any) => {
    setDropdownOpen(false);
    setMachineDropdownOpen(false);
    e.stopPropagation();
  };

  // console.log("initTask:", initTask);
  // console.log("taskId:", taskId);
  // console.log("taskState:", taskState);

  return (
    <>
    {/* Machines container */}
    <div className={`${styles.container}`}>

      {/* Assign User button */}
      {initTask.state !== "DONE" && initTask.allowUsers && (
        <div className={`${styles.container}`}
        onMouseEnter={() => setIsHoveredPerson(true)}
        onMouseLeave={() => setIsHoveredPerson(false)}
      >
          <button
            ref={personButtonRef}
            onClick={(e) => {
              if (personButtonRef.current) {
                console.log(personButtonRef.current)
                const rect = personButtonRef.current.getBoundingClientRect();
                setDropdownPersonPosition({
                  top: rect.bottom + window.scrollY,
                  left: rect.left + window.scrollX,
                });
              }
              setDropdownOpen(!dropdownOpen);
              e.stopPropagation();
            }}
            onMouseEnter={() => setIsHoveredPerson(true)}
            onMouseLeave={() => setIsHoveredPerson(false)}
            className={`${styles.button_icon}`}
          >
            <Tooltip title={"Add employee"}>
              <PersonAdd
                className={isHoveredPerson ? styles.icon_hover : styles.icon}
              />
            </Tooltip>
          </button>
        </div>
      )}

      {/* Assigned users */}
      <div className={`${styles.container__initials}`}>
        {assignedUsers.map((uid) => {
          const user = operators?.find((e) => e.id === uid);
          return (
            user && (
              <InitialsCircle
                  initials={`${user?.email?.charAt(0)}${user?.email?.charAt(1)}${user?.email?.charAt(2)}`}
                  onClick={(e) => {
                    removeUser(user.id);
                    e.stopPropagation();
                  }}
                />
            )
          );
        })}
      </div>

      {/* Assign User Drowpdown */}
      {dropdownOpen && (
        <div className={styles.overlay} onClick={closeDropdown} />
      )}
      {dropdownOpen && (
        <div
          className={styles.dropdown_container}
          style={{
            display: dropdownOpen ? "block" : "none",
            top: isModal ? "" : `${dropdownPersonPosition.top}px`,
            left: isModal ? "" : `${dropdownPersonPosition.left}px`,
          }}
        >
          {operators?.map((employee) => (
            <div
              key={`employee${employee.id}`}
              onClick={(e) => {
                toggleItem("user", employee.id);
                e.stopPropagation();
              }}
              style={{
                color: assignedUsers.includes(employee.id)
                  ? "white"
                  : "rgba(0, 0, 0, 0.87)",
                backgroundColor: assignedUsers.includes(employee.id)
                  ? "#075E5B"
                  : "#fafafa",
              }}
              className={`${styles.dropdown_item}`}
            >
              <span className={`${styles.dropdown__item}`}>
                {employee.firstName} {employee.lastName}
              </span>
            </div>
          ))}
        </div>
      )}
      </div>

      {/* Machines container */}
      <div className={`${styles.container}`}>

        {/* Assign Machine Button */}
        {initTask.state !== "DONE" && initTask.allowMachines && (
          <button
            ref={machineButtonRef}
            className={`${styles.button_icon}`}
            onClick={(e) => {
              if (machineButtonRef.current) {
                const rect = machineButtonRef.current.getBoundingClientRect();
                setDropdownMachinePosition({
                  top: rect.bottom + window.scrollY,
                  left: rect.left + window.scrollX,
                });
              }
              setMachineDropdownOpen(!machineDropdownOpen);
              e.stopPropagation();
            }}  
            onMouseEnter={() => setIsHoveredMachine(true)}
            onMouseLeave={() => setIsHoveredMachine(false)}
          >
            <Tooltip title={"Add machine"}>
              <AddBoxIcon className={isHoveredMachine ? styles.icon_hover : styles.icon}/>
            </Tooltip>
          </button>
        )}

        {/* Assigned Machines */}
        <div className={styles.container__initials}>
          {assignedMachines.map((machineID) => {
              return (
                <InitialsCircle
                  key={`AM${machineID}`}
                  initials={machineID}
                  style
                  onClick={(e) => {
                    removeMachine(machineID);
                    e.stopPropagation();
                  }}
                />
              );
            })
          }
        </div>

        {/* Assigned Machines Dropdown */}
        {machineDropdownOpen && (
          <div className={styles.overlay} onClick={closeDropdown} />
        )}
        {machineDropdownOpen && (
          <div
            className={styles.dropdown_container}
            style={{
              display: machineDropdownOpen ? "block" : "none",
              top: isModal ? "" : `${dropdownMachinePosition.top}px`,
              left: isModal ? "" : `${dropdownMachinePosition.left}px`,
              minWidth: "40px",
            }}
          >
            {machines?.map((machine) => (
              <div
                key={`M${machine.id}`}
                onClick={(e) => {
                  toggleItem("machine", `M${machine.id}`);
                  e.stopPropagation();
                }}
                style={{
                  color: assignedMachines.includes(`M${machine.id}`)
                    ? "white"
                    : "rgba(0, 0, 0, 0.87)",
                  backgroundColor: assignedMachines.includes(`M${machine.id}`)
                    ? "#075E5B"
                    : "#fafafa",
                }}
                className={`${styles.dropdown_item}`}
              >
                <span className={`${styles.dropdown__item}`}>
                  {machine.label}
                </span>
              </div>
            ))}
          </div>
        )}
        
      </div>
    </>
  );
};

export default AssignPersonMachine;
