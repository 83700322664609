import AppStateContext from "contexts/AppStateContext";
import React, { useState, useContext, FormEvent } from "react";
import { apiCreateMachine } from "util/network/Machine";
import styles from "./CreateMachineForm.module.css";

const MachineForm = () => {
  const [number, setNumber] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { token } = useContext(AppStateContext);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    const name = `Machine ${number}`;
    const label = `M${number}`;

    apiCreateMachine(token, name, label)
      .then((res) => {
        console.log(res);
        setNumber("");
        alert("Machine created successfully!");
      })
      .catch((err) => {
        alert("Error: Could not create machine");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={styles.container}>
      <h2>Create a New Machine</h2>
      <p>
        Please enter a number for the new machine. The machine name and label
        will be generated based on the number.
      </p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label htmlFor="number" className={styles.label}>
          Number:
        </label>
        <input
          type="number"
          id="number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          className={styles.input}
          required
        />
        <button type="submit" disabled={submitting} className={styles.button}>
          Create Machine
        </button>
      </form>
    </div>
  );
};

export default MachineForm;
