// CNCPlansAPI.ts

import { API_URL } from "../network/Common";

// Define the endpoint URL where you'll fetch the data (replace with your actual API URL)
const baseURL = `${API_URL}/v1/tooldb/cnc_plan`;

// Define a type for the CNC plan data
export interface CNCPlan {
  id: number;
  item?: number;
  task?: number;
  program: string;
  description: string;
  tools: number[];
  start?: Date;
  end?: Date;
  machine?: string;
  expected_work_min: number;
  status: string;
}

// Function to fetch CNC plans data from the API
export async function fetchCNCPlans() {
  try {
    const response = await fetch(baseURL);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    // Convert UTC timestamps to local time
    const cncPlans: CNCPlan[] = data.map((data: any) => ({
      id: data.id,
      item: Number(data.item),
      task: Number(data.task),
      program: data.program,
      description: data.description,
      tools: data.tools
        ? data.tools.split(";").map((tool: string) => parseInt(tool, 10))
        : [],
      start: data.start ? new Date(data.start) : undefined,
      end: data.end ? new Date(data.end) : undefined,
      machine: data.machine,
      expected_work_min: Number(data.expected_work_min),
      status: data.status,
    }));

    return cncPlans;
  } catch (error) {
    console.error("Error fetching CNC plans data:", error);
    // throw error;
    return [];
  }
}

// Function to add a new CNC plan to the API
export async function addCNCPlan(newCNCPlan: CNCPlan) {
  try {
    console.log(newCNCPlan.start);
    console.log(JSON.stringify(newCNCPlan.start));
    console.log(JSON.stringify(newCNCPlan));
    const response = await fetch(baseURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newCNCPlan),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error adding CNC plan:", error);
    // throw error;
  }
}

// Function to update an existing CNC plan in the API by ID
export async function updateCNCPlan(
  cncPlanId: number,
  updatedCNCPlan: unknown
) {
  try {
    const response = await fetch(`${baseURL}/${cncPlanId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedCNCPlan),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating CNC plan:", error);
    // throw error;
  }
}

// Function to delete a CNC plan from the API by ID
export async function deleteCNCPlan(cncPlanId: number) {
  try {
    const response = await fetch(`${baseURL}/${cncPlanId}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error deleting CNC plan:", error);
    // throw error;
  }
}
