import { FunctionComponent } from "react";
import { Card, CardActionArea, Typography } from "@mui/material";

const styles = {
  shippingCardSelected: {
    backgroundColor: "#E1A900",
    color: "white",
    marginTop: "1%",
    marginBottom: "1%",
    borderLeft: "3px solid " + "#E1A900",
    borderTop: "3px solid " + "#E1A900",
    borderRight: "3px solid " + "#E1A900",
    transition: "transform .2s",
    width: "7vw",
    height: "7vh",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  shippingCardNotSelected: {
    backgroundColor: "#fff",
    color: "black",
    marginTop: "1%",
    marginBottom: "1%",
    transition: "transform .2s",
    width: "7vw",
    height: "7vh",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
  },
  shippingCardDisabled: {
    width: "7vw",
    height: "7vh",
    backgroundColor: "#eeeeee",
    color: "black",
    marginTop: "1%",
    marginBottom: "1%",
    transition: "transform .2s",
  },
};

type PropsType = {
  shippingTitle: string;
  shippingPrice?: number;
  isSelected: boolean;
  disabled?: boolean;
  onClick?: Function;
};

const ShippingCard: FunctionComponent<PropsType> = ({
  shippingTitle,
  shippingPrice,
  isSelected,
  disabled,
  onClick,
}) => {
  const formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
  });

  return (
    <Card
      sx={
        !disabled
          ? isSelected
            ? styles.shippingCardSelected
            : styles.shippingCardNotSelected
          : styles.shippingCardDisabled
      }
      onClick={() => {
        if (disabled) return;
        onClick && onClick();
      }}
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CardActionArea
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <Typography
            gutterBottom
            sx={{ fontSize: "0.8rem", fontWeight: "bold", textAlign: "center" }}
          >
            {shippingTitle?.toUpperCase()}
          </Typography>
        </div>
        <div
          style={{
            fontSize: "0.7rem",
          }}
        >
          {shippingPrice && formatter.format(shippingPrice)}
        </div>
      </CardActionArea>
    </Card>
  );
};

export default ShippingCard;
