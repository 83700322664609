import { FunctionComponent, useContext, useMemo, useState } from "react";
import AppStateContext from "../../../contexts/AppStateContext";
import { CheckCircle, Info } from "@mui/icons-material";
import { DFMInfoType } from "types/RenderTypes";
import { Icon, Tooltip } from "@mui/material";

type PropsType = {
  dfmErrors?: DFMInfoType | null;
};

const DFMErrors: FunctionComponent<PropsType> = ({ dfmErrors }) => {
  const { strings } = useContext(AppStateContext);

  const holeDeeperThan5xDiameter = useMemo(() => {
    return (
      dfmErrors?.holeAnalysis?.holes?.some((hole) => {
        const allDiameters = [
          ...hole.bores.map((bore) => bore.diameter),
          ...hole.countersinks.map((countersink) => countersink.diameter),
          ...hole.counterbores.map((counterbore) => counterbore.diameter),
        ];

        const maxDiameter = Math.max(...allDiameters);

        // hole.totalDepth > 5 * maxDiameter;
        return hole.totalDepth > 5 * maxDiameter;
      }) ?? false
    );
  }, [dfmErrors]);

  const errors = useMemo(() => {
    if (!dfmErrors)
      return {
        inaccessibleEdges: false,
        inaccessibleVertices: false,
        inaccessibleFaces: false,
        oversized: false,
        undersized: false,
        thin: false,
        clearance: false,
        deep: false,
      };
    let x,
      y,
      z = 0;
    let numbers = [
      dfmErrors.boundingAnalysis.bboxDx,
      dfmErrors.boundingAnalysis.bboxDy,
      dfmErrors.boundingAnalysis.bboxDz,
    ];
    numbers.sort((a, b) => b - a);
    x = numbers[0];
    y = numbers[1];
    z = numbers[2];
    return {
      inaccessibleEdges: dfmErrors?.edgeAccessibility.numInaccessibleEdges > 0,
      inaccessibleVertices:
        dfmErrors?.vertexAccessibility.numInaccessibleVertices > 0,
      inaccessibleFaces: dfmErrors?.faceAccessibility.numInaccessibleFaces > 0,
      oversized:
        x > dfmErrors.boundingAnalysis.refDx ||
        y > dfmErrors.boundingAnalysis.refDy ||
        z > dfmErrors.boundingAnalysis.refDz,
      undersized: x < 8 || y < 9 || z < 2,
      thin:
        dfmErrors?.thicknessAnalysis.minThickness !== null &&
        dfmErrors?.thicknessAnalysis.minThickness <
          dfmErrors?.thicknessAnalysis.refThickness,
      clearance:
        dfmErrors?.clearanceAnalysis.minClearance <
        dfmErrors?.clearanceAnalysis.refClearance,
      deep: holeDeeperThan5xDiameter,
    };
  }, [dfmErrors]);

  const DFMWarnings = useMemo(
    () => (
      <div>
        <Icon>
          {dfmErrors ? (
            <Tooltip
              title={
                <div>
                  {/* {false && <div>{strings.DFMErrorNoSolidsTitle}</div>} */}
                  {errors["inaccessibleEdges"] && (
                    <div>{strings.DFMErrorEdgeTitle}</div>
                  )}
                  {errors["oversized"] && (
                    <div>{strings.DFMErrorOverdimensionTitle}</div>
                  )}
                  {false && <div>{strings.DFMErrorMultipleSolidsTitle}</div>}
                  {errors["undersized"] && (
                    <div>{strings.DFMErrorUnderdimensionTitle}</div>
                  )}
                  {errors["inaccessibleVertices"] && (
                    <div>{strings.DFMErrorPointTitle}</div>
                  )}
                  {errors["inaccessibleFaces"] && (
                    <div>{strings.DFMErrorFaceTitle}</div>
                  )}
                  {errors["thin"] && (
                    <div>{strings.DFMErrorThinPlaneTitle}</div>
                  )}
                  {false && <div>{strings.DFMErrorDeepTitle}</div>}
                  {errors["clearance"] && (
                    <div>{strings.DFMErrorClearanceTitle}</div>
                  )}
                  {errors["deep"] && <div>{strings.DFMErrorDeepTitle}</div>}
                </div>
              }
            >
              <Info color={"info"} />
            </Tooltip>
          ) : (
            <Tooltip title="Ingen fejl">
              <CheckCircle color={"primary"} />
            </Tooltip>
          )}{" "}
        </Icon>
      </div>
    ),
    [dfmErrors]
  );

  return DFMWarnings;
};

export default DFMErrors;
