import {
  ProgramItem,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram,
  ProgramResizeHandle,
} from "@nessprim/planby";

export const ProgramItemView = ({ isVerticalMode, program, ...rest }: ProgramItem) => {
  const {
    isLive,
    isMinWidth,
    isMouseEvent,
    styles,
    resizeEvents,
    formatTime,
    getMouseEvents,
    set12HoursTimeFormat,
    getMouseEventTempTime,
  } = useProgram({
    isVerticalMode,
    program,
    ...rest,
  });

  const { data } = program;
  const { image, title, subtitle, since, till, channelUuid } = data;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
  const dragTime = getMouseEventTempTime();
  return (
    <ProgramBox
      width={styles.width}
      style={styles.position}
      {...getMouseEvents()}
    >
      <ProgramContent
        isVerticalMode={isVerticalMode}
        width={styles.width}
        isLive={isLive}
        style={{ padding: '2px' }}
        // onClick={() => alert(JSON.stringify(data))}
      >
        <ProgramFlex isVerticalMode={isVerticalMode}>
            <ProgramImage
              isVerticalMode={isVerticalMode}
              src={image}
              // alt="Preview"
              style={{ width: 'unset', marginRight: '10px'}}
              // onClick={() => alert(JSON.stringify(data))}
            />
          <ProgramStack>
            <ProgramTitle style={{ marginBottom: '1px', fontWeight: 'bold', color: 'unset'}}>{title}</ProgramTitle>
            <ProgramText style={{ fontSize: '11px'}}>{subtitle}</ProgramText>
            {/* <ProgramText>
              {isMouseEvent ? (
                <>
                  {dragTime.since} - {dragTime.till}
                </>
              ) : (
                <>
                  {sinceTime} - {tillTime}
                </>
              )}
            </ProgramText> */}
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};
