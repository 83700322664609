import { API_URL } from "./common";
import { FetchError } from "../exceptions";
import {
  ProductionReportType,
  SalesLiveType,
  SalesReportType,
} from "../../types/OverviewTypes";

const ENDPOINT = `${API_URL}/v1/ecommerce/overview`;

export const apiGetSalesLive = (token: string): Promise<SalesLiveType> => {
  return fetch(`${ENDPOINT}/live`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetSalesReport = (
  token: string,
  start: Date,
  end: Date
): Promise<SalesReportType> => {
  const request = `${ENDPOINT}/salesreport?start=${start.toISOString().slice(0, -5)}&end=${end.toISOString().slice(0, -5)}`;
  const requestConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return fetch(request, requestConfig).then((res) => 
  {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
// Needs testing
export const apiGetProductionReport = (
  token: string,
  start: Date,
  end: Date
): Promise<ProductionReportType> => {
  return fetch(
    `${ENDPOINT}/productionreport?start=${start
      .toISOString()
      .slice(0, -5)}&end=${end.toISOString().slice(0, -5)}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
export const apiGetKpiReport = (token: string, start: Date, end: Date) => {
  //Options
  return fetch(
    `${ENDPOINT}/weekly?start=${start.toISOString().slice(0, -5)}&end=${end
      .toISOString()
      .slice(0, -5)}`,
    {
      headers: {
        Accept: "text/cvs",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.ok) {
      // Check if the response content type is 'text/csv'
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.includes("text/csv")) {
        return res.blob().then((blob) => {
          // Create a temporary URL for the Blob
          const url = URL.createObjectURL(blob);

          // Create an anchor element to trigger the file download
          const a = document.createElement("a");
          a.href = url;
          a.download = "kpi_result.csv"; // Set the filename for the downloaded file
          a.click();

          // Clean up the temporary URL
          URL.revokeObjectURL(url);

          return { status: "success" }; // Return a success status
        });
      } else {
        throw new Error("Unexpected response content type.");
      }
    } else {
      throw new FetchError(res.statusText, res.status);
    }
  });
};
