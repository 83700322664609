import {
  OrderItemType,
  OrderListType,
  OrderStatusEnum,
  OrderSummaryType,
  OrderType,
} from "types/OrderTypes";
import { FetchError } from "../exceptions";
import { API_URL } from "./common";

const ENDPOINT = `${API_URL}/v1/ecommerce/orders`;

export const apiGetOrders = (
  token: string,
  page: number,
  pageSize: number,
  sortKey: string = "id",
  sortDirection: "asc" | "desc" = "desc",
  status?: string,
  search?: string
): Promise<OrderListType> => {
  return fetch(
    `${ENDPOINT}?page=${page}&pageSize=${pageSize}&sortKey=${sortKey}&sortDirection=${sortDirection}${
      search ? "&search=" + search : ""
    }${status ? "&status=" + status : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetOrder = (
  token: string,
  orderID: number
): Promise<OrderType> => {
  return fetch(`${ENDPOINT}/${orderID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetOrderItem = (
  token: string,
  orderItemId: number
): Promise<OrderItemType> => {
  return fetch(`${ENDPOINT}/items/${orderItemId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiUpdateOrderStatus = (
  token: string,
  orderID: number,
  status: string
): Promise<any> => {
  const body = JSON.stringify({ status: status });
  return fetch(`${ENDPOINT}/${orderID}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  });
};

export const apiRefreshOrderFromQuote = (
  token: string,
  orderID: number,
  quoteId?: number
): Promise<OrderType> => {
  const body = JSON.stringify({ quoteId: quoteId });
  return fetch(`${ENDPOINT}/${orderID}/refresh`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetOrderInvoicePdf = (
  token: string,
  orderID: number
): Promise<any> => {
  return fetch(`${ENDPOINT}/${orderID}/invoice/pdf`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const apiGetOrderInvoice = (
  token: string,
  orderID: number
): Promise<any> => {
  return fetch(`${ENDPOINT}/${orderID}/invoice`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateInvoice = (
  token: string,
  orderID: number,
  sendEmail: boolean = false
): Promise<Response> => {
  return fetch(`${ENDPOINT}/${orderID}/invoice?sendEmail=${sendEmail}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const apiSendOrderEmail = (
  token: string,
  orderID: number,
  status?: OrderStatusEnum
): Promise<any> => {
  return fetch(`${ENDPOINT}/${orderID}/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ status: status }),
  });
};

export const apiGetOrderPdf = (
  token: string,
  orderID: number,
  emailType?: "confirmation" | "complete"
): Promise<any> => {
  return fetch(
    `${ENDPOINT}/${orderID}/pdf${emailType ? "?emailType=" + emailType : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// Sends out invoice again. Invoice must first be created, see apiCreateInvoice.
export const apiSendInvoiceEmail = (
  token: string,
  orderId: number
): Promise<any> => {
  return fetch(`${ENDPOINT}/${orderId}/invoice/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const apiGetOrdersSummary = (
  token: string
): Promise<OrderSummaryType> => {
  return fetch(`${ENDPOINT}/summary`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateShipment = (
  token: string,
  orderId: number
): Promise<any> => {
  return fetch(`${ENDPOINT}/${orderId}/shipment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
