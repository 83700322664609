import { useContext, useEffect, useState, FunctionComponent } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Paper,
  Skeleton,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import "../../styles/global.css";
import { primaryColor } from "../../assets/colors";
import StatusSortMenu from "./StatusSortMenu";
import AppStateContext from "../../contexts/AppStateContext";
import OrderRow from "./OrderRow";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import { apiGetOrders } from "util/network/Orders";
import { SimpleOrderType } from "types/OrderTypes";

type PropsType = {
  searchValue: string;
};

const OrderTable: FunctionComponent<PropsType> = ({ searchValue }) => {
  const { token } = useContext(AppStateContext);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<SimpleOrderType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useStateWithSessionStorage<number>(0, "orderPage");
  const [pageSize, setPageSize] = useStateWithSessionStorage<number>(
    25,
    "orderPageSize"
  );
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<string>("created");
  const [statusFilter, setStatusFilter] = useStateWithLocalStorage(
    ["REVIEW", "PROCESSING", "COMPLETE", "UNPAID"],
    "orderStatusFilter"
  );

  const refreshOrders = (): Promise<void> => {
    if (token) {
      return apiGetOrders(
        token,
        page + 1,
        pageSize,
        sortKey,
        sortAsc ? "asc" : "desc",
        statusFilter.join("+"),
        searchValue
      )
        .then((res) => {
          setTotal(res.total);
          setOrders(res.items);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error retrieving orders", err);
          setOrders([]);
          setLoading(false);
        });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    setLoading(true);
    refreshOrders().then(() => setLoading(false));
    const intervalId = setInterval(() => {
      refreshOrders().then(() => setLoading(false));
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [token, page, pageSize, sortKey, sortAsc, statusFilter, searchValue]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setLoading(true);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setLoading(true);
    setPageSize(+event.target.value);
    setPage(0);
  };

  const doSort = (key: string) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(false);
    }
  };

  const options = ["REVIEW", "PROCESSING", "COMPLETE", "UNPAID", "CANCELLED"];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer style={{ paddingBottom: "1rem" }}>
        <Table
          stickyHeader
          padding={"none"}
          sx={{ color: primaryColor[0] }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead
            sx={{
              backgroundColor: "var(--header-2)",
            }}
          >
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  backgroundColor: "var(--header-2)",
                  paddingLeft: "1rem",
                }}
              >
                <Tooltip
                  arrow
                  title={
                    <Typography
                      sx={{
                        fontSize: "1.1em",
                        background: primaryColor[0],
                        padding: "5px",
                        borderRadius: "10px",
                        color: "#fff",
                      }}
                      fontWeight={"bold"}
                      variant={"button"}
                    >
                      State
                    </Typography>
                  }
                >
                  <StatusSortMenu
                    options={options}
                    onChange={setStatusFilter}
                    statusFilter={statusFilter}
                  />
                </Tooltip>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  paddingLeft: "0.5em",
                  backgroundColor: "var(--header-2)",
                }}
              >
                <TableSortLabel
                  onClick={() => {
                    doSort("order_no");
                  }}
                  active={sortKey === "order_no"}
                  disabled={loading}
                  direction={sortAsc ? "asc" : "desc"}
                >
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Order
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                sx={{ backgroundColor: "var(--header-2)" }}
              >
                <Typography
                  fontSize={"1.1em"}
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Customer
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ backgroundColor: "var(--header-2)" }}
              >
                <Typography
                  fontSize={"1em"}
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  PO number
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "var(--header-2)" }}
              >
                <Typography
                  fontSize={"1em"}
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Shipping
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                size="small"
                sx={{
                  backgroundColor: "var(--header-2)",
                  paddingRight: "1rem",
                }}
              >
                <Typography
                  fontSize={"1em"}
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Invoice
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              {Array.from({ length: pageSize }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={6} style={{ padding: "0.5rem" }}>
                    <Skeleton key={index} variant="rectangular" height={30} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {orders.map((order) => (
                <OrderRow key={order.id} orderIn={order} />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 100]}
        component="div"
        count={total}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default OrderTable;
