import EmployeeRow from "./EmployeeRow";
import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { primaryColor } from "../../assets/colors";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AccessEnum,
  UserListType,
  UserType,
} from "../../types/users/UserTypes";
import AppStateContext from "../../contexts/AppStateContext";
import { apiGetEmployees } from "../../util/network/Users";
import CustomerRow from "../customer_management/CustomerRow";
import RefreshIcon from "@mui/icons-material/Refresh";

type PropsType = {
  setExisting: Function;
  setEditEmployeeModal: Function;
  setShowDeleteUser: Function;
  refreshTrigger: number;
};

const EmployeeTable: FunctionComponent<PropsType> = ({
  setExisting,
  setEditEmployeeModal,
  setShowDeleteUser,
  refreshTrigger,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserType[]>([]);
  const { token } = useContext(AppStateContext);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  // useEffect(() => {
  //   if (users) {
  //     if (users?.length > 0) {
  //       setLoading(false);
  //     } else if (!users || users?.length === 0) {
  //       setLoading(true);
  //       refreshUsers();
  //     }
  //   }
  // });

  const filteredUsers = useMemo(() => {
    return users.map((user: UserType, index: number) => {
      return (
        <EmployeeRow
          key={index}
          setExisting={setExisting}
          user={user}
          setEditEmployeeModal={setEditEmployeeModal}
          setShowDeleteUser={setShowDeleteUser}
        />
      );
    });
  }, [users]);

  const refreshUsers = () => {
    if (token) {
      apiGetEmployees(token, page + 1, pageSize).then((res) => {
        const employees = res.items.filter((user) => {
          return user.accessLevel > 0;
        });
        // console.log(employees);
        setTotal(res.total ?? 0);
        setUsers(res.items);
      });
    }
  };

  useEffect(() => {
    if (token) {
      refreshUsers();
    }
  }, [page, pageSize, token, refreshTrigger]);

  return (
    <Paper>
      {loading ? <LinearProgress /> : null}
      <TableContainer>
        {users ? (
          <Table padding="normal" size="small" aria-label="a dense table">
            <TableHead
              sx={{
                background: "var(--header)",
              }}
            >
              <TableRow>
                <TableCell align="left" sx={{ paddingLeft: "20px" }}>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Email
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Access level
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Tooltip title="Genindlæs">
                    <IconButton
                      sx={{ color: "white" }}
                      onClick={() => refreshUsers()}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{filteredUsers}</TableBody>
          </Table>
        ) : (
          <LinearProgress />
        )}
      </TableContainer>
      {users ? (
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={total}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Paper>
  );
};

export default EmployeeTable;
