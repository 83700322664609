import React, { FunctionComponent, useContext, useMemo, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button,
    LinearProgress,
    TableHead,
    Typography,
} from "@mui/material";
import CustomReqItemRow from "./CustomReqItemRow";
import { apiCreateProduct } from "util/network/Products";
import { CreateOtherProductType, ProductTypeEnum } from "types/products/ProductCommandsType";
import { OrderItemType} from "types/OrderTypes"; // Assuming these types include the necessary fields
import { CartItemType} from "types/CartTypes"; // Assuming these types include the necessary fields
import AppStateContext from "contexts/AppStateContext";

import styles from "./Styles/ReqOrderCustomItemTable.module.css";

type ReqOrderCustomItemTableProps = {
    customItems: (OrderItemType | CartItemType)[];
    onAddCustomItem: (item: OrderItemType | CartItemType) => void;
    onUpdateCustomItem: (item: OrderItemType | CartItemType) => void;
    onDeleteCustomItem: (item: OrderItemType | CartItemType) => void;
};

const ReqOrderCustomItemTable: FunctionComponent<ReqOrderCustomItemTableProps> = ({
    customItems,
    onAddCustomItem,
    onUpdateCustomItem: onUpdateItem,
    onDeleteCustomItem: onDeleteItem,
}) => {
    const { token } = useContext(AppStateContext);
    const [loading, setLoading] = useState(false);

    const rows = useMemo(() => {
        return customItems.map((item) => (
            <CustomReqItemRow
                key={item.id}
                item={item}
                updateItem={onUpdateItem}
                deleteItem={onDeleteItem}
            />
        ));
    }, [customItems, onDeleteItem, onUpdateItem]);

    const handleAddCustomItem = () => {
        setLoading(true);
    
        // Define the command for the API call
        const command: CreateOtherProductType = {
            name: "Custom item",
            price: 0,
            weight: 0,
            description: "",
            fileResources: [],
            productType: ProductTypeEnum.OTHER,
            qty: 1
        };
    
        // Make the API call
        apiCreateProduct(token, command)
            .then((item) => {
                // Create a new custom item from the API response
                const newCustomItem: OrderItemType | CartItemType = {
                    id: item.id, // Use the ID from the API response
                    product: {
                        id: item.id,
                        name: item.name,
                        productType: item.productType,
                        details: item.details || {},
                    },
                    quantity: 1,
                    price: 0,
                    description: "",
                };
    
                // Add the new custom item
                onAddCustomItem(newCustomItem);
            })
            .catch((error) => {
                console.error("Failed to create custom item:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    

    return (
        <Paper>
            {loading ? <LinearProgress /> : null}
            <TableContainer>
                <Table stickyHeader padding="checkbox" className={styles.customTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={`${styles.headerCell} ${styles.deleteCell}`}><span className={`${styles.squareSpan}`}></span></TableCell>
                            <TableCell className={`${styles.headerCell} ${styles.qtyCell}`}>Qty</TableCell>
                            <TableCell className={`${styles.headerCell} ${styles.nameCell}`}>Name</TableCell>
                            <TableCell className={`${styles.headerCell} ${styles.descriptionCell}`}>Description</TableCell>
                            <TableCell className={`${styles.headerCell} ${styles.filesCell}`}>Files</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                <Button onClick={handleAddCustomItem}>Add custom item</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ReqOrderCustomItemTable;
