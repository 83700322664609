import { Button } from "@mui/material";
import ThreadSelector from "components/ThreadSelector";
import AppStateContext from "contexts/AppStateContext";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { ThreadType } from "types/ConfigTypes";
import {
  DFMInfoType,
  ModelDataType,
  ReceivedModelType,
  SelectedThreadsInfoType,
} from "types/RenderTypes";
import { apiGetThreadsList } from "util/network/Models";
import {
  apiGetGLTF,
  apiGetErrorGLTFModel,
  apiGetDfmInfo,
  apiGetModelInfo,
} from "util/network/Products";
import Modal from "./Modal";
type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  threads: SelectedThreadsInfoType[];
  modelId: number;
  onUpdate: (e: SelectedThreadsInfoType[]) => void;
};
const ThreadsModal: FunctionComponent<PropsType> = ({
  isOpen,
  onClose,
  threads,
  modelId,
  onUpdate,
}) => {
  const [model, setModel] = useState<ReceivedModelType>();
  const [dfm, setDfm] = useState<GLTF>();
  const [dfmInfo, setDfmInfo] = useState<DFMInfoType>();
  const [modelInfo, setModelInfo] = useState<ModelDataType>();
  const [threadList, setThreadList] = useState<ThreadType[]>([]);
  const [selectedThreads, setSelectedThreads] =
    useState<SelectedThreadsInfoType[]>(threads);
  const { token } = useContext(AppStateContext);

  const beforeUpdate = (selectedThreads: SelectedThreadsInfoType[]) => {
    const finalThreads: SelectedThreadsInfoType[] = [];
    console.log(selectedThreads);
    selectedThreads.forEach((thread) => {
      if (thread.name !== "none") {
        finalThreads.push({
          ...thread,
        });
      }
    });
    onUpdate(finalThreads);
  };

  useEffect(() => {
    setSelectedThreads(threads);
  }, [threads]);

  useEffect(() => {
    if (isOpen) {
      setModel(undefined);

      apiGetGLTF(token, modelId).then((res) => setModel(res));
      apiGetErrorGLTFModel(token, modelId).then((res) => setDfm(res));
      apiGetModelInfo(token, modelId).then((dat) => {
        apiGetDfmInfo(token, modelId).then((res) => {
          setModelInfo(dat);
          setDfmInfo({
            ...res,
            holeAnalysis: {
              holes: dat?.features?.holes ?? [],
            },
          });
        });
      });
      apiGetThreadsList().then((res) => {
        setThreadList(res);
      });
    }
  }, [token, modelId, isOpen]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        style={{
          width: "70em",
        }}
      >
        <ThreadSelector
          specialModelRender
          model={model}
          info={modelInfo}
          onChange={(e: SelectedThreadsInfoType[]) => {
            setSelectedThreads(e);
          }}
          existing={threads}
          threadList={threadList}
        />
      </div>
      <Button
        sx={{
          borderRadius: 0,
          maxHeight: 40,
          width: "10rem",
          background: "#075E5B",
          color: "#fff",
          "&:hover": {
            color: "#075E5B",
            border: "1px solid #075E5B",
            background: "#fff",
          },
        }}
        onClick={(e) => {
          beforeUpdate(selectedThreads);
          onClose();
        }}
      >
        Gem
      </Button>
    </Modal>
  );
};

export default ThreadsModal;
