import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "@mui/material/Button";
import CommentIcon from "@mui/icons-material/Comment";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import ChatBox from "./ChatBox";
import { Close } from "@mui/icons-material";
import { UserType } from "types/users/UserTypes";
import AppStateContext from "contexts/AppStateContext";
import { apiGetUser } from "util/network/Users";

type Props = {
  customer: UserType;
  productId: number;
  selectedParticipant: "customer" | "employee";
  onClose: () => void;
  customerComment?: string | null;
};

const Popup: React.FunctionComponent<Props> = ({
  customerComment,
  customer,
  productId,
  selectedParticipant,
  onClose,
}) => {
  const { token } = useContext(AppStateContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (anchorRef.current) {
      setAnchorEl(anchorRef.current);
    }
  }, []);

  return (
    <>
      <div
        ref={anchorRef}
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
          zIndex: 1000,
        }}
      ></div>
      {/* <Button
        onClick={handleClick}
        ref={anchorRef}
        sx={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
          borderRadius: "50%",
          padding: "15px",
          backgroundColor: "#075E5B",
          "&:hover": {
            backgroundColor: "#0A0B0B",
          },
          zIndex: 1000,
        }}
      >
        <CommentIcon sx={{ width: "35px", height: "35px", color: "white" }} />
      </Button> */}

      <Popover
        open={true}
        anchorEl={anchorEl}
        onClose={() => onClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            height: "524px",
            width: "350px",
            bgcolor: "transparent",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <ChatBox
            customerComment={customerComment}
            customerEmail={customer?.email}
            productId={productId}
            customerId={customer.id}
            customerName={`${customer?.firstName} ${customer?.lastName}`}
            selectedParticipant={selectedParticipant}
          />
        </Box>
        <Button
          onClick={(e) => onClose()}
          sx={{
            position: "absolute",
            top: "-5px", // Adjusted position to give space
            right: "10px", // Adjusted position to give space
            padding: "15px",
          }}
        >
          <Close sx={{ width: "25px", height: "25px", color: "green" }} />
        </Button>
      </Popover>
    </>
  );
};

export default Popup;
