import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  LinearProgress,
  Paper,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import "../../styles/global.css";
import { primaryColor } from "../../assets/colors";
import AppStateContext from "../../contexts/AppStateContext";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import { CartStatusEnum, CartType, SimpleCartType } from "types/CartTypes";
import { apiGetCarts } from "util/network/Carts";
import StatusSortMenu from "pages/order_management/StatusSortMenu";
import QuoteRow from "./QuoteRow";

type PropsType = {
  searchValue: string;
};

const QuotesTable: FunctionComponent<PropsType> = ({ searchValue }) => {
  const { token } = useContext(AppStateContext);
  const [loading, setLoading] = useState(true);
  const [quotes, setQuotes] = useState<SimpleCartType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useStateWithSessionStorage<number>(0, "quotePage");
  const [pageSize, setPageSize] = useStateWithSessionStorage<number>(
    25,
    "quotePageSize"
  );
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<string>("modified");
  const [statusFilter, setStatusFilter] = useStateWithLocalStorage(
    [CartStatusEnum.LOCKED, CartStatusEnum.REVIEW],
    "quoteStatusFilter"
  );
  const override = Boolean(sessionStorage.getItem("debug") === "true");

  const options = [
    CartStatusEnum.DRAFT,
    CartStatusEnum.LOCKED,
    CartStatusEnum.REVIEW,
    CartStatusEnum.LOST,
    CartStatusEnum.DELETED,
    ...(override ? [CartStatusEnum.UNSAVED] : []),
  ];

  const refreshQuotes = (): Promise<void> => {
    if (token) {
      return apiGetCarts(
        token,
        page + 1,
        pageSize,
        sortKey,
        sortAsc ? "asc" : "desc",
        statusFilter,
        searchValue
      )
        .then((res) => {
          setTotal(res.total);
          setQuotes(res.items);
        })
        .catch((err) => {
          console.error("Error retrieving orders", err);
          setQuotes([]);
        })
        .finally(() => setLoading(false));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    refreshQuotes();
    const intervalId = setInterval(refreshQuotes, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [token, page, pageSize, sortKey, sortAsc, statusFilter, searchValue]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const doSort = (key: string) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(false);
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer style={{ paddingBottom: "1rem" }}>
        {quotes ? (
          <Table
            stickyHeader
            padding={"none"}
            sx={{ color: primaryColor[0] }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead
              sx={{
                backgroundColor: "var(--header-2)",
              }}
            >
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    backgroundColor: "var(--header-2)",
                    paddingLeft: "1rem",
                  }}
                >
                  <Tooltip
                    arrow
                    title={
                      <Typography
                        sx={{
                          fontSize: "1.1em",
                          background: primaryColor[0],
                          padding: "5px",
                          borderRadius: "10px",
                          color: "#fff",
                        }}
                        fontWeight={"bold"}
                        variant={"button"}
                      >
                        State
                      </Typography>
                    }
                  >
                    <StatusSortMenu
                      options={options}
                      onChange={setStatusFilter}
                      statusFilter={statusFilter}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    paddingLeft: "0.5em",
                    backgroundColor: "var(--header-2)",
                  }}
                >
                  <TableSortLabel
                    onClick={() => {
                      doSort("modified");
                    }}
                    active={sortKey === "created"}
                    disabled={loading}
                    direction={sortAsc ? "asc" : "desc"}
                  >
                    <Typography
                      fontSize={"1.1em"}
                      color={"white"}
                      fontWeight={"bold"}
                      variant={"body1"}
                    >
                      Quote
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ backgroundColor: "var(--header-2)" }}
                >
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Customer
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ backgroundColor: "var(--header-2)" }}
                >
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Last edit by
                  </Typography>
                </TableCell>
                <TableCell sx={{ backgroundColor: "var(--header-2)" }}>
                  <Typography
                    fontSize={"1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Lead time
                  </Typography>
                </TableCell>
                <TableCell
                  size="small"
                  sx={{
                    backgroundColor: "var(--header-2)",
                    paddingRight: "1rem",
                  }}
                >
                  <Typography
                    fontSize={"1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Total
                  </Typography>
                </TableCell>
                <TableCell
                  size="small"
                  sx={{
                    backgroundColor: "var(--header-2)",
                    paddingRight: "1rem",
                  }}
                  align="left"
                  colSpan={2}
                >
                  <Typography
                    fontSize={"1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Assigned
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                {Array.from({ length: pageSize }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={8} style={{ padding: "0.5rem" }}>
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={30}
                      />{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {quotes.map((quote) => {
                  return (
                    <QuoteRow
                      key={quote.id}
                      quote={quote}
                      refresh={() =>
                        refreshQuotes().then(() => setLoading(false))
                      }
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        ) : (
          <LinearProgress />
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 100]}
        component="div"
        count={total}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default QuotesTable;
