import { Reducer } from "react";

export enum ListReducerEnum {
  ADD,
  UPDATE,
  ADDORUPDATE,
  REMOVE,
  CLEAR,
  SET,
}

export type actionType<T> = {
  value: T | T[];
  type: ListReducerEnum;
};

export default <T>(key: keyof T): Reducer<T[], actionType<T>> =>
  (state: T[], action: actionType<T>) => {
    switch (action.type) {
      case ListReducerEnum.SET:
        return Array.isArray(action.value) ? action.value : [action.value];

      case ListReducerEnum.ADD:
        // Directly return the current state if the value to add is an empty array
        if (Array.isArray(action.value) && action.value.length === 0) {
          return state;
        }

        // If action.value is an array, concatenate it to the current state; otherwise, append the single item
        return Array.isArray(action.value)
          ? [...state, ...action.value]
          : [...state, action.value];

      case ListReducerEnum.UPDATE:
        return state.map((item) => {
          if (Array.isArray(action.value)) {
            const found = (action.value as T[]).find(
              (updateItem) => updateItem[key] === item[key]
            );
            return found ? { ...item, ...found } : item;
          } else {
            return item[key] === action.value[key]
              ? { ...item, ...action.value }
              : item;
          }
        });

      case ListReducerEnum.ADDORUPDATE:
        if (Array.isArray(action.value)) {
          const updateState = state.filter(
            (item: T) =>
              (action.value as T[]).find((i) => i[key] == item[key]) ==
              undefined
          );
          return [...updateState, ...(action.value as T[])];
        } else {
          const updateState = state.filter(
            (item: T) => item[key] != (action.value as T)[key]
          );
          return [...updateState, action.value as T];
        }

      case ListReducerEnum.REMOVE:
        if (Array.isArray(action.value)) {
          // Create a Set for more efficient checking of ids to be removed
          const idsToRemove = new Set(action.value.map((item) => item[key]));
          // Filter out items whose keys are in the Set
          return state.filter((item) => !idsToRemove.has(item[key]));
        } else {
          // Directly filter out the single item
          return state.filter((item) => item[key] !== (action.value as T)[key]);
        }

      case ListReducerEnum.CLEAR:
        return [];
      default:
        console.error("ListReducer: unknown action type", action);
        return state;
    }
  };
