import { AddressType } from "./AddressTypes";
import { LeadOptionsType } from "./CheckoutTypes";
import { ProductVMType } from "./products/ProductCommandsType";
import { UserType } from "./users/UserTypes";

export enum CartStatusEnum {
  OPEN = "OPEN",
  LOCKED = "LOCKED",
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  REVIEW = "REVIEW",
  DELETED = "DELETED",
  UNSAVED = "UNSAVED",
  LOST = "LOST",
}

export type CartTotalsType = {
  discount: number;
  shippingTax: number;
  shipping: number;
  subtotal: number;
  subtotalTax: number;
  total: number;
  totalTax: number;
};

export type CartType = CartTotalsType & {
  id: number;
  created: string;
  modified: string;
  name: string;
  message: string;
  modified_by?: string | null;
  created_by?: string;
  author: UserType;
  status: CartStatusEnum;
  billingAddress: AddressType;
  shippingAddress: AddressType;
  couponCode: string;
  paymentMethod: string;
  eanNumber: string;
  poNumber: string;
  shippingMethod: string;
  isCustomShippingMethod: boolean;
  invoiceEmail: string;
  items: CartItemType[];
  leadTimeOption: LeadOptionsType;
  leadTimeDays: number;
  leadTimeDate: string;
  internalComment: string;
  weight: number;
  responsibleId?: number | null;
};

export type CartListType = {
  items: SimpleCartType[];
  page: number;
  pageSize: number;
  total: number;
};

export type SimpleCartType = CartTotalsType & {
  id: number;
  created: string;
  modified: string;
  name: string;
  message: string;
  modified_by?: string | null;
  created_by?: string;
  authorId: number;
  authorName: string;
  status: CartStatusEnum;
  billingAddress: AddressType;
  shippingAddress: AddressType;
  couponCode: string;
  paymentMethod: string;
  eanNumber: string;
  poNumber: string;
  shippingMethod: string;
  isCustomShippingMethod: boolean;
  invoiceEmail: string;
  items: SimpleCartItemType[];
  itemSummary: {
    cnc: number;
    print3d: number;
    sheet: number;
    other: number;
  };
  leadTimeOption: LeadOptionsType;
  leadTimeDays: number;
  leadTimeDate: string;
  internalComment: string;
  weight: number;
  responsibleId?: number | null;
};

export type CartItemType = {
  id: number;
  created: string;
  modified: string;
  quoteId: number;
  product: ProductVMType;
  name: string;
  quantity: number;
  total: number;
  pricePerUnit: number;
  priceOverridden: boolean;
  totalTax: number;
  taxPerUnit: number;
  weight: number;
};

export type SimpleCartItemType = {
  id: number;
  created: string;
  modified: string;
  quoteId: number;
  productId: number;
  name: string;
  quantity: number;
  total: number;
  pricePerUnit: number;
  totalTax: number;
  taxPerUnit: number;
  weight: number;
};

export type UpdateCartItemCommandType = {
  quantity?: number;
  pricePerUnit?: number;
  weight?: number;
};

export type ShippingMethodType = {
  code: string;
  price: number;
  priceInclTax: number;
};

export type PaymentMethodsType = {
  code: string;
};

export type ReviewItemType = {
  quoteReviewId: number;
  itemId: number;
  originalPrice: number;
  newPrice: number | null;
};

export type ReviewType = {
  id: number;
  created: string;
  modified: string;
  created_by: UserType;
  modified_by: UserType;
  quoteId: number;
  status: "OPEN" | "CLOSED";
  reviewer: UserType;
  reviewDate: string;
  reviewNote: string;
  items: ReviewItemType[];
};

export type ReviewsListType = {
  items: ReviewType[];
  page: number;
  pageSize: number;
  total: number;
};

export type SubmitReviewItemType = {
  id: number;
  newPrice: number;
};

export type SubmitReviewType = {
  note: string;
  items: SubmitReviewItemType[];
};

export type CustomShippingMethod = {
  name: string;
  price: number;
};

export type UpdateCartInfoCommandType = {
  billingAddress?: AddressType;
  shippingAddress?: AddressType;
  sameAsBilling?: boolean;
  poNumber?: string;
  paymentMethod?: string;
  shippingMethod?: string;
  customShippingMethod?: CustomShippingMethod;
  invoiceEmail?: string;
  status?: CartStatusEnum;
  authorId?: number;
  internalComment?: string;
  message?: string;
  responsibleId?: number | null;
};

export type CartsSummaryType = {
  totalDraft: number;
  totalReview: number;
  totalQuote: number;
};
