import { useContext, useEffect, useState, FunctionComponent } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  IconButton,
  LinearProgress,
  Paper,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Typography,
  Skeleton,
} from "@mui/material";
import "../../styles/global.css";
import AppStateContext from "../../contexts/AppStateContext";
import { Refresh } from "@mui/icons-material";
import OutsourcingRow from "./OutsourcingRow";
import { RequisitionType } from "types/RequisitionTypes";
import { apiGetRequisitions } from "util/network/Requisitions";

type PropsType = {
  searchValue: string;
  statusFilter: string[];
  setRequisitionEdit: (requisition: RequisitionType) => void;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  orderId?: number;
};

// Requisition table on outsourcing management page
const OutsourcingTable: FunctionComponent<PropsType> = ({
  searchValue,
  statusFilter,
  setRequisitionEdit,
  refresh,
  setRefresh,
  orderId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useContext(AppStateContext);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<string>("created");
  const [requisitions, setRequisitions] = useState<RequisitionType[]>([]);

  //changes the page number
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  //changes the number of rows per page
  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    refreshRequisitions().then((res) => {
      setRefresh(false);
    });
  }, [refresh, statusFilter, searchValue, page, pageSize, sortKey, sortAsc]);

  //refreshes the requisitions
  const refreshRequisitions = () => {
    console.log("refreshing requisitions");
    setLoading(true);
    return apiGetRequisitions(
      token,
      page + 1,
      pageSize,
      sortKey,
      sortAsc ? "asc" : "desc",
      statusFilter.join("+"),
      searchValue,
      orderId ? `order_id=${orderId}` : ""
    ).then((res) => {
      setRequisitions(res.items);
      setTotal(res.total);
      setLoading(false);
    });
  };

  //decides which direction to sort
  const doSort = (key: string) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(false);
    }
  };

  return (
    <Paper sx={{ width: "100%" }}>
      {loading ? <LinearProgress /> : null}

      <TableContainer>
        {requisitions ? (
          <Table padding={"none"}>
            <TableHead
              sx={{
                background: "var(--header)",
              }}
            >
              <TableRow>
                <TableCell align="left" colSpan={1}>
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                    paddingLeft={"0.5rem"}
                  >
                    Status
                  </Typography>
                </TableCell>
                <TableCell align="left" colSpan={1}>
                  <TableSortLabel
                    onClick={() => {
                      doSort("req_no");
                    }}
                    active={sortKey === "req_no"}
                    disabled={loading}
                    direction={sortAsc ? "asc" : "desc"}
                  >
                    <Typography
                      fontSize={"1.1em"}
                      color={"white"}
                      fontWeight={"bold"}
                      variant={"body1"}
                    >
                      Rekvisition
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" colSpan={1}>
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Partner
                  </Typography>
                </TableCell>
                <TableCell align="left" colSpan={1}>
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    For
                  </Typography>
                </TableCell>
                <TableCell align="left" colSpan={1}>
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Items
                  </Typography>
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Author
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={1}>
                  <TableSortLabel
                    onClick={() => {
                      doSort("date");
                    }}
                    active={sortKey === "date"}
                    disabled={loading}
                    direction={sortAsc ? "asc" : "desc"}
                  >
                    <Typography
                      fontSize={"1.1em"}
                      color={"white"}
                      fontWeight={"bold"}
                      variant={"body1"}
                    >
                      Deadline
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell align="center" colSpan={1}>
                  <Typography
                    fontSize={"1.1em"}
                    textAlign={"center"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Foreslået pris
                  </Typography>
                </TableCell> */}
                <TableCell align="right" size="small" colSpan={1}>
                  <IconButton
                    sx={{ color: "white" }}
                    onClick={() => refreshRequisitions()}
                  >
                    <Refresh />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                {Array.from({ length: pageSize }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={8} style={{ padding: "0.5rem" }}>
                      <Skeleton key={index} variant="rectangular" height={30} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {requisitions.map((requisition: RequisitionType) => {
                  return (
                    <OutsourcingRow
                      key={requisition.id}
                      requisition={requisition}
                      refresh={refreshRequisitions}
                      edit={() => {
                        setRequisitionEdit(requisition);
                      }}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        ) : (
          <LinearProgress />
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 100]}
        component="div"
        count={total}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default OutsourcingTable;
