import {
  useState,
  useEffect,
  FunctionComponent,
  useMemo,
  useContext,
} from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Button,
  Checkbox,
} from "@mui/material";
import { OrderItemType } from "types/OrderTypes";
import columns from "./RequisitionColumns";
import ReqOrderItemRow from "./ReqOrderItemRow";
import { CreateRequisitionItemType } from "types/RequisitionTypes";
import CustomReqItemRow from "./CustomReqItemRow";
import { apiCreateProduct } from "util/network/Products";
import {
  CreateOtherProductType,
  ProductTypeEnum,
} from "types/products/ProductCommandsType";
import AppStateContext from "contexts/AppStateContext";
import { CartItemType } from "types/CartTypes";

type PropsType = {
  selectedItems: CreateRequisitionItemType[];
  customItems?: CreateRequisitionItemType[];
  customItemsDispatch?: Function;
  prodItems: (OrderItemType | CartItemType)[];
  changeSelected: Function;
  changeItemPrice: Function;
  disabled?: boolean;
  type: "order" | "cart";
};

// Requisition Order Table
const ReqOrderTable: FunctionComponent<PropsType> = ({
  prodItems,
  changeSelected,
  changeItemPrice,
  selectedItems,
  disabled,
  type,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useContext(AppStateContext);
  const [selectedAll, setSelectedAll] = useState(false);

  //loading
  useEffect(() => {
    if (prodItems) setLoading(false);
    if (!prodItems) setLoading(true);
  }, [prodItems]);

  // find out if the order is selected and render the row
  const projectMemo = useMemo(() => {
    const items = prodItems?.map((prodItemType) => {
      if ((prodItemType as any).orderId || (prodItemType as any).quoteId) {
        var selected = selectedItems.find(
          (item) => item.productId === prodItemType.product.id
        );
        let isSelected = selected != null;
        return (
          <>
            <ReqOrderItemRow
              selected={isSelected}
              key={prodItemType.id}
              project={prodItemType}
              priceIn={selected?.price}
              changeSelected={changeSelected}
              changeItemPrice={changeItemPrice}
              disabled={disabled}
              type={type}
            />
          </>
        );
      }
    });

    return [...items];
  }, [prodItems, selectedItems]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedAll = e.target.checked;
    // setSelectedAll(newSelectedAll);

    // prodItems?.map((prodItemType) => {
    //   if ((prodItemType as any).orderId || (prodItemType as any).quoteId) {
    //     var selected = selectedItems.find(
    //       (item) => item.productId === prodItemType.product.id
    //     );
    //     let isSelected = selected != null;

    changeSelected(undefined, undefined, newSelectedAll);
    // }
    // });
  };

  return (
    <Paper>
      {loading ? <LinearProgress /> : null}
      <TableContainer>
        {prodItems ? (
          <Table stickyHeader padding={"checkbox"}>
            <TableHead
              sx={{
                background: "var(--header)",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    background: "var(--header)",
                    width: "1%"
                  }}
                >
                  <Checkbox
                    checked={selectedItems.length === prodItems.length}
                    // checked={selectedAll}
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < prodItems.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {columns.map((column, index) => (
                  <>
                    {disabled && index == 0 ? null : (
                      <TableCell
                        key={column.id + index}
                        align={"left"}
                        sx={{ backgroundColor: "var(--header-2)" }}
                      >
                        <Typography
                          color={"white"}
                          fontWeight={"bold"}
                          variant={"body1"}
                        >
                          {column.label}
                        </Typography>
                      </TableCell>
                    )}
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {prodItems ? projectMemo : null}
            </TableBody>
          </Table>
        ) : (
          <LinearProgress />
        )}
      </TableContainer>
    </Paper>
  );
};

export default ReqOrderTable;
