import { FunctionComponent, useMemo, useState } from "react";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { ThemeContext } from "@emotion/react";
import {
  DFMInfoType,
  ErrorType,
  ModelDataType,
  ReceivedModelType,
  SelectedThreadsInfoType,
} from "../../types/RenderTypes";
import AppStateContext from "../../contexts/AppStateContext";
import DFMContainer from "../ThreeJS/DFMControls/DFMContainer";
import ComponentRender from "../ThreeJS/ModelRender";
import { staticColors } from "util/ColorUtil";

type PropsType = {
  specialModelRender?: boolean;
  model?: ReceivedModelType;
  dfmModel?: GLTF;
  info?: ModelDataType;
  dfmInfo?: DFMInfoType | null;
  selectedThreads?: SelectedThreadsInfoType[];
  onUpload?: (id: number) => void;
  disableUpload?: boolean;
  demo?: boolean;
};

const ThreeDModelTab: FunctionComponent<PropsType> = ({
  specialModelRender,
  model,
  dfmModel,
  info,
  dfmInfo,
  selectedThreads,
  demo,
  onUpload,
  disableUpload,
}) => {
  const [selectedError, setSelectedError] = useState<ErrorType>();

  const dfmContainer = useMemo(() => {
    return (
      dfmInfo !== null && (
        <DFMContainer
          dfmInfo={dfmInfo}
          selectedError={selectedError}
          setSelectedError={setSelectedError}
        />
      )
    );
  }, [dfmInfo, selectedError]);

  const holeDeeperThan5xDiameter = useMemo(() => {
    return dfmInfo?.holeAnalysis?.holes
      ?.filter((hole) => {
        const allDiameters = [
          ...hole.bores.map((bore) => bore.diameter),
          ...hole.countersinks.map((countersink) => countersink.diameter),
          ...hole.counterbores.map((counterbore) => counterbore.diameter),
        ];

        const maxDiameter = Math.max(...allDiameters);

        return hole.totalDepth > 5 * maxDiameter;
      })
      .map((item) => item.faceIds)
      .flat();
  }, [dfmInfo]);

  // const reuploadButton = useMemo(() => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         position: "absolute",
  //         top: "1em",
  //         right: "1em",
  //         alignItems: "center",
  //       }}
  //     >
  //       {demo && (
  //         <Typography
  //           variant="subtitle2"
  //           style={{
  //             // bottom: "1em",
  //             marginTop: ".2em",
  //             marginRight: "1em",
  //             color: "rgb(255, 152, 0)",
  //             textAlign: "center",
  //           }}
  //         >
  //           {strings.DemoModeText}
  //         </Typography>
  //       )}
  //       <Button
  //         style={{
  //           background: primaryColor[0],
  //         }}
  //         className={`${demo && pulseEffect.pulse} ${styles.reuploadButton}`}
  //         component="div"
  //         onClick={() => {
  //           handleClick();
  //         }}
  //       >
  //         {strings.ChangeModel} <UploadFile style={{ fontSize: "20px" }} />
  //       </Button>
  //     </div>
  //   );
  // }, [demo, handleClick, strings]);

  return (
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      {model && (
        <div style={{ display: "flex", height: "95%", width: "100%" }}>
          <ThemeContext.Consumer>
            {(themeContext) => (
              <AppStateContext.Consumer>
                {(appContext) => (
                  <ComponentRender
                    gltf={model}
                    dfmGltf={dfmModel}
                    info={info}
                    specialModelRender={specialModelRender}
                    selectedError={selectedError}
                    disableSelect
                    context={appContext}
                    themeContext={themeContext}
                    selectedThreads={selectedThreads}
                    color={
                      selectedError == "deep" ? staticColors.error : undefined
                    }
                    selection={
                      selectedError == "deep" ? holeDeeperThan5xDiameter : []
                    }
                  >
                    <>{dfmContainer}</>
                  </ComponentRender>
                )}
              </AppStateContext.Consumer>
            )}
          </ThemeContext.Consumer>
        </div>
      )}
    </div>
  );
};

export default ThreeDModelTab;
