import { DoubleSide, WebGLRenderTarget } from "three";
import { FunctionComponent, useEffect, useMemo } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { CustomOutlinePass } from "./CustomOutlinePass";

extend({ EffectComposer, ShaderPass, RenderPass, FXAAShader, OutlinePass });

const Effects: FunctionComponent = () => {
  const { gl, scene, camera, size } = useThree();

  const base = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const offscreenTarget = new WebGLRenderTarget(size.width, size.height);

    const comp = new EffectComposer(gl, offscreenTarget);
    //comp.renderToScreen = false;
    comp.addPass(renderScene);

    const customOutline = new CustomOutlinePass(size, scene, camera);
    // const outline = new OutlinePass(
    //   new Vector2(size.width, size.height),
    //   scene,
    //   camera,
    //   selectedObjects
    // );
    // outline.edgeStrength = 10;
    // outline.visibleEdgeColor.set(new Color(0xffffff));
    customOutline.normalOverrideMaterial.transparent = true;
    customOutline.normalOverrideMaterial.opacity = 0.5;
    //customOutline.normalOverrideMaterial.depthTest = false;
    customOutline.normalOverrideMaterial.side = DoubleSide;
    customOutline.normalOverrideMaterial.depthTest = false;
    customOutline.normalOverrideMaterial.wireframe = false;
    comp.addPass(customOutline);
    // const clearPass = new ClearPass();
    // comp.addPass(clearPass);
    // const effectFXAA = new ShaderPass(FXAAShader);
    // effectFXAA.uniforms['resolution'].value.set(
    //   1 / window.innerWidth,
    //   1 / window.innerHeight
    // );
    // comp.addPass(effectFXAA);

    return comp;
  }, [size]);

  useEffect(() => {
    base.setSize(size.width, size.height);
  }, [base, size]);

  useFrame(({ gl, camera }) => {
    // gl.clear();
    // camera.layers.set(2);
    base.render();
    // gl.clearDepth();
    // camera.layers.set(0);
    // gl.render(scene, camera);
  }, 1);

  return null;
};

export default Effects;
