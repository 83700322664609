import { Typography } from "@mui/material";
import AppStateContext from "contexts/AppStateContext";
import { useContext, useEffect, useState } from "react";
import { apiGetManufacturedProductCalculationValues } from "../../../util/network/Products";
import styles from "components/Modals/ModalStyles/threeDModalStyle.module.css";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
  ProductVMType,
  SheetProductDetailsType,
} from "types/products/ProductCommandsType";

const PriceTab: React.FC<{
  productId: number;
  product: ProductVMType | undefined;
}> = ({ productId, product }) => {
  const { token } = useContext(AppStateContext);
  // const [product, setProduct] = useState<ProductVMType>();
  const [modelId, setModelId] = useState<number>();
  const [priceDetails, setPriceDetails] = useState<any>();

  const details = product?.details as
    | CNCProductDetailsType
    | Print3DProductDetailsType
    | SheetProductDetailsType;

  const handleClose = () => {
    setPriceDetails(undefined);
  };

  useEffect(() => {
    if (!productId) return;
    apiGetManufacturedProductCalculationValues(token, productId).then((res) => {
      setPriceDetails(res);
      console.log(res);
    });
  }, [productId]);

  // CNC Details
  const renderCNCDetails = () => {
    if (priceDetails == undefined) {
      return "No pricing data";
    }

    return (
      <div className={styles.textRow}>
        {/*  Column 1 */}
        <div className={styles.textColumn}>
          {/* Material Info */}
          <div className={styles.textTable}>
            <span className={styles.sectionHeader}>Material Analysis</span>
            {/* <span className={classes.sectionHeader}>{priceDetails?.material.price.toFixed(0)} kr</span> */}
          </div>
          <div className={styles.textTable}>
            <span>Length</span>
            <span>{priceDetails?.material.x.toFixed(0)} mm</span>
          </div>
          <div className={styles.textTable}>
            <span>Width</span>
            <span>{priceDetails?.material.y.toFixed(0)} mm</span>
          </div>
          <div className={styles.textTable}>
            <span>Thickness</span>
            <span>{priceDetails?.material.z.toFixed(0)} mm</span>
          </div>
          <div className={styles.textTable}>
            <span>Weight PP</span>
            <span>
              {priceDetails?.material.singleStockWeight.toFixed(1)} kg
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Weight Total</span>
            <span>{priceDetails?.material.totalStockWeight.toFixed(1)} kg</span>
          </div>

          <div className={styles.textTable}>
            <span>Clamping</span>
            <span>{priceDetails?.material.clamping}</span>
          </div>

          {/* CAM Details */}
          <div className={styles.textTable}>
            <span className={styles.sectionHeader}>CAM Analysis</span>
          </div>
          <div className={styles.textTable}>
            <span>Time</span>
            <span>
              {priceDetails?.machining.camDetails.time.toFixed(0)} min
            </span>
          </div>

          {/* Machining Details */}
          <div className={styles.textTable}>
            <span className={styles.sectionHeader}>Machining Analysis</span>
          </div>
          <div className={styles.textTable}>
            <span>Technology</span>
            <span>{priceDetails?.machining.technology}</span>
          </div>
          <div className={styles.textTable}>
            <span>Setups</span>
            <span>{priceDetails?.machining.machiningDetails.setups}</span>
          </div>
          <div className={styles.textTable}>
            <span>Setup Time</span>
            <span>
              {priceDetails?.machining.machiningDetails.setupTime} min
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Machining Time</span>
            <span>
              {priceDetails?.machining.machiningDetails.machiningTime.toFixed(
                0
              )}{" "}
              min
            </span>
          </div>

          {/* Machining Details */}
          <div className={styles.textTable}>
            <span className={styles.sectionHeader}>
              Surface Treatment Analysis
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Price</span>
            <span>{priceDetails?.finish.price.toFixed(0)} kr</span>
          </div>
          <div className={styles.textTable}>
            <span>Leadtime</span>
            <span>{priceDetails?.finish.leadtime} days</span>
          </div>
        </div>

        {/* Column 2 */}
        <div className={styles.textColumn}>
          {/* Cost Details */}
          <div className={styles.textTable}>
            <span className={styles.sectionHeader}>Cost Analysis</span>
          </div>

          {/* Time */}
          <div className={styles.textTable}>
            <span>Prep. Time</span>
            <span>
              {priceDetails?.machining.costDetails.machinePrepTime.toFixed(0)}{" "}
              min
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Validation Time</span>
            <span>
              {priceDetails?.machining.costDetails.validationTime.toFixed(0)}{" "}
              min
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Setup Time (pp)</span>
            <span>
              {priceDetails?.machining.costDetails.setupTimePP.toFixed(0)} min
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Machining Time (pp)</span>
            <span>
              {priceDetails?.machining.costDetails.machiningTimePP.toFixed(0)}{" "}
              min
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Operation Time (pp)</span>
            <span>
              {priceDetails?.machining.costDetails.operationTimePP.toFixed(0)}{" "}
              min
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Manual Time (pp)</span>
            <span>
              {priceDetails?.machining.costDetails.manualFinishTimePP.toFixed(
                0
              )}{" "}
              min
            </span>
          </div>

          <hr />

          <div className={styles.textTable}>
            <span>Machine Time</span>
            <span>
              {priceDetails?.machining.costDetails.machineTime.toFixed(0)} min
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Operator Time</span>
            <span>
              {priceDetails?.machining.costDetails.operatorTime.toFixed(0)} min
            </span>
          </div>

          <hr />

          {/* Cost */}
          <div className={styles.textTable}>
            <span>Operator Cost</span>
            <span>
              {priceDetails?.machining.costDetails.operatorCost.toFixed(0)} kr
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Machine Cost</span>
            <span>
              {priceDetails?.machining.costDetails.machineCost.toFixed(0)} kr
            </span>
          </div>
          <div className={styles.textTable}>
            <span>Tool Cost</span>
            <span>
              {priceDetails?.machining.costDetails.toolCost.toFixed(0)} kr
            </span>
          </div>
          <div className={styles.textTable}>
            <span className={styles.boldParagraph}>Total Cost</span>
            <span className={styles.boldParagraph}>
              {priceDetails?.machining.costDetails.totalCost.toFixed(0)} kr
            </span>
          </div>

          {/* Price Details */}
          <div className={styles.textTable}>
            <span className={styles.sectionHeader}>Price Analysis</span>
            {/* <span className={styles.sectionHeader}>{priceDetails?.machining.cost.toFixed(0)} kr</span> */}
          </div>
          <div className={styles.textTable}>
            <span>Economic</span>
            <span>{priceDetails?.product.priceEconomic.toFixed(0)} kr</span>
          </div>
          <div className={styles.textTable}>
            <span>Standard</span>
            <span>{priceDetails?.product.priceStandard.toFixed(0)} kr</span>
          </div>
          <div className={styles.textTable}>
            <span>Express</span>
            <span>{priceDetails?.product.priceExpress.toFixed(0)} kr</span>
          </div>
        </div>
      </div>
    );
  };

  const renderProductDetails = () => {
    if (product == undefined) {
      return <div>{"Missing product"}</div>;
    }

    switch (product?.productType) {
      case ProductTypeEnum.CNC:
        return renderCNCDetails();
      case ProductTypeEnum.SHEET:
        return <div>{/* Your Sheet-specific content here */}</div>;
      case ProductTypeEnum.PRINT3D:
        return <div>{/* Your 3D Print-specific content here */}</div>;
      default:
        return (
          <Typography className={styles.typography_config}>
            {"No pricing data"}
          </Typography>
        );
    }
  };

  return <div>{renderProductDetails()}</div>;
};

export default PriceTab;
