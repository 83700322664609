import { FunctionComponent, useContext, useState } from "react";
import { OrderItemType } from "types/OrderTypes";
import { TableCell, TableRow } from "@mui/material";
import AppStateContext from "contexts/AppStateContext";

type PropsType = {
  item: OrderItemType;
};

const FeeOrderItemRow: FunctionComponent<PropsType> = ({ item }) => {
  const { strings, token } = useContext(AppStateContext);
  const { id, product, quantity, price, weight } = item;

  const splitFee = item.product.name.split("_");
  console.log(item.product.name);
  const feeType = splitFee[0];
  const extraFee = splitFee[1];
  let feeName = `${strings[feeType]}` ?? item.product.name;
  if (extraFee) {
    feeName += ` (${extraFee})`;
  }

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  return (
    <TableRow>
      <TableCell colSpan={2}>{truncateString(feeName, 50)}</TableCell>
      <TableCell colSpan={4}>{strings[`${feeType}Description`]}</TableCell>
      <TableCell colSpan={2} align={"right"}>
        {price.toFixed(2)} kr.
      </TableCell>
    </TableRow>
  );
};

export default FeeOrderItemRow;
