import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import AppStateContext from "contexts/AppStateContext";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { ProductionListType, ProductionVMType } from "types/ProductionType";
import { apiGetProductions } from "util/network/Productions";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import HeaderRow from "../HeaderRow";
import QualityControlRow from "./QualityControlRow";

type PropsType = {
  selection: string;
  searchValue?: string;
  orderId?: number;
};
const QualityControlTable: React.FC<PropsType> = ({
  selection,
  searchValue,
  orderId,
}) => {
  const { token } = useContext(AppStateContext);
  const [fetchTimeStamp, setFetchTimeStamp] = useState<number>(0);
  const [testUrl, setTestUrl] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>(
    15,
    "qcPageSize"
  );
  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");
  const [sortKey, setSortKey] = useStateWithLocalStorage<
    "deadline_date" | "order_id" | "priority"
  >("deadline_date", "productionSortKey");
  const [sortDirection, setSortDirection] = useStateWithLocalStorage<
    "asc" | "desc"
  >("asc", "productionSortDirection");

  const getAllProductions = (
    page: number,
    pageSize: number,
    selection: string,
    sortKey: "deadline_date" | "order_id" | "priority",
    sortDirection: "asc" | "desc",
    search?: string
  ) => {
    return apiGetProductions(
      token,
      page,
      pageSize,
      `${selection == "ready" && "afterwork=ACTIVE"}&status=0`,
      sortKey,
      sortDirection,
      search,
      selection == "coming" ? "afterwork" : undefined,
      selection == "all" ? "afterwork=ACTIVE;afterwork=TODO" : undefined
    ).then((res) => {
      setFetchTimeStamp(Date.now());
      return res;
    });
  };

  const { data } = useQuery<ProductionListType, any>(
    [
      "production",
      page,
      pageSize,
      selection,
      sortKey,
      sortDirection,
      searchValue,
    ],
    () =>
      getAllProductions(
        page,
        pageSize,
        selection,
        sortKey,
        sortDirection,
        searchValue
      ),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
    }
  );

  // const filteredData = useMemo(() => {
  //   if (!data) return [];

  //   const filteredItems = data.items.filter((project) => {
  //     if (project.status === 1) return false;

  //     const qcTasks = project.tasks.filter(
  //       (task) =>
  //         task.name === "QC" ||
  //         (task.description && task.description.includes("QC"))
  //     );

  //     return (
  //       qcTasks.length > 0 && qcTasks.some((task) => task.state !== "DONE")
  //     );
  //   });

  //   return filteredItems;
  // }, [data]);

  // const paginatedData = useMemo(() => {
  //   const startIndex = page * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   return filteredData.slice(startIndex, endIndex);
  // }, [filteredData, page, pageSize]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const projectMemo = useMemo(() => {
    let oldId = 0;
    const rows = data?.items.map((project) => {
      const groupByOrderId = data?.items.reduce((acc, item) => {
        if (!acc[item.orderId]) acc[item.orderId] = [];
        acc[item.orderId].push(item);
        return acc;
      }, {} as Record<number, ProductionVMType[]>);
      if (
        project.orderId &&
        project.orderId !== oldId &&
        orderId === undefined
      ) {
        oldId = project.orderId;
        const itemsInGroup = groupByOrderId[project.orderId];
        const allProdTasksDoneInGroup = itemsInGroup.every((item) =>
          item.tasks.every(
            (task) => task.name === "material" && task.state === "DONE"
          )
        );
        return (
          <React.Fragment key={project.id}>
            {/* {!allProdTasksDoneInGroup && ( */}
            <HeaderRow
              key={`${project.orderId}`}
              project={project}
              productions={data}
              qc
            />
            {/* )} */}
            <Suspense
              fallback={
                <TableRow>
                  <TableCell colSpan={11}>
                    <Skeleton variant="rectangular" height={55} />
                  </TableCell>
                </TableRow>
              }
            >
              <QualityControlRow
                key={`${project.id}${project.modified}${fetchTimeStamp}`}
                project={project}
              />
            </Suspense>
          </React.Fragment>
        );
      } else
        return (
          <Suspense
            key={project.id}
            fallback={
              <TableRow>
                <TableCell colSpan={11}>
                  <Skeleton variant="rectangular" height={55} />
                </TableCell>
              </TableRow>
            }
          >
            <QualityControlRow
              key={`${project.id}${project.modified}${fetchTimeStamp}`}
              project={project}
            />
          </Suspense>
        );
    });
    return rows;
  }, [fetchTimeStamp, orderId, data]);

  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>{projectMemo}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 50]}
        component="div"
        count={data?.total ?? 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default QualityControlTable;
