import React, { useEffect, useState } from "react";
import styles from "../styles/Table.module.css";
import {
  fetchCNCPlans,
  CNCPlan,
  addCNCPlan,
  updateCNCPlan,
} from "./CNCPlansAPI"; // Import the fetchCNCPlans, addCNCPlan, and updateCNCPlan functions
import AddEditCNCPlanForm from "./CNCPlanAddEditForm"; // Import the AddEditCNCPlanForm component

const CNCPlanList: React.FC = () => {
  const [cncPlans, setCNCPlans] = useState<CNCPlan[]>([]);
  const [selectedCNCPlan, setSelectedCNCPlan] = useState<CNCPlan | null>(null);
  const [isAddEditCNCPlanModalOpen, setIsAddEditCNCPlanModalOpen] =
    useState(false);
  const [editCNCPlan, setEditCNCPlan] = useState<CNCPlan | null>(null);

  // Fetch CNC plans data when the component mounts
  async function fetchData() {
    try {
      const data = await fetchCNCPlans();
      setCNCPlans(data);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching CNC plans data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddCNCPlanClick = () => {
    setIsAddEditCNCPlanModalOpen(true);
    setEditCNCPlan(null);
  };

  const handleAddEditCNCPlanFormClose = () => {
    setEditCNCPlan(null);
    setIsAddEditCNCPlanModalOpen(false);
  };

  const handleCNCPlanSelectClick = (cncplan: CNCPlan) => {
    if (selectedCNCPlan && selectedCNCPlan.id == cncplan.id)
      setSelectedCNCPlan(null);
    else setSelectedCNCPlan(cncplan);
  };

  const handleCNCPlanEditClick = (
    event: React.MouseEvent,
    cncplan: CNCPlan
  ) => {
    event.stopPropagation();
    setEditCNCPlan(cncplan);
    setIsAddEditCNCPlanModalOpen(true);
  };

  return (
    <div>
      <h1>CNC Plan List</h1>
      <button onClick={handleAddCNCPlanClick}>Add CNC Plan</button>
      <table className={styles.tooldb__table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Program</th>
            <th>Time</th>
            <th>Machine</th>
          </tr>
        </thead>
        <tbody>
          {cncPlans.map((cncPlan) => (
            <tr
              key={cncPlan.id}
              onClick={() => handleCNCPlanSelectClick(cncPlan)}
            >
              <td>{cncPlan.id}</td>
              <td>{cncPlan.program}</td>
              <td>{cncPlan.start?.toLocaleString()}</td>
              <td>{cncPlan.machine}</td>
              <td>
                <button onClick={(e) => handleCNCPlanEditClick(e, cncPlan)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedCNCPlan && (
        <div>
          {/* You can add details or edit options for the selected CNC plan here */}
          <p>Selected CNC Plan: {selectedCNCPlan.program}</p>
          {/* Add additional details here */}
        </div>
      )}

      {isAddEditCNCPlanModalOpen && (
        <div>
          {/* Display the modal to add/edit a CNC plan */}
          <AddEditCNCPlanForm
            onClose={handleAddEditCNCPlanFormClose}
            onSubmit={fetchData}
            cncPlanToEdit={editCNCPlan}
          />
        </div>
      )}
    </div>
  );
};

export default CNCPlanList;
