import { FunctionComponent, useEffect, useState } from "react";
import { Button, Dialog, MenuItem, Select, TextField } from "@mui/material";
import styles from "../../order_management/orderedit_components/Styles/loginPageStyle.module.css";
import {
  REGEX_EMAIL,
  REGEX_FIRSTNAME,
  REGEX_LASTNAME,
} from "../../../util/RegExUtil";
import { AccessEnum, UserType } from "../../../types/users/UserTypes";
import { AddressType } from "../../../types/AddressTypes";
import { UserUpdateType } from "../../../types/users/UserCommandsType";
import ErrorCollapse from "components/Generic/ErrorCollapse";

type PropsType = {
  setUser: Function;
  existing?: UserType | null;
  editEmployeeModal: boolean;
  setEditEmployeeModal: Function;
  editEmployee: Function;
};

const EditEmployeeModal: FunctionComponent<PropsType> = ({
  existing,
  editEmployeeModal,
  setEditEmployeeModal,
  editEmployee,
}) => {
  const [firstname, setFirstName] = useState<string>("");
  const [lastname, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorNames, setErrorNames] = useState<string[]>([]);
  const [access_level, setAccessLevel] = useState<AccessEnum>(
    AccessEnum.MANAGER
  );

  useEffect(() => {
    if (existing) {
      setFirstName(existing.firstName);
      setLastName(existing?.lastName);
      setEmail(existing?.email);
      // setAccessLevel(existing?.accessLevel)
    }
  }, [existing]);

  const handleSubmit = () => {
    if (!firstname || !lastname) {
      errorNames.push("Der mangler nødvendig brugerinformation");
      setErrorOpen(true);
      return;
    } else {
      const addressInfo: AddressType = {
        firstName: "",
        lastName: "",
        email: "",
        street: "Ikke oplyst",
        company: "Ikke oplyst",
        phone: "Ikke oplyst",
        postalCode: "Ikke oplyst",
        vat: "Ikke oplyst",
        city: "Ikke oplyst",
        countryId: "DK",
      };
      const changes: UserUpdateType = {
        firstName: firstname,
        lastName: lastname,
        billingAddress: addressInfo,
        accessLevel: access_level,
      };
      if (existing) {
        editEmployee(existing.id, changes);
      }
    }
  };

  const handleClose = () => {
    setEditEmployeeModal(false);
  };

  return (
    <div>
      {existing ? (
        <Dialog open={editEmployeeModal} onClose={() => handleClose()}>
          <div className={styles.userModalContainer}>
            <div className={styles.pdLeft}>
              <h3>{"BRUGER OPLYSNINGER"}</h3>
              <span className={styles.requiredText}>{"*krævede felter"}</span>
            </div>
            <div className={styles.pd}>
              <form id={"addressSubmit"}>
                <TextField
                  label={"Fornavn: "}
                  id={"fornavn"}
                  required
                  fullWidth={false}
                  type="text"
                  value={firstname}
                  error={!REGEX_FIRSTNAME.test(firstname)}
                  onChange={(e) => setFirstName(e.target.value)}
                  variant="standard"
                  size="small"
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                <TextField
                  label={"Efternavn: "}
                  id={"efternavn"}
                  required
                  fullWidth={false}
                  variant="standard"
                  error={!REGEX_LASTNAME.test(lastname)}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  size="small"
                  value={lastname}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                <br />
                <br />
                <TextField
                  label={"Email: "}
                  id="email"
                  disabled={!!existing}
                  required
                  fullWidth={false}
                  value={email}
                  error={!REGEX_EMAIL.test(email)}
                  type="email"
                  size="small"
                  variant="standard"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <br />
                <h5 className={styles.smallText}>{"Vælg rolle"}</h5>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    // @ts-ignore
                    setAccessLevel(e.target.value as keyof typeof AccessEnum);
                    console.log(e.target.value as keyof typeof AccessEnum);
                  }}
                  defaultValue={access_level.toString()}
                >
                  <MenuItem value={AccessEnum.MANAGER}>MANAGER</MenuItem>
                  <MenuItem value={AccessEnum.OPERATOR}>OPERATOR</MenuItem>
                  <MenuItem value={AccessEnum.UNVERIFIED}>UNVERIFIED</MenuItem>
                </Select>

                <br />
              </form>

              <div className={styles.flRg}>
                <Button
                  color={"error"}
                  variant="contained"
                  className={styles.mrrg}
                  onClick={() => handleClose()}
                >
                  Annuller
                </Button>
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Opdater
                </Button>
              </div>
            </div>
          </div>
          <ErrorCollapse
            open={errorOpen}
            setOpen={setErrorOpen}
            errorNames={errorNames}
            setErrorNames={setErrorNames}
          />
        </Dialog>
      ) : (
        <br />
      )}
    </div>
  );
};

export default EditEmployeeModal;
