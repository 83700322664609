import {
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useToast } from "contexts/ToastContext";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import { ToastPosition } from "react-toastify";

const SettingsUI = () => {
  const { addToast, toastPosition, setToastPosition } = useToast();

  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Settings
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ padding: "1rem 1.5rem 0 1.5rem" }}>
        <FormControlLabel
          control={
            <Switch
              checked={debug}
              onChange={(event) => {
                setDebug(event.target.checked);
              }}
              name="debug"
              color="primary"
            />
          }
          label="Debug Mode"
        />
        <h2>Toast Location</h2>
        <Select
          value={toastPosition}
          onChange={(event) => {
            setToastPosition(event.target.value as ToastPosition);
            addToast({
              type: "success",
              message: "Toast Location Updated",
              location: event.target.value as ToastPosition,
            });
          }}
        >
          <MenuItem value="top-right">Top Right</MenuItem>
          <MenuItem value="top-left">Top Left</MenuItem>
          <MenuItem value="bottom-right">Bottom Right</MenuItem>
          <MenuItem value="bottom-left">Bottom Left</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default SettingsUI;
