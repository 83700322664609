import {
  TableRow,
  TableCell,
  Collapse,
  Dialog,
  IconButton,
} from "@mui/material";
import { primaryColor, successColor, warningColor } from "assets/colors";
import { FunctionComponent, useState, useContext } from "react";
import {
  RequisitionStatusEnum,
  RequisitionType,
  UpdateRequisitionType,
} from "types/RequisitionTypes";
import { reformatDate } from "util/formatter";
import AppStateContext from "contexts/AppStateContext";
import RequisitionItemsTable from "./RequisitionTable/RequisitionItemsTable";
import RequisitionComponent from "components/Requisition/RequisitionComponent";
import {
  apiPreviewRequisitionFromId,
  apiUpdateRequisition,
} from "util/network/Requisitions";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { ReactComponent as MessageIcon } from "../../assets/svg/messageOutsorced.svg";
import RequisitionStatusSelector from "./RequisitionStatusSelector";
import { Download } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import CopyPhone from "components/Copy/CopyPhone";
import CopyEmail from "components/Copy/CopyEmail";
import { ProductTypeEnum } from "types/products/ProductCommandsType";
import { useHistory } from "react-router";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

type PropsType = {
  requisition: RequisitionType;
  refresh: Function;
  edit: Function;
};

// Requisition row
const OutsourcingRow: FunctionComponent<PropsType> = ({
  requisition,
  refresh,
  edit,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openReq, setOpenReq] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState<RequisitionStatusEnum>(
    requisition.state
  );
  const { token } = useContext(AppStateContext);
  const override = Boolean(sessionStorage.getItem("debug") === "true");

  //handle open/close of requisition
  const handleClose = () => {
    refresh();
    setOpenReq(!openReq);
  };
  //calculates the total price of the requisition item
  const totalPrice = () => {
    let total = 0;
    requisition.items.forEach((item) => {
      if (item.price) {
        total += item.price;
      }
    });
    if (total == 0) {
      return "Ingen pris";
    }
    return total + " kr";
  };
  //updates the status of the requisition
  const updateStatus = () => {
    if (status) {
      let updateRequisition: UpdateRequisitionType;
      updateRequisition = {
        state: status,
      };
      try {
        apiUpdateRequisition(
          token,
          requisition.id,
          updateRequisition,
          false
        ).then((res) => {
          setOpen(false);
          refresh();
        });
      } catch (e) {
        console.log(e);
      }
    }
  };
  //handle status update and show alert
  const handleStatusUpdate = (status: RequisitionStatusEnum) => {
    setStatus(status);
    setShowAlert(true);
  };

  //color of the status
  let stateColor = () => {
    var color = "black";
    if (requisition.state === RequisitionStatusEnum.PROCESSING) {
      color = "#3ea0f0";
    } else if (requisition.state === RequisitionStatusEnum.REQUEST) {
      color = successColor[0];
    } else if (requisition.state === RequisitionStatusEnum.RESPONSE) {
      color = warningColor[0];
    } else if (requisition.state === RequisitionStatusEnum.RECEIVED) {
      color = primaryColor[0];
    }
    return color;
  };

  const statusOptions = () => {
    // if (override) return Object.values(RequisitionStatusEnum);
    // if (requisition.state == RequisitionStatusEnum.CANCELLED) return [];
    // let options: string[] = [
    //   RequisitionStatusEnum.CANCELLED || "",
    //   RequisitionStatusEnum.PICKUP || "",
    // ];
    // if (requisition.state === RequisitionStatusEnum.REQUEST) {
    //   options.push(RequisitionStatusEnum.PROCESSING);
    //   options.push(RequisitionStatusEnum.RESPONSE);
    //   options.push(RequisitionStatusEnum.RECEIVED);
    // } else if (requisition.state === RequisitionStatusEnum.RESPONSE) {
    //   options.push(RequisitionStatusEnum.REQUEST);
    //   options.push(RequisitionStatusEnum.PROCESSING);
    //   options.push(RequisitionStatusEnum.RECEIVED);
    // } else if (requisition.state === RequisitionStatusEnum.PROCESSING) {
    //   options.push(RequisitionStatusEnum.PAUSED);
    //   options.push(RequisitionStatusEnum.RECEIVED);
    // } else if (requisition.state === RequisitionStatusEnum.RECEIVED) {
    // } else if (requisition.state === RequisitionStatusEnum.PAUSED) {
    //   options.push(RequisitionStatusEnum.PROCESSING);
    // } else if (requisition.state === RequisitionStatusEnum.PICKUP) {
    //   options.push(RequisitionStatusEnum.RECEIVED);
    // }
    return Object.values(RequisitionStatusEnum);
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const handleDownloadPDF = () => {
    apiPreviewRequisitionFromId(token, requisition.id, "pdf")
      .then((response) => response.blob())
      .then((blob) => {
        if (blob instanceof Blob) {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );

          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            newWindow.onload = () => {
              newWindow.print();
              window.URL.revokeObjectURL(url);
            };

            setTimeout(() => {
              if (!newWindow.closed) {
                newWindow.print();
                window.URL.revokeObjectURL(url);
              }
            }, 1000);
          }

          const a = document.createElement("a");
          a.href = url;
          a.download = "RequisitionPreview.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          console.error("Expected blob but received:", blob);
        }
      })
      .catch((error) => {
        console.error("Error fetching and downloading PDF:", error);
      });
  };

  const totalQuantity = requisition.items.reduce(
    (sum, item) =>
      sum + (item?.orderItem?.quantity || item.quoteItem?.quantity || 0),
    0
  );

  const formatId = (id: any) => {
    const strId = id.toString();
    return strId.length === 3 ? `0${strId}` : strId;
  };

  const companies = Array.from(
    new Set(
      requisition.items
        .map((item) => item.product.author!.defaultBillingAddress?.company)
        .filter(Boolean)
    )
  );

  const CNCitems = requisition.items.filter((i) => {
    return i.product.productType === ProductTypeEnum.CNC;
  });

  const PRINT3Ditems = requisition.items.filter((i) => {
    return i.product.productType === ProductTypeEnum.PRINT3D;
  });

  const SHEETitems = requisition.items.filter((i) => {
    return i.product.productType === ProductTypeEnum.SHEET;
  });

  const OTHERitems = requisition.items.filter((i) => {
    return i.product.productType === ProductTypeEnum.OTHER;
  });

  const renderItemCount = (items: any[], label: string) => {
    if (items.length > 0) {
      return (
        <span style={{ marginRight: "0.5rem" }}>
          {items.length} {label}
        </span>
      );
    }
    return null;
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        open={openReq ? true : false}
        onClose={handleClose}
      >
        {openReq ? (
          <RequisitionComponent
            oldRequisition={requisition}
            closeRequisition={handleClose}
            prodType={requisition.orderId ? "order" : "cart"}
          />
        ) : null}
      </Dialog>
      <TableRow
        sx={{
          background: open ? "GainsBoro" : "",
        }}
        onClick={() => setOpen(!open)}
        className={"reqRow"}
      >
        <TableCell
          align={"left"}
          colSpan={1}
          sx={{ color: stateColor, paddingLeft: "0.5rem" }}
        >
          {/* {requisition.state} */}
          <RequisitionStatusSelector
            currentStatus={status}
            entityID={requisition.id}
            onChange={(val) => {
              handleStatusUpdate(val as RequisitionStatusEnum);
            }}
            options={statusOptions()}
          />
        </TableCell>
        <TableCell align="left" scope="reqRow" colSpan={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end ",
            }}
          >
            <span style={{ paddingRight: "0.5rem", cursor: "pointer" }}>
              <LightTooltip
                title={
                  <span style={{ fontSize: "0.9rem" }}>
                    {requisition.message}
                  </span>
                }
              >
                <MessageIcon />
              </LightTooltip>
            </span>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span>{"#" + requisition.id}</span>
              <span style={{ fontSize: "0.7rem" }}>{requisition.type}</span>
            </span>
          </div>
        </TableCell>
        <TableCell align={"left"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              style={{ lineHeight: "0", display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  paddingRight: "0.5rem",
                  lineHeight: "0",
                }}
                onClick={handleClick}
              >
                <CopyPhone phone={requisition.partner.info.phone} />
              </span>
              <span style={{ paddingRight: "0.5rem" }} onClick={handleClick}>
                <CopyEmail
                  email={requisition.partner.info?.email}
                  subject={`Rekvisition: #${requisition.id}`}
                />
              </span>
            </span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <span>{requisition.partner.info?.company}</span>
              </span>
              <Tooltip title={"Contact person"}>
                <span style={{ fontSize: "0.75rem" }}>
                  {requisition.partner.info?.firstName}{" "}
                  {requisition.partner.info.lastName}
                </span>
              </Tooltip>
            </div>
          </div>
        </TableCell>
        <TableCell align={"left"}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {requisition.title != null ? (
                <span>
                  {requisition.title} <span>{companies}</span>
                </span>
              ) : (
                <span>
                  {requisition.orderId ? (
                    <>
                      <span style={{ fontSize: "0.8rem" }}>Order #</span>
                      <a
                        href={`/ordrestyring/${requisition.orderId}`}
                        style={{
                          textDecoration: "underline",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          paddingRight: "4.5rem",
                        }}
                      >
                        {formatId(requisition.orderId)}
                      </a>
                      <span>{companies}</span>
                    </>
                  ) : requisition.quoteId ? (
                    <>
                      <span style={{ fontSize: "0.8rem" }}>Quote #</span>
                      <a
                        href={`/quotes/${requisition.quoteId}`}
                        style={{
                          textDecoration: "underline",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          paddingRight: "4.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        {formatId(requisition.quoteId)}
                      </a>{" "}
                      <span>{companies}</span>
                    </>
                  ) : (
                    "No ID"
                  )}
                </span>
              )}
            </div>
            <div>
              <span style={{ fontSize: "0.7rem" }}>
                <Tooltip title={`Created by: ${requisition.created_by}`}>
                  <span>{requisition.created_by}</span>
                </Tooltip>
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell align={"left"} colSpan={1}>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <span>Items: {requisition.items.length.toString()}</span>
            <span style={{ fontSize: "0.7rem" }}>
              {renderItemCount(CNCitems, "CNC")}
              {renderItemCount(PRINT3Ditems, "3D")}
              {renderItemCount(SHEETitems, "Sheet")}
              {renderItemCount(OTHERitems, "Other")}
            </span>
          </span>
        </TableCell>
        <TableCell align={"center"} colSpan={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{requisition.created_by}</span>
            <span style={{ paddingRight: "2.5rem", fontSize: "0.7rem" }}>
              {reformatDate(requisition.created)}
            </span>
          </div>
        </TableCell>
        <TableCell align={"right"} colSpan={1}>
          <div
            style={{
              fontSize: "0.8rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{requisition.deliveryMethod} </span>
            <span>
              {reformatDate(requisition.deliveryDate).substring(0, 10) ===
              "01/01/1970"
                ? "-"
                : reformatDate(requisition.deliveryDate).substring(0, 10)}
            </span>
          </div>
        </TableCell>
        <TableCell align={"right"} colSpan={1}>
          {requisition.state != RequisitionStatusEnum.CANCELLED ? (
            <span onClick={handleClick}>
              <IconButton
                onClick={() => edit()}
                style={{ cursor: "pointer", paddingRight: "0.5rem" }}
              >
                <EditIcon />
              </IconButton>
            </span>
          ) : null}
          <span onClick={handleClick}>
            <Tooltip title={"Download email preview"}>
              <IconButton onClick={() => handleDownloadPDF()}>
                <Download />
              </IconButton>
            </Tooltip>
          </span>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > *": {
            background: open ? "WhiteSmoke" : "",
            borderRadius: open ? "0 0 5px 5px" : "",
            borderBottom: open ? "2px solid" + primaryColor[0] : "",
            borderLeft: open ? "2px solid" + primaryColor[0] : "",
            borderRight: open ? "2px solid" + primaryColor[0] : "",
          },
        }}
      >
        <TableCell style={{ paddingBottom: 1, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!requisition.items || requisition.items.length === 0 ? null : (
              <RequisitionItemsTable
                requisitionItems={requisition.items}
              ></RequisitionItemsTable>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OutsourcingRow;
