import { ConversationType } from "types/ConversationType";
import { API_URL } from "./common";

const TALK_JS_ID = process.env.REACT_APP_TALK_JS_ID ?? "";

export const apiGetConversation = (
  conversationId: string,
  token: string
): Promise<ConversationType> => {
  return fetch(
    `${API_URL}/talk/v1/${TALK_JS_ID}/conversations/${conversationId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    return res.json();
  });
};
