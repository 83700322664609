import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceDot,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatter } from "util/formatter";

type SaleProps = {
  data: { name: string; value: number }[];
  strokeColor?: string;
  price?: boolean;
  currentMonthName?: string;
};

const CustomLineGraph: React.FC<SaleProps> = ({
  data,
  strokeColor,
  price = false,
  currentMonthName,
}) => {
  const valueFormatter = (value: number | string) => {
    if (price) {
      return `${formatter.format(Number(value))} dkk`;
    }
    return value;
  };
  return (
    <div>
      {" "}
      <LineChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Line
          type="monotone"
          dataKey="value"
          stroke="#075E5B"
          activeDot={{ r: 8 }}
        />
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={valueFormatter} />
        {/* <ReferenceArea stroke="red" /> */}
        <ReferenceDot x="a" y={currentMonthName} label={currentMonthName} />
      </LineChart>
    </div>
  );
};
export default CustomLineGraph;
