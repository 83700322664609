import { InputAdornment, TextField } from "@mui/material";
import { FunctionComponent, useState } from "react";
import styles from "./seachField.module.css";
import { ClearIcon } from "@mui/x-date-pickers/icons";

type PropsType = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const SearchField: FunctionComponent<PropsType> = ({
  value,
  onChange,
  placeholder,
  onFocus,
  onKeyDown,
}) => {
  const [tempSearchVal, setTempSearchVal] = useState<string>(value);

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const handleClear = () => {
    setTempSearchVal("");
    onChange("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timer) {
      clearTimeout(timer);
    }
    setTempSearchVal(event.target.value);
    const timeout = setTimeout(() => {
      onChange(event.target.value);
    }, 2000);
    setTimer(timeout);
  };

  return (
    <TextField
      size="small"
      className={styles.searchField}
      value={tempSearchVal || ""}
      onChange={handleChange}
      placeholder={placeholder ?? `Search`}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      InputProps={{
        endAdornment: tempSearchVal && (
          <InputAdornment position="end">
            <ClearIcon onClick={handleClear} style={{ cursor: "pointer" }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
