import {
  cloneElement,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import { FunctionComponent, ReactElement } from "react";

type PropsType = {
  primaryElement: ReactElement;
  expandingElements: ReactElement[];
  open: boolean;
  setOpen: Function;
  direction?: "column" | "row";
};

const ExpandingContainer: FunctionComponent<PropsType> = ({
  primaryElement,
  expandingElements,
  open,
  setOpen,
  direction = "column",
}) => {
  const [closedDivHeight, setClosedDivHeight] = useState<number | null>(null);
  const [openDivHeight, setOpenDivHeight] = useState<number | null>(null);
  const openDivRef = useRef<HTMLDivElement>(null);
  const closedDivRef = useRef<HTMLDivElement>(null);
  const containerDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerDivRef.current &&
        !containerDivRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }

    if (open) {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [containerDivRef, open, setOpen]);

  useLayoutEffect(() => {
    if (closedDivRef.current) {
      setClosedDivHeight(closedDivRef.current.clientHeight);
    }
    if (openDivRef.current) {
      setOpenDivHeight(openDivRef.current.clientHeight);
    }
  }, [closedDivRef]);

  const onPrimaryInputClick = () => {
    setOpen(true);
    console.log("Primary input clicked");
  };

  const primaryDiv = <div ref={closedDivRef}>{primaryElement}</div>;

  return (
    <div
      ref={containerDivRef}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: direction,
        transition: "height 0.3s ease-in-out",
        height: closedDivHeight ?? "auto",
        zIndex: open ? 100000000 : 0,
      }}
    >
      <div
        style={{
          border: open ? "1px solid #c4c4c4" : "none",
          borderRadius: "5px",
          background: "white",
          overflow: "visible",
          transition: "height 0.3s ease-in-out",
        }}
      >
        {primaryDiv}
        {!open && (
          <div
            onClick={onPrimaryInputClick}
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "absolute",
              background: "transparent",
            }}
          ></div>
        )}
        {open && (
          <div
            style={{
              display: "flex",
              flexDirection: direction,
            }}
            ref={openDivRef}
          >
            {expandingElements}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandingContainer;
