import { FunctionComponent, useContext } from "react";
import { Grid } from "@mui/material";
import PaymentCard from "./Card/PaymentCard";
import AppStateContext from "../../../contexts/AppStateContext";
import { PUBLIC_BASE_URL } from "../../../util/network/common";
import styles from "../../order_management/orderedit_components/Styles/loginPageStyle.module.css";

type PropsType = {
  paymentMethod?: string;
  setPaymentMethod?: Function;
  disabled?: boolean;
  hideUnused?: boolean;
};

const PaymentMethods: FunctionComponent<PropsType> = ({
  paymentMethod,
  setPaymentMethod,
  disabled,
  hideUnused,
}) => {
  const { strings } = useContext(AppStateContext);

  const paymentOptions = [
    {
      method: "banktransfer",
      title: strings.PaymentBankTransfer,
      picture: `${PUBLIC_BASE_URL}/img/pay_bank.png`,
    },
    {
      method: "card",
      title: strings.PaymentCardTransfer,
      picture: `${PUBLIC_BASE_URL}/img/pay_card.png`,
    },
    {
      method: "ean",
      title: strings.PaymentEAN,
      picture: `${PUBLIC_BASE_URL}/img/GLNTEXT.png`,
    },
  ];

  const cardClassName = `${styles.cardDraftEdit} ${
    disabled ? styles.cardDraftEditDisabled : ""
  }`;

  return (
    <div className={cardClassName}>
      <div className={styles.pd}>
        <span className={styles.draftCardsHeader}>Payment method</span>
        <div className={styles.pdTop}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Grid container={true} direction={"row"}>
              {paymentOptions.map((option) => {
                if (hideUnused && paymentMethod !== option.method) {
                  return null;
                }
                return (
                  <Grid
                    key={option.method}
                    item
                    style={{
                      width: "5wv",
                      margin: 5,
                    }}
                  >
                    <PaymentCard
                      title={option.title}
                      picture={option.picture}
                      active={paymentMethod === option.method}
                      onClick={() =>
                        setPaymentMethod && setPaymentMethod(option.method)
                      }
                      disabled={disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
