import React, { useState, useContext, useEffect, useRef } from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import CustomerTable from "../CustomerTable";
import UserModal from "../../order_management/orderedit_components/UserModal";
import AppStateContext from "../../../contexts/AppStateContext";
import { UserType } from "types/users/UserTypes";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import SearchField from "components/SearchField";

const CustomerManagementUI = () => {
  const { setShowUserModal } = useContext(AppStateContext);
  const [existing, setExisting] = useState<UserType | null>();
  const [searchVal, setSearchVal] = useStateWithSessionStorage<string>(
    "",
    "customerSearchVal"
  );

  const refresh = useRef<any>(null);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "10px",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
            paddingTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
              fontWeight={"bold"}
              variant={"h6"}
            >
              &nbsp;Customer overview
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ paddingRight: "1rem" }}>
              <SearchField
                onChange={(value) => setSearchVal(value)}
                placeholder={`Søg i kunder..`}
                value={searchVal}
              />
            </div>
            <div style={{ paddingRight: "0.5rem" }}>
              <Button
                sx={{
                  borderRadius: 0,
                  maxHeight: 40,
                  width: "10rem",
                  background: "#E0A900",
                }}
                onClick={() => setShowUserModal(true)}
                variant="contained"
              >
                <h4
                  color="white"
                  style={{
                    // margin: "1em",
                    color: "#fff",
                  }}
                >
                  Create new
                </h4>
              </Button>
              <UserModal
                setCustomer={setExisting}
                existing={existing}
                onSubmit={() => {
                  setExisting(null);
                  refresh.current();
                }}
                onClose={() => {
                  setExisting(null);
                }}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ padding: "2rem 1.5rem 0 1.5rem" }}>
        <CustomerTable
          setExisting={setExisting}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          doRefresh={refresh}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerManagementUI;
