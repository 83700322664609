import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Dangerous, Warning } from "@mui/icons-material";
import "./layout/StockManagementUI.css";

const height = 200;

const MaterialCard = ({ material, status, imageResource, openMaterial }) => {
  return (
    <Card
      className={
        status === "low"
          ? "cardWarning"
          : status === "critical"
          ? "cardCritical"
          : "card"
      }
      onClick={() => openMaterial(material.toUpperCase())}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height={height * 0.7}
          image={imageResource}
          className={
            status === "low"
              ? "imageWarning"
              : status === "critical"
              ? "imageDanger"
              : "imageOkay"
          }
        />
        <CardContent>
          <div className={"inlineContainer"}>
            <Typography align={"left"} variant="h5">
              {material.toUpperCase()}
            </Typography>
            {status === "low" ? (
              <>
                <Warning
                  style={{ marginLeft: "auto" }}
                  className={"cardWarningText"}
                />
                <span className={"cardWarningText"}>LAV BEHOLDING</span>
              </>
            ) : status === "critical" ? (
              <>
                <Dangerous
                  style={{ marginLeft: "auto" }}
                  className={"cardCriticalText"}
                />
                <span className={"cardCriticalText"}>KRITISK BEHOLDNING</span>
              </>
            ) : null}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MaterialCard;
