import {
  createRef,
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import styles from "./ThreadSelector.module.css";
import ThreadCard from "./ThreadCard";
import {
  ModelDataType,
  ReceivedModelType,
  SelectedThreadsInfoType,
} from "../../types/RenderTypes";
import { ThreadCardInfo, ThreadType } from "../../types/ConfigTypes";
import ListReducer, { ListReducerEnum } from "../../util/ListReducer";
import ComponentRender from "../ThreeJS/ModelRender";

type PropsType = {
  specialModelRender?: boolean;
  model?: ReceivedModelType;
  info?: ModelDataType;
  onChange: Function;
  existing?: SelectedThreadsInfoType[];
  threadList: ThreadType[];
};

const ThreadSelector: FunctionComponent<PropsType> = ({
  specialModelRender,
  model,
  info,
  onChange,
  existing,
  threadList,
}) => {
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const [isAtTop, setIsAtTop] = useState<boolean>(true);
  const [lastScrollPos, setLastScrollPos] = useState<number>(0);
  const [hovered, setHovered] = useState<number[]>([]);
  const [selection, setSelection] = useState<number[]>();

  const [cardRefs, setCardRefs] = useState<
    { ids: number[]; ref: RefObject<any> }[]
  >([]);

  const [selectedThreads, selectedThreadsDispatch] = useReducer(
    ListReducer<SelectedThreadsInfoType>("id"),
    existing ?? []
  );

  const setSelectedThreads = useCallback(
    (data: SelectedThreadsInfoType | SelectedThreadsInfoType[]) => {
      selectedThreadsDispatch({
        type: ListReducerEnum.ADDORUPDATE,
        value: data,
      });
    },
    []
  );

  const removeSelectedThreads = useCallback(
    (data: SelectedThreadsInfoType | SelectedThreadsInfoType[]) => {
      selectedThreadsDispatch({
        type: ListReducerEnum.REMOVE,
        value: data,
      });
    },
    []
  );

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLDivElement;
    setIsAtBottom(
      target.scrollHeight - target.scrollTop <= target.clientHeight + 10
    );
    setIsAtTop(target.scrollTop < 10);
    setLastScrollPos(target.scrollTop);
  }, []);

  const scrollContainerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    selectedThreads.length > 0 && onChange(selectedThreads);
    console.log(selectedThreads);
  }, [selectedThreads]);

  useEffect(() => {
    console.log(selectedThreads);
    if (selectedThreads.length == 0 && existing && existing?.length > 0) {
      setSelectedThreads(existing);
    }
  }, [existing?.length]);

  useEffect(() => {
    selection &&
      selection?.length > 0 &&
      cardRefs.forEach((ref) => {
        if (ref.ids.includes(selection[0])) {
          ref.ref.current &&
            ref.ref.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
        }
      });
  }, [selection]);

  /**
   * Groups the holes of the same diameter and depth together in a list.
   * Then assign all the threads, compatible with the holes, to the group.
   */
  const groupHoles = useMemo(() => {
    if (!info || !info.features) return [];
    let id = 0;
    const groups: ThreadCardInfo[] = [];
    info.features?.holes.forEach((hole) => {
      hole.bores.forEach((bore) => {
        const group = groups.find(
          (g) =>
            g.diameter === bore.diameter &&
            g.depth === bore.depth &&
            g.type === "bore"
        );
        if (group) {
          group.holes.push({ id: bore.faceIds[0], faceIds: bore.faceIds });
        } else {
          const compThreads = threadList.filter(
            (thread) =>
              thread.maxDiameter >= bore.diameter &&
              thread.minDiameter <= bore.diameter
          );
          groups.push({
            diameter: bore.diameter,
            depth: bore.depth,
            holes: [{ id: bore.faceIds[0], faceIds: bore.faceIds }],
            type: "bore",
            threads: compThreads,
          });
        }
        id++;
      });
      hole.counterbores.forEach((bore) => {
        const group = groups.find(
          (g) =>
            g.diameter === bore.diameter &&
            g.depth === bore.depth &&
            g.type === "counterbore"
        );
        if (group) {
          group.holes.push({ id: bore.faceIds[0], faceIds: bore.faceIds });
        } else {
          const compThreads = threadList.filter(
            (thread) =>
              thread.maxDiameter >= bore.diameter &&
              thread.minDiameter <= bore.diameter
          );
          groups.push({
            diameter: bore.diameter,
            depth: bore.depth,
            holes: [{ id: bore.faceIds[0], faceIds: bore.faceIds }],
            type: "counterbore",
            threads: compThreads,
          });
        }
        id++;
      });
      hole.countersinks.forEach((sink) => {
        const group = groups.find(
          (g) =>
            g.diameter === sink.diameter &&
            g.depth === sink.depth &&
            g.type === "countersink"
        );
        if (group) {
          group.holes.push({ id: sink.faceIds[0], faceIds: sink.faceIds });
        } else {
          const compThreads = threadList.filter(
            (thread) =>
              thread.maxDiameter >= sink.diameter &&
              thread.minDiameter <= sink.diameter
          );
          groups.push({
            diameter: sink.diameter,
            depth: sink.depth,
            holes: [{ id: sink.faceIds[0], faceIds: sink.faceIds }],
            type: "countersink",
            threads: compThreads,
          });
        }
        id++;
      });
    });
    info.features?.shafts.forEach((shaft) => {
      const group = groups.find(
        (g) =>
          g.diameter === shaft.diameter &&
          g.depth === shaft.depth &&
          g.type === "shaft"
      );
      if (group) {
        group.holes.push({ id: shaft.faceIds[0], faceIds: shaft.faceIds });
      } else {
        const compThreads = threadList.filter(
          (thread) =>
            thread.maxDiameter >= shaft.diameter &&
            thread.minDiameter <= shaft.diameter
        );
        groups.push({
          diameter: shaft.diameter,
          depth: shaft.depth,
          holes: [{ id: shaft.faceIds[0], faceIds: shaft.faceIds }],
          type: "shaft",
          threads: compThreads,
        });
      }
      id++;
    });
    return groups.sort((a, b) => {
      if (a.diameter === b.diameter) {
        return a.depth - b.depth;
      } else {
        return a.diameter - b.diameter;
      }
    });
  }, [info?.features, threadList]);

  const threadCards = useMemo(() => {
    const cardRefs: { ids: number[]; ref: RefObject<any> }[] = [];
    const res = groupHoles.map((group: ThreadCardInfo, key: number) => {
      if (group.type === "countersink") return null;
      const cardRef = createRef();
      const isHovered =
        group.holes.find((hole) =>
          hovered.find((hover) => hole.faceIds.includes(hover))
        ) !== undefined;
      const isSelected =
        group.holes.find((hole) => {
          const res = selection?.find((select) => {
            const res1 = hole.faceIds.includes(select);
            return res1;
          });
          return res;
        }) !== undefined;

      const init = existing?.filter(
        (e) =>
          group.holes.find((h) => {
            return h.id === e.id;
          }) !== undefined
      );

      const card = (
        <ThreadCard
          key={key}
          data={group}
          ref={cardRef}
          hover={isHovered || isSelected}
          setHover={setHovered}
          onChange={(val: SelectedThreadsInfoType[], remove?: boolean) => {
            if (remove) {
              removeSelectedThreads(val);
            } else {
              setSelectedThreads(val);
            }
          }}
          init={init}
        />
      );
      cardRefs.push({
        ids: group.holes.map((hol) => hol.faceIds).flat(),
        ref: cardRef,
      });
      return card;
    });
    setCardRefs(cardRefs);
    return res;
  }, [
    existing,
    groupHoles,
    hovered,
    removeSelectedThreads,
    selection,
    setSelectedThreads,
  ]);

  const scrollContainer = document
    .getElementsByClassName(styles.scrollContainer)
    .item(0);

  useEffect(() => {
    //Used to check whether or not the scroll indicator should be shown, such it doesn't show when loading a page which does not have enough content to scroll.
    if (!scrollContainer) return;
    const scrollable =
      scrollContainer.scrollHeight - scrollContainer.scrollTop ===
      scrollContainer.clientHeight;
    setIsAtBottom(scrollable);
  }, [scrollContainer]);

  return (
    <div className={styles.container}>
      {model && (
        <div className={styles.canvasContainer}>
          <ComponentRender
            gltf={model}
            info={info}
            specialModelRender={specialModelRender}
            selection={selection}
            setSelection={setSelection}
            hovered={hovered}
            setHovered={setHovered}
          />
        </div>
      )}
      <div className={styles.threadContainer}>
        <div
          className={`${styles.scrollContainer}`}
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          {threadCards}
        </div>
        <div
          className={`${styles.shadowContainer} ${
            !isAtTop && styles.shadowTop
          }`}
        />
        <div
          className={`${styles.shadowContainer} ${
            !isAtBottom && styles.shadowBottom
          }`}
        />
      </div>
    </div>
  );
};

export default ThreadSelector;
