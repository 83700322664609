import React, { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Tooltip } from "@mui/material";
import AppStateContext from "../../contexts/AppStateContext";
import useStateWithSessionStorage from "../../hooks/UseStateWithSessionStorage";
import {
  CartStatusEnum,
  CartType,
  SimpleCartType,
} from "../../types/CartTypes";
import { apiGetCarts } from "../../util/network/Carts";

type BadgeProps = {
  lastVisitedQuote: number;
  setLastVisitedQuote: (value: { QUOTE: number }) => void;
};

const ReviewQuoteBadge: React.FC<BadgeProps> = ({
  lastVisitedQuote,
  setLastVisitedQuote,
}) => {
  const { token } = useContext(AppStateContext);
  const [quotes, setQuotes] = useState<SimpleCartType[]>([]);
  const [page, setPage] = useStateWithSessionStorage<number>(0, "quotePage");
  const [pageSize, setPageSize] = useStateWithSessionStorage<number>(
    25,
    "quotePageSize"
  );
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<string>("created");

  const fetchQuotes = (): Promise<void> => {
    if (token) {
      return apiGetCarts(
        token,
        page,
        pageSize,
        sortKey,
        sortAsc ? "asc" : "desc",
        [CartStatusEnum.REVIEW]
      )
        .then((res) => {
          setQuotes(res.items);
        })
        .catch((err) => {
          console.error("Error retrieving quotes", err);
        });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    fetchQuotes();
    const intervalId = setInterval(fetchQuotes, 600000);
    return () => clearInterval(intervalId);
  }, [token]);

  const numberOfQuotesInReview = useMemo(() => {
    return quotes.length;
  }, [quotes]);

  return (
    <>
      {numberOfQuotesInReview > 0 && (
        <Tooltip title="state: REVIEW">
          <Badge color={"primary"} badgeContent={numberOfQuotesInReview} />
        </Tooltip>
      )}
    </>
  );
};
export default ReviewQuoteBadge;
