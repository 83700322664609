import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as TotalOrderIcon } from "../../assets/svg/totalOrders.svg";
import { ReactComponent as ActiveOrderIcon } from "../../assets/svg/activeOrder.svg";
import { ReactComponent as QuoteOrderIcon } from "../../assets/svg/quoteOrder.svg";
import { ReactComponent as ItemsIcon } from "../../assets/svg/cube-outline.svg";
import { ReactComponent as CncPartsIcon } from "../../assets/svg/cncParts.svg";
import { ReactComponent as ShippingIcon } from "../../assets/svg/shippingIcon.svg";
import { ReactComponent as QualityControlIcon } from "../../assets/svg/qualityControl.svg";
import { ReactComponent as ProductionIcon } from "../../assets/svg/milling.svg";
import { ReactComponent as ReviewIcon } from "../../assets/svg/review.svg";
import { ReactComponent as ManufaturinfIcon } from "../../assets/svg/manufacturing.svg";
import OrderStatusBox from "components/Dashboard/OrderStatusBox";
import {
  ProductionReportType,
  SalesLiveType,
  SalesReportType,
} from "types/OverviewTypes";
import {
  apiGetProductionReport,
  apiGetSalesLive,
  apiGetSalesReport,
} from "util/network/Overview";
import AppStateContext from "contexts/AppStateContext";
import { formatterNoDec, reformatDate2 } from "util/formatter";
import SalesReportChart from "./graph/SalesReportChart";
import ProductionReportChart from "./graph/ProductionReportChart";
import { apiGetProductionsSummary } from "util/network/Productions";
import { ProductionsSummaryType } from "types/ProductionType";

type SalesDataType = { name: string; value: number };

const Dashboard = () => {
  const [date, setDate] = useState("");
  const [production, setProduction] = useState<ProductionReportType>();
  const [productionMonthly, setProductionMonthly] =
    useState<ProductionReportType>();
  const [sales, setSales] = useState<SalesReportType>();
  const [salesLive, setSalesLive] = useState<SalesLiveType>();
  const [kpiFirstDay, setKpiFirstDay] = useState<Date | null>();
  const [kpiLastDay, setKpiLastDay] = useState<Date | null>();
  const { token } = useContext(AppStateContext);
  const [newOrdersData, setNewOrdersData] = useState<SalesDataType[]>([]);
  const [newUsersData, setNewUsersData] = useState<SalesDataType[]>([]);
  const [newOrdersValueData, setNewOrdersValueData] = useState<SalesDataType[]>(
    []
  );
  const [completedPartsData, setCompletedPartsData] = useState<SalesDataType[]>(
    []
  );
  const [completedItemsData, setCompletedItemsData] = useState<SalesDataType[]>(
    []
  );
  const [completedPartsSumData, setCompletedPartsSumData] = useState<
    SalesDataType[]
  >([]);
  const [productionSummary, setProductionSummary] =
    useState<ProductionsSummaryType>();
  const [isLoading, setIsLoading] = useState(true);

  const dates = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthName = monthNames[dates.getMonth()];

  // Monday as a first day of the week
  const getStartOfWeek = (date: any) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const startOfWeek = new Date(date.getFullYear(), date.getMonth(), diff);
    return startOfWeek;
  };

  // Sunday as the last day of the week
  const getEndOfWeek = (date: any) => {
    const startOfWeek = getStartOfWeek(date);
    const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
    const endOfWeekAdjusted = new Date(
      endOfWeek.getFullYear(),
      endOfWeek.getMonth(),
      endOfWeek.getDate(),
      23,
      59,
      59
    );
    return endOfWeekAdjusted;
  };

  const getFirstDayOfMonth = (date: Date): Date => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
    return firstDay;
  };

  const getLastDayOfMonth = (date: Date): Date => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
    return lastDay;
  };

  // Get first and last day of the month
  let firstMonthDay = getFirstDayOfMonth(dates);
  let lastMonthDay = getLastDayOfMonth(dates);

  const getCurrentWeekNumber = (date: Date): string => {
    let target = new Date(date.valueOf());
    let dayNr = (date.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNr + 3);
    let firstThursday = target.valueOf();
    target.setMonth(0, 1);
    if (target.getDay() !== 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
    }
    let weekNumber =
      1 + Math.ceil((firstThursday - target.valueOf()) / 604800000); // 604800000 = 7 * 24 * 60 * 60 * 1000, number of milliseconds in a week
    return `Week ${weekNumber}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    const startOfCurrentWeek = getStartOfWeek(currentDate);
    const endOfCurrentWeek = getEndOfWeek(currentDate);

    // weekly basis
    apiGetProductionReport(token, startOfCurrentWeek, endOfCurrentWeek).then(
      (report) => {
        setProduction(report);
      }
    );

    // mothly basis
    apiGetSalesReport(token, firstMonthDay, lastMonthDay).then((sale) => {
      setSales(sale);
    });

    apiGetProductionReport(token, firstMonthDay, lastMonthDay).then(
      (report) => {
        setProductionMonthly(report);
      }
    );

    // live
    setIsLoading(true);
    apiGetSalesLive(token).then((live) => {
      setSalesLive(live);
      setIsLoading(false);
    });

    apiGetProductionsSummary(token).then((res) => setProductionSummary(res));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date().toString().split("GMT")[0];
      setDate(currentDate);
    }, 1000); // update every second

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    const months = Array.from({ length: 6 }, (_, index) => {
      const date = new Date();

      date.setMonth(currentMonth - 5 + index);
      return {
        firstDay: getFirstDayOfMonth(date),
        lastDay: getLastDayOfMonth(date),
      };
    });

    Promise.all(
      months.map((month) =>
        apiGetSalesReport(token, month.firstDay, month.lastDay)
      )
    ).then((sales) => {
      const newOrdersData = sales.map((sale, index) => ({
        name: months[index].firstDay.toLocaleString("default", {
          month: "short",
        }),
        value: sale.newOrders ?? 0,
      }));
      const newUsersData = sales.map((sale, index) => ({
        name: months[index].firstDay.toLocaleString("default", {
          month: "short",
        }),
        value: sale.newUsers ?? 0,
      }));
      const newOrdersValueData = sales.map((sale, index) => ({
        name: months[index].firstDay.toLocaleString("default", {
          month: "short",
        }),
        value: sale.newOrdersValue ?? 0,
      }));
      setNewOrdersData(newOrdersData);
      setNewUsersData(newUsersData);
      setNewOrdersValueData(newOrdersValueData);
    });

    Promise.all(
      months.map((month) =>
        apiGetProductionReport(token, month.firstDay, month.lastDay)
      )
    ).then((production) => {
      const completedParts = production.map((production, index) => ({
        name: months[index].firstDay.toLocaleString("default", {
          month: "short",
        }),
        value: production.completedParts ?? 0,
      }));
      const completedItems = production.map((production, index) => ({
        name: months[index].firstDay.toLocaleString("default", {
          month: "short",
        }),
        value: production.completedItems ?? 0,
      }));
      const completedPartsSum = production.map((production, index) => ({
        name: months[index].firstDay.toLocaleString("default", {
          month: "short",
        }),
        value: production.completedPartsSum ?? 0,
      }));
      setCompletedPartsData(completedParts);
      setCompletedItemsData(completedItems);
      setCompletedPartsSumData(completedPartsSum);
    });
  }, [token]);

  const currentMonth = new Date().toLocaleString("default", {
    month: "short",
  });

  const rawAmount = Number(salesLive?.activeOrdersTotal);
  const amount = Number.isNaN(rawAmount)
    ? rawAmount
    : formatterNoDec.format(rawAmount);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Dashboard
            </Typography>
          </div>
          <div style={{ paddingRight: "3rem" }}>
            <span style={{ fontWeight: "700", fontSize: "1.1rem" }}>
              Today,{" "}
            </span>
            <span>{date}</span>
          </div>
        </div>
      </Grid>
      <Grid
        xs={12}
        style={{
          padding: "0.5rem 1.5rem 1rem 4rem",
        }}
      >
        <Typography fontWeight={"bold"} fontSize={"1.3rem"}>
          Active Orders
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ paddingRight: "1rem" }}>
              <OrderStatusBox
                title="Order Value"
                icon={<TotalOrderIcon />}
                amount={amount}
              />
            </div>
            <div style={{ paddingRight: "1rem" }}>
              <OrderStatusBox
                title="Orders"
                icon={<ActiveOrderIcon />}
                amount={salesLive?.activeOrders}
              />
            </div>
            <div style={{ paddingRight: "1rem" }}>
              <OrderStatusBox
                title="Quotes"
                icon={<QuoteOrderIcon />}
                amount={salesLive?.activeBaskets}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ paddingRight: "1rem" }}>
              <OrderStatusBox
                title="Review"
                icon={<ReviewIcon />}
                amount={productionSummary?.reviewReady}
              />
            </div>
            <div style={{ paddingRight: "1rem" }}>
              <OrderStatusBox
                title="CAM"
                icon={<ManufaturinfIcon />}
                amount={productionSummary?.camReady}
              />
            </div>
            <div style={{ paddingRight: "1rem" }}>
              <span style={{ color: "#000" }}>
                <OrderStatusBox
                  title="Production"
                  icon={<ProductionIcon />}
                  amount={productionSummary?.productionReady}
                />
              </span>
            </div>

            <div style={{ paddingRight: "1rem" }}>
              <OrderStatusBox
                title="Afterwork"
                icon={<QualityControlIcon />}
                amount={productionSummary?.afterworkReady}
              />
            </div>
            <div style={{ paddingRight: "1rem" }}>
              <OrderStatusBox
                title="Shipping"
                icon={<ShippingIcon />}
                amount={productionSummary?.shippingReady}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        xs={6}
        style={{
          padding: "0.5rem 1.5rem 1rem 4rem",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            background: "#E9F5F3",
            height: "fit-content",
            boxShadow:
              "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
            borderRadius: "4px",
            padding: "0.5rem",
            minWidth: "300px",
          }}
        >
          <span
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              paddingBottom: "0.5rem",
            }}
          >
            Last Order
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {isLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: "500",
                }}
              >
                #{salesLive?.latestOrder?.orderNo}
              </span>
            )}
            {isLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              <span style={{ fontWeight: "500" }}>
                {reformatDate2(salesLive?.latestOrder.created)}
              </span>
            )}
          </div>
          <div>
            {isLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                {salesLive?.latestOrder?.company}
              </span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {isLoading ? (
                <CircularProgress size="1rem" />
              ) : (
                <span
                  style={{
                    paddingTop: "0.5rem",
                    fontWeight: "500",
                    fontSize: "0.8rem",
                  }}
                >
                  {salesLive?.latestOrder?.leadTimeOption}{" "}
                  {salesLive?.latestOrder?.leadTimeDays}wd
                </span>
              )}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {isLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontWeight: "500",
                      alignItems: "center",
                      paddingRight: "0.5rem",
                    }}
                  >
                    {salesLive?.latestOrder?.parts} <ItemsIcon />
                  </span>
                )}
                {isLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    {salesLive?.latestOrder.items} <CncPartsIcon />
                  </span>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {isLoading ? (
                <CircularProgress size="1rem" />
              ) : (
                <span style={{ fontSize: "1.2rem" }}>
                  {formatterNoDec.format(Number(salesLive?.latestOrder.total))}
                </span>
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          padding: "1rem 1.5rem 1rem 4rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Sales, {currentMonth}
          </span>
          <SalesReportChart
            newOrdersData={newOrdersData}
            newUsersData={newUsersData}
            newOrdersValueData={newOrdersValueData}
            orderNum={sales?.newOrders}
            orderVal={formatterNoDec.format(Number(sales?.newOrdersValue))}
            usersNum={sales?.newUsers}
          />
        </div>
        <div>
          <span
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Production, {currentMonth}
          </span>
          <ProductionReportChart
            completedItems={completedItemsData}
            completedParts={completedPartsData}
            completedPartsSum={completedPartsSumData}
            value={formatterNoDec.format(
              Number(productionMonthly?.completedPartsSum)
            )}
            models={productionMonthly?.completedParts}
            cncParts={productionMonthly?.completedItems}
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default Dashboard;
