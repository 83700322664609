import React, { useState, useEffect } from 'react';
import { Tool, addTool, updateTool, defaultTool } from './ToolsAPI'; // Import the Tool interface
import './../modal.css';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel } from '@mui/material';

interface AddToolProps {
  onClose: () => void;
  onSubmit: () => void;
  toolToEdit: Tool | null; // Accept a Tool or null for editing
}

const AddEditToolForm: React.FC<AddToolProps> = ({ onClose, onSubmit: onSubmitCallback, toolToEdit }) => {
  const [formData, setFormData] = useState<Tool>({...defaultTool, 
    id: toolToEdit == null ? 0 : toolToEdit.id,
    number: toolToEdit == null ? 0 : toolToEdit.number,
    sister: toolToEdit == null ? "" : toolToEdit.sister,
    description: toolToEdit == null ? '' : toolToEdit.description,
    sku: toolToEdit == null ? '' : toolToEdit.sku,
    position: toolToEdit == null ? '' : toolToEdit.position,
    geom_length: toolToEdit == null ? 0.0 : toolToEdit.geom_length,
    geom_diameter: toolToEdit == null ? 0.0 : toolToEdit.geom_diameter,
    life_count: toolToEdit == null ? 0.0 : toolToEdit.life_count,
    life_time: toolToEdit == null ? 0.0 : toolToEdit.life_time,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [changedFields, setChangedFields] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    // If a toolToEdit is provided, populate the form fields for editing
    if (toolToEdit) {
      setFormData(toolToEdit);
    }
  }, [toolToEdit]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27) { // Escape key
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      setChangedFields({ ...changedFields, [name]: value });
    };

    const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;
      setFormData({ ...formData, [name]: checked });
      setChangedFields({ ...changedFields, [name]: checked });
    };

  const handleSisterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const modifiedValue = value
      .replace(/[^A-Za-z]/g, '')
      .toUpperCase()
      .slice(0, 2);
    setFormData({ ...formData, [name]: modifiedValue });
  };



  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    if (toolToEdit == null) {
      await addTool(formData);
    }
    else {
      await updateTool(toolToEdit.id, changedFields);
    }
    setIsLoading(false);
    onSubmitCallback();
    onClose();
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{toolToEdit == null ? 'Add Tool' : `Edit Tool T${toolToEdit.number}`}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              type="number"
              name="number"
              label="Number"
              value={formData.number}
              onChange={handleChange}
              disabled={isLoading}
              required
              sx={{ width: '100px' }}
              InputLabelProps={{ shrink: true }}
            />
            <span> </span>
            <TextField
              type="text"
              name="sister"
              label="Sister"
              value={formData.sister}
              onChange={handleSisterChange}
              disabled={isLoading}
              sx={{ width: '80px' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
            <TextField
              type="text"
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleChange}
              disabled={isLoading}
              sx={{ width: '300px' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
            <TextField
              type="text"
              name="sku"
              label="SKU"
              value={formData.sku}
              onChange={handleChange}
              disabled={isLoading}
              sx={{ width: '300px' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
          <h3>Geometry</h3>
            <TextField
              type="number"
              name="geom_length"
              label="Length"
              value={formData.geom_length}
              onChange={handleChange}
              InputProps={{
                inputProps: {
                  min: 0, // Specify the minimum value allowed
                  step: 0.1, // Specify the step size for incrementing/decrementing the value
                },
              }}
              disabled={isLoading}
              sx={{ width: '100px' }}
              InputLabelProps={{ shrink: true }}
            />
            <span> </span>
            <TextField
              type="number"
              name="geom_diameter"
              label="Diameter"
              value={formData.geom_diameter}
              onChange={handleChange}
              InputProps={{
                inputProps: {
                  min: 0, // Specify the minimum value allowed
                  step: 0.1, // Specify the step size for incrementing/decrementing the value
                },
              }}
              disabled={isLoading}
              sx={{ width: '100px' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
          <h3>Measurement</h3>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={<Checkbox checked={formData.measure_length} onChange={handleChangeCheck} name="measure_length"/>}
                label="Length"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.measure_diameter} onChange={handleChangeCheck} name="measure_diameter"/>}
                label="Diameter"
              />
            </div>
            <TextField
              type="number"
              name="measure_center_offset"
              label="Offset"
              value={formData.measure_center_offset}
              onChange={handleChange}
              InputProps={{
                inputProps: {
                  min: 0, // Specify the minimum value allowed
                  step: 0.1, // Specify the step size for incrementing/decrementing the value
                },
              }}
              disabled={isLoading}
              sx={{ width: '80px' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          </div>
          {(toolToEdit != null) &&  
            (
              <div>
              <TextField
                type="number"
                name="measure_length"
                label="Length"
                value={formData.measure_length}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    min: 0, // Specify the minimum value allowed
                    step: 0.1, // Specify the step size for incrementing/decrementing the value
                  },
                }}
                disabled={isLoading}
                sx={{ width: '100px' }}
                InputLabelProps={{ shrink: true }}
              />
              <span> </span>
              <TextField
                type="number"
                name="measured_diameter"
                label="Diameter"
                value={formData.measured_diameter}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    min: 0, // Specify the minimum value allowed
                    step: 0.1, // Specify the step size for incrementing/decrementing the value
                  },
                }}
                disabled={isLoading}
                sx={{ width: '100px' }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            )
          }
          {(toolToEdit != null) &&  
            (
              <div>
                <h3>Position</h3>
              <TextField
                type="text"
                name="position"
                label="Machine"
                value={formData.position}
                onChange={handleChange}
                disabled={isLoading}
                sx={{ width: '100px' }}
                InputLabelProps={{ shrink: true }}
              />
              <span> </span>
              <TextField
                type="number"
                name="position_id"
                label="Id"
                value={formData.position_id}
                onChange={handleChange}
                disabled={isLoading}
                sx={{ width: '100px' }}
                required={formData.position != ""}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            )
          }
          <button type="button" onClick={onClose} disabled={isLoading}>
            Cancel
          </button>
          <span> </span>
          <button type="submit" disabled={isLoading} onSubmit={handleSubmit}>
            {isLoading ? 'Saving...' : (toolToEdit == null ? 'Create' : 'Save' )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEditToolForm;
