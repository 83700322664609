import { Badge } from "@mui/material";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { OrderType, SimpleOrderType } from "types/OrderTypes";
import { apiGetOrders } from "util/network/Orders";
import AppStateContext from "../../contexts/AppStateContext";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";

type BadgeProps = {
  newOrder: number;
  lastVisited: number;
  setLastVisited: (value: { ORDER: number }) => void;
};

const NewOrderBadge: React.FC<BadgeProps> = ({
  newOrder,
  lastVisited,
  setLastVisited,
}) => {
  const { token } = useContext(AppStateContext);
  const [orders, setOrders] = useState<SimpleOrderType[]>();
  const [page, setPage] = useStateWithSessionStorage<number>(0, "orderPage");
  const [pageSize, setPageSize] = useStateWithSessionStorage<number>(
    25,
    "orderPageSize"
  );
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<string>("created");
  const [statusFilter, setStatusFilter] = useStateWithLocalStorage(
    ["REVIEW", "PROCESSING", "COMPLETE", "UNPAID"],
    "orderStatusFilter"
  );
  const [showBadge, setShowBadge] = useState(true);

  const handleBadgeClick = () => {
    setLastVisited({ ORDER: Date.now() });
    setShowBadge(false);
  };

  const fetchOrders = (): Promise<void> => {
    if (token) {
      return apiGetOrders(
        token,
        page + 1,
        pageSize,
        sortKey,
        sortAsc ? "asc" : "desc",
        statusFilter.join("+")
      )
        .then((res) => {
          setOrders(res.items);
          if (!lastVisited && res.items.length > 0) {
            const latestOrderTime = new Date(res.items[0].created).getTime();
            const defaultTimestamp = latestOrderTime - 24 * 60 * 60 * 1000;
            setLastVisited({ ORDER: defaultTimestamp });
          }

          const newOrderCount = res.items.filter(
            (order) => new Date(order.created).getTime() > lastVisited
          ).length;

          if (newOrderCount > 0) {
            setShowBadge(true);
          }
        })
        .catch((err) => {
          console.error("Error retrieving orders", err);
        });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    fetchOrders();

    const intervalId = setInterval(fetchOrders, 600000);

    return () => clearInterval(intervalId);
  }, [token, page, pageSize, sortKey, sortAsc, statusFilter]);

  useEffect(() => {
    if (newOrder > 0) {
      setLastVisited({ ORDER: Date.now() });
    }
  }, [newOrder]);

  const numberOfNewOrders = useMemo(() => {
    return (
      orders?.filter((order) => {
        const orderCreatedUTC = new Date(order.created + "Z").getTime();
        return orderCreatedUTC > lastVisited;
      }).length || 0
    );
  }, [orders, lastVisited]);

  useEffect(() => {
    if (numberOfNewOrders && numberOfNewOrders > 0) {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
  }, [numberOfNewOrders]);

  return (
    <>
      {showBadge && numberOfNewOrders > 0 && (
        <Badge
          color={"primary"}
          badgeContent={numberOfNewOrders}
          onClick={handleBadgeClick}
        ></Badge>
      )}
    </>
  );
};
export default NewOrderBadge;
