import { FunctionComponent, useContext, useEffect, useState } from "react";
import {
  ProductionTaskEnum,
  ProductionTaskStateEnum,
  ProductionTaskType,
  ProductionVMType,
  UpdateProductionTaskType,
} from "types/ProductionType";
import PartStateButton from "components/Buttons/PartStateButton";
import TaskTableRow from "../TaskTableRow";
import { apiUpdateProductionTask } from "util/network/Productions";
import { useToast } from "contexts/ToastContext";
import AppStateContext from "contexts/AppStateContext";
import { MachineType } from "types/MachineType";

export interface ProductionPartState {
  state: ProductionTaskStateEnum | undefined;
  type: ProductionTaskEnum | undefined;
}

type PropsType = {
  project: ProductionVMType;
  machines?: MachineType[];
};

const MillingRow: FunctionComponent<PropsType> = ({ project, machines }) => {
  const { token } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [taskStatus, setTaskStatus] = useState<ProductionPartState>({
    type: undefined,
    state: undefined,
  });
  const [tasks, setTasks] = useState<ProductionTaskType[]>(project.tasks);

  useEffect(() => {
    const millingTask = project.tasks.find((tsk) => tsk.name === "production");
    const cuttingTask = project.tasks.find((tsk) => tsk.name === "production");

    if (
      cuttingTask &&
      cuttingTask.state === ProductionTaskStateEnum.DONE &&
      millingTask
    ) {
      setTaskStatus({
        type: ProductionTaskEnum.MATERIALCUT,
        state: cuttingTask.state as ProductionTaskStateEnum,
      });
    } else if (
      cuttingTask &&
      cuttingTask.state === ProductionTaskStateEnum.DONE
    ) {
      setTaskStatus({
        type: ProductionTaskEnum.MATERIALCUT,
        state: ProductionTaskStateEnum.DONE,
      });
    } else if (
      cuttingTask &&
      cuttingTask.state === ProductionTaskStateEnum.ACTIVE
    ) {
      setTaskStatus({
        type: ProductionTaskEnum.MATERIALCUT,
        state: ProductionTaskStateEnum.ACTIVE,
      });
    } else if (
      cuttingTask &&
      cuttingTask.state === ProductionTaskStateEnum.ACTIVE
    ) {
      setTaskStatus({
        type: ProductionTaskEnum.CAM,
        state: ProductionTaskStateEnum.ACTIVE,
      });
    } else if (
      cuttingTask &&
      cuttingTask.state === ProductionTaskStateEnum.TODO
    ) {
      setTaskStatus({
        type: ProductionTaskEnum.CAM,
        state: ProductionTaskStateEnum.TODO,
      });
    } else {
      setTaskStatus({ type: undefined, state: undefined });
    }
  }, [project.tasks]);

  const handleTaskUpdate = (changes: Partial<ProductionTaskType>) => {
    if (project) {
      const task = project.tasks.find((task) => task.id === changes.id);
      if (!task) {
        return;
      }
      const updatedTask = {
        ...task,
        ...changes,
      } as ProductionTaskType;

      setTasks((prevTasks) => {
        return prevTasks.map((prevTask) => {
          if (prevTask.id === changes.id) {
            return {
              ...prevTask,
              ...changes,
            };
          } else if (changes.state) {
            if (
              changes.state === ProductionTaskStateEnum.DONE ||
              changes.state === ProductionTaskStateEnum.ACTIVE
            ) {
              if (prevTask.priority < updatedTask.priority) {
                return {
                  ...prevTask,
                  state: ProductionTaskStateEnum.DONE,
                };
              } else {
                return prevTask;
              }
            } else if (updatedTask.state === ProductionTaskStateEnum.TODO) {
              if (prevTask.priority > updatedTask.priority) {
                return {
                  ...prevTask,
                  state: ProductionTaskStateEnum.TODO,
                };
              } else {
                return prevTask;
              }
            } else {
              return prevTask;
            }
          } else {
            return prevTask;
          }
        });
      });
      let id = Number(changes.id);
      if (isNaN(id)) {
        return;
      }
      const command = {
        id: id,
        name: changes.name,
        description: changes.description,
        state: changes.state,
        priority: changes.priority,
        progress: changes.progress,
        progressMax: changes.progressMax,
        deadline: changes.deadline,
        leadTime: changes.leadTime,
        workTime: changes.workTime,
        users: changes.users?.map((user) => user.id),
        machines: changes.machines?.map((machine) => machine.id),
      } as UpdateProductionTaskType;
      apiUpdateProductionTask(token, id, command)
        .then((res) => {
          if (res) {
            addToast({
              type: "success",
              message: "Task updated",
              time: 1000,
            });
          }
        })
        .catch((error) => {
          addToast({
            type: "error",
            message: "Failed to update task",
            keep: true,
          });
          console.error("Failed to update task:", error);
        });
    }
  };

  const getTaskToDisplay = () => {
    const orderedTasks = project.tasks.sort((a, b) =>
      a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0
    );

    const millingTaskIndex = orderedTasks.findIndex(
      (tsk) =>
        (tsk.description == "Milling" || tsk.description == "Material") &&
        tsk.state != ProductionTaskStateEnum.DONE
    );

    if (project.productId == 9539) {
      console.log(JSON.stringify(orderedTasks[millingTaskIndex], null, 4));
    }

    if (millingTaskIndex === -1) {
      return (
        orderedTasks.find((tsk) => tsk.state != ProductionTaskStateEnum.DONE) ||
        orderedTasks.reduce(
          (prev, curr) => (prev.priority < curr.priority ? curr : prev),
          project.tasks[0]
        )
      );
    }

    const millingTask = orderedTasks[millingTaskIndex];

    const beforeTask =
      millingTaskIndex > 0 ? orderedTasks[millingTaskIndex - 1] : undefined;

    if (beforeTask?.state == ProductionTaskStateEnum.DONE) {
      return millingTask;
    }

    return beforeTask ?? millingTask;
  };

  const taskToDisplay = getTaskToDisplay();

  return (
    <>
      {taskToDisplay && (
        <TaskTableRow
          task={taskToDisplay}
          key={taskToDisplay.id}
          project={project}
          machines={machines}
          onChange={handleTaskUpdate}
          buttonComponent={
            <PartStateButton
              key={taskToDisplay.id}
              type={taskToDisplay.name as ProductionTaskEnum}
              state={taskToDisplay.state}
              project={project}
              onChange={handleTaskUpdate}
              task={taskToDisplay}
              title={
                taskToDisplay.description != ""
                  ? taskToDisplay.description
                  : undefined
              }
            />
          }
          taskStatus={taskStatus}
          mill
        />
      )}
    </>
  );
};
export default MillingRow;
