import React, { useState, useEffect } from 'react';
import { Tool, addTool, updateTool, defaultTool, moveTool } from './ToolsAPI'; // Import the Tool interface
import './../modal.css';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel } from '@mui/material';

interface AddToolProps {
  onClose: () => void;
  onSubmit: () => void;
  toolToEdit: Tool; // Accept a Tool or null for editing
}

const MoveToolForm: React.FC<AddToolProps> = ({ onClose, onSubmit: onSubmitCallback, toolToEdit }) => {
  const [formData, setFormData] = useState<Tool>({...defaultTool, 
    id: toolToEdit == null ? 0 : toolToEdit.id,
    number: toolToEdit == null ? 0 : toolToEdit.number,
    sister: toolToEdit == null ? "" : toolToEdit.sister,
    position: toolToEdit == null ? '' : toolToEdit.position,
    position_id: toolToEdit == null ? null : toolToEdit.position_id,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // If a toolToEdit is provided, populate the form fields for editing
    if (toolToEdit) {
      setFormData(toolToEdit);
    }
  }, [toolToEdit]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27) { // Escape key
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    if (toolToEdit == null) {
      await addTool(formData);
    }
    else {
      await moveTool(toolToEdit.id, formData.position, formData.position_id);
    }
    setIsLoading(false);
    onSubmitCallback();
    onClose();
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{`Move Tool T${toolToEdit.number}`}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              type="text"
              name="position"
              label="Machine"
              value={formData.position}
              onChange={handleChange}
              disabled={isLoading}
              sx={{ width: '100px' }}
              InputLabelProps={{ shrink: true }}
            />
            <span> </span>
            <TextField
              type="number"
              name="position_id"
              label="Id"
              value={formData.position_id}
              onChange={handleChange}
              disabled={isLoading}
              sx={{ width: '100px' }}
              required={formData.position != ""}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <button type="button" onClick={onClose} disabled={isLoading}>
            Cancel
          </button>
          <span> </span>
          <button type="submit" disabled={isLoading} onSubmit={handleSubmit}>
            {isLoading ? 'Saving...' : 'Move'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MoveToolForm;
