import { extend, Object3DNode, useFrame, useThree } from "@react-three/fiber";
import { FunctionComponent, useMemo, useRef } from "react";
import {
  DoubleSide,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Points,
  Vector2,
  Vector3,
} from "three";
import { ErrorType } from "../../../types/RenderTypes";
import { staticColors } from "../../../util/ColorUtil";
import DFMPoints from "./DFMPoints";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";
extend({ LineMaterial, LineGeometry, LineSegments2 });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      lineSegments2: Object3DNode<LineSegments2, typeof LineSegments2>;
    }
  }
}

type PropsType = {
  models: {
    face: null | Mesh;
    edge: null | LineSegments;
    vertex: null | Points;
    thickness: null | Mesh;
    clearance: null | Mesh;
  };
  showError?: ErrorType;
  modelCenter?: Vector3;
};

const DFMErrorsModels: FunctionComponent<PropsType> = ({
  models,
  showError,
  modelCenter = new Vector3(),
}) => {
  const { scene, camera, size } = useThree();
  let line;
  useFrame(
    ({ gl }) =>
      void ((gl.autoClear = false),
      gl.clearDepth(),
      gl.render(scene.children[1], camera)),
    11
  );
  // // EXPERIMENTS

  //const positions: number[] = [];
  // useEffect(() => {
  //   const indices: number[] = [];
  //   const points: Vector3[] = [];
  //   let startIndex = 0;

  //   if (models.edge) {
  //     const edgePositions = models.edge?.geometry.getAttribute("position");
  //     //if (key < 10) console.log(edge.geometry);
  //     const size = edgePositions.itemSize;
  //     let number = edgePositions.array.length;

  //     for (let i = 0; i < number; i += size) {
  //       points.push(
  //         new Vector3(
  //           edgePositions.array[i],

  //           edgePositions.array[i + 1],

  //           edgePositions.array[i + 2]
  //         )
  //       );
  //       indices.push(startIndex, startIndex + 1);
  //       startIndex++;
  //     }
  //     points.push(new Vector3(NaN, NaN, NaN));
  //     var geom = new LineSegmentsGeometry().fromLineSegments(models.edge);
  //     geom.setIndex(indices);

  //     let matLine = new LineMaterial({
  //       color: staticColors.error,
  //       linewidth: 5, // in world units with size attenuation, pixels otherwise
  //       vertexColors: true,
  //       //resolution:  // to be set by renderer, eventually
  //       dashed: false,
  //       alphaToCoverage: true,
  //     });

  //     line = new LineSegments2(geom, matLine);
  //     line.computeLineDistances();
  //     line.scale.set(1, 1, 1);
  //     scene.add(line);
  //     console.log("i tried", line);
  //   }
  // }, [models.edge]);

  // //

  const meshMaterial = useMemo(() => {
    const mat = new MeshBasicMaterial();
    mat.color.set(staticColors.error);
    //mat.specular.set(new Color(0xf3f3f3));
    //mat.shininess = 200;
    mat.side = DoubleSide;
    mat.polygonOffset = true;
    mat.polygonOffsetFactor = 1;
    mat.polygonOffsetUnits = 4;
    return <meshBasicMaterial {...mat} />;
  }, []);

  const meshTransparentMaterial = useMemo(
    () => (
      <meshBasicMaterial
        transparent={true}
        opacity={0.2}
        color={staticColors.error}
        side={DoubleSide}
      />
    ),
    []
  );

  const lineMaterial = useMemo(() => {
    const mat = new LineBasicMaterial();
    mat.color.set(staticColors.error);
    //mat.specular.set(new Color(0xf3f3f3));
    //mat.shininess = 200;
    mat.side = DoubleSide;
    mat.polygonOffset = true;
    mat.polygonOffsetFactor = -1;
    mat.polygonOffsetUnits = 4;
    return <lineBasicMaterial {...mat} />;
  }, []);

  // const meshLineMaterial = useMemo(() => {
  //   const mat = new MeshLineMaterial();
  //   mat.color.set(staticColors.error);
  //   mat.lineWdith = 4;

  //   return <MeshLineMaterial {...mat} />;
  // }, []);

  const overdimensionRef = useRef<Mesh>();
  const underdimensionRef = useRef<Mesh>();
  const facesRef = useRef<Mesh>();
  const edgesRef = useRef<LineSegments>();
  const verticesRef = useRef<Points>();
  const thicknessRef = useRef<Mesh>();
  const clearanceRef = useRef<Mesh>();

  // const meshgeo = models.edge?.geometry;
  // const meshline = new MeshLine();
  // meshline.setGeometry(meshgeo);

  const faces = useMemo(() => {
    return (
      <mesh>
        <bufferGeometry {...models.face?.geometry} />
        {meshMaterial}
      </mesh>
    );
  }, [meshMaterial, models.face?.geometry]);

  const edges = useMemo(() => {
    //console.log(models.edge);
    const indices: number[] = [];
    const points: Vector3[] = [];
    let startIndex = 0;

    if (models.edge) {
      const edgePositions = models.edge?.geometry.getAttribute("position");
      //if (key < 10) console.log(edge.geometry);
      const sizeEdge = edgePositions.itemSize;
      let number = edgePositions.array.length;

      for (let i = 0; i < number; i += sizeEdge) {
        points.push(
          new Vector3(
            edgePositions.array[i],

            edgePositions.array[i + 1],

            edgePositions.array[i + 2]
          )
        );
        indices.push(startIndex, startIndex + 1);
        startIndex++;
      }
      points.push(new Vector3(NaN, NaN, NaN));
      var geom = new LineSegmentsGeometry().fromLineSegments(models.edge);
      //geom.setIndex(indices);

      let matLine = new LineMaterial({
        color: staticColors.error,
        linewidth: 5, // in world units with size attenuation, pixels otherwise

        resolution: new Vector2(size.width, size.height), // to be set by renderer, eventually
        dashed: false,
        alphaToCoverage: false,
      });

      line = new LineSegments2(geom, matLine);
      // line.computeLineDistances();
      // line.scale.set(1, 1, 1);
      // scene.add(line);
      return <lineSegments2 {...line}></lineSegments2>;
    }

    return;
    // <lineSegments {...models.edge}>{lineMaterial}</lineSegments>;
  }, [lineMaterial, models.edge]);

  const vertices = useMemo(() => {
    if (models.vertex) {
      return <DFMPoints vertices={models.vertex} />;
    }
  }, [models.vertex]);

  const clearance = useMemo(() => {
    return <mesh {...models.clearance}>{meshMaterial}</mesh>;
  }, [meshMaterial, models.clearance]);

  const thickness = useMemo(() => {
    return <mesh {...models.thickness}>{meshTransparentMaterial}</mesh>;
  }, [meshTransparentMaterial, models.thickness]);

  const maxDimBox = useMemo(() => {
    return (
      <mesh position={modelCenter}>
        <boxGeometry args={[600, 500, 150]} />
        {meshTransparentMaterial}
      </mesh>
    );
  }, [meshTransparentMaterial, modelCenter]);

  const minDimBox = useMemo(() => {
    return (
      <mesh position={modelCenter}>
        <boxGeometry args={[8, 8, 2]} />
        {meshTransparentMaterial}
      </mesh>
    );
  }, [meshTransparentMaterial, modelCenter]);

  return (
    <>
      {showError == "face" && faces}
      {showError == "edge" && edges}
      {showError == "vertex" && vertices}
      {showError == "clearance" && clearance}
      {showError == "thickness" && thickness}
      {showError == "overdimension" && maxDimBox}
      {showError == "underdimension" && minDimBox}
    </>
  );
};

export default DFMErrorsModels;
