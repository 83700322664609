import React, { useEffect, useState, useContext, useMemo } from "react";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import { RequisitionStatusEnum, RequisitionType } from "types/RequisitionTypes";
import { apiGetRequisitions } from "util/network/Requisitions";
import AppStateContext from "../../contexts/AppStateContext";
import { Badge } from "@mui/material";
import dayjs from "dayjs";

type BadgeProps = {
  newReq: number;
};

const NewSourcingBadge: React.FC<BadgeProps> = ({ newReq }) => {
  const { token } = useContext(AppStateContext);
  const [showBadge, setShowBadge] = useState(true);
  const [requisitions, setRequisitions] = useState<RequisitionType[]>([]);
  const [numOfNewReq, setNumOfNewReq] = useState(0);
  const [timestamp, setTimestamp] = useStateWithLocalStorage(
    Date.now(),
    "lastVisitedReq"
  );

  const handleBadgeClick = () => {
    setTimestamp(Date.now());
    setShowBadge(false);
  };

  const fetchRequisitions = (): Promise<void> => {
    return apiGetRequisitions(
      token,
      1,
      300,
      undefined,
      undefined,
      undefined,
      undefined,
      `created>${dayjs(new Date(timestamp))
        .subtract(2, "hours")
        .format("YYYY-MM-DDTHH:mm:ss")}`
    )
      .then((res) => {
        setRequisitions(res.items);
        setNumOfNewReq(res.total);

        if (!timestamp && res.items.length > 0) {
          const latestReqTime = new Date(res.items[0].created).getTime();
          setTimestamp(latestReqTime);
        }

        const newReqCount = res.total;
        if (newReqCount > 0) {
          setShowBadge(true);
        }
      })
      .catch((err) => {
        console.error("Error retrieving quotes", err);
      });
    return Promise.resolve();
  };

  useEffect(() => {
    fetchRequisitions();

    const intervalId = setInterval(fetchRequisitions, 600000);

    return () => clearInterval(intervalId);
  }, [token]);

  useEffect(() => {
    if (newReq > 0) {
      setTimestamp(Date.now());
      setNumOfNewReq(0);
    }
  }, [newReq]);

  return (
    <>
      <Badge
        color={"primary"}
        badgeContent={numOfNewReq}
        onClick={handleBadgeClick}
      ></Badge>
    </>
  );
};
export default NewSourcingBadge;
