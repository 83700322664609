import { FunctionComponent } from "react";
import { Dialog, DialogActions, DialogContent, Button } from "@mui/material";
import { UserType } from "../../../types/users/UserTypes";
import styles from "../../order_management/orderedit_components/Styles/loginPageStyle.module.css";

type PropsType = {
  existing?: UserType | null;
  setUser: Function;
  deleteUser: Function;
  showDeleteUser: boolean;
  setShowDeleteUser: Function;
};

const DeleteUser: FunctionComponent<PropsType> = ({
  existing,
  setUser,
  deleteUser,
  showDeleteUser,
  setShowDeleteUser,
}) => {
  //Need solution to both show snack message/another message on success AND close the dialog
  const submitDelete = () => {
    existing && deleteUser(existing?.id);
  };

  const dialogCancel = () => {
    setShowDeleteUser(false);
  };

  return existing?.email ? (
    <Dialog open={showDeleteUser} onClose={() => dialogCancel()}>
      <DialogContent>
        <div className={styles.pd}>
          Delete <a>{existing?.email}</a> employee?
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dialogCancel()}
          variant="contained"
          color={"error"}
          className={styles.modalCancelBtn}
        >
          Nej
        </Button>
        <Button
          type={"submit"}
          form={"resetPasswordEmail"}
          color="primary"
          variant="contained"
          onClick={() => submitDelete()}
          className={styles.modalAcceptBtn}
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <br />
  );
};

export default DeleteUser;
