import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useContext,
  useMemo,
  useCallback,
  ReactElement,
  useEffect,
  forwardRef,
  useReducer,
} from "react";
import styles from "./ThreadCard.module.css";
import clsx from "clsx";
import {
  Button,
  Card,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Clear, ExpandMore } from "@mui/icons-material";
import AppStateContext from "../../../contexts/AppStateContext";
import { ThreadCardInfo, ThreadType } from "../../../types/ConfigTypes";
import { SelectedThreadsInfoType } from "../../../types/RenderTypes";
import ListReducer, { ListReducerEnum } from "../../../util/ListReducer";
import { arraysLike } from "util/ArrayUtil";

type IndividualHoleSelectorPropsType = {
  groupIds: number[];
  holeIds: number[];
  threadSelection: ReactElement[][];
  selectedOption: string;
  setSelectedOptions: (option: string, index?: number) => void;
  setHover?: (ids: number[]) => void;
};

const IndividualHoleSelector: FunctionComponent<
  IndividualHoleSelectorPropsType
> = ({
  groupIds,
  holeIds,
  threadSelection,
  selectedOption,
  setSelectedOptions,
  setHover,
}) => {
  const { strings } = useContext(AppStateContext);
  const [isHovered, setHovered] = useState<boolean>(false);

  const changeHover = useCallback(
    (ids: number[]) => {
      setHovered(ids.length > 0);
      if (setHover) {
        setHover(ids);
      }
    },
    [setHover]
  );

  const isCustom = useMemo(() => {
    return (
      selectedOption !== "none" &&
      !threadSelection.flat().some((val) => val.props.value === selectedOption)
    );
  }, [selectedOption, threadSelection]);

  return isCustom ? (
    <TextField
      className={styles.formControlSecondary}
      InputProps={{
        className: isHovered ? styles.formControlSecondaryHover : "",
        classes: {
          notchedOutline: isHovered ? styles.formControlSecondaryHover : "",
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setSelectedOptions("none", holeIds[0])}
              edge="end"
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={selectedOption}
      onChange={
        (event: any) => {
          setSelectedOptions(event.target.value, holeIds[0]);
        }
        //handleChangeForIndividual(event, hole)
      }
      // Add a X button to remove the custom thread
      variant="outlined"
      size={"small"}
      margin={"dense"}
      onMouseEnter={(e: any) => {
        e.preventDefault();
        changeHover && changeHover(holeIds);
        //onMouseEnterOne(hole.uuids);
        //highlightsOnSingle(hole.uuids);
      }}
      onMouseLeave={
        (e: any) => {
          e.preventDefault();
          changeHover && changeHover(groupIds);
        }
        //onMouseLeaveOne(hole.uuids)
        //highlightsOn(flatHolesArr)
      }
    />
  ) : (
    <TextField
      className={styles.formControlSecondary}
      InputProps={{
        className: isHovered ? styles.formControlSecondaryHover : "",
        classes: {
          notchedOutline: isHovered ? styles.formControlSecondaryHover : "",
        },
      }}
      select
      value={selectedOption}
      onChange={
        (event: any) => {
          setSelectedOptions(event.target.value);
        }
        //handleChangeForIndividual(event, hole)
      }
      SelectProps={{
        native: true,
      }}
      variant="outlined"
      size={"small"}
      margin={"dense"}
      onMouseEnter={(e: any) => {
        e.preventDefault();
        changeHover && changeHover(holeIds);
        //onMouseEnterOne(hole.uuids);
        //highlightsOnSingle(hole.uuids);
      }}
      onMouseLeave={
        (e: any) => {
          e.preventDefault();
          changeHover && changeHover(groupIds);
        }
        //onMouseLeaveOne(hole.uuids)
        //highlightsOn(flatHolesArr)
      }
    >
      <option value={"none"}>{`\xa0 ${strings.ThreadCardNoThreads}`}</option>
      <option
        value={""}
        style={{ cursor: "pointer", color: "#008b80" }}
        id="customThreadSelectOption"
      >{` \xa0 ${strings.AddCustomThread}`}</option>
      {threadSelection}
      {
        // {threadOptions.map((val, key) => (
        //     <option key={key} value={val.name}>
        //       {val.name}
        //       {val.name.charAt(0) === "M" &&
        //         " (" +
        //           strings.ThreadCardMaxDepth +
        //           maximumThreadDepth +
        //           strings.Milimeter +
        //           ")"}
        //     </option>
        //   ))}
      }
    </TextField>
  );
};

type PropsType = {
  data: ThreadCardInfo;
  hover?: boolean;
  setHover?: (ids: number[]) => void;
  onChange?: Function;
  init?: SelectedThreadsInfoType[];
  //selection:
};

const ThreadCard = forwardRef<any, PropsType>(
  ({ data, hover, setHover, onChange, init }, ref) => {
    const { strings } = useContext(AppStateContext);

    const singleHole = useMemo(() => data.holes.length <= 1, [data.holes]);
    const [expanded, setExpanded] = useState(false);
    const [isHovered, setHovered] = useState<boolean>(false);
    const [selectedOptions, selectedOptionsDispatch] = useReducer(
      ListReducer<SelectedThreadsInfoType>("id"),
      data.holes.map((hol) => {
        // Check if hol faceIds is in the init
        const existing = init?.find((val) => val.id === hol.id);
        if (existing) {
          return { ...hol, name: existing.name };
        }
        return { ...hol, name: "none" };
      })
    );

    const getSelectedOption = useCallback(
      (id: number) => {
        return selectedOptions.find((val) => val.id === id);
      },
      [selectedOptions]
    );

    const updateSelectedOptions = useCallback(
      (option: SelectedThreadsInfoType, id?: number) => {
        if (id !== undefined) {
          // Setting the Thread on one selection
          selectedOptionsDispatch({
            value: option,
            type: ListReducerEnum.UPDATE,
          });
          onChange && onChange(option);
        } else {
          // Setting the same thread to all the selections
          const newSelection = selectedOptions.map((op) => ({
            ...op,
            name: option.name,
          }));
          selectedOptionsDispatch({
            value: newSelection,
            type: ListReducerEnum.SET,
          });
          onChange && onChange(newSelection);
        }
      },
      [onChange, selectedOptions]
    );

    useEffect(() => {
      setHovered(hover ?? false);
    }, [hover]);

    const changeHover = useCallback(
      (ids: number[]) => {
        setHovered(ids.length > 0);
        if (setHover) {
          setHover(ids);
        }
      },
      [setHover]
    );

    // const updateOptions = useCallback(
    //   (newOption: SelectedThreadsInfoType, id: number) => {
    //     if (id !== undefined) {
    //       setSelectedOptions(oldOptions => {
    //         const newOptions = (oldOptions.find(op => op.id === id)?.name =
    //           newOption);
    //         return oldOptions;
    //       });
    //     } else {
    //       setSelectedOptions(data.holes.map(() => newOption));
    //     }
    //   },
    //   [data.holes]
    // );

    const handleClear = () => {
      updateSelectedOptions({
        id: 0,
        faceIds: [],
        name: "none",
      });
    };

    const threadsByCategories = useMemo(() => {
      const threadsByCategories: { [key: string]: ThreadType[] } = {};
      data.threads.forEach((thread) => {
        if (!threadsByCategories[thread.category]) {
          threadsByCategories[thread.category] = [];
        }
        if (thread.category === "Tolerance") {
          if (data.type === "shaft") {
            threadsByCategories[thread.category].push({
              ...thread,
              name: thread.name.toLowerCase(),
            });
          } else {
            threadsByCategories[thread.category].push(thread);
          }
        } else {
          threadsByCategories[thread.category].push(thread);
        }
      });
      return threadsByCategories;
    }, [data.threads, data.type]);

    const threadSelection = useMemo(() => {
      return Object.entries(threadsByCategories).map(([objKey, cat]) => [
        <option disabled={true} key={objKey} value={objKey}>
          {objKey}
        </option>,
        ...cat.map((val, key) => (
          <option key={key} value={val.name}>
            {`\xa0 ${val.name}`}
          </option>
        )),
      ]);
    }, [threadsByCategories]);

    const allSelector = useMemo(() => {
      const mixed =
        selectedOptions.find((val) => val.name !== selectedOptions[0].name) !==
        undefined;
      let custom = false;
      if (!mixed) {
        const holeName = selectedOptions[0].name;
        const exists = Object.values(threadsByCategories)
          .flat()
          .some((item) => item.name == holeName);
        custom = holeName !== undefined && holeName !== "none" && !exists;
      }
      return custom ? (
        <TextField
          id={"Selector"}
          className={styles.formControl}
          InputProps={{
            className: styles.formControlInput,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} edge="end">
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={selectedOptions[0].name}
          onChange={
            (event: ChangeEvent<{ value: string }>) => {
              updateSelectedOptions({
                id: 0,
                faceIds: data.holes.map((hol) => hol.faceIds).flat(),
                name: event.target.value,
              });
            }
            //handleChangeForAll(event)
          }
          // Add a X button to remove the custom thread
          variant="outlined"
          size={"small"}
        />
      ) : (
        <TextField
          id={"Selector"}
          className={styles.formControl}
          InputProps={{
            className: styles.formControlInput,
          }}
          select
          value={mixed ? "mixed" : selectedOptions[0].name}
          onChange={
            (event: ChangeEvent<{ value: string }>) => {
              updateSelectedOptions({
                id: 0,
                faceIds: data.holes.map((hol) => hol.faceIds).flat(),
                name: event.target.value,
              });
            }
            //handleChangeForAll(event)
          }
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          size={"small"}
        >
          {/* {allThreadsLabel === strings.ThreadCardMixedThreads ? (
        <option value={strings.ThreadCardMixedThreads} disabled={true}>
          {`\xa0 ${strings.ThreadCardMixedThreads}`}
        </option>
      ) : null} */}
          {mixed ? (
            <option value={"mixed"} disabled={true}>
              {`\xa0 ${strings.ThreadCardMixedThreads}`}
            </option>
          ) : null}
          <option
            value={"none"}
          >{`\xa0 ${strings.ThreadCardNoThreads}`}</option>
          <option
            value={""}
            style={{ cursor: "pointer", color: "#008b80" }}
            id="customThreadAllSelect"
          >{`\xa0 ${strings.AddCustomThread}`}</option>
          {threadSelection}
        </TextField>
      );
    }, [
      selectedOptions,
      strings.ThreadCardMixedThreads,
      strings.ThreadCardNoThreads,
      threadSelection,
      updateSelectedOptions,
      data.holes,
    ]);

    const individualHolesSelectors = useMemo(() => {
      return data.holes.map((hole, key) => (
        <IndividualHoleSelector
          key={key}
          groupIds={data.holes.map((hole) => hole.id)}
          holeIds={hole.faceIds}
          threadSelection={threadSelection}
          selectedOption={getSelectedOption(hole.id)?.name ?? "none"}
          setSelectedOptions={(val: string) => {
            updateSelectedOptions({ ...hole, name: val }, hole.id);
          }}
          setHover={setHover}
        />
      ));
    }, [
      data.holes,
      getSelectedOption,
      setHover,
      threadSelection,
      updateSelectedOptions,
    ]);

    // const individualHoleSelectList = useMemo(() => {
    //   return (

    //   );
    // }, [data.holes, isHovered, strings.ThreadCardNoThreads, threadSelection]);

    return (
      <div
        className={`${styles.container} ${isHovered && styles.containerHover}`}
        onMouseEnter={
          (e: any) => changeHover(data.holes.map((hole) => hole.faceIds).flat())
          //onMouseEnterAll(holes.map((hole) => hole.uuids).flat())
          //highlightsOn(flatHolesArr)
        }
        onMouseLeave={
          (e: any) => changeHover([])
          //onMouseLeaveAll(holes.map((hole) => hole.uuids).flat())
          //highlightsOff(flatHolesArr)
        }
      >
        <Card
          //@ts-ignore
          ref={ref}
          className={`${styles.root} ${isHovered && styles.rootHover}`}
        >
          <div className={styles.cardContent}>
            <div className={styles.cardHeader}>
              <div>
                <div className={styles.headerText}>
                  {strings.ThreadCardDiameter}
                </div>
                <div className={styles.headerValue}>
                  {
                    data.diameter
                    //diameter
                  }
                  {strings.Milimeter}
                </div>
              </div>
              <div>
                <div className={styles.headerText}>
                  {strings.ThreadCardDepth}
                </div>
                <div className={styles.headerValue}>
                  {
                    data.depth
                    //depth
                  }
                  {strings.Milimeter}
                </div>
              </div>
              <div>
                <div className={styles.headerText}>
                  {strings.ThreadCardQuantity}
                </div>
                <div className={styles.headerValue}>
                  {
                    0
                    /*selectForAllThreads === "Ingen gevind"
                      ? 0 : */
                    //      individualThreadVal.filter(
                    //   ind => ind.thread !== undefined
                    // ).length
                  }
                  /
                  {
                    data.holes.length
                    //flatHolesArr.length
                  }
                </div>
              </div>
            </div>
            {/* {maximumThreadDepth < depth && typeOfHole !== strings.Outer ? (
                <Typography
                  variant={"subtitle2"}
                  align={"center"}
                  className={classes.smallText}
                >
                  {strings.ThreadCardDepthErrorMsg1}
                  {maximumThreadDepth}
                  {strings.ThreadCardDepthErrorMsg2}
                </Typography>
              ) : null} */}
            {!singleHole && allSelector}
          </div>
          {!singleHole ? (
            <>
              <div
                style={{
                  marginTop: "1em",
                }}
                className={styles.expandContainer}
                onClick={() => setExpanded(!expanded)}
              >
                <Button
                  aria-expanded={expanded}
                  aria-label="show more"
                  color={"primary"}
                  startIcon={
                    <ExpandMore
                      className={clsx(styles.expand, {
                        [styles.expandOpen]: expanded,
                      })}
                    />
                  }
                >
                  {strings.ThreadCardSpecify}
                </Button>
              </div>

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className={styles.formControlSecondaryContainer}>
                  {individualHolesSelectors}
                </div>
              </Collapse>
            </>
          ) : (
            individualHolesSelectors
          )}
        </Card>
      </div>
    );
  }
);
export default ThreadCard;
