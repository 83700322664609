import { FetchError } from "exceptions/exceptions";
import { ThreadType } from "types/ConfigTypes";
import { ModelType, UploadedModelsType } from "types/ModelTypes";
import { API_URL } from "./common";
import { ProductTypeEnum } from "types/products/ProductCommandsType";

const BASE_URL = `${API_URL}/v1/ecommerce/models`;

export const apiGetThreadsList = (): Promise<ThreadType[]> => {
  return fetch(`${BASE_URL}/threads`).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateModel = (
  token: string,
  type?: ProductTypeEnum,
  file?: File,
  name?: string
): Promise<UploadedModelsType> => {
  const data = new FormData();
  if (file) {
    data.append("file", file);
    data.append("filename", file.name);
  }
  if (name) {
    data.append("name", name);
  }
  if (type) {
    data.append("type", type);
  }
  return fetch(`${BASE_URL}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  }).then((res) => res.json());
};

export const apiUploadModelFile = (
  token: string,
  file: File,
  type: ProductTypeEnum
): Promise<UploadedModelsType> => {
  const data = new FormData();
  data.append("file", file);
  data.append("filename", file.name);
  data.append("type", type);
  return fetch(`${BASE_URL}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  }).then((res) => res.json());
};

export const apiUpdateModelFile = (
  token: string,
  file: File,
  id: string
): Promise<UploadedModelsType> => {
  const data = new FormData();
  data.append("file", file);
  data.append("filename", file.name);
  return fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetModel = (token: string, id: number): Promise<ModelType> => {
  return fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
