import { REGEX_EMAIL } from "util/RegExUtil";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { apiLogin, apiRequestPasswordReset } from "util/network/Auth";
import { useState, useContext, FunctionComponent } from "react";
import AppStateContext from "contexts/AppStateContext";
import { Email, Lock } from "@mui/icons-material";
import styles from "../../pages/order_management/orderedit_components/Styles/loginPageStyle.module.css";

type PropsType = {};

const MagentoLogin: FunctionComponent<PropsType> = () => {
  const { strings, setToken } = useContext(AppStateContext);
  const history = useHistory();
  const location = useLocation();
  const [magentoEmail, setMagentoEmail] = useState<string>("");
  const [magentoPassword, setMagentoPassword] = useState<string>("");
  const [resetPassword, setResetPassword] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [loginText, setLoginText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openReset, setOpenReset] = useState<boolean>(false);

  // Submits login information to Magento
  function handleSubmit(e: any) {
    e.preventDefault();
    setError(false);
    if (!magentoEmail || !magentoPassword) {
      setError(true);
      setLoginText(strings.LoginMissingInfo);
      return;
    }
    if (!loading) {
      setLoading(true);
    }
    setLoginText(strings.LoginWaitMsg);
    apiLogin(magentoEmail, magentoPassword)
      .then((res) => {
        setToken(res);
        if (location.state?.sendBack) {
          history.goBack();
        } else {
          history.replace("dashboard");
        }

        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        if (e.message === "UNAUTHORIZED") {
          setLoginText(strings.LoginWrongCredentials);
        } else {
          setLoginText(e.message);
        }
      });
  }

  function submitReset(e: any) {
    e.preventDefault();
    apiRequestPasswordReset(resetPassword).catch((e) => {
      console.log(e);
    });
    setOpenReset(false);
  }

  return (
    <>
      <div className={`${styles.card} ${styles.loginCardWidth}`}>
        <div color="info" className={styles.cardHeader}>
          <h2 className={styles.cardTitle}>{strings.LoginButton}</h2>
        </div>
        <div className={styles.pdLeftRight}>
          <div style={{ paddingRight: "2em", paddingLeft: "2em" }}>
            {loginText}
          </div>
          {loading ? <LinearProgress /> : null}
          <form id={"login"} onSubmit={handleSubmit}>
            <TextField
              label={strings.Email}
              id="loginEmail"
              required
              type="email"
              fullWidth
              variant="standard"
              value={magentoEmail}
              onChange={(e) => setMagentoEmail(e.target.value)}
              error={!REGEX_EMAIL.test(magentoEmail) && magentoEmail !== ""}
              helperText={
                !REGEX_EMAIL.test(magentoEmail) && magentoEmail !== ""
                  ? "Invalid email address"
                  : ""
              }
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={styles.inputAdornment}
                  >
                    <Email className={styles.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.pdTop}>
              <TextField
                variant="standard"
                label={strings.Password}
                fullWidth={true}
                required={true}
                type="password"
                autoComplete="off"
                value={magentoPassword}
                onChange={(e) => setMagentoPassword(e.target.value)}
                error={error}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={styles.inputAdornment}
                    >
                      <Lock className={styles.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={styles.pdTop}>
              {" "}
              <Button type={"submit"} className={styles.loginBtn}>
                {strings.LoginButton}
              </Button>
            </div>
          </form>
          <a
            className={styles.forgottenPassword}
            onClick={() => setOpenReset(true)}
            href={"#top"}
          >
            {strings.ForgottenPassword}
          </a>
          <Dialog
            style={{ zIndex: 10000 }}
            open={openReset}
            onClose={() => setOpenReset(false)}
          >
            <DialogTitle>{strings.ForgottenPassword}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {strings.ForgottenPasswordMsg}
              </DialogContentText>
              <form id={"resetPasswordEmail"} onSubmit={submitReset}>
                <div className={styles.pdTop}>
                  <TextField
                    variant="standard"
                    label={strings.Email}
                    id="resetEmail"
                    fullWidth={true}
                    required={true}
                    type="email"
                    value={resetPassword}
                    onChange={(e) => setResetPassword(e.target.value)}
                    error={
                      !REGEX_EMAIL.test(resetPassword) && resetPassword !== ""
                    }
                    helperText={
                      !REGEX_EMAIL.test(resetPassword) && resetPassword !== ""
                        ? "Invalid email address"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={styles.inputAdornment}
                        >
                          <Email className={styles.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setOpenReset(false)}
                color={"error"}
                style={{ fontWeight: "bold" }}
              >
                {strings.CancelButton}
              </Button>
              <Button
                variant="contained"
                type={"submit"}
                form={"resetPasswordEmail"}
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {strings.SendLinkButton}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default MagentoLogin;
