import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Tooltip,
  ButtonGroup,
  LinearProgress,
} from "@mui/material";
import ThreeDModelTab from "components/ThreeDModelTab/ThreeDModelTab";
import AppStateContext from "contexts/AppStateContext";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  Suspense,
  useMemo,
} from "react";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import {
  DFMInfoType,
  ModelDataType,
  ReceivedModelType,
} from "types/RenderTypes";
import {
  apiGetDfmInfo,
  apiGetErrorGLTFModel,
  apiGetGLTF,
  apiGetModelInfo,
  apiGetProduct,
} from "../../util/network/Products";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ThreadDisplayInModal from "components/OrderView/ThreadDisplayInModal";
import {
  checkColorStep,
  downloadModelJson,
  fetchPDFFile,
  fetchSTEPFile,
  fetchThreadFile,
} from "util/FileDownload";
import { dangerColor } from "assets/colors";
import { DoDisturb } from "@mui/icons-material";
import styles from "components/Modals/ModalStyles/threeDModalStyle.module.css";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
  ProductVMType,
  SheetProductDetailsType,
} from "types/products/ProductCommandsType";
import PDFViewer from "components/PDFViewer";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import PriceTab from "./PartModelTabs";
import ProductionTasks from "components/ProductionTasks/ProductionTasks";
import { ProductionTaskType, ProductionVMType } from "types/ProductionType";
import { apiGetProduction, apiGetProductions } from "util/network/Productions";

const ThreeDModal: FunctionComponent = () => {
  const [model, setModel] = useState<ReceivedModelType>();
  const [dfm, setDfm] = useState<GLTF>();
  const [dfmInfo, setDfmInfo] = useState<DFMInfoType | null>();
  const [modelInfo, setModelInfo] = useState<ModelDataType>();
  const [product, setProduct] = useState<ProductVMType>();
  const [modelId, setModelId] = useState<number>();
  const [comment, setComment] = useState<string | null>();
  const [blueprint, setBlueprint] = useState<string>();
  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");
  const [productions, setProductions] = useState<ProductionVMType[]>();
  const [production, setProduction] = useState<ProductionVMType>();
  const [loading, setLoading] = useState(false);
  const [colorStepExist, setColorStepExist] = useState<boolean>();
  const [showTab, setTab] = useState<string>("Render");
  const [tasks, setTasks] = useState<ProductionTaskType[]>(
    production?.tasks ?? []
  );
  const {
    token,
    showThreeDModal,
    setShowThreeDModal,
    productId,
    setProductId,
  } = useContext(AppStateContext);

  const details = product?.details as
    | CNCProductDetailsType
    | Print3DProductDetailsType
    | SheetProductDetailsType;

  const handleClose = () => {
    setProductId(0);
    setProduct(undefined);
    setModel(undefined);
    setDfm(undefined);
    setDfmInfo(undefined);
    setModelInfo(undefined);
    setModelId(undefined);
    setComment(undefined);
    setBlueprint(undefined);
    setColorStepExist(undefined);
    setShowThreeDModal(false);
    setProduction(undefined);
    setTab("Render");
  };

  // fetching model
  useEffect(() => {
    if (!modelId) return;
    setModel(undefined);

    apiGetGLTF(token, modelId).then((res) => setModel(res));
    apiGetModelInfo(token, modelId).then((dat) => {
      setModelInfo(dat);
      if (product?.productType === ProductTypeEnum.CNC) {
        apiGetErrorGLTFModel(token, modelId).then((res2) => setDfm(res2));
        apiGetDfmInfo(token, modelId).then((res) => {
          setDfmInfo({
            ...res,
            holeAnalysis: {
              holes: dat?.features?.holes ?? [],
            },
          });
        });
      } else {
        setDfmInfo(null);
      }
    });
  }, [token, modelId, product?.productType]);

  useEffect(() => {
    if (productId) {
      setLoading(true);
      apiGetProductions(token, 1, 50, `filter=productId=${productId}`)
        .then((res) => {
          const matchingProd = res.items.find(
            (prod) => prod.productId === productId
          );

          if (matchingProd) {
            apiGetProduction(token, matchingProd.id)
              .then((production) => {
                setProduction(production);
                setLoading(false);
              })
              .catch((error) => {
                console.error("Failed to fetch production:", error);
                setLoading(false);
              });
          } else {
            setProduction(undefined);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching productions:", error);
          setLoading(false);
        });
    }
  }, [token, showTab, productId, token]);

  // fetching model data
  useEffect(() => {
    if (productId !== 0) {
      apiGetProduct(token, productId).then((data) => {
        if (data.productType === ProductTypeEnum.CNC) {
          const details = data.details as CNCProductDetailsType;
          setModelId(details?.modelId);
          setComment(details.comment);
          details.blueprint && setBlueprint(details.blueprint);
        } else if (data.productType === ProductTypeEnum.PRINT3D) {
          const details = data.details as Print3DProductDetailsType;
          setModelId(details?.modelId);
          setComment(details.comment);
        } else if (data.productType === ProductTypeEnum.SHEET) {
          const details = data.details as SheetProductDetailsType;
          setModelId(details?.modelId);
          setComment(details.comment);
          details.blueprint && setBlueprint(details.blueprint);
        }
        setProduct(data);
      });
    }
  }, [token, productId]);

  const saveTasks = () => {
    if (production) {
      return apiGetProduction(token, production?.id).then((res) => {
        setTasks(res.tasks);
      });
    }
  };

  useEffect(() => {
    modelId &&
      checkColorStep(token, modelId).then((res) => {
        if (res === 200) {
          setColorStepExist(true);
        } else {
          setColorStepExist(false);
        }
      });
  }, [modelId, productId]);

  const renderWindow = useMemo(() => {
    return (
      <div className={styles.model_div}>
        {model && modelInfo && productId !== 0 ? (
          <ThreeDModelTab
            specialModelRender
            model={model}
            info={modelInfo}
            dfmModel={dfm}
            dfmInfo={dfmInfo}
            selectedThreads={details?.threads ?? []}
          />
        ) : null}
        <Typography fontWeight={"bold"}>
          <div className={styles.model_dimensions}>
            {modelInfo
              ? (modelInfo.bboxDx?.toFixed(1) ?? "?") +
                "mm x " +
                (modelInfo.bboxDy?.toFixed(1) ?? "?") +
                "mm x " +
                (modelInfo.bboxDz?.toFixed(1) ?? "?") +
                "mm"
              : null}
          </div>
        </Typography>
      </div>
    );
  }, [
    model,
    modelInfo,
    productId,
    details?.threads,
    dfm,
    dfmInfo,
    styles.model_div,
    styles.model_dimensions,
  ]);

  const renderTabContent = () => {
    switch (showTab) {
      case "Render":
        return renderWindow;
      case "PDF":
        return <PDFViewer productId={productId} />;
      case "Price":
        return <PriceTab productId={productId} product={product} />;
      case "Tasks":
        return (
          <>
            {loading ? (
              <div style={{ padding: "1rem" }}>
                <LinearProgress />
              </div>
            ) : (
              <ProductionTasks
                isOpen={showThreeDModal}
                onClose={handleClose}
                onSave={saveTasks}
                project={production}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      maxWidth={"xl"}
      open={showThreeDModal}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "80%",
        },
      }}
    >
      <DialogContent className={styles.dialogContent}>
        <div className={styles.tabContent}>
          <ButtonGroup>
            <Button
              onClick={() => setTab("Render")}
              variant={showTab == "Render" ? "contained" : "outlined"}
            >
              Render
            </Button>
            <Button
              onClick={() => setTab("PDF")}
              disabled={!blueprint}
              variant={showTab == "PDF" ? "contained" : "outlined"}
            >
              PDF
            </Button>
            <Button
              onClick={() => setTab("Price")}
              variant={showTab == "Price" ? "contained" : "outlined"}
            >
              PRICING
            </Button>
            <Button
              onClick={() => setTab("Tasks")}
              variant={showTab == "Tasks" ? "contained" : "outlined"}
              disabled={!production}
            >
              TASKS
            </Button>
          </ButtonGroup>
          {renderTabContent()}
        </div>
        <div className={styles.extra_info_div}>
          <div>
            <Typography className={styles.productId}>#{productId}</Typography>
            <Typography className={styles.email}>
              {product?.author!.email}
            </Typography>
            <Typography className={styles.product_created}>
              {product?.created}
            </Typography>
            <Typography className={styles.typography_info_fileDownload}>
              {product?.name.toUpperCase()}
              <Tooltip title={`Download: ${product?.name}`}>
                {product && modelId ? (
                  <Button
                    startIcon={<FileDownloadIcon />}
                    className={styles.typography_info_fileDownload__button}
                    onClick={() =>
                      colorStepExist
                        ? fetchThreadFile(token, product.name, modelId)
                        : fetchSTEPFile(token, product.name, modelId)
                    }
                  ></Button>
                ) : (
                  <DoDisturb sx={{ color: dangerColor[0] }} />
                )}
              </Tooltip>
            </Typography>
            {debug && (
              <Typography className={styles.typography_info_fileDownload}>
                {product?.name.toUpperCase()}.JSON
                <Tooltip title={`Download: ${product?.name}`}>
                  {product && modelId ? (
                    <Button
                      startIcon={<FileDownloadIcon />}
                      className={styles.typography_info_fileDownload__button}
                      onClick={() =>
                        downloadModelJson(
                          token,
                          `${product.name}.json`,
                          modelId
                        )
                      }
                    ></Button>
                  ) : (
                    <DoDisturb sx={{ color: dangerColor[0] }} />
                  )}
                </Tooltip>
              </Typography>
            )}

            <div className={styles.info_parameters}>
              <Typography className={styles.config_info__font}>
                Weight: <strong>{product?.weight.toFixed(1)}kg</strong>&nbsp;
              </Typography>
            </div>

            <div>
              <Typography className={styles.typography_config}>
                Configuration
                <div className={styles.typography_config__div}>
                  <Typography className={styles.config_info}>
                    <strong>{details?.quantity}pcs</strong>
                    <span className={styles.info_span}>|</span>
                  </Typography>
                  <Typography className={styles.config_info}>
                    {details?.customMaterial ? (
                      <strong>{details?.customMaterial.name}</strong>
                    ) : (
                      <strong>
                        {details?.standardMaterial?.name ?? details?.material}
                      </strong>
                    )}
                    <span className={styles.info_span}>|</span>
                  </Typography>
                  <Typography className={styles.config_info}>
                    {details?.customFinish ? (
                      <>
                        <strong>{details?.customFinish.name}</strong>
                      </>
                    ) : (
                      <>
                        {Array.isArray(details?.finish) &&
                        details?.finish.length === 1 &&
                        details?.finish[0] === "standard" ? (
                          <strong>no finish</strong>
                        ) : (
                          <strong>{details?.finish?.join(" ")}</strong>
                        )}
                      </>
                    )}
                    &nbsp;
                  </Typography>
                </div>
                <Typography className={styles.typography_threads}>
                  {" "}
                  {details && details?.threads ? (
                    details?.threads?.length > 0 ? (
                      <div>
                        <div style={{ backgroundColor: "white" }}>
                          <ThreadDisplayInModal
                            specs={details?.threads ?? []}
                          />
                        </div>
                      </div>
                    ) : (
                      <p className={styles.noFile_font}>No Threads</p>
                    )
                  ) : (
                    "-"
                  )}
                </Typography>
                <Typography className={styles.typography_pdf}>
                  <p className={styles.typography_pdf_name}>{blueprint}</p>

                  {product ? (
                    <Suspense fallback={<p>Loading...</p>}>
                      {blueprint ? (
                        <Tooltip title={`Download: ${blueprint}`}>
                          <Button
                            startIcon={<FileDownloadIcon />}
                            className={styles.typography_pdf__button}
                            onClick={() =>
                              fetchPDFFile(token, product.name, product.id)
                            }
                          ></Button>
                        </Tooltip>
                      ) : (
                        <p className={styles.noFile_font}>No PDF</p>
                      )}
                    </Suspense>
                  ) : (
                    "No PDF"
                  )}
                </Typography>
                <Typography className={styles.typography_comment}>
                  <Tooltip title={"Kommentar"}>
                    <p className={styles.typography_comment__p}>
                      {comment ? comment : "Ingen kommentar"}
                    </p>
                  </Tooltip>
                </Typography>
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ThreeDModal;
