import {
  TableCell,
  Tooltip,
  TableRow,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
  Dialog,
  Box,
  Menu,
  MenuItem,
  Typography,
  ButtonGroup,
} from "@mui/material";
import {
  ArrowDropDown,
  Delete,
  DoDisturb,
  Message,
  Percent,
  PictureAsPdf,
  Description,
} from "@mui/icons-material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
} from "react";
import { formatter } from "../../../util/TextUtil";
import styles from "./Styles/cartTableRowStyle.module.css";
import AmountInput from "./AmountInput";
import { API_URL } from "../../../util/network/common";
import AppStateContext from "../../../contexts/AppStateContext";
import DFMErrors from "./DFMErrors";
import {
  DFMInfoType,
  ModelDataType,
  SelectedThreadsInfoType,
} from "../../../types/RenderTypes";
import MatSelector from "./MatSelector";
import FinishSelector from "./FinishSelector";
import ThreadDisplay from "../../../components/OrderView/ThreadDisplay";
import { dangerColor, primaryColor } from "../../../assets/colors";
import PreviewButton from "components/Generic/PreviewButton";
import { fetchPDFFile } from "util/FileDownload";
import ModelSelector from "./ModelSelector";
import {
  apiAddBlueprint,
  apiChangeProductType,
  apiCheckDXFFile,
  apiGetDXFFile,
  apiGetDfmInfo,
  apiGetModelInfo,
  apiReplaceBlueprint,
  apiUpdateProduct,
} from "util/network/Products";
import { CartItemType, UpdateCartItemCommandType } from "types/CartTypes";
import { LeadOptionsType } from "types/CheckoutTypes";
import {
  ProductTypeEnum,
  SheetProductDetailsType,
  UpdateSheetProductType,
} from "types/products/ProductCommandsType";
import {
  CustomFinishType,
  CustomMaterialType,
} from "types/products/ProductOptionsType";
import CustomMaterialSelector from "./CustomMaterialSelector";
import CustomFinishSelector from "./CustomFinishSelector";
import { useToast } from "contexts/ToastContext";
import Checkbox from "components/Checkbox/Checkbox";
import { apiUploadModelFile } from "util/network/Models";
import React from "react";
import { apiUpdateCartItem } from "util/network/Carts";
import { ModelType } from "types/ModelTypes";
import CustomBorderlessSelect from "components/Select/CustomBorderlessSelector";

type PropsType = {
  cartId: number;
  item: CartItemType;
  //materialSpec: ConfigurationType;
  leadTime: LeadOptionsType;
  setCartLoading: (loading: boolean) => void;
  deleteItem: Function;
  onChange: (input: CartItemType, refreshItems?: boolean) => void;
  isChecked: boolean;
  index: number;
  onCheckedChange: (index: number, isChecked: boolean) => void;
  selectedMaterial: string | CustomMaterialType | null;
  selectedFinish: string[] | CustomFinishType | null;
};

const SheetItemTableRow: FunctionComponent<PropsType> = React.memo(
  ({
    cartId,
    item,
    leadTime,
    setCartLoading,
    deleteItem,
    onChange,
    isChecked,
    index,
    onCheckedChange,
    selectedFinish,
    selectedMaterial,
  }) => {
    const { strings, token } = useContext(AppStateContext);
    const { addToast } = useToast();
    const details = useMemo(
      () =>
        ({
          ...item.product.details,
          finish: (
            (item.product.details as SheetProductDetailsType)?.finish as any
          ).split(","),
        } as SheetProductDetailsType),
      [item.product.details]
    );

    const [disabled, setDisabled] = useState<boolean>(false);

    const [priceStandard, setPriceStandard] = useState<number>(
      item.product.priceStandard
    );
    const [priceExpress, setPriceExpress] = useState<number>(
      item.product.priceExpress
    );
    const [priceEconomic, setPriceEconomic] = useState<number>(
      item.product.priceEconomic
    );
    const [price, setPrice] = useState<number>(item.pricePerUnit);
    const [total, setTotal] = useState<number>(item.total);
    const [priceText, setPriceText] = useState<string>(
      String(item.pricePerUnit)
    );
    const [priceOverridden, setPriceOverridden] = useState<boolean>(
      item.priceOverridden
    );
    const [showProductPrice, setShowProductPrice] = useState<boolean>(false);
    const [name, setName] = useState<string>(item.name);

    // Product detail states
    const [modelId, setModelId] = useState<number>(details.modelId);
    const [model, setModel] = useState<ModelType>(details.model);
    const [material, setMaterial] = useState<string | undefined>(
      details?.standardMaterial ? details?.standardMaterial.name : undefined
    );
    const [customMaterial, setCustomMaterial] = useState<
      CustomMaterialType | undefined
    >(
      details?.customMaterial
        ? (details?.customMaterial as CustomMaterialType)
        : undefined
    );
    const [finish, setFinish] = useState<string[] | undefined>(
      details?.finish ? details?.finish : undefined
    );
    const [customFinish, setCustomFinish] = useState<
      CustomFinishType | undefined
    >(details?.customFinish ? details?.customFinish : undefined);
    const [comment, setComment] = useState<string | null>(
      details?.comment ?? null
    );
    const [threads, setThreads] = useState<SelectedThreadsInfoType[]>(
      details?.threads ?? []
    );
    const [weight, setWeight] = useState<number>(item.product.weight);
    const [customProduct, setCustomProduct] = useState<boolean>(
      item.product.custom
    );
    const [dxfFileExists, setDxfFileExists] = useState<boolean>(false);

    const [prevPdf, setPrevPdf] = useState<boolean>();
    const [pdfState, setPdfState] = useState<boolean>(false);
    const [blueprint, setBlueprint] = useState<any>("");
    const [pdfLoad, setPdfLoad] = useState<boolean>(false);

    const badModel = model.status == "Bad";
    const [dataDFM, setDataDFM] = useState<DFMInfoType>();
    const [modelData, setModelData] = useState<ModelDataType>();
    const [quantity, setQuantity] = useState<number>(item.quantity ?? 1);

    const [localLeadTime, setLocalLeadTime] = useState<LeadOptionsType>();
    const [openComment, setOpenComment] = useState<boolean>(false);

    // Control states
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [pdfDrag, setPdfDrag] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);

    const optionsCertif = [
      { value: "Certificate", label: "Certificate" },
      { value: "-", label: "-" },
    ];
    const [certificate, setCertificate] = useState<boolean>(
      details.materialCertificate
    );

    const [openThreadModal, setOpenThreadModal] = useState<boolean>(false);
    const handleOpenThreadModal = () => {
      setOpenThreadModal((prev) => !prev);
    };

    const [openModal, setOpenModal] = useState(false);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      onCheckedChange(index, checked);
    };

    useEffect(() => {
      if (selectedMaterial && isChecked) {
        if (typeof selectedMaterial === "string") {
          setMaterial(selectedMaterial);
          setCustomMaterial(undefined);
        } else {
          setCustomMaterial(selectedMaterial);
        }
        setFinish(["standard"]);
        setCustomFinish(undefined);
      }
    }, [selectedMaterial, isChecked]);

    useEffect(() => {
      if (isChecked) {
        if (Array.isArray(selectedFinish)) {
          setCustomFinish(undefined);
          setFinish(selectedFinish);
        } else if (selectedFinish) {
          setCustomFinish(selectedFinish);
        }
      }
    }, [selectedFinish, isChecked]);

    const handlePriceChange = (newPrice: number) => {
      setPriceText(newPrice.toString());
      updatePrice(newPrice.toString());
    };

    const modelCheck = () => {
      return (
        modelData &&
        (modelData?.features?.holes.length > 0 ||
          modelData?.features?.shafts.length > 0 ||
          modelData?.features?.threads.length > 0)
      );
    };

    const updateItem = (input: UpdateCartItemCommandType) => {
      setDisabled(true);
      return apiUpdateCartItem(token, cartId, item.id, input)
        .then((res) => {
          setPrice(res.pricePerUnit ?? 0);
          setPriceText(String(res.pricePerUnit ?? 0));
          setPriceOverridden(res.priceOverridden);
          setTotal(res.total);
          onChange(res);
          return res;
        })
        .catch((err) => {
          addToast({
            type: "error",
            message: "Could not update item correctly in cart" + err,
            keep: true,
          });
          throw err;
        })
        .finally(() => {
          setDisabled(false);
          setCartLoading(false);
        });
    };

    const updateConfig = (command: UpdateSheetProductType) => {
      setCartLoading(true);
      setDisabled(true);
      return apiUpdateProduct(token, item.product.id, command).then((res) => {
        setPriceStandard(res.priceStandard);
        setPriceExpress(res.priceExpress);
        setPriceEconomic(res.priceEconomic);
        setCustomProduct(res.custom);
        setName(res.name);
        const cncDetails = res.details as SheetProductDetailsType;
        // setMaterial(cncDetails?.standardMaterial?.name);
        // setCustomMaterial(cncDetails?.customMaterial);
        // setFinish(cncDetails?.finish);
        // setCustomFinish(cncDetails?.customFinish);
        setModelId(cncDetails?.modelId);
        setModel(cncDetails?.model);
        setComment(cncDetails?.comment);
        setThreads(cncDetails?.threads);
        setBlueprint(cncDetails?.blueprint);
        setWeight(res.weight);
        updateItem({
          quantity: (res.details as SheetProductDetailsType)?.quantity ?? 1,
        });
      });
    };

    const updatePrice = (amount: string) => {
      let newPrice = amount.trim();
      if (newPrice === String(price)) return;
      if (newPrice === "") {
        let resetPrice = priceStandard;
        if (leadTime === "EXPRESS") {
          resetPrice = priceExpress;
        } else if (leadTime === "ECONOMIC") {
          resetPrice = priceEconomic;
        }
        setPriceText(String(resetPrice));
        setPriceOverridden(false);
        updateItem({ pricePerUnit: -1 });
        return;
      }
      newPrice = newPrice.replace(",", ".");
      let floatPrice = parseFloat(newPrice);
      if (isNaN(floatPrice)) {
        //setCustomPrice(String(item.pricePerUnit ?? 0));
        return;
      }
      floatPrice = parseFloat(floatPrice.toFixed(2));
      setPriceText(String(floatPrice));
      setPriceOverridden(true);
      updateItem({
        pricePerUnit: floatPrice,
      });
    };

    useEffect(() => {
      if (!badModel) {
        apiCheckDXFFile(token, modelId).then((res) => {
          setDxfFileExists(res);
        });
      }
    }, [modelId]);

    // This useEffect is used to reset the custom price when the lead time changes
    useEffect(() => {
      let productPrice = null;
      if (leadTime === "EXPRESS") {
        productPrice = item.product.priceExpress;
      } else if (leadTime === "STANDARD") {
        productPrice = item.product.priceStandard;
      } else if (leadTime === "ECONOMIC") {
        productPrice = item.product.priceEconomic;
      }
      if (productPrice && !priceOverridden) {
        setPrice(productPrice);
        setPriceText(String(item.pricePerUnit));
        setTotal(item.total);
      }
      if (!localLeadTime && leadTime === "CUSTOM") {
        setLocalLeadTime(leadTime);
      }
    }, [leadTime]);

    useEffect(() => {
      setPriceOverridden(item.priceOverridden);
      updateModelData(details.modelId);
      if (!details?.customMaterial) {
        setCustomMaterial(undefined);
      }
      if (!details?.customFinish) {
        setCustomFinish(undefined);
      }
      if (item.product.custom === false) {
        setCustomProduct(false);
      }
      if (details.blueprint) {
        if (blueprint == "") {
          setBlueprint(item.name + ".pdf");
        }

        setPrevPdf(true);
      }
      console.log("details", details);
      setThreads(details.threads);
    }, [item, details]);

    const updateModelData = useCallback(
      (id: number) => {
        if (!badModel) {
          setCartLoading(true);
          apiGetModelInfo(token, id)
            .then((tempModel) => {
              setModelData(tempModel);
              // setDensityText(
              //   (item.product.weight / 1e-9 / tempModel.totalVolume).toFixed(2)
              // );
              // TODO Move into a separate function
              return apiGetDfmInfo(token, id).then((res) => {
                setDataDFM(res);
              });
            })
            .catch((error) => {
              addToast({
                type: "error",
                message: error.message,
              });
            })
            .then(() => {
              setCartLoading(false);
            });
        }
      },
      [token]
    );

    const updateAmount = (amount: number) => {
      setQuantity(amount);
      const command: UpdateSheetProductType = {
        quantity: amount,
      };
      return updateConfig(command);
    };

    const updateThreads = (input: SelectedThreadsInfoType[]) => {
      console.log("input", input);
      setThreads(input);
      const command: UpdateSheetProductType = {
        quantity: quantity,
        threads: input,
        blueprint: blueprint,
        comment: comment,
      };
      return updateConfig(command);
    };

    // const updateDensity = (density: string) => {
    //   if (modelData && customMaterial) {
    //     density = density.replace(",", ".");
    //     // Check if the density is a number
    //     let floatDensity = parseFloat(density);
    //     if (isNaN(floatDensity)) {
    //       setDensityText("NaN");
    //       return;
    //     }
    //     // Check if the density is a positive number
    //     if (floatDensity < 0) {
    //       setDensityText("0");
    //       floatDensity = 0;
    //     }
    //     setCustomMaterial({
    //       name: customMaterial.name,
    //       description: customMaterial.description,
    //       customerProvides: customMaterial.customerProvides,
    //       density: floatDensity,
    //     });
    //     // Multiply by 1e-9 to get the density in g/cm^3
    //     floatDensity = floatDensity * 1e-9;
    //     // Update the density
    //     let newWeight = floatDensity * modelData.totalVolume;
    //     newWeight = parseFloat(newWeight.toFixed(2));
    //     setWeight(newWeight);
    //   }
    // };

    const handleUpdateModel = (id: number, file: File) => {
      setCartLoading(true);
      setDisabled(true);
      apiUploadModelFile(token, file, ProductTypeEnum.SHEET).then((res) => {
        if (res.filesAdded > 1) {
          addToast({
            type: "error",
            message:
              "The uploaded file contained more than one solid. Please upload a file with only one solid.",
          });
          return;
        }
        const command: UpdateSheetProductType = {
          quantity: quantity,
          threads: [],
          blueprint: blueprint,
          comment: comment,
          modelId: res.filesList[0].id,
        };
        updateConfig(command).then(() => {
          updateModelData(res.filesList[0].id);
          addToast({
            type: "success",
            message: (
              <>
                New model was <strong>updated</strong> successfully.
              </>
            ),
          });
        });
      });
    };

    const pdfUpload = (file: File, reupload?: boolean) => {
      if (reupload) {
        // Replace the blueprint
        return apiReplaceBlueprint(token, item.product.id, file);
      } else {
        // Upload a new blueprint
        return apiAddBlueprint(token, item.product.id, file);
      }
    };

    const handleSubmit = async (file: any) => {
      //states here
      console.log("file", file);
      const data = new FormData();
      data.append("file", file);
      data.append("filename", file.name);

      if (
        (file.type === "application/pdf" || file.type.startsWith("image")) &&
        item?.product.details
      ) {
        setPdfLoad(true);
        const pdfExists = !!blueprint;

        try {
          const res = await pdfUpload(file, pdfExists);
          setBlueprint(res);
          updateConfig({
            blueprint: res,
          });
          setPdfState(!pdfState);
          addToast({
            type: "success",
            message: "Blueprint uploaded successfully!",
          });
        } catch (err: unknown) {
          let errorMessage = "An unknown error occurred.";

          if (err instanceof Error) {
            errorMessage = err.message;
          }

          addToast({
            type: "error",
            message: `Could not upload the blueprint: ${errorMessage}`,
          });
        } finally {
          setPdfLoad(false);
        }
      } else {
        addToast({
          type: "error",
          message: strings.FileNotSupported,
        });
      }
    };
    const hiddenFileInput = useRef<any>(null);

    const handlePdfClick = () => {
      hiddenFileInput.current?.click();
    };

    const handlePdfDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setPdfDrag(false);
      if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        handleSubmit(file);
      }
    };

    const handleChange = async (event: any) => {
      const file = event.target.files[0];
      await handleSubmit(file);
    };

    const deletePDF = async () => {
      if (!item?.product?.details) {
        addToast({
          type: "error",
          message: "Product details are missing. Cannot delete PDF.",
        });
        return;
      }

      setPdfLoad(true);
      const url = `${API_URL}/v1/ecommerce/products/${item.product.id}/blueprint`;

      try {
        const pdfDelete = await fetch(url, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (pdfDelete.status == 200 && item.product.details) {
          setPdfState(!pdfState);
          setPrevPdf(false);
          setBlueprint("");
          updateConfig({
            quantity: quantity,
            threads: threads,
            comment: comment,
            blueprint: null,
          });
          addToast({
            type: "success",
            message: "Blueprint deleted successfully!",
          });
        } else {
          throw new Error("Failed to delete the blueprint");
        }
      } catch (err: unknown) {
        let errorMessage =
          "An unknown error occurred while deleting the blueprint.";

        if (err instanceof Error) {
          errorMessage = err.message;
        }

        addToast({
          type: "error",
          message: `Error deleting blueprint: ${errorMessage}`,
        });
      } finally {
        setPdfLoad(false);
      }
    };

    const updateFinish = (finish: string[]) => {
      if (finish.includes("custom")) {
        setCustomFinish({
          name: "",
          description: "",
        });
        if (!customProduct) {
          setCustomProduct(true);
        }
      } else {
        setFinish(finish);
        updateConfig({
          finish: finish,
        });
      }
    };

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const openPriceCalculator = () => {
      setOpenModal(true);
    };

    const finishSelector = useMemo(() => {
      return customFinish !== undefined ? (
        <CustomFinishSelector
          value={customFinish}
          onChange={(val: CustomFinishType | null) => {
            if (val) {
              setCustomFinish(val);
              updateConfig({
                customFinish: val,
              });
            } else {
              setCustomFinish(undefined);
              updateConfig({
                finish: ["standard"],
              });
            }
          }}
          disabled={disabled}
        />
      ) : (
        <FinishSelector
          key={`${material}-${customMaterial?.name}`}
          material={customMaterial ? "custom" : material ?? ""}
          currentSel={finish ?? ["standard"]}
          onChange={updateFinish}
          finishFile={"sheetFinish.json"}
          disabled={disabled}
        />
      );
    }, [customFinish, customMaterial, finish, material, updateFinish]);

    const updateCertificate = useCallback(
      (certificate: boolean) => {
        const updateCommand: UpdateSheetProductType = {
          quantity: quantity,
          comment: comment,
          blueprint: blueprint,
          threads: details.threads,
          materialCertificate: certificate,
        };
        setCertificate(certificate);
        updateConfig(updateCommand);
      },
      [
        blueprint,
        details.threads,
        finish,
        updateConfig,
        material,
        quantity,
        comment,
      ]
    );

    return (
      <>
        <TableRow
          key={item.id}
          className={styles.cellsNoWrap}
          sx={{
            backgroundColor: deleting ? "#f8d7da" : isChecked ? "#e7f3ff" : "",
          }}
        >
          <TableCell align="left" sx={{ padding: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              {`\u00A0`}
              <span>{item.product.id}</span>
            </div>
          </TableCell>
          {/* Preview Image Cell */}
          <TableCell align="left" sx={{ padding: 0 }}>
            <div
              style={{
                position: "relative",
                display: "inline-block",
                border: customProduct ? "1px solid red" : undefined,
              }}
            >
              {details ? (
                <PreviewButton
                  key={`${model.modified}`}
                  productId={item.product.id}
                  modelId={modelId}
                />
              ) : (
                <div className={styles.previewImage} />
              )}
              <div style={{ position: "absolute", top: "0px", right: "0px" }}>
                <DFMErrors dfmErrors={dataDFM} />
              </div>
            </div>
          </TableCell>
          {/* Item + Configuration Cell */}
          <TableCell align="left">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
              }}
            >
              <p className={styles.name}>{name}</p>

              <CustomBorderlessSelect
                options={[
                  { label: "CNC", value: "CNC" },
                  { label: "3D Print", value: "PRINT3D" },
                  { label: "Sheet", value: "SHEET" },
                ]}
                onChange={(e) => {
                  const chosenType: ProductTypeEnum = e.target
                    .value as ProductTypeEnum;
                  setDisabled(true);
                  apiChangeProductType(token, item.product.id, chosenType)
                    .then((res) => {
                      onChange(item, true);
                      setDisabled(false);
                    })
                    .catch((err) => {
                      setDisabled(false);
                      addToast({
                        type: "error",
                        message: "Could not change product type" + err,
                        keep: true,
                      });
                    });
                }}
                selectedValue={"SHEET"}
              />
            </div>
            <div style={{ display: "flex", justifyItems: "center" }}>
              {/* Upload Model, Update Model*/}
              <ModelSelector
                id={modelId}
                name={name}
                handleUpdateModel={handleUpdateModel}
                disabled={disabled}
              />
              {/*upload pdf*/}
              {pdfLoad ? (
                <CircularProgress />
              ) : blueprint ? (
                <>
                  <div>
                    <Button
                      endIcon={<ArrowDropDown color="info" />}
                      onClick={handleClick}
                      style={{ fontWeight: "bold" }}
                      disabled={disabled}
                    >
                      <PictureAsPdf color="info" />
                    </Button>

                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <MenuItem
                        value={"download_pdf"}
                        onClick={() => {
                          handleClose();
                          fetchPDFFile(
                            token,
                            blueprint ?? name,
                            item.product.id
                          );
                        }}
                        disabled={disabled}
                      >
                        Download {blueprint}
                      </MenuItem>
                      <MenuItem
                        value={"replace_pdf"}
                        onClick={() => {
                          handleClose();
                          handlePdfClick();
                        }}
                        disabled={disabled}
                      >
                        Replace
                      </MenuItem>
                      <MenuItem
                        value={"remove_pdf"}
                        onClick={() => {
                          handleClose();
                          deletePDF();
                        }}
                        disabled={disabled}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              ) : (
                <div
                  onDragEnter={(e) => setPdfDrag(true)}
                  onDragLeave={() => setPdfDrag(false)}
                >
                  {pdfDrag ? (
                    <div
                      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onDrop={(e: any) => {
                        if (!disabled) {
                          handlePdfDrop(e);
                        }
                      }}
                    >
                      <Box
                        border={1}
                        borderRadius={5}
                        borderColor={primaryColor[0]}
                        padding={"0.5em"}
                      >
                        <Typography component={"h4"} color={primaryColor[1]}>
                          <span>Drop pdf here</span>
                        </Typography>
                      </Box>
                    </div>
                  ) : (
                    <>
                      <Button
                        endIcon={<ArrowDropDown />}
                        onClick={handleClick}
                        style={{ fontWeight: "bold" }}
                        disabled={disabled}
                      >
                        <PictureAsPdf />
                      </Button>

                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        aria-disabled={disabled}
                      >
                        <MenuItem
                          value={"Upload_pdf"}
                          onClick={() => {
                            handleClose();
                            handlePdfClick();
                          }}
                          disabled={disabled}
                        >
                          Upload PDF
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </div>
              )}
              <input
                type="file"
                accept="application/pdf,image/*"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
                multiple={false}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
                disabled={disabled}
              />

              {/*add comment*/}
              <Button
                color={!comment ? "primary" : "info"}
                onClick={() => setOpenComment((e) => !e)}
                disabled={disabled}
              >
                <Tooltip
                  title={!comment ? "Tilføj kommentar.." : "Ændre kommentar.."}
                >
                  <Message />
                </Tooltip>
              </Button>
              <Dialog open={openComment} onClose={() => setOpenComment(false)}>
                <h4 className={styles.modalHeader}>{"Kommentar"}</h4>

                <div className={styles.pd}>
                  <TextField
                    style={{ width: 450, height: 400 }}
                    label={
                      (comment ? comment?.length.toString() : "0") + "/400"
                    }
                    multiline
                    fullWidth
                    rows={15}
                    inputProps={{ maxLength: 400 }}
                    sx={{ size: 400 }}
                    value={comment}
                    onBlur={() =>
                      updateConfig({
                        quantity: quantity,
                        blueprint: blueprint,
                        threads: threads,
                        comment: comment,
                      })
                    }
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </div>
              </Dialog>
              {/* ThreadDisplay  */}
              {/* Make clickable such it will open a modal with the thread selector */}

              <div
                onClick={modelCheck() ? handleOpenThreadModal : () => {}}
                style={{ cursor: "pointer" }}
              >
                {item && threads ? (
                  threads.length > 0 ? (
                    <Tooltip
                      sx={{ backgroundColor: "white" }}
                      title={
                        <div style={{ backgroundColor: "white" }}>
                          <ThreadDisplay specs={threads} />
                        </div>
                      }
                    >
                      <BuildCircleIcon color="info" />
                    </Tooltip>
                  ) : (
                    <BuildCircleIcon
                      color={modelCheck() ? "primary" : "disabled"}
                      sx={{ pt: "7px", pl: "10px" }}
                    />
                  )
                ) : (
                  <DoDisturb sx={{ color: dangerColor[0] }} />
                )}
              </div>
              {!badModel && (
                <Button
                  color={!dxfFileExists ? "info" : "primary"}
                  onClick={() =>
                    apiGetDXFFile(token, `${model.name}.dxf`, modelId)
                  }
                  disabled={disabled}
                >
                  <Tooltip title={"Hent DXF fil"}>
                    <Description />
                  </Tooltip>
                </Button>
              )}
            </div>
          </TableCell>
          <TableCell sx={{ padding: 0 }}>
            {" "}
            <CustomBorderlessSelect
              options={optionsCertif}
              selectedValue={certificate ? "Certificate" : "-"}
              onChange={(e) => {
                const newCertificateValue = e.target.value === "Certificate";
                updateCertificate(newCertificateValue);
              }}
              disabled={disabled}
            />
          </TableCell>
          <TableCell align="left" sx={{ minWidth: "4em" }}>
            {customMaterial !== undefined ? (
              <CustomMaterialSelector
                value={customMaterial}
                onChange={(val: CustomMaterialType | null) => {
                  if (val) {
                    setCustomMaterial(val);
                    updateConfig({
                      customMaterial: val,
                    });
                  } else {
                    setCustomMaterial(undefined);
                    setMaterial("alu-5754");
                    updateConfig({
                      finish: ["standard"],
                      material: "alu-5754",
                    });
                  }
                }}
                disabled={disabled}
              />
            ) : (
              <MatSelector
                currentSel={material ?? ""}
                onChange={(mat: string) => {
                  if (mat === "custom") {
                    setCustomMaterial({
                      name: "",
                      description: "",
                      customerProvides: false,
                      density: 0,
                    });
                    if (!customProduct) {
                      setCustomProduct(true);
                    }
                  } else {
                    setMaterial(mat);
                    setFinish(["standard"]);
                    updateConfig({
                      finish: ["standard"],
                      material: mat,
                    });
                  }
                }}
                disabled={disabled}
                materialFile={"sheetMaterials.json"}
              />
            )}
          </TableCell>
          <TableCell align="left">{finishSelector}</TableCell>
          {/* Quantity Cell */}
          <TableCell align="center">
            <AmountInput
              initVal={quantity}
              onChange={updateAmount}
              direction="column-reverse"
              disabled={disabled}
            />
          </TableCell>
          {/* Price Cell */}
          <TableCell align="center">
            <TextField
              value={showProductPrice ? item.product.priceStandard : priceText}
              onChange={(e) => {
                setPriceText(e.target.value);
              }}
              onBlur={(e) => updatePrice(e.target.value)}
              id="outlined-start-adornment"
              sx={{ m: 1, width: "19ch" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kr/stk</InputAdornment>
                ),
              }}
              disabled={disabled}
            />
            <ButtonGroup
              sx={{ marginTop: 1 }}
              //variant="contained"
              orientation="vertical"
              size="small"
              aria-label="outlined primary button group"
            >
              <Tooltip
                title={<h5>Press and hold for original price</h5>}
                placement="bottom"
              >
                <span>
                  <Button
                    aria-label={"Calculate"}
                    color={showProductPrice ? "info" : "primary"}
                    onMouseDown={() => {
                      setShowProductPrice(true);
                    }}
                    onMouseUp={() => {
                      setShowProductPrice(false);
                    }}
                    disabled={!priceOverridden || disabled}
                  >
                    <VisibilityIcon fontSize={"small"} />
                  </Button>
                </span>
              </Tooltip>
            </ButtonGroup>
          </TableCell>

          <TableCell>{formatter.format(total ?? 0)}</TableCell>

          {/* Actions Cell */}
          <TableCell>
            {/*Percent button*/}
            {/* <Tooltip title={<h5>Percent</h5>} placement="bottom">
              <Button
                aria-label={"Percent"}
                color={"primary"}
                onClick={() => console.log("todo")}
                disabled={disabled}
              >
                <Percent fontSize={"large"} />
              </Button>
            </Tooltip> */}

            {/* Delete Button */}
            <Tooltip title={<h5>SLET</h5>} placement="bottom">
              <IconButton
                aria-label={"Delete"}
                color={"secondary"}
                onClick={() => deleteItem(item)}
                disabled={disabled}
              >
                <Delete fontSize={"large"} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </>
    );
  }
);

export default SheetItemTableRow;
