import { Button } from "@mui/material";
import { Remove, Add } from "@mui/icons-material";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./Styles/amountInputStyle.module.css";

type PropsType = {
  initVal: number;
  onChange: Function;
  disabled?: boolean;
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
};

const AmountInput: FunctionComponent<PropsType> = ({
  initVal,
  onChange,
  disabled,
  direction = "row",
}) => {
  const [value, setValue] = useState<number>(initVal);
  const [tempAmount, setTempAmount] = useState<any>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();

  useEffect(() => {
    setValue(initVal);
  }, [initVal]);

  const updateAmount = useCallback(
    (amount: number) => {
      if (timer) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout(() => {
        onChange(amount);
      }, 500);
      setTimer(newTimer);
    },
    [onChange, timer]
  );

  function decreaseOnClick() {
    updateAmount(value - 1);
    setValue((old) => old - 1);
    setTempAmount(null);
  }

  function increaseOnClick() {
    updateAmount(value + 1);
    setValue((old) => old + 1);
    setTempAmount(null);
  }

  function inputOnblur(e: any) {
    if (isNaN(parseInt(e.target.value))) {
      setTempAmount(1);
      setValue(1);
      setTempAmount(null);
      updateAmount(1);
    } else if (parseInt(e.target.value) !== 0) {
      if (parseInt(e.target.value) < 0) {
        setValue(1);
        setTempAmount(null);
        updateAmount(1);
      } else if (parseInt(e.target.value) > 1000) {
        setValue(1000);
        setTempAmount(null);
        updateAmount(1000);
      } else {
        setValue(Number.parseInt(e.target.value));
        setTempAmount(null);
        updateAmount(Number.parseInt(e.target.value));
      }
    }
  }

  function inputOnChange(e: any) {
    if (isNaN(parseInt(e.target.value))) {
      setTempAmount(e.target.value);
    } else {
      if (parseInt(e.target.value) < 1) {
        setTempAmount(1);
      } else if (parseInt(e.target.value) > 1000) {
        setTempAmount(1000);
      } else {
        setTempAmount(e.target.value);
      }
    }
  }

  return (
    <div
      className={styles.buttonContainer}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <Button
        className={styles.decreaseButton}
        aria-label={"Decrease"}
        variant={"text"}
        size="small"
        disabled={value <= 1 || disabled}
        onClick={() => decreaseOnClick()}
        sx={{
          background: "#E12C50",
          color: "white !important",
          "&:hover": {
            color: "red !important",
          },
        }}
      >
        <Remove style={{ fontSize: "15px" }} />
      </Button>
      <input
        className={styles.quantityText}
        onBlur={(e) => inputOnblur(e)}
        onChange={(e) => inputOnChange(e)}
        value={tempAmount ?? value}
        disabled={disabled}
      />
      <Button
        className={styles.increaseButton}
        aria-label={"Increase"}
        variant={"text"}
        size="small"
        disabled={disabled || value >= 1000}
        onClick={() => increaseOnClick()}
        sx={{
          background: "#075E5B",
          color: "white !important",
          "&:hover": {
            color: "green !important",
          },
        }}
      >
        <Add style={{ fontSize: "15px" }} />
      </Button>
    </div>
  );
};

export default AmountInput;
