import React, { useState, useRef, ChangeEvent } from "react";
import Button, { ButtonProps, ButtonTypeMap } from "@mui/material/Button";
import { ExtendButtonBase } from "@mui/material";

interface DraggableButtonProps {
  onFilesDropped: (files: File[]) => void;
  accept?: string;
  buttonProps?: ButtonProps;
  children: React.ReactNode;
}

const DraggableButton: React.FC<DraggableButtonProps> = ({
  onFilesDropped,
  accept,
  buttonProps,
  children,
}) => {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDragging(false);

    if (event.dataTransfer.items) {
      const files: File[] = [];
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === "file") {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file) {
            files.push(file);
          }
        }
      }
      onFilesDropped(files);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onFilesDropped(Array.from(event.target.files));
    }
  };

  return (
    <div>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        style={{ opacity: dragging ? 0.5 : 1, borderRadius: "0" }}
        {...buttonProps}
      >
        {children}
      </Button>
    </div>
  );
};

export default DraggableButton;
