import { ThreeEvent, useThree } from "@react-three/fiber";
import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  BackSide,
  Color,
  DoubleSide,
  EdgesGeometry,
  Group,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
} from "three";
import { ModelHoleFeatureType, RGBType } from "../../../types/RenderTypes";
import { staticColors } from "../../../util/ColorUtil";

type PropsType = {
  mesh: Mesh;
  showEdges?: boolean;
  disableSelect?: boolean;
  transparent?: boolean;
  baseColor?: RGBType;
  info?: ModelHoleFeatureType;
  onClick?: (event: ThreeEvent<MouseEvent>) => void;
  onPointerMissed?: (event: ThreeEvent<MouseEvent>) => void;
  onPointerEnter?: (event: ThreeEvent<MouseEvent>) => void;
  onPointerLeave?: (event: ThreeEvent<MouseEvent>) => void;
  ref?: MutableRefObject<Group>;
  select?: boolean;
  hover?: boolean;
  color?: number;
};

const HoleModel: FunctionComponent<PropsType> = ({
  mesh,
  showEdges,
  disableSelect,
  transparent,
  baseColor,
  info,
  onClick,
  onPointerMissed,
  onPointerEnter,
  onPointerLeave,
  ref,
  select,
  hover,
  color,
}) => {
  const modelRef = useRef<Mesh>();
  const edgeRef = useRef<Mesh>();
  const outlineRef = useRef<Mesh>();

  const { scene } = useThree();

  const meshMaterial = useMemo(() => {
    const mat = new MeshBasicMaterial();
    if (baseColor) {
      const col = new Color(
        baseColor.r / 255,
        baseColor.g / 255,
        baseColor.b / 255
      );
      mat.color.set(col);
    } else {
      mat.color.set(staticColors.default);
    }

    mat.polygonOffset = true;
    mat.polygonOffsetFactor = 1;
    mat.polygonOffsetUnits = 4;
    return <meshBasicMaterial {...mat} />;
  }, [baseColor]);

  const lineMaterial = useMemo(() => {
    let color = new Color(staticColors.edge);
    if (baseColor) {
      const col = new Color(
        baseColor.r / 255,
        baseColor.g / 255,
        baseColor.b / 255
      );
      color = col;
    }
    return new LineBasicMaterial({
      color: color,
      polygonOffset: true,
      polygonOffsetFactor: 1,
      polygonOffsetUnits: 4,
    });
  }, [baseColor]);

  const edgeModel = useMemo(() => {
    if (mesh) {
      return (
        <lineSegments
          name={mesh.uuid + "line"}
          position={mesh.position}
          matrixAutoUpdate={false}
          ref={edgeRef}
        >
          <edgesGeometry args={[mesh.geometry, 90]} />
          <lineBasicMaterial {...lineMaterial} />
        </lineSegments>
      );
    }
    return;
  }, [lineMaterial, mesh]);

  const hoverModel = useMemo(() => {
    const mat = new MeshBasicMaterial({
      color: staticColors.hover,
      side: DoubleSide,
    });
    const hoverMesh = new Mesh(mesh.geometry, mat);

    const edgeGeo = new EdgesGeometry(mesh.geometry, 90);
    const segments = new LineSegments(edgeGeo, lineMaterial);

    const group = new Group();
    //group.name = 'hoverHole';
    group.add(hoverMesh);
    group.add(segments);

    return group;
  }, [lineMaterial, mesh.geometry]);

  const selectModel = useMemo(() => {
    const mat = new MeshBasicMaterial({
      color: staticColors.select,
      side: DoubleSide,
    });
    const highlightMesh = new Mesh(mesh.geometry, mat);

    const edgeGeo = new EdgesGeometry(mesh.geometry, 90);
    const segments = new LineSegments(edgeGeo, lineMaterial);

    const group = new Group();
    //group.name = 'hoverHole';
    group.add(highlightMesh);
    group.add(segments);

    return group;
  }, [lineMaterial, mesh.geometry]);

  useEffect(() => {
    const holeSelectionGroup = scene.getObjectByName("holeSelection");
    const holeHoverGroup = scene.getObjectByName("holeHover");
    if (holeSelectionGroup) {
      if (select) {
        if (color) {
          for (let i = 0; i < selectModel.children.length; i++) {
            if (selectModel.children[i].type == "Mesh") {
              (
                (selectModel.children[i] as Mesh).material as MeshBasicMaterial
              ).color = new Color(color);
            }
          }
        }
        holeSelectionGroup.add(selectModel);
      } else {
        holeSelectionGroup.remove(selectModel);
      }
    }
    if (holeHoverGroup) {
      if (hover) {
        holeHoverGroup.add(hoverModel);
      } else {
        holeHoverGroup.remove(hoverModel);
      }
    }
  }, [selectModel, hover, scene, select, hoverModel]);

  const mouseHover = useCallback(
    (over: boolean) => {
      const holeHoverGroup = scene.getObjectByName("holeHover");
      if (over && holeHoverGroup) {
        holeHoverGroup.add(hoverModel);
      } else if (holeHoverGroup) {
        holeHoverGroup.clear();
      }
    },
    [hoverModel, scene]
  );

  // const highlightedModel = useMemo(() => {
  //   return <mesh
  //   {...mesh}
  //   matrixAutoUpdate={false}
  // >
  //   <bufferGeometry {...mesh.geometry} />
  //   {meshMaterial}
  // </mesh>
  // }, [])

  const prepModels = useMemo(() => {
    if (mesh) {
      return (
        <mesh {...mesh} matrixAutoUpdate={false} ref={modelRef}>
          <bufferGeometry {...mesh.geometry} />
          {meshMaterial}
        </mesh>
      );
    }
    return;
  }, [mesh, meshMaterial]);

  const outlineModel = useMemo(() => {
    if (mesh) {
      return (
        <mesh {...mesh} matrixAutoUpdate={false} ref={outlineRef}>
          <bufferGeometry {...mesh.geometry} />

          {/* <meshPhongMaterial
              color={staticColors.default}
              specular={new Color(0xf3f3f3)}
              shininess={200}
              side={BackSide}
            /> */}

          <meshBasicMaterial
            side={BackSide}
            color={
              baseColor
                ? new Color(
                    baseColor.r / 255,
                    baseColor.g / 255,
                    baseColor.b / 255
                  )
                : staticColors.edge
            }
          />
        </mesh>
      );
    }
    return;
  }, [mesh]);

  // const transparentModel = useMemo(() => {
  //   if (mesh) {
  //     return (
  //       <mesh {...mesh} matrixAutoUpdate={false}>
  //         <bufferGeometry {...mesh.geometry} />

  //         {/* <meshPhongMaterial
  //             color={staticColors.default}
  //             specular={new Color(0xf3f3f3)}
  //             shininess={200}
  //             side={BackSide}
  //           /> */}

  //         <meshBasicMaterial
  //           depthTest={false}
  //           side={DoubleSide}
  //           color={new Color(0x000000)}
  //           transparent
  //           opacity={0}
  //         />
  //       </mesh>
  //     );
  //   }
  //   return;
  // }, [mesh]);

  useEffect(() => {
    if (transparent) {
      if (edgeRef.current) {
        edgeRef.current.visible = false;
      }
      // if (modelRef?.current) {
      //   modelRef.current.visible = false;
      // }
      if (outlineRef?.current) {
        outlineRef.current.visible = false;
      }
    } else {
      if (edgeRef.current) {
        edgeRef.current.visible = true;
      }
      // if (modelRef?.current) {
      //   modelRef.current.visible = true;
      // }
      if (outlineRef?.current) {
        outlineRef.current.visible = true;
      }
    }
  }, [transparent]);

  return (
    <group
      //name="holeModels"
      ref={ref}
      matrixAutoUpdate={false}
      onClick={onClick}
      onPointerEnter={(e) => {
        if (disableSelect) return;
        e.stopPropagation();
        if (e.nativeEvent.buttons === 0 && !hover) {
          mouseHover(true);
          onPointerEnter && onPointerEnter(e);
        }
      }}
      onPointerLeave={(e) => {
        if (disableSelect) return;
        e.stopPropagation();
        if (e.nativeEvent.buttons === 0) {
          if (!hover) {
            mouseHover(false);
          }
          onPointerLeave && onPointerLeave(e);
        }
      }}
      onPointerMissed={onPointerMissed}
    >
      {edgeModel}
      {prepModels}
      {showEdges && outlineModel}
      {/* {transparentModel} */}
    </group>
  );
};

export default HoleModel;
