import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
} from "react";
import Tooltip from "@mui/material/Tooltip";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import BasketConfigRow from "./BasketConfigRow";
import { CartItemType, CartType, SimpleCartType } from "types/CartTypes";
import AppStateContext from "contexts/AppStateContext";
import { ProductTypeEnum } from "types/products/ProductCommandsType";
import OtherCartItemRow from "pages/quote_management/OtherCartItemRow";
import CopyPhone from "../../../components/Copy/CopyPhone";
import CopyEmail from "../../../components/Copy/CopyEmail";
import { ReactComponent as PickupIcon } from "../../../assets/svg/pickup.svg";
import { ReactComponent as GlsIcon } from "../../../assets/svg/glslogo.svg";
import { ReactComponent as PostNordIcon } from "../../../assets/svg/postnord.svg";
import { formatter } from "../../../util/formatter";
import { primaryColor } from "../../../assets/colors";
import "../../../styles/global.css";
import "../../order_management/orderManagement.css";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { apiGetCart, apiGetCartItems } from "util/network/Carts";
import { UserType } from "types/users/UserTypes";

type PropsType = {
  basket: SimpleCartType;
  refresh?: Function;
};

const BasketRow: FunctionComponent<PropsType> = ({ basket, refresh }) => {
  const [open, setOpen] = useState(false);
  const { token } = useContext(AppStateContext);
  const [basketStatus, setBasketStatus] = useState("INACTIVE");
  const [differenceInHours, setDifferenceInHours] = useState(0);
  const [differenceInMinutes, setDifferenceInMinutes] = useState(0);
  const history = useHistory();
  const [items, setItems] = useState<CartItemType[]>([]);
  const [reqProjects, setReqProjects] = useState<CartType | null>(null);

  useEffect(() => {
    if (open && items.length === 0) {
      apiGetCartItems(token, basket.id).then((res) => {
        setItems(res);
      });
    }
  }, [open, token, basket.id]);

  const formatDate = (date: any) => {
    const timezoneOffset = dayjs().utcOffset() / 60;
    return dayjs(date)
      .add(timezoneOffset, "hour")
      .format("HH:mm\u00A0\u00A0  D. MMM.");
  };
  const formattedModifiedDate = formatDate(basket.modified);

  useEffect(() => {
    const calculateBasketStatus = () => {
      // Get current timezone
      const timezoneOffset = new Date().getTimezoneOffset() / 60;
      // The server is in GMT+0 so we need to add the timezone offset to the modified date

      const modifiedDate = dayjs(basket.modified).add(timezoneOffset, "hour");

      const currentDate = dayjs();

      const differenceInHours = Math.floor(
        currentDate.diff(modifiedDate, "minute") / 60
      );
      const differenceInMinutes = currentDate.diff(modifiedDate, "minute") % 60;

      setDifferenceInHours(differenceInHours);
      setDifferenceInMinutes(differenceInMinutes);

      const totalDifferenceInMinutes =
        differenceInHours * 60 + differenceInMinutes;

      if (totalDifferenceInMinutes <= 120) {
        return "ACTIVE";
      } else if (
        totalDifferenceInMinutes > 120 &&
        totalDifferenceInMinutes <= 4320
      ) {
        return "INACTIVE";
      } else {
        return "ABANDONED";
      }
    };

    const status = calculateBasketStatus();
    setBasketStatus(status);
  }, [basket.modified]);

  const timeDisplay = () => {
    const days = Math.floor(differenceInHours / 24);
    const hours = differenceInHours % 24;

    if (days > 0) {
      return `${days}d ${hours}h`;
    } else {
      return `${hours}h ${differenceInMinutes}m`;
    }
  };

  useEffect(() => {
    if (basket?.id) {
      const alreadyOpen = sessionStorage.getItem("OpenedBasket");
      if (alreadyOpen) {
        if (parseInt(alreadyOpen) === basket.id) {
          setOpen(true);
        }
      }
    }
  }, [basket, token]);

  const cncTableContent = useMemo(() => {
    const cncItems = items.filter(
      (i) => i.product.productType == ProductTypeEnum.CNC
    );
    if (cncItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow className={"orderRow"}>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                CNC
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cncItems.map((item, index) => {
            return <BasketConfigRow item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const print3dTableContent = useMemo(() => {
    const printItems = items.filter(
      (i) => i.product.productType == ProductTypeEnum.PRINT3D
    );
    if (printItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Print3D
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printItems.map((item, index) => {
            return <BasketConfigRow item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const sheetTableContent = useMemo(() => {
    const sheetItems = items.filter(
      (i) => i.product.productType == ProductTypeEnum.SHEET
    );
    if (sheetItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Sheet Metal
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sheetItems.map((item, index) => {
            return <BasketConfigRow item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const otherTableContent = useMemo(() => {
    const otherItems = items.filter(
      (i) => i.product.productType == ProductTypeEnum.OTHER
    );
    if (otherItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Other
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otherItems.map((item: CartItemType, index: number) => {
            return <OtherCartItemRow key={index} item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [items]);

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const CNCitems =
    items.filter((i) => {
      return i.product.productType === ProductTypeEnum.CNC;
    }) ?? [];

  const PRINT3Ditems =
    items.filter((i) => {
      return i.product.productType === ProductTypeEnum.PRINT3D;
    }) ?? [];

  const SHEETitems =
    items.filter((i) => {
      return i.product.productType === ProductTypeEnum.SHEET;
    }) ?? [];

  const OTHERitems =
    items.filter((i) => {
      return i.product.productType === ProductTypeEnum.OTHER;
    }) ?? [];

  const renderItemCount = (items: any[], label: string) => {
    if (items.length > 0) {
      return (
        <span style={{ marginRight: "0.5rem" }}>
          {items.length} {label}
        </span>
      );
    }
    return "\u00A0";
  };

  const closeRequisition = () => {
    setReqProjects(null);
  };

  return (
    <>
      <TableRow
        sx={{
          background: open ? "#A9D6CE" : "#fff",
        }}
      >
        <TableCell align="left" onClick={() => setOpen((open) => !open)}>
          <div
            style={{
              width: "fit-content",
              paddingLeft: "0.3rem",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                padding: "0.5rem",
                textTransform: "uppercase",
                color: "#fff",
                background:
                  basketStatus == "INACTIVE"
                    ? "#E0A900"
                    : basketStatus == "ACTIVE"
                    ? "#007474"
                    : basketStatus == "ABANDONED"
                    ? "#C2C2C2"
                    : undefined,
                height: "1.3rem",
              }}
            >
              {basketStatus}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "0.3rem",
                lineHeight: "1rem",
                justifyContent: "center",
              }}
            >
              <span>{timeDisplay()}</span>
              <span style={{ fontSize: "0.7rem" }}>
                {formattedModifiedDate}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell align="left" onClick={() => setOpen((open) => !open)}>
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              style={{ lineHeight: "0", display: "flex", alignItems: "center" }}
            >
              {basket.billingAddress?.phone ? (
                <span
                  style={{
                    paddingRight: "0.5rem",
                    lineHeight: "0",
                  }}
                  onClick={handleClick}
                >
                  <CopyPhone phone={basket.billingAddress.phone} />
                </span>
              ) : (
                <p style={{ paddingRight: "0.9rem" }}>-</p>
              )}
              <span
                style={{ paddingRight: "0.5rem", lineHeight: "0" }}
                onClick={handleClick}
              >
                {basket.billingAddress?.email ? (
                  <CopyEmail
                    subject={`Kurv: #${basket.id}`}
                    email={basket.billingAddress?.email}
                  />
                ) : (
                  <p style={{ paddingRight: "0.9rem" }}>-</p>
                )}
              </span>
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>{basket.authorName}</span>
              <span style={{ fontSize: "0.7rem" }}>
                {basket.billingAddress?.company
                  ? basket.billingAddress.company
                  : "\u00A0"}
              </span>
            </span>
          </div>
        </TableCell>
        <TableCell align="center" onClick={() => setOpen((open) => !open)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ paddingRight: "0.5rem" }}>
              {basket && basket.shippingMethod ? (
                basket.shippingMethod === "pickup" ? (
                  <Tooltip title="Pickup">
                    <PickupIcon />
                  </Tooltip>
                ) : basket.shippingMethod === "tnt_before_12" ? (
                  <Tooltip title="GLS">
                    <GlsIcon />
                  </Tooltip>
                ) : basket.shippingMethod.startsWith("postnord") ? (
                  <Tooltip title="PostNord">
                    <PostNordIcon />
                  </Tooltip>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "0.7rem",
                }}
              >{`${basket.leadTimeOption} ${basket.leadTimeDays}wd`}</span>
            </span>
          </div>
        </TableCell>
        <TableCell align="right" onClick={() => setOpen((open) => !open)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ paddingRight: "0.5rem", textAlign: "right" }}>
                  {formatter.format(basket?.total ?? 0)}
                </span>
                <span style={{ fontSize: "0.7rem" }}>
                  {renderItemCount(CNCitems, "CNC")}
                  {renderItemCount(PRINT3Ditems, "3D")}
                  {renderItemCount(SHEETitems, "Sheet")}
                  {renderItemCount(OTHERitems, "Other")}
                </span>
              </div>
            </span>
            <span>
              <IconButton
                onClick={() => {
                  sessionStorage.setItem("OpenedBasket", basket.id.toString());
                  history.push(`/quotes/${basket.id}`);
                }}
              >
                <ShoppingCartCheckoutIcon />
              </IconButton>
            </span>
          </div>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > *": {
            background: open ? "WhiteSmoke" : "",
            borderRadius: open ? "0 0 5px 5px" : "",
            borderBottom: open ? "2px solid" + primaryColor[0] : "",
            borderLeft: open ? "2px solid" + primaryColor[0] : "",
            borderRight: open ? "2px solid" + primaryColor[0] : "",
          },
        }}
      >
        <TableCell colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                {cncTableContent}
                {print3dTableContent}
                {sheetTableContent}
                {otherTableContent}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default BasketRow;
