import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import QuoteTable from "../QuoteTable";
import SearchField from "components/SearchField";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import { apiCreateDraftCart, apiGetCartsSummary } from "util/network/Carts";
import { primaryColor } from "assets/colors";
import AppStateContext from "contexts/AppStateContext";
import { useHistory } from "react-router";
import { useState, useContext, useEffect } from "react";
import StatusBox from "components/Dashboard/StatusBox";
import { CartsSummaryType } from "types/CartTypes";

const QuoteManagementUI = () => {
  const { token } = useContext(AppStateContext);
  const [quoteSummary, setQuoteSummary] = useState<CartsSummaryType>();
  const history = useHistory();
  const [searchValue, setSearchValue] = useStateWithSessionStorage<string>(
    "",
    "quoteSearchVal"
  );
  const [loadingCreateDraft, setLoadingCreateDraft] = useState<boolean>(false);

  useEffect(() => {
    // Periodically check every minute for new quotes
    if (token) {
      apiGetCartsSummary(token).then((res) => {
        setQuoteSummary(res);
      });
    }
    const interval = setInterval(() => {
      if (token) {
        apiGetCartsSummary(token).then((res) => {
          setQuoteSummary(res);
        });
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [token]);
  return (
    <Grid
      container
      // style={{ padding: "0.5em", paddingTop: "0.5em" }}
    >
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // paddingTop: "20px",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "8vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
              paddingTop: "1.2rem",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Quote overview
            </Typography>
          </div>
          {/* <br /> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: "1rem",
              }}
            >
              <SearchField onChange={setSearchValue} value={searchValue} />
            </div>
            <div style={{ padding: "0 1rem 0 0" }}>
              <Button
                sx={{
                  borderRadius: 0,
                  maxHeight: 40,
                  width: "10rem",
                  background: "#E0A900",
                }}
                onClick={(e) => {
                  setLoadingCreateDraft(true);
                  if (token) {
                    apiCreateDraftCart(token).then((res) => {
                      setLoadingCreateDraft(false);
                      localStorage.removeItem("orderEditing");
                      history.push(`/quotes/${res.id}`);
                    });
                  }
                }}
                variant="contained"
              >
                {loadingCreateDraft ? (
                  <CircularProgress
                    //left={-20}
                    //top={10}
                    size={"2em"}
                    style={{
                      margin: "1em",
                      color: primaryColor[0],
                    }}
                  />
                ) : (
                  <h4 color="white">New Quote</h4>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={8}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem 1.5rem 0 1.5rem",
        }}
      >
        <StatusBox title={"Total review"} amount={quoteSummary?.totalReview} />
        <StatusBox title={"Total quotes"} amount={quoteSummary?.totalQuote} />
        <StatusBox title={"Total drafts"} amount={quoteSummary?.totalDraft} />
      </Grid>
      <Grid item xs={12} style={{ padding: "0.5rem 1.5rem 0 1.5rem" }}>
        <QuoteTable searchValue={searchValue} />
      </Grid>
    </Grid>
  );
};

export default QuoteManagementUI;
