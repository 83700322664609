import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Collapse,
  Box,
  TableSortLabel,
} from "@mui/material";
import {
  primaryColor,
  dangerColor,
  warningColor,
  infoColor,
} from "../../assets/colors";
import dayjs from "dayjs";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import AppStateContext from "../../contexts/AppStateContext";
import {
  apiGetMachineStatus,
  apiGetMachineHistory,
} from "../../util/network/Machine";
import { fetchSound } from "../../util/network/common";
import Timer from "../../components/Timer/Timer";
import { MachineStatusType } from "../../types/MachineType";

type PropsType = {
  id: string;
  sound: any;
};

const MachineRow: FunctionComponent<PropsType> = ({ id, sound }) => {
  const [machineInf, setMachineInf] = useState<MachineStatusType>();
  const [machineHis, setMachineHis] = useState<MachineStatusType[]>([]);
  const { token } = useContext(AppStateContext);
  const [ledColor, setLedColor] = useState<string>("");
  const [retryCheck, setRetryCheck] = useState<boolean>(false);
  const [failCount, setFailCount] = useState<number>(0);
  const [fetcher, setFetcher] = useState<NodeJS.Timeout>();
  const [open, setOpen] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [sortedHist, setSortedHist] = useState<MachineStatusType[] | null>(
    null
  );
  const [sortByTime, setSortByTime] = useState<boolean>(true);

  const handleRetry = () => {
    setFailCount(0);
    setRetryCheck(!retryCheck);
  };

  const startTime = useMemo(() => {
    if (machineInf) {
      let start = dayjs(Number(machineInf.start_time)).format("HH:mm:ss");
      return start;
    }
  }, [machineInf, machineInf?.start_time]);

  const elapsedTime = useMemo(() => {
    if (machineInf) {
      let now = dayjs();
      let starttime = dayjs(machineInf.start_time);
      let timeCalc = now.diff(starttime);
      //@ts-ignore
      let timeElapsed = dayjs.duration(timeCalc).format("HH:mm:ss");
      return timeElapsed;
    }
  }, [machineInf, machineInf?.start_time]);

  const sortByStartTime = () => {
    if (machineHis.length === 0) return;

    if (!sortedHist) {
      setSortedHist(
        machineHis
          // .slice(0, machineHis.length)
          .sort((a, b) =>
            sortByTime === true
              ? a.start_time - b.start_time
              : b.start_time - a.start_time
          )
      );
    } else if (sortedHist) {
      setSortedHist(
        sortedHist.sort((a, b) =>
          sortByTime === true
            ? a.start_time - b.start_time
            : b.start_time - a.start_time
        )
      );
    }
  };

  function playSound(param: any) {
    if (param == "success") {
      fetchSound("/successnotif.mp3").then((res) => new Audio(res).play());
    } else fetchSound("/notifsound.mp3").then((res) => new Audio(res).play());
  }

  useEffect(() => {
    apiGetMachineStatus(token, id)
      .then((res) => {
        setMachineInf(res);
        setFailCount(0);
      })
      .catch((e) => {
        console.log(e + " Maskinen er ikke at finde");
        setFailCount((old) => old + 1);
      });
    apiGetMachineHistory(token, id)
      .then((res) => {
        setMachineHis(res);
      })
      .catch((e) => {
        console.log(e + " Maskinen har ingen historik");
      });
    const interval = setInterval(() => {
      if (failCount < 10) {
        apiGetMachineStatus(token, id)
          .then((res) => {
            setMachineInf(res);
            setFailCount(0);
          })
          .catch((e) => {
            console.log(e + " Maskinen er ikke at finde");
            setFailCount((old) => old + 1);
          });
      }
    }, 10000);
    setFetcher(interval);
    return () => clearInterval(interval);
  }, [token, id, retryCheck]);

  useEffect(() => {
    if (failCount >= 10 && fetcher) clearInterval(fetcher);
  }, [failCount]);

  useEffect(() => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }
  });

  useEffect(() => {
    if (machineInf && sound && !firstTime) {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        if (
          machineInf.status.toLowerCase() == "idle" &&
          (machineInf?.prev_status?.toLowerCase() == "running" ||
            machineInf?.prev_status?.toLowerCase() == "waiting for tool")
        ) {
          var options = { silent: true };
          playSound("success");
          var notification = new Notification(
            "MASKINE " + id + " ER FÆRDIG MED PROGRAM",
            options
          );
        }
        var options = { silent: true };
        playSound("normal");
        var notification = new Notification(
          "STATUS ÆNDRET TIL " + machineInf.status + " PÅ MASKINE " + id,
          options
        );
      }
    } else if (machineInf && sound) {
      setFirstTime(false);
    }
  }, [machineInf?.status]);

  useEffect(() => {
    apiGetMachineHistory(token, id)
      .then((res) => {
        setMachineHis(res);
        //console.log(machineHis);
      })
      .catch((e) => {
        console.log(e + " Maskinen har ingen historik");
      });
  }, [machineInf?.status, id]);

  //LED farve skifter
  useEffect(() => {
    switch (machineInf?.status.toLowerCase()) {
      case "idle":
        setLedColor(infoColor[3]);
        break;
      case "running":
        setLedColor(primaryColor[1]);
        break;
      case "paused":
        setLedColor(warningColor[1]);
        break;
      case "error":
        setLedColor(dangerColor[0]);
        break;
      case "waiting for tool":
        setLedColor(infoColor[4]);
        break;
      case "off":
        setLedColor("disabled");
        break;
      case "stopped":
        setLedColor("black");
        break;
      default:
        setLedColor("white");
        break;
    }
  }, [machineInf, id]);

  useEffect(() => {
    if (sortByTime !== undefined && machineHis) {
      //@ts-ignore
      setSortedHist(sortByStartTime);
    }
  }, [sortByTime, machineHis]);
  return (
    <>
      <TableRow
        sx={{
          borderRight: "1px solid" + primaryColor[0],
          borderLeft: "1px solid" + primaryColor[0],
          borderTop: "2px solid" + primaryColor[0],
        }}
      >
        <TableCell onClick={() => setOpen(!open)}>{id}</TableCell>
        <TableCell
          onClick={() => setOpen(!open)}
          size="small"
          sx={{
            wordWrap: "break-word",
            maxWidth: "10em",
          }}
        >
          Igangværende program
          {machineInf?.program ? (
            <Typography fontWeight={"bold"}>{machineInf?.program}</Typography>
          ) : (
            <Typography fontWeight={"bold"}>
              {failCount >= 10 ? "Fejl" : "Henter.."}
            </Typography>
          )}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          Værktøjs ID
          {machineInf ? (
            machineInf.toolno ? (
              <Typography fontWeight={"bold"}>{machineInf?.toolno}</Typography>
            ) : (
              <Typography fontWeight={"bold"}>{"No tool"}</Typography>
            )
          ) : (
            <Typography fontWeight={"bold"}>
              {failCount >= 10 ? "Fejl" : "Henter.."}
            </Typography>
          )}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          Status
          {machineInf?.status ? (
            <Typography fontWeight={"bold"}>{machineInf?.status}</Typography>
          ) : (
            <Typography fontWeight={"bold"}>
              {failCount >= 10 ? "Fejl" : "Henter.."}
            </Typography>
          )}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          Start tid
          {startTime ? (
            <Typography fontWeight={"bold"}>{startTime}</Typography>
          ) : (
            <Typography fontWeight={"bold"}>
              {failCount >= 10 ? "Fejl" : "Henter.."}
            </Typography>
          )}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          Tid brugt
          {machineInf ? (
            <Typography fontWeight={"bold"}>
              <Timer initialVal={machineInf?.start_time} />
            </Typography>
          ) : (
            <Typography fontWeight={"bold"}>
              {failCount >= 10 ? "Fejl" : "Henter.."}
            </Typography>
          )}
        </TableCell>
        <TableCell align="right" sx={{ maxWidth: "8em" }}>
          <div
            style={{
              float: "right",
              display: "flex",
              alignItems: "center",
            }}
          >
            {failCount >= 10 && (
              <Button onClick={() => handleRetry()} variant="outlined">
                Prøv igen
              </Button>
            )}
            <CircleIcon sx={{ width: 60, height: 60, color: ledColor }} />
            <IconButton
              onClick={() => setOpen(!open)}
              color={"primary"}
              size="small"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > *": {
            background: open ? "WhiteSmoke" : "",
            borderRadius: open ? "0 0 5px 5px" : "",
            borderBottom: open ? "2px solid" + primaryColor[0] : "",
            borderLeft: open ? "1px solid" + primaryColor[0] : "",
            borderRight: open ? "1px solid" + primaryColor[0] : "",
          },
        }}
      >
        <TableCell style={{ paddingBottom: 1, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open}>
            <Box
              sx={{
                margin: 5,
                height: "20em",
                overflow: "hidden",
                overflowY: "scroll",
              }}
              justifyContent="column"
              alignItems="center"
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <Typography>Historik</Typography>
                      <Typography fontWeight={"bold"}> Program kørt</Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography fontWeight={"bold"}>
                        Status ændring
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <h4>
                        <TableSortLabel
                          onClick={() =>
                            setSortByTime(
                              sortByTime === undefined ? true : !sortByTime
                            )
                          }
                          active={sortByTime !== undefined}
                          direction={sortByTime === true ? "asc" : "desc"}
                        >
                          Tid for status ændring
                        </TableSortLabel>
                      </h4>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"}>Tid brugt</Typography>{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!sortedHist
                    ? machineHis
                        //.slice(0, 10)
                        .map((hist, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{hist.program}</TableCell>
                              <TableCell>{hist.status}</TableCell>
                              <TableCell>
                                {dayjs(Number(hist.start_time)).format(
                                  "DD-MMM-HH:mm:ss"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : sortedHist
                        //.slice(0, 10)
                        .map((hist, index) => {
                          const item = sortByTime
                            ? sortedHist[index + 1]
                            : sortedHist[index - 1];
                          return (
                            <TableRow key={index}>
                              <TableCell>{hist.program}</TableCell>
                              <TableCell>{hist.status}</TableCell>
                              <TableCell width="40%">
                                {dayjs(Number(hist.start_time)).format(
                                  "DD-MMM-HH:mm:ss"
                                )}
                              </TableCell>
                              <TableCell>
                                {item
                                  ? item.start_time &&
                                    dayjs
                                      //@ts-ignore
                                      .duration(
                                        Math.abs(
                                          hist.start_time - item.start_time
                                        )
                                      )
                                      .format("HH:mm:ss")
                                  : ""}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default MachineRow;
