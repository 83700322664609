import { Grid, Typography } from "@mui/material";
import SearchField from "components/SearchField";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import BasketTable from "../BasketTable";

const CustomerBasketsUI = () => {
  const [searchValue, setSearchValue] = useStateWithSessionStorage<string>(
    "",
    "basketSearchVal"
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Baskets
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                marginRight: "1rem",
              }}
            >
              <SearchField onChange={setSearchValue} value={searchValue} />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ padding: "2rem 1.5rem 0 1.5rem" }}>
        <BasketTable searchValue={searchValue} />
      </Grid>
    </Grid>
  );
};
export default CustomerBasketsUI;
