import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import dayjs from "dayjs";
import MachineRow from "../MachineRow";
import { fetchSound } from "../../../util/network/common";
import MachineModal from "components/MachineModal";
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const MachineManagementUI: FunctionComponent = () => {
  const [sound, setSound] = useState<any>();

  useEffect(() => {
    fetchSound("/notifsound.mp3").then((res) => setSound(res));
    console.log("Sound loaded as: ", sound);
  }, []);

  return (
    <Grid
      sm={2}
      md={6}
      lg={11}
      xl={12}
      container
      direction="row"
      justifyContent="flex-center"
      alignItems="center"
    >
      <Grid
        sm={1}
        lg={11}
        xl={12}
        sx={{ display: { sm: "none", md: "none", lg: "block" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "10px",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
            paddingTop: "20px",
          }}
        >
          <Typography
            style={{
              textTransform: "uppercase",
              color: "#fff",
              paddingLeft: "1rem",
            }}
            fontWeight={"bold"}
            variant={"h6"}
          >
            &nbsp;Maskineoversigt
          </Typography>
          <div style={{ marginLeft: "auto", height: "3vh" }}>
            <MachineModal />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ padding: "2rem 1.5rem 0 1.5rem" }}>
        <Table size="small" aria-label="a dense table">
          <TableHead
            sx={{
              background: "var(--header)",
            }}
          >
            <TableRow>
              <TableCell colSpan={7}>
                <Typography
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                  sx={{
                    fontSize: "1.1em",
                    padding: "5px",
                  }}
                >
                  Maskiner
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MachineRow id="1" sound={sound} />
            <MachineRow id="2" sound={sound} />
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default MachineManagementUI;
