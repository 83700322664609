import { TableCell, TableRow } from "@mui/material";
import styles from "./css/tasksPages.module.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ProductionListType, ProductionVMType } from "types/ProductionType";
import CopyUser from "components/Copy/CopyUser";
import { OrderType } from "types/OrderTypes";
import { apiGetOrder } from "util/network/Orders";
import AppStateContext from "contexts/AppStateContext";
import CopyEmail from "components/Copy/CopyEmail";
import CopyPhone from "components/Copy/CopyPhone";

interface OrderIdCount {
  [key: string]: number;
}

type PropsType = {
  project: ProductionVMType;
  productions?: ProductionListType;
  cam?: boolean;
  review?: boolean;
  cut?: boolean;
  mill?: boolean;
  shipping?: boolean;
  qc?: boolean;
};

const HeaderRow: React.FC<PropsType> = ({
  project,
  productions,
  cam,
  review,
  cut,
  mill,
  shipping,
  qc,
}) => {
  const { token } = useContext(AppStateContext);
  const [order, setOrder] = useState<OrderType>();

  useEffect(() => {
    apiGetOrder(token, project.orderId).then((res) => setOrder(res));
  }, [token, project.orderId]);

  const lowerCaseLeadTime = order?.leadTimeOption.toLowerCase();

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const reviewTasks = useMemo(() => {
    const allTodoByOrder: { [key: number]: boolean } = {};

    productions?.items.forEach((item) => {
      const orderId = item.orderId;
      const reviewTasks = item.tasks.filter((task) => task.name === "review");
      const allTodo = reviewTasks.every((task) => task.state === "TODO");
      allTodoByOrder[orderId] = reviewTasks.length > 0 && allTodo;
    });

    return allTodoByOrder;
  }, [productions]);

  // Counting total items per orderId
  const orderIdCount = useMemo(() => {
    const initialCount: OrderIdCount = {};
    productions?.items.forEach((item) => {
      const id = item.orderId;
      if (initialCount[id]) {
        initialCount[id]++;
      } else {
        initialCount[id] = 1;
      }
    });
    return initialCount;
  }, [productions]);

  // Counting done cam tasks per orderId
  const countCamDone = useMemo(() => {
    const initialCamDoneCounts: OrderIdCount = {};

    productions?.items.forEach((item) => {
      const orderId = item.orderId;
      const doneCamCount = item.tasks.filter(
        (task) => task.name === "cam" && task.state === "DONE"
      ).length;

      if (initialCamDoneCounts[orderId]) {
        initialCamDoneCounts[orderId] += doneCamCount;
      } else {
        initialCamDoneCounts[orderId] = doneCamCount;
      }
    });

    return initialCamDoneCounts;
  }, [productions]);

  // Counting done review tasks per orderId
  const countReviewDone = useMemo(() => {
    const initialReviewDoneCounts: OrderIdCount = {};

    productions?.items.forEach((item) => {
      const orderId = item.orderId;
      const doneReviewCount = item.tasks.filter(
        (task) => task.name === "review" && task.state === "DONE"
      ).length;

      if (initialReviewDoneCounts[orderId]) {
        initialReviewDoneCounts[orderId] += doneReviewCount;
      } else {
        initialReviewDoneCounts[orderId] = doneReviewCount;
      }
    });

    return initialReviewDoneCounts;
  }, [productions]);

  const countCutDone = useMemo(() => {
    const initialCutDoneCounts: OrderIdCount = {};

    productions?.items.forEach((item) => {
      const orderId = item.orderId;
      const doneCutCount = item.tasks.filter(
        (task) =>
          task.name === "Material" &&
          (task.description?.includes("Material") || false) &&
          task.state === "DONE"
      ).length;

      if (initialCutDoneCounts[orderId]) {
        initialCutDoneCounts[orderId] += doneCutCount;
      } else {
        initialCutDoneCounts[orderId] = doneCutCount;
      }
    });

    return initialCutDoneCounts;
  }, [productions]);

  const countProdDone = useMemo(() => {
    const initialProdDoneCounts: OrderIdCount = {};

    productions?.items.forEach((item) => {
      const orderId = item.orderId;
      const doneProdCount = item.tasks.filter(
        (task) => task.name === "production" && task.state === "DONE"
      ).length;

      if (initialProdDoneCounts[orderId]) {
        initialProdDoneCounts[orderId] += doneProdCount;
      } else {
        initialProdDoneCounts[orderId] = doneProdCount;
      }
    });

    return initialProdDoneCounts;
  }, [productions]);

  const countShippingDone = useMemo(() => {
    const initialShippingDoneCounts: OrderIdCount = {};

    productions?.items.forEach((item) => {
      const orderId = item.orderId;
      const doneShippingCount = item.tasks.filter(
        (task) => task.name === "shipping" && task.state === "DONE"
      ).length;

      if (initialShippingDoneCounts[orderId]) {
        initialShippingDoneCounts[orderId] += doneShippingCount;
      } else {
        initialShippingDoneCounts[orderId] = doneShippingCount;
      }
    });

    return initialShippingDoneCounts;
  }, [productions]);

  const countQcDone = useMemo(() => {
    const initialQcDoneCounts: OrderIdCount = {};

    productions?.items.forEach((item) => {
      const orderId = item.orderId;
      const doneQcCount = item.tasks.filter(
        (task) => task.name === "afterwork" && task.state === "DONE"
      ).length;

      if (initialQcDoneCounts[orderId]) {
        initialQcDoneCounts[orderId] += doneQcCount;
      } else {
        initialQcDoneCounts[orderId] = doneQcCount;
      }
    });

    return initialQcDoneCounts;
  }, [productions]);

  console.log(countCutDone);

  return (
    <TableRow key={`${project?.orderId}`}>
      <TableCell align="left" className={styles.headerCell}>
        <br />
        <span
          className={styles.title}
          style={{ opacity: reviewTasks[project.orderId] ? 0.3 : 1 }}
        >
          Order
        </span>
        <a
          href={`/ordrestyring/${project?.orderId}`}
          className={styles.orderNum}
        >
          {"#" + project?.orderId.toString().padStart(4, "0")}
        </a>
      </TableCell>
      <TableCell align="left" className={styles.headerCell}>
        <br />
        <span
          className={`${styles.title} ${styles.capitalizeText}`}
          style={{
            opacity: reviewTasks[project.orderId] ? 0.3 : 1,
            textTransform: "capitalize",
          }}
        >
          <span
            style={{
              color:
                order?.leadTimeOption == "EXPRESS" ? "var(--mainYellow)" : "",
            }}
          >
            {lowerCaseLeadTime}
          </span>{" "}
          {order?.leadTimeDays}
          <span className={`${styles.smallText} ${styles.lowerText}`}>wd</span>
        </span>
      </TableCell>
      <TableCell align="left" colSpan={6} className={styles.headerCell}>
        <br />
        <div className={styles.flexRow}>
          <span onClick={handleClick} className={styles.headerTxt}>
            {order && (
              <CopyPhone phone={order.billingAddress.phone} blackIcon />
            )}
          </span>
          <span onClick={handleClick} className={styles.headerTxt}>
            {order && (
              <CopyEmail
                blackIcon
                email={order?.billingAddress.email}
                subject={`Ordre: #${order?.id}`}
              />
            )}
          </span>
          <span>
            {order && (
              <CopyUser
                user={`${order?.firstName || ""} ${order?.lastName || ""}`}
              />
            )}
          </span>
          <span
            className={styles.title}
            style={{ opacity: reviewTasks[project.orderId] ? 0.3 : 1 }}
          >
            {" "}
            {project?.orderCompanyName}{" "}
          </span>
        </div>
      </TableCell>
      <TableCell className={styles.headerCell} align="right">
        <br />
        <span className={styles.headerNumContainer}>
          <span className={styles.completedTasks}>
            {cam
              ? countCamDone[project.orderId] || 0
              : review
              ? countReviewDone[project.orderId] || 0
              : cut
              ? countCutDone[project.orderId] || 0
              : mill
              ? countProdDone[project.orderId] || 0
              : shipping
              ? countShippingDone[project.orderId] || 0
              : qc
              ? countQcDone[project.orderId] || 0
              : null}
          </span>
          /{orderIdCount && orderIdCount[project.orderId]}
        </span>
      </TableCell>
    </TableRow>
  );
};
export default HeaderRow;
