import React, { useContext, useState } from "react";
import AppStateContext from "../../contexts/AppStateContext";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";

const LogOutDialog = () => {
  const { strings, showLogoutModal, setShowLogoutModal } =
    useContext(AppStateContext);

  const submitLogOut = () => {
    window.location.replace("/login");
    sessionStorage.clear();
    localStorage.clear();
  };

  const handleClose = () => {
    setShowLogoutModal(false);
  };

  return (
    <Dialog
      open={showLogoutModal}
      onBackdropClick={() => handleClose()}
      onClose={() => handleClose()}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {strings.LogoutBody}
      </DialogTitle>

      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Check />}
          onClick={() => submitLogOut()}
        >
          {strings.YesButton?.toUpperCase()}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Clear />}
          onClick={() => setShowLogoutModal(false)}
        >
          {strings.NoButton?.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogOutDialog;
