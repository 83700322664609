import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
} from "@mui/material";
import Modal from "./Modal";
import { useState } from "react";
import { ProductTypeEnum } from "types/products/ProductCommandsType";

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string, type: ProductTypeEnum) => void;
};

const DummyProductModal: React.FC<PropsType> = ({
  isOpen,
  onClose,
  onSubmit,
}: PropsType) => {
  const [type, setType] = useState<string>(ProductTypeEnum.CNC);
  const [name, setName] = useState<string>("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          width: "300px",
        }}
      >
        <h1>Create dummy product</h1>
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup
            value={type}
            onChange={(e) => setType(e.target.value)}
            row
          >
            <FormControlLabel
              value={ProductTypeEnum.CNC}
              control={<Radio />}
              label="CNC"
            />
            <FormControlLabel
              value={ProductTypeEnum.PRINT3D}
              control={<Radio />}
              label="3D print"
            />
            <FormControlLabel
              value={ProductTypeEnum.SHEET}
              control={<Radio />}
              label="Sheet"
            />
          </RadioGroup>
        </FormControl>
        <Input
          placeholder="Name"
          aria-label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <button onClick={() => onSubmit(name, type as ProductTypeEnum)}>
            Create
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DummyProductModal;
