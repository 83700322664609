import { FunctionComponent, useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AppStateContext from "../../../contexts/AppStateContext";
import styles from "./Styles/loginPageStyle.module.css";
import {
  BusinessCenter,
  Email,
  Face,
  LocationCity,
  Phone,
} from "@mui/icons-material";
import {
  REGEX_ADDRESS,
  REGEX_EMAIL,
  REGEX_FIRSTNAME,
  REGEX_LASTNAME,
} from "../../../util/RegExUtil";
import { apiCreateUser, apiUpdateUser } from "../../../util/network/Users";
import ErrorCollapse from "../../../components/Generic/ErrorCollapse";
import { AccessEnum, CreateUserType, UserType } from "types/users/UserTypes";
import { AddressType } from "../../../types/AddressTypes";
import { UserUpdateType } from "types/users/UserCommandsType";
import { useToast } from "contexts/ToastContext";
import CompanySearch, {
  CompanyData,
} from "components/CompanySearch/CompanySearch";

type PropsType = {
  setCustomer: (customer: UserType) => void;
  existing?: UserType | null;
  accessLevel?: AccessEnum | null;
  onSubmit?: Function;
  onClose?: Function;
};

const UserModal: FunctionComponent<PropsType> = ({
  setCustomer,
  existing,
  accessLevel,
  onSubmit,
  onClose,
}) => {
  const { showUserModal, setShowUserModal, setShowLoadingModal } =
    useContext(AppStateContext);
  const { addToast } = useToast();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [billingFirstName, setBillingFirstName] = useState<string>("");
  const [billingLastName, setBillingLastName] = useState<string>("");
  const [billingEmail, setBillingEmail] = useState<string>("");
  const [company, setCompany] = useState<string | undefined>("");
  const [addressName, setAddressName] = useState<string>("");
  const [zipCode, setZipCode] = useState<string | undefined>("");
  const [city, setCity] = useState<string | undefined>("");
  const [phone, setPhone] = useState<string | undefined>("");
  const [cvr, setCvr] = useState<string | undefined>("");
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [useSameForShipping, setUseSameForShipping] = useState<boolean>(true);
  const { token } = useContext(AppStateContext);

  const [shippingFirstName, setShippingFirstName] = useState<string>("");
  const [shippingLastName, setShippingLastName] = useState<string>("");
  const [shippingAddressName, setShippingAddressName] = useState<string>("");
  const [shippingZipCode, setShippingZipCode] = useState<string | undefined>(
    ""
  );
  const [shippingCity, setShippingCity] = useState<string | undefined>("");
  const [shippingPhone, setShippingPhone] = useState<string | undefined>("");
  const [shippingCvr, setShippingCvr] = useState<string | undefined>("");
  const [shippingCompany, setShippingCompany] = useState<string | undefined>(
    ""
  );
  const [shippingEmail, setShippingEmail] = useState<string>("");

  useEffect(() => {
    if (existing) {
      let areAddressesSame = true;

      setUseSameForShipping(false);
      setFirstName(existing.firstName);
      setLastName(existing.lastName);
      setEmail(existing.email);

      if (existing.defaultBillingAddress) {
        setBillingFirstName(existing.defaultBillingAddress.firstName);
        setBillingLastName(existing.defaultBillingAddress.lastName);
        setBillingEmail(existing.defaultBillingAddress?.email);
        setCompany(existing.defaultBillingAddress.company);
        setAddressName(existing.defaultBillingAddress.street);
        setZipCode(existing.defaultBillingAddress.postalCode);
        setCity(existing.defaultBillingAddress.city);
        setPhone(existing.defaultBillingAddress.phone);
        setCvr(existing.defaultBillingAddress.vat);
      }
      if (existing.defaultShippingAddress) {
        setShippingCompany(existing.defaultShippingAddress.company);
        setShippingAddressName(existing.defaultShippingAddress.street);
        setShippingZipCode(existing.defaultShippingAddress.postalCode);
        setShippingCity(existing.defaultShippingAddress.city);
        setShippingPhone(existing.defaultShippingAddress.phone);
        setShippingCvr(existing.defaultShippingAddress.vat);
        setShippingFirstName(existing.defaultShippingAddress.firstName);
        setShippingLastName(existing.defaultShippingAddress.lastName);
        setShippingEmail(existing.defaultShippingAddress.email);
      }

      const inputsToCompare = [
        "firstName",
        "lastName",
        "email",
        "company",
        "street",
        "postalCode",
        "city",
        "phone",
        "vat",
      ];

      areAddressesSame = inputsToCompare.every(
        (input) =>
          existing.defaultBillingAddress &&
          existing.defaultShippingAddress &&
          existing.defaultBillingAddress[input as keyof AddressType] ===
            existing.defaultShippingAddress[input as keyof AddressType]
      );

      setUseSameForShipping(areAddressesSame);
    } else if (existing === null) {
      clearData();
    }
  }, [existing]);

  const handleCompanySelect = (data: CompanyData) => {
    setCompanyData(data);
    setCompany(data.name);
    setCvr(data.vat.toString());
    setAddressName(data.address);
    setZipCode(data.zipcode.toString());
    setCity(data.city);
    setPhone(data.phone?.toString());
  };

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setBillingFirstName("");
    setBillingLastName("");
    setBillingEmail("");
    setCompany("");
    setAddressName("");
    setZipCode("");
    setCity("");
    setPhone("");
    setCvr("");
    setShippingCompany("");
    setShippingAddressName("");
    setShippingZipCode("");
    setShippingCity("");
    setShippingPhone("");
    setShippingCvr("");
    setShippingFirstName("");
    setShippingLastName("");
    setShippingEmail("");
  };

  const handleSubmit = () => {
    setShowLoadingModal(true);
    if (
      !billingEmail ||
      !billingFirstName ||
      !billingLastName ||
      !addressName
    ) {
      if (!billingEmail) errorNames.push("Email er påkrævet");
      if (!billingFirstName) errorNames.push("Fornavn er påkrævet");
      if (!billingLastName) errorNames.push("Efternavn er påkrævet");
      if (!addressName) errorNames.push("Vejnavn er påkrævet");

      //errorNames.push("Der mangler nødvendig brugerinformation");
      setErrorOpen(true);
      setShowLoadingModal(false);
      return;
    }
    if (existing) {
      const addressInfo: AddressType = {
        firstName: billingFirstName,
        lastName: billingLastName,
        email: billingEmail,
        street: addressName,
        company: company ? company : "Ikke oplyst",
        phone: phone ? phone : "Ikke oplyst",
        postalCode: zipCode ? zipCode : "Ikke oplyst",
        vat: cvr ? cvr : "Ikke oplyst",
        city: city ? city : "Ikke oplyst",
        countryId: "DK",
      };
      const shippingAddressInfo: AddressType = {
        firstName: shippingFirstName,
        lastName: shippingLastName,
        email: billingEmail,
        street: shippingAddressName,
        company: shippingCompany ? shippingCompany : "Ikke oplyst",
        phone: shippingPhone ? shippingPhone : "Ikke oplyst",
        postalCode: shippingZipCode ? shippingZipCode : "Ikke oplyst",
        vat: shippingCvr ? shippingCvr : "Ikke oplyst",
        city: shippingCity ? shippingCity : "Ikke oplyst",
        countryId: "DK",
      };

      const changes: UserUpdateType = {
        firstName: firstName,
        lastName: lastName,
        billingAddress: addressInfo,
        shippingAddress: useSameForShipping ? addressInfo : shippingAddressInfo,
      };

      apiUpdateUser(token, existing.id, changes).then((customer) => {
        setShowLoadingModal(false);
        if (!customer.message) {
          setCustomer(customer);
          setShowUserModal(false);
          onSubmit && onSubmit();
        } else if (customer.message) {
          window.alert("Der skete en fejl ved redigere: \n" + customer.message);
        }
      });
    } else {
      const addressInfo: AddressType = {
        firstName: billingFirstName,
        lastName: billingLastName,
        email: billingEmail,
        street: addressName,
        company: company ? company : "Ikke oplyst",
        phone: phone ? phone : "Ikke oplyst",
        postalCode: zipCode ? zipCode : "Ikke oplyst",
        vat: cvr ? cvr : "Ikke oplyst",
        city: city ? city : "Ikke oplyst",
        countryId: "DK",
      };
      const shippingAddressInfo: AddressType = {
        firstName: shippingFirstName,
        lastName: shippingLastName,
        email: billingEmail,
        street: shippingAddressName,
        company: shippingCompany ? shippingCompany : "Ikke oplyst",
        phone: shippingPhone ? shippingPhone : "Ikke oplyst",
        postalCode: shippingZipCode ? shippingZipCode : "Ikke oplyst",
        vat: shippingCvr ? shippingCvr : "Ikke oplyst",
        city: shippingCity ? shippingCity : "Ikke oplyst",
        countryId: "DK",
      };
      const customerCon: CreateUserType = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        address: addressInfo,
        shippingAddress: useSameForShipping ? addressInfo : shippingAddressInfo,
        accessLevel: accessLevel || AccessEnum.CUSTOMER,
      };
      apiCreateUser(token, customerCon).then((customer) => {
        setShowLoadingModal(false);
        if (!customer.message) {
          setCustomer(customer);
          setShowUserModal(false);
          addToast({
            type: "success",
            message: (
              <>
                New user was <strong>created</strong> successfully.
              </>
            ),
          });
          onSubmit && onSubmit();
        } else if (customer.message) {
          addToast({
            type: "error",
            message: "Der skete en fejl ved oprettelse: \n" + customer.message,
            keep: true,
          });
        }
      });
    }
  };
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorNames, setErrorNames] = useState<string[]>([]);

  //To avoid clearing info in DrafEdit
  const handleClose = () => {
    onClose && onClose();
    setShowUserModal(false);
  };

  return (
    <Dialog open={showUserModal} onClose={() => handleClose()}>
      <div className={styles.userModalContainer}>
        <div className={`${styles.pdLeft} ${styles.pdbottom}`}>
          <h3>{"BRUGER OPLYSNINGER"}</h3>
          <span className={styles.requiredText}>{"*Required"}</span>
        </div>
        <ErrorCollapse
          errorNames={errorNames}
          setErrorNames={setErrorNames}
          open={errorOpen}
          setOpen={setErrorOpen}
        />
        <form id={"addressSubmit"}>
          <div className={styles.flexR}>
            <div className={styles.leftInputContainer}>
              <TextField
                label={"Fornavn: "}
                size="small"
                id={"fornavn"}
                required={true}
                fullWidth={false}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!REGEX_FIRSTNAME.test(firstName) && firstName.length > 0}
                helperText={
                  !REGEX_FIRSTNAME.test(firstName) && firstName.length > 0
                    ? "Invalid first name"
                    : ""
                }
                inputProps={{
                  maxLength: 30,
                }}
              />
            </div>
            <div className={styles.rightInputContainer}>
              <TextField
                label={"Efternavn: "}
                id={"efternavn"}
                size="small"
                required={true}
                fullWidth={false}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={!REGEX_LASTNAME.test(lastName) && lastName.length > 0}
                helperText={
                  !REGEX_LASTNAME.test(lastName) && lastName.length > 0
                    ? "Invalid last name"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={styles.inputAdornment}
                    >
                      <Face className={styles.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </div>
          </div>
          <br />
          <div className={styles.leftInputContainer}>
            <TextField
              label={"Email: "}
              id="email"
              size="small"
              disabled={!!existing}
              fullWidth={false}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!REGEX_EMAIL.test(email) && email.length > 0}
              helperText={
                !REGEX_EMAIL.test(email) && email.length > 0
                  ? "Invalid email address"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={styles.inputAdornment}
                  >
                    <Email className={styles.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <br />
          <div className={styles.searchCompany}>
            <CompanySearch onCompanySelect={handleCompanySelect} />
          </div>
          <h4 className={styles.addressTitle}>Billing: </h4>
          <div className={styles.pdbottom}>
            <Typography variant={"body1"}>
              Same for shipping:{" "}
              <input
                type="checkbox"
                checked={useSameForShipping}
                onChange={(e) => setUseSameForShipping(!useSameForShipping)}
              />
            </Typography>
          </div>
          <div className={styles.flexR}>
            <div className={styles.leftInputContainer}>
              <TextField
                label={"Firmannavn:"}
                id="Firmanavn"
                size="small"
                type="text"
                fullWidth={false}
                required={false}
                value={company}
                onChange={(e) => {
                  if (e.target.value.length < 45) {
                    setCompany(e.target.value);
                  }
                }}
              />
            </div>
            <div className={styles.rightInputContainer}>
              <TextField
                label={"CVR: "}
                id="cvr"
                type="text"
                size="small"
                fullWidth={false}
                required={false}
                value={cvr}
                onChange={(e) => setCvr(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={styles.inputAdornment}
                    >
                      <BusinessCenter className={styles.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <br />
          <div className={styles.flexR}>
            <div className={styles.leftInputContainer}>
              <TextField
                label={"Fornavn: "}
                id={"fornavn"}
                size="small"
                required
                type="text"
                value={billingFirstName}
                error={!REGEX_FIRSTNAME.test(billingFirstName)}
                helperText={
                  !REGEX_FIRSTNAME.test(billingFirstName)
                    ? "Invalid first name"
                    : ""
                }
                onChange={(e) => setBillingFirstName(e.target.value)}
                inputProps={{ maxLength: 30 }}
              />
            </div>
            <div className={styles.rightInputContainer}>
              <TextField
                label="Efternavn:"
                id="efternavn"
                size="small"
                fullWidth={false}
                required
                type="text"
                value={billingLastName}
                error={!REGEX_LASTNAME.test(billingLastName)}
                helperText={
                  !REGEX_LASTNAME.test(billingLastName)
                    ? "Invalid last name"
                    : ""
                }
                onChange={(e) => setBillingLastName(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Face className={styles.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  inputProps: { maxLength: 30 },
                }}
              />
            </div>
          </div>
          <br />
          <div className={styles.leftInputContainer}>
            <TextField
              label="Vej:"
              id="vej"
              fullWidth={false}
              required
              type="text"
              size="small"
              value={addressName}
              error={!REGEX_ADDRESS.test(addressName)}
              helperText={
                !REGEX_ADDRESS.test(addressName) ? "Invalid address" : ""
              }
              onChange={(e) => setAddressName(e.target.value)}
              inputProps={{ maxLength: 400 }}
            />
          </div>
          <br />
          <div className={styles.flexR}>
            <div className={styles.leftInputContainer}>
              <TextField
                label="Postnummer:"
                id="postnummer"
                size="small"
                fullWidth={false}
                type="text"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className={styles.number}
              />
            </div>
            <div className={styles.rightInputContainer}>
              <TextField
                label="By:"
                id="by"
                size="small"
                fullWidth={false}
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={styles.inputAdornment}
                    >
                      <LocationCity className={styles.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  inputProps: { maxLength: 50 },
                }}
              />
            </div>
          </div>
          <br />
          <div className={styles.flexR}>
            <div className={styles.leftInputContainer}>
              <TextField
                label="Tlf.nr.:"
                id="telefonnummer"
                size="small"
                fullWidth={false}
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={styles.inputAdornment}
                    >
                      <Phone className={styles.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={styles.rightInputContainer}>
              <TextField
                label="Email:"
                size="small"
                id="email"
                fullWidth={false}
                required
                type="email"
                value={billingEmail}
                onChange={(e) => setBillingEmail(e.target.value)}
                error={!REGEX_EMAIL.test(billingEmail)}
                helperText={
                  !REGEX_EMAIL.test(billingEmail) ? "Invalid email address" : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={styles.inputAdornment}
                    >
                      <Email className={styles.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <br />
          {!useSameForShipping && (
            <>
              <h4 className={styles.addressTitle}>Shipping:</h4>
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label="Firmannavn:"
                    size="small"
                    id="Firmanavn"
                    fullWidth={false}
                    type="text"
                    value={shippingCompany}
                    onChange={(e) => {
                      if (e.target.value.length < 45) {
                        setShippingCompany(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className={styles.rightInputContainer}>
                  <TextField
                    label="CVR:"
                    size="small"
                    id="cvr"
                    type="text"
                    value={shippingCvr}
                    onChange={(e) => setShippingCvr(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <BusinessCenter />
                        </InputAdornment>
                      ),
                      className: styles.number,
                    }}
                  />
                </div>
              </div>
              <br />
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label="Fornavn:"
                    size="small"
                    id="fornavn"
                    type="text"
                    value={shippingFirstName}
                    error={!REGEX_FIRSTNAME.test(shippingFirstName)}
                    helperText={
                      !REGEX_FIRSTNAME.test(shippingFirstName)
                        ? "Invalid first name"
                        : ""
                    }
                    onChange={(e) => setShippingFirstName(e.target.value)}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
                <div className={styles.rightInputContainer}>
                  <TextField
                    label="Efternavn:"
                    id="efternavn"
                    size="small"
                    type="text"
                    value={shippingLastName}
                    error={!REGEX_LASTNAME.test(shippingLastName)}
                    helperText={
                      !REGEX_LASTNAME.test(shippingLastName)
                        ? "Invalid last name"
                        : ""
                    }
                    onChange={(e) => setShippingLastName(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face />
                        </InputAdornment>
                      ),
                      inputProps: { maxLength: 30 },
                    }}
                  />
                </div>
              </div>
              <br />
              <div className={styles.leftInputContainer}>
                <TextField
                  label="Vej:"
                  size="small"
                  id="vej"
                  fullWidth={false}
                  required
                  type="text"
                  value={shippingAddressName}
                  error={!REGEX_ADDRESS.test(shippingAddressName)}
                  helperText={
                    !REGEX_ADDRESS.test(shippingAddressName)
                      ? "Invalid address"
                      : ""
                  }
                  onChange={(e) => setShippingAddressName(e.target.value)}
                  inputProps={{ maxLength: 400 }}
                />
              </div>
              <br />
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label="Postnummer:"
                    size="small"
                    id="postnummer"
                    fullWidth={false}
                    required={false}
                    type="text"
                    value={shippingZipCode}
                    onChange={(e) => setShippingZipCode(e.target.value)}
                    className={styles.number}
                  />
                </div>
                <div className={styles.rightInputContainer}>
                  <TextField
                    label="By:"
                    id="by"
                    size="small"
                    fullWidth={false}
                    required={false}
                    type="text"
                    value={shippingCity}
                    onChange={(e) => setShippingCity(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={styles.inputAdornment}
                        >
                          <LocationCity className={styles.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      inputProps: { maxLength: 50 },
                    }}
                  />
                </div>
              </div>
              <br />
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label="Tlf.nr.:"
                    size="small"
                    id="telefonnummer"
                    fullWidth={false}
                    required={false}
                    type="text"
                    value={shippingPhone}
                    onChange={(e) => setShippingPhone(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={styles.inputAdornment}
                        >
                          <Phone className={styles.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.rightInputContainer}>
                  <TextField
                    label="Email:"
                    size="small"
                    id="email"
                    fullWidth={false}
                    required
                    type="email"
                    value={shippingEmail}
                    error={!REGEX_EMAIL.test(shippingEmail)}
                    helperText={
                      !REGEX_EMAIL.test(shippingEmail) ? "Invalid email" : ""
                    }
                    onChange={(e) => setShippingEmail(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={styles.inputAdornment}
                        >
                          <Email className={styles.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </form>
        <div className={styles.flRg}>
          <Button
            color={"error"}
            variant="contained"
            sx={{ marginRight: "1em" }}
            onClick={(e) => handleClose()}
          >
            Annuller
          </Button>
          <Button variant="contained" onClick={(e) => handleSubmit()}>
            {!existing ? "Opret" : "Opdater"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UserModal;
