import {
  ChannelItem as IChannelItem,
  ChannelBox,
  ChannelLogo,
} from "@nessprim/planby";

export const ChannelItem = ({ isVerticalMode, channel }: IChannelItem) => {
  const { position, logo, title } = channel;

  return (
    <ChannelBox
      data-testid="sidebar-item"
      isVerticalMode={isVerticalMode}
      style={{
        border: "1px solid transparent",
        borderBottomColor: "#7180961a",
        borderRightColor: "#7180961a",
      }}
      {...position}
    >
      {/* <ChannelLogo src={logo} alt="Logo" /> */}
      <p>{title}</p>
    </ChannelBox>
  );
};
