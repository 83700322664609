import {
  ProgramItem,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram,
  ProgramResizeHandle,
} from "@nessprim/planby";
import React, { useEffect, useState } from "react";
import { CNCPlan, updateCNCPlan } from "../../plans/CNCPlansAPI";

export const ProgramItemEdit = ({ isVerticalMode, program, ...rest }: ProgramItem) => {
  const {
    isLive,
    isMinWidth,
    isMouseEvent,
    styles,
    resizeEvents,
    formatTime,
    getMouseEvents,
    set12HoursTimeFormat,
    getMouseEventTempTime,
  } = useProgram({
    isVerticalMode,
    program,
    ...rest,
  });

  const { data } = program;
  const { image, title, since, till, channelUuid } = data;
  

  async function saveUpdate() {
    const id = Number(data.id);
    const updatedFields = {
      start: data.channelUuid == "UNPLANNED" ? "" : new Date(data.since), //Start and end should be deleted when moved to undefined, but this is not working
      end: data.channelUuid == "UNPLANNED" ? "" : new Date(data.till),
      machine: data.channelUuid == "UNPLANNED" ? "" : data.channelUuid,
    };
    await updateCNCPlan(id, updatedFields);
  }

  useEffect(() => {
    // This effect will run whenever `since` or `till` changes. Will also run on load, which i cannot get it to no do, as load is also what happens when dragging between channels
    saveUpdate();
    console.log("Updating: " + data.id)
  }, [since, till, channelUuid]);

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
  const dragTime = getMouseEventTempTime();
  return (
    <ProgramBox
      width={styles.width}
      style={styles.position}
      {...getMouseEvents()}
    >
      <ProgramContent
        isVerticalMode={isVerticalMode}
        width={styles.width}
        isLive={isLive}
        style={{ padding: '2px' }}
        // onClick={() => alert(JSON.stringify(data))}
      >
        <ProgramResizeHandle {...resizeEvents.eventsLeft} />
        <ProgramResizeHandle {...resizeEvents.eventsRight} />
        <ProgramFlex isVerticalMode={isVerticalMode} style={{ marginLeft: '15px', marginRight: '15px'}}>
            <ProgramImage
              isVerticalMode={isVerticalMode}
              src={image}
              // alt="Preview"
              style={{ width: 'unset', marginRight: '10px'}}
              // onClick={() => alert(JSON.stringify(data))}
            />
          <ProgramStack>
            <ProgramTitle style={{ marginBottom: '1px', fontWeight: 'bold', color: 'unset'}}>{title}</ProgramTitle>
            <ProgramText style={{ fontSize: '11px'}}>
              {isMouseEvent ? (
                <>
                  {dragTime.since} - {dragTime.till}
                </>
              ) : (
                <>
                  {sinceTime} - {tillTime}
                </>
              )}
            </ProgramText>
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};
