import React, { useEffect, useState, useContext } from "react";
import { Button, Select, MenuItem, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Modal from "./Modal"; // Assuming you have a generic modal component
import { apiGetProductTasks, apiUpdateProductionTask } from "util/network/Productions"; // Replace with your correct API paths
import AppStateContext from "contexts/AppStateContext";
import { useToast } from "contexts/ToastContext";
import { ProductionTaskStateEnum } from "types/ProductionType";

const updateTaskWithRequisition = async (
  token: string,
  task: { id: number; requisitions?: number[] } | null, // Allow null for "None"
  requisitionId: number
): Promise<void> => {
  try {
      if (!task) {
          console.log("No task selected. Skipping update.");
          return; // Do nothing if "None" is selected
      }

      // Prepare the updated requisitions list
      const updatedRequisitions = task.requisitions
          ? Array.from(new Set([...task.requisitions, requisitionId]))
          : [requisitionId];

      // Update the task with the new requisition
      await apiUpdateProductionTask(token, task.id, {
          id: task.id,
          requisitions: updatedRequisitions,
      });

      console.log(`Requisition ${requisitionId} successfully assigned to task ${task.id}.`);
  } catch (error) {
      console.error("Error assigning requisition to task:", error);
      throw error;
  }
};


type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  requisitionId: number; // Requisition ID to assign to tasks
  productionItems: { id: number; productId: number; name: string }[]; // Example structure for production items
  onCreate: (selectedTasks: { productionId: number; taskId: number }[]) => void; // Add this property
};


const RequisitionAssignTaskModal: React.FC<PropsType> = ({
  isOpen,
  onClose,
  requisitionId,
  productionItems,
}) => {
  
    

  const { token } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [taskOptions, setTaskOptions] = useState<
      Record<number, { id: number; name: string; requisitions?: number[] }[]>
  >({});
  const [selectedTasks, setSelectedTasks] = useState<Record<number, number>>({});

  useEffect(() => {
    if (isOpen) {
        if(productionItems.length === 0) {
          onClose();
          return;
        }

        // Fetch tasks for each production item
        const fetchTasks = async () => {
            const options: Record<number, { id: number; name: string; requisitions?: number[] }[]> = {};
            const initialSelections: Record<number, number> = {};

            for (const item of productionItems) {
                try {
                    const tasks = await apiGetProductTasks(token, item.productId);

                    // Filter tasks that allow requisitions
                    const allowedTasks = tasks.filter((task) => task.allowRequisitions);

                    options[item.id] = allowedTasks.map((task) => ({
                        id: task.id,
                        name: task.description,
                        requisitions: task.requisitions.map((requisition) => requisition.id),
                    }));

                    // Set the default selection to the first allowed and non-completed task
                    const firstNonCompletedTask = allowedTasks.find(
                        (task) => task.state !== ProductionTaskStateEnum.DONE
                    );
                    if (firstNonCompletedTask) {
                        initialSelections[item.id] = firstNonCompletedTask.id;
                    }
                } catch (error) {
                    console.error(`Failed to fetch tasks for production ${item.id}:`, error);
                    options[item.id] = [];
                }
            }

            setTaskOptions(options);
            setSelectedTasks(initialSelections);
        };

        fetchTasks();
    }
}, [isOpen, productionItems, token, onClose]);



  const handleTaskChange = (productionId: number, taskId: number) => {
      setSelectedTasks((prev) => ({ ...prev, [productionId]: taskId }));
  };

  const handleAssignRequisition = async () => {
    try {
        const assignments = Object.entries(selectedTasks)
            .map(([productionId, taskId]) => ({
                task: taskId
                    ? taskOptions[Number(productionId)]?.find((task) => task.id === taskId)
                    : null, // Null for "None"
                requisitionId,
            }))
            .filter(({ task }) => task !== null); // Ensure the task exists

        const results = await Promise.allSettled(
            assignments.map(({ task, requisitionId }) =>
                updateTaskWithRequisition(token, task!, requisitionId)
            )
        );

        // Check results for errors
        const allSuccess = results.every((result) => result.status === "fulfilled");

        if (allSuccess) {
            addToast({
                type: "success",
                message: "Requisition assigned to tasks",
            });
            onClose(); // Close the modal
        } else {
            console.error("Some tasks failed to update:", results);
            addToast({
                type: "error",
                message: "Failed to assign requisition to some tasks. Please try again.",
            });
        }
    } catch (error) {
        console.error("Unexpected error during task assignment:", error);
        addToast({
            type: "error",
            message: "An unexpected error occurred. Please try again.",
        });
    }
};



  return (
      <Modal isOpen={isOpen && productionItems.length > 0} onClose={onClose}>
          <h2>Assign Requisition to Tasks</h2>
          <Table>
            <TableBody>
                {productionItems.map((item) => (
                    <TableRow key={item.id}>
                        <TableCell><b>#{item.id}</b></TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                            <Select
                                value={selectedTasks[item.id] || ""} // Default to "None" if no task is selected
                                onChange={(e) =>
                                    handleTaskChange(item.id, Number(e.target.value))
                                }
                                displayEmpty
                                style={{ width: "100%" }}
                            >
                                <MenuItem value="">None</MenuItem> {/* "None" option */}
                                {taskOptions[item.id]?.map((task) => (
                                    <MenuItem key={task.id} value={task.id}>
                                        {task.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
          <div
              style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                  marginTop: "20px",
              }}
          >
              <Button variant="contained" color="error" onClick={onClose}>
                  Dont Assign
              </Button>
              <Button variant="contained" color="success" onClick={handleAssignRequisition}>
                  Assign Requisition
              </Button>
          </div>
      </Modal>
  );
};

export default RequisitionAssignTaskModal;

