import { Button, IconButton, TextField } from "@mui/material";
import ExpandingContainer from "components/ExpandingContainer";
import { useEffect, useState } from "react";
import { CustomMaterialType } from "types/products/ProductOptionsType";
import { Close } from "@mui/icons-material";

type PropsType = {
  value: CustomMaterialType;
  onChange: (value: CustomMaterialType | null) => void;
  disabled?: boolean;
};

const CustomMaterialSelector: React.FC<PropsType> = ({
  value,
  onChange,
  disabled,
}) => {
  const [name, setName] = useState<string>(value.name);
  const [density, setDensity] = useState<string>(value.density.toString());
  const [description, setDescription] = useState<string>(value.description);
  const [customerProvides, setCustomerProvides] = useState<boolean>(
    value.customerProvides
  );

  const [open, setOpen] = useState<boolean>(false);

  const makeChange = () => {
    // Cleanup density text and convert to number
    const densityText = density.replace(/[^0-9.]/g, "");
    const densityNumber = parseFloat(densityText);

    console.log(name, densityNumber, description, customerProvides);

    const change = {
      name: name,
      density: densityNumber,
      description: description,
      customerProvides: customerProvides,
    };
    onChange(change);
  };

  useEffect(() => {
    setName(value.name);
    setDensity(value.density.toString());
    setDescription(value.description);
    setCustomerProvides(value.customerProvides);
  }, [value]);

  return (
    <ExpandingContainer
      open={open}
      setOpen={(_open: boolean) => {
        if (!_open) {
          makeChange();
        }
        setOpen(_open);
      }}
      primaryElement={
        <TextField
          value={name}
          sx={{ m: 1, minWidth: "12em" }}
          onChange={(e: any) => {
            console.log(e.target.value);
            setName(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={(e) => {
                  onChange(null);
                }}
              >
                <Close />
              </IconButton>
            ),
          }}
          disabled={disabled}
        />
      }
      expandingElements={[
        <TextField
          sx={{ m: 1 }}
          label={"Density"}
          value={density}
          onChange={(e) => {
            setDensity(e.target.value);
          }}
          InputProps={{
            endAdornment: "kg/m3",
          }}
        ></TextField>,
        <TextField
          sx={{ m: 1 }}
          label={"Description"}
          multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        ></TextField>,
        /* Toggle for customer provides, small checkbox with a label */
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "1ch",
          }}
        >
          <label>Material sourced</label>
          <input
            type="checkbox"
            checked={customerProvides}
            onChange={(e) => {
              setCustomerProvides(e.target.checked);
            }}
          />
        </div>,

        <Button
          sx={{ m: 1, width: "19ch" }}
          variant="contained"
          onClick={() => {
            makeChange();
            setOpen(false);
          }}
        >
          UPDATE
        </Button>,
      ]}
    />
  );
};

export default CustomMaterialSelector;
