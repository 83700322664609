export const REGEX_FIRMNAME = new RegExp(/\b[A-ZÆØÅa-zæøå_0-9]{1,30}\b/);
export const REGEX_CVRNUMBER = new RegExp(/\b\d{8}\b/);
export const REGEX_FIRSTNAME = new RegExp(/\b.{1,30}\b/);
export const REGEX_LASTNAME = new RegExp(/\b.{1,30}\b/);
export const REGEX_PHONENUMBER = new RegExp(/\b.{8}\b/);
export const REGEX_EMAIL = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const REGEX_ADDRESS = new RegExp(/\b.{1,50}\b/);
export const REGEX_ADDRESSNUMBER = new RegExp(/\b\d{1,10}\b/);
export const REGEX_ZIPCODE = new RegExp(/\b\d{4}\b/);
export const REGEX_CITY = new RegExp(/\b.{1,50}\b/);
export const REGEX_EAN = new RegExp(/\b\d{13}\b/);
