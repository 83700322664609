import { Button, Card, TextField } from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import AppStateContext from "../../../../contexts/AppStateContext";
import { useClickOutside } from "../../../../hooks/use-click-outside";
import { apiSearchUsers } from "../../../../util/network/Users";
import ResultRow from "./ResultRow";
import { UserType } from "../../../../types/users/UserTypes";

type PropsType = {
  onClick?: (user: UserType) => void;
  disabled?: boolean;
};

const CustomerSearchField: FunctionComponent<PropsType> = ({
  onClick,
  disabled,
}) => {
  const { token } = useContext(AppStateContext);
  const [value, setValue] = useState<string>("");
  const [results, setResults] = useState<UserType[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(false);
  const ref = useClickOutside(() => setShowResults(false));
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();

  const searchValue = () => {
    setEmpty(false);
    setShowResults(false);
    value &&
      value !== "" &&
      apiSearchUsers(token, value).then((res) => {
        if (res.length === 0) {
          setEmpty(true);
        }
        setShowResults(true);
        setResults(res);
      });
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      searchValue();
    }, 200);
    setTimer(newTimer);
  }, [value]);

  return (
    <div ref={ref}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          value={value}
          placeholder={"Søg efter kunde"}
          onChange={(e) => setValue(e.target.value)}
          onClick={() => results.length > 0 && setShowResults(true)}
          disabled={disabled}
          size="small"
        />
      </div>
      {showResults && (
        <Card
          style={{
            position: "absolute",
            zIndex: 1000,
            maxHeight: "20em",
            overflowY: "scroll",
          }}
        >
          {empty ? (
            <ResultRow />
          ) : (
            results.map((item, key) => (
              <ResultRow
                key={key}
                info={item}
                onClick={() => {
                  onClick && onClick(item);
                  setShowResults(false);
                }}
              />
            ))
          )}
        </Card>
      )}
    </div>
  );
};

export default CustomerSearchField;
