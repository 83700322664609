import { FunctionComponent, useContext, useState } from "react";
import AppStateContext from "../../../contexts/AppStateContext";
import ShippingCard from "./Card/ShippingCard";
import { ShippingMethodType } from "../../../types/CartTypes";
import PostNord from "assets/img/postnord_erhverv.jpg";
import EasyPartz from "assets/img/easypartz.jpg";
import TNT from "assets/img/TNT.png";
import Modal from "components/Modals/Modal";
import { Card, Grid } from "@mui/material";
import cardStyles from "../../../components/Card/css/card.module.css";

type PropsType = {
  currentMethod?: string;
  updateShipping: (code: string, price?: number) => void;
  shippingMethods: ShippingMethodType[];
  isCustomShippingMethod?: boolean;
  disabled?: boolean;
};

const ShippingMethods: FunctionComponent<PropsType> = ({
  currentMethod,
  updateShipping,
  shippingMethods,
  isCustomShippingMethod,
  disabled = false,
}) => {
  const { strings } = useContext(AppStateContext);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [customName, setCustomName] = useState<string>();
  const [customPrice, setCustomPrice] = useState<number>();

  const getShippingText = (id: string) => {
    if (id.includes("postnord")) {
      return {
        title: strings.PostNordDelivery,
        description: strings.PostNordDeliveryText,
        timeText: strings.PostNordDeliveryTimeShort,
        logo: PostNord,
      };
    }
    if (id.includes("tnt_before_12")) {
      return {
        title: strings.TNT2TitelShort,
        description: strings.TNT2Body,
        timeText: strings.TNT2DeliveryTime,
        logo: TNT,
      };
    }
    if (id.includes("tnt_next_day")) {
      return {
        title: strings.TNT2TitelShort,
        description: strings.TNT3Body,
        timeText: strings.TNT3DeliveryTime,
        logo: TNT,
      };
    }
    return {
      title: strings.SelfCollectShort,
      description: strings.SelfCollectText,
      timeText: strings.SelfCollectTimeShort,
      logo: EasyPartz,
    };
  };

  return (
    <>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div>
          <h2>Custom shipping</h2>
          <p>Enter the name for the custom shipping method</p>
          <input
            type="text"
            onChange={(e) => setCustomName(e.target.value)}
            value={customName}
            required
          />
          <p>Enter the price for the custom shipping method</p>
          <input
            type="number"
            onChange={(e) => setCustomPrice(Number(e.target.value))}
            value={customPrice}
            required
          />
          <br />
          <br />
          <button onClick={() => setModalOpen(false)}>Cancel</button>
          <button
            onClick={() => {
              setModalOpen(false);
              if (customName !== undefined && customPrice !== undefined) {
                updateShipping(customName, customPrice);
              }
            }}
          >
            Save
          </button>
        </div>
      </Modal>
      <Card className={cardStyles.card}>
        <div style={{ margin: "1rem" }}>
          <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
            Shipping
          </span>
        </div>
        <div style={{ margin: "1rem" }}>
          <Grid
            container={true}
            style={{ display: "flex", flexWrap: "nowrap" }}
          >
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {shippingMethods.map((item, key) => {
                  const shippingTexts = getShippingText(item.code ?? "");
                  return (
                    <Grid
                      key={key}
                      item
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        onClick={() => !disabled && updateShipping(item.code)}
                        style={{ paddingRight: "0.5rem" }}
                      >
                        <ShippingCard
                          shippingTitle={shippingTexts.title}
                          shippingPrice={
                            item.price > 0 ? item.price : undefined
                          }
                          isSelected={currentMethod === item.code}
                          onClick={() => {
                            !disabled &&
                              updateShipping &&
                              updateShipping(item.code);
                          }}
                          disabled={disabled && currentMethod !== item.code}
                        />
                      </div>
                    </Grid>
                  );
                })}
                <Grid
                  key={"customShipping"}
                  item
                  style={{
                    width: "100%",
                  }}
                >
                  <div style={{ paddingRight: "0.5rem" }}>
                    <ShippingCard
                      shippingTitle={customName ?? "Custom"}
                      shippingPrice={customPrice}
                      isSelected={isCustomShippingMethod || false}
                      onClick={() => {
                        !disabled && setModalOpen(true);
                      }}
                      disabled={disabled}
                    />
                  </div>
                </Grid>
              </div>
            </>
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default ShippingMethods;
