export const API_URL = process.env.REACT_APP_BACKEND_URL;
export const PUBLIC_BASE_URL = "";

const cache: { [key: string]: any } = {};

export const fetchPublic = (path: string): Promise<any> => {
  const url = `${PUBLIC_BASE_URL}${path}`;

  // Check if the data for this URL is already in cache
  if (cache[url]) {
    // Return the cached data as a promise
    return Promise.resolve(cache[url]);
  }

  // If not in cache, fetch it and then cache the result
  return fetch(url)
    .then((res) => res.json())
    .then((data) => {
      cache[url] = data; // Cache the fetched data
      return data;
    });
};

export const fetchSound = (path: string) => {
  return fetch(`${PUBLIC_BASE_URL}${path}`).then((res) => res.url);
};
