import { Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { FunctionComponent, useContext } from "react";
import CustomerSearchField from "./SearchField/CustomerSearchField";
import AppStateContext from "../../../contexts/AppStateContext";
import { UserType } from "../../../types/users/UserTypes";
import { Add, Person } from "@mui/icons-material";

type PropsType = {
  customer?: UserType;
  setCustomer?: (customer: UserType) => void;
  disabled?: boolean;
};

const AccountInfoTable: FunctionComponent<PropsType> = ({
  customer,
  setCustomer,
  disabled,
}) => {
  const { setShowUserModal } = useContext(AppStateContext);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      ></div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ borderBottom: "none" }}>
              <div
                style={{
                  padding: "1rem",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <span>Customer Name:</span>{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {customer?.firstName} {customer?.lastName}
                  </span>
                </div>
                <div>
                  <span>Email: </span>
                  <span style={{ fontWeight: "bold" }}>{customer?.email}</span>
                </div>
              </div>
            </TableCell>
            {!disabled && (
              <TableRow>
                <TableCell style={{ borderBottom: "none" }}>
                  <CustomerSearchField onClick={setCustomer} />
                </TableCell>
                <TableCell style={{ borderBottom: "none" }}>
                  <Button
                    variant="contained"
                    onClick={() => setShowUserModal(true)}
                    sx={{
                      borderRadius: 0,
                      maxHeight: 40,
                      width: "2rem",
                      background: "#E0A900",
                    }}
                  >
                    <Add /> <Person />
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
export default AccountInfoTable;
