import React from "react";
import styles from "./FileInput.module.css";

interface FileInputProps {
  handleAddFileResource: (files: File[]) => void;
}

const FileInput: React.FC<FileInputProps> = ({ handleAddFileResource }) => {
  return (
    <>
      <label className={styles.fileInputLabel}>
        <input
          type="file"
          multiple
          onChange={(e) => {
            if (e.target.files) {
              handleAddFileResource(Array.from(e.target.files));
            }
            // Clear the input so that the same file can be uploaded again
            e.target.value = "";
          }}
        />
        <span className={styles.fileInputButton}>Upload</span>
      </label>
    </>
  );
};

export default FileInput;
