import { Address, AddressType } from "../AddressTypes";

export enum AccessEnum {
  ADMIN = 5,
  MANAGER = 4,
  OPERATOR = 3,
  PARTNER = 2,
  CUSTOMER = 1,
  UNVERIFIED = 0,
}
export enum StatusEnum {
  Enabmled = "Enabled",
  Disabled = "Disabled",
}
export type UserType = {
  id: number;
  created?: string;
  modified?: string;
  lastLogin?: string;
  lastActionDate?: string;
  email: string;
  invoiceEmail?: string;
  defaultBillingAddress?: AddressType;
  defaultShippingAddress?: AddressType;
  status?: StatusEnum;
  firstName: string;
  lastName: string;
  accessLevel: AccessEnum;
};

export type CreateUserType = {
  firstName: string;
  lastName: string;
  email: string;
  address?: Address;
  shippingAddress?: Address;
  accessLevel?: AccessEnum;
};

export type UserListType = {
  items: UserType[];
  page: number;
  pageSize: number;
  total: number;
};
