import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { toast, ToastContainer, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";

interface ToastState {
  type: "info" | "error" | "warning" | "success";
  message: ReactNode;
  keep?: boolean;
  location?: ToastPosition;
  time?: number;
}

const ToastContext = createContext<
  | {
      addToast: (toast: ToastState) => void;
      toastPosition: ToastPosition;
      setToastPosition: (position: ToastPosition) => void;
    }
  | undefined
>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toastPosition, setToastPosition] =
    useStateWithLocalStorage<ToastPosition>(
      toast.POSITION.BOTTOM_RIGHT,
      "toastPosition"
    );

  const addToast = (toastState: ToastState) => {
    const id = uuidv4();
    const onClose = () => toast.dismiss(id);
    toast[toastState.type](toastState.message, {
      toastId: id,
      position: toastState.location ?? toastPosition,
      autoClose: toastState.keep ? false : toastState.time ?? 5000,
      closeButton: true,
      onClose,
    });
  };

  return (
    <ToastContext.Provider
      value={{ addToast, toastPosition, setToastPosition }}
    >
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
