import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

type PropsType = {
  title: string;
  icon?: React.ReactNode;
  amount: React.ReactNode;
};

const OrderStatusBox: React.FC<PropsType> = ({ title, icon, amount }) => {
  const loading =
    amount === undefined ||
    amount === null ||
    amount === "" ||
    Number.isNaN(amount);

  return (
    <div
      style={{
        minWidth: "130px",
        height: "130px",
        background: "#E9F5F3",
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
        borderRadius: "4px",
        padding: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0.5rem",
        }}
      >
        <span>{icon}</span>
        <span>{title}</span>
      </div>
      <div>
        <span
          style={{
            fontWeight: "700",
            fontSize: "3rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "0.9rem",
              }}
            >
              <CircularProgress size="2.5rem" />
            </span>
          ) : (
            amount
          )}
        </span>
      </div>
    </div>
  );
};
export default OrderStatusBox;
