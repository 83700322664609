import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
//@ts-ignore
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Badge, Dialog, IconButton, Typography } from "@mui/material";
import { primaryColor } from "../../assets/colors";
import "./orderManagement.css";
import OrderStatusButton from "./OrderStatusButton";
import { formatter } from "../../util/formatter";
import { reformatDate } from "../../util/formatter";
import { Receipt, AltRoute } from "@mui/icons-material";
import AppStateContext from "../../contexts/AppStateContext";
import { useHistory } from "react-router";
import { OrderItemType, OrderType, SimpleOrderType } from "types/OrderTypes";
import OrderConfigRow from "./OrderConfigRow";
import { ProductTypeEnum } from "types/products/ProductCommandsType";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as OpenOrderIcon } from "../../assets/svg/openOrder.svg";
import { apiGetProductions } from "util/network/Productions";
import { ProductionListType } from "types/ProductionType";
import OtherOrderItemRow from "components/OrderView/OtherOrderItemRow";
import RequisitionComponent from "components/Requisition/RequisitionComponent";
import InvoiceModal from "components/Modals/InvoiceModal";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import { ReactComponent as PickupIcon } from "../../assets/svg/pickup.svg";
import { ReactComponent as GlsIcon } from "../../assets/svg/glslogo.svg";
import { ReactComponent as PostNordIcon } from "../../assets/svg/postnord.svg";
import CopyEmail from "components/Copy/CopyEmail";
import CopyPhone from "components/Copy/CopyPhone";
import { apiGetOrder, apiGetOrderInvoice } from "util/network/Orders";
import FeeOrderItemRow from "components/OrderView/FeeOrderItemRow";
import { ReactComponent as CommentIcon } from "../../assets/svg/commentOrder.svg";
import { ReactComponent as CommentIconFilled } from "../../assets/svg/commentOrderFilled.svg";

const d = new Date();

const today = d
  .toLocaleTimeString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  .substring(0, 10);

const threeDaysAgo = new Date(d.setDate(d.getDate() - 3))
  .toLocaleTimeString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  .substring(0, 10);

type PropsType = {
  orderIn: SimpleOrderType;
};
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));
const OrderRow: FunctionComponent<PropsType> = ({ orderIn }) => {
  const history = useHistory();
  const { token } = useContext(AppStateContext);
  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");
  const [open, setOpen] = useState(false);
  //const [modelData, setModelData] = useState<any[]>([]);
  const [invoiceExist, setInvoiceExist] = useState<boolean>();
  const [reqProjects, setReqProjects] = useState<OrderType | null>(null);
  const [itemState, setItemState] = useState<ProductionListType>();
  const [order, setOrder] = useState<OrderType>();

  useEffect(() => {
    if (open) {
      if (!token) return;
      if (!itemState || itemState?.items.length == 0) {
        apiGetProductions(
          token,
          1,
          orderIn.items.length,
          `orderId=${orderIn.id}`,
          "order_id",
          "asc"
        ).then((res) => {
          setItemState(res);
        });
      }
      if (!order) {
        apiGetOrder(token, orderIn.id).then((res) => {
          setOrder(res);
        });
      }
    }
  }, [open]);

  useEffect(() => {
    if (orderIn?.id) {
      const alreadyOpen = sessionStorage.getItem("OpenedOrder");
      if (alreadyOpen) {
        if (parseInt(alreadyOpen) === orderIn.id) {
          setOpen(true);
        }
      }
      if (orderIn.invoiceId) {
        setInvoiceExist(true);
      }
    }
  }, [orderIn, token]);

  const closeRequisition = () => {
    setReqProjects(null);
  };

  const cncItems = useMemo(() => {
    return order?.items.filter(
      (i) => i.product.productType == ProductTypeEnum.CNC
    );
  }, [order]);

  const print3dItems = useMemo(() => {
    return order?.items.filter(
      (i) => i.product.productType == ProductTypeEnum.PRINT3D
    );
  }, [order]);

  const sheetItems = useMemo(() => {
    return order?.items.filter(
      (i) => i.product.productType == ProductTypeEnum.SHEET
    );
  }, [order]);

  const cncTableContent = useMemo(() => {
    if (!cncItems || cncItems?.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                CNC
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cncItems.map((item, index) => {
            const data = {
              ...item.product.details,
              finish: (item.product.details as any).finish.split(","),
            };
            const prod = itemState?.items.find(
              (i) => i.productId == item.productId
            );
            return <OrderConfigRow item={item} data={data} production={prod} />;
          })}
        </TableBody>
      </>
    );
  }, [cncItems, itemState]);

  const print3dTableContent = useMemo(() => {
    if (!print3dItems || print3dItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Print3D
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {print3dItems.map((item, index) => {
            const data = {
              ...item.product.details,
              finish: (item.product.details as any).finish.split(","),
            };
            const prod = itemState?.items.find(
              (i) => i.productId == item.productId
            );
            return <OrderConfigRow item={item} data={data} production={prod} />;
          })}
        </TableBody>
      </>
    );
  }, [print3dItems, itemState]);

  const sheetTableContent = useMemo(() => {
    if (!sheetItems || sheetItems.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Sheet Metal
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sheetItems.map((item, index) => {
            const data = {
              ...item.product.details,
              finish: (item.product.details as any).finish.split(","),
            };
            const prod = itemState?.items.find(
              (i) => i.productId == item.productId
            );
            return <OrderConfigRow item={item} data={data} production={prod} />;
          })}
        </TableBody>
      </>
    );
  }, [sheetItems, itemState]);

  const otherTableContent = useMemo(() => {
    if (!order) return null;
    const items = order.items.filter(
      (i) => i.product.productType == ProductTypeEnum.OTHER
    );
    if (items.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Other
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: OrderItemType, index: number) => {
            return <OtherOrderItemRow key={index} item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [order]);

  const feeTableContent = useMemo(() => {
    if (!order) return null;
    const items = order.items.filter(
      (i) => i.product.productType == ProductTypeEnum.FEE
    );
    if (items.length === 0) return null;
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={8}>
              <Typography
                fontWeight={"bold"}
                variant={"body1"}
                style={{ color: "#858585" }}
              >
                Fees
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: OrderItemType, index: number) => {
            return <FeeOrderItemRow key={index} item={item} />;
          })}
        </TableBody>
      </>
    );
  }, [order]);

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  const invoiceButton = useMemo(() => {
    return invoiceExist ? (
      <Tooltip title="Afsendt">
        <IconButton
          onClick={() => {
            apiGetOrderInvoice(token, orderIn.id).then((res) => {
              if (res) {
                const url = `https://secure.e-conomic.com/secure/include/visfaktura.asp?showdoc=yes&bogf=1&faknr=${res.id}`;
                window.open(url, "_blank");
              }
            });
          }}
        >
          <Receipt color="primary" />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title={"Invoice doesn't exist yet"}>
        <IconButton
          onClick={() => {
            setInvoiceModalOpen(true);
          }}
        >
          <Receipt sx={{ color: "LightGrey" }} />
        </IconButton>
      </Tooltip>
    );
  }, [invoiceExist]);

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const renderItemCount = (count: number, label: string) => {
    if (count > 0) {
      return (
        <span style={{ marginRight: "0.5rem" }}>
          {count} {label}
        </span>
      );
    }
    return null;
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        open={reqProjects ? true : false}
        onClose={(e, reasom) => {
          if (reasom !== "backdropClick") {
            closeRequisition();
          }
        }}
      >
        {reqProjects ? (
          <RequisitionComponent
            prodObjIn={reqProjects}
            closeRequisition={closeRequisition}
            prodType="order"
          ></RequisitionComponent>
        ) : null}
      </Dialog>
      <InvoiceModal
        isOpen={invoiceModalOpen}
        onClose={() => setInvoiceModalOpen(false)}
        onSubmit={(created) => {
          setInvoiceExist(created);
        }}
        order={orderIn}
      />
      <TableRow
        sx={{
          background: open ? "#A9D6CE" : "#F5F5F5",
        }}
        className={"orderRow"}
      >
        <TableCell align={"left"} onClick={() => setOpen((open) => !open)}>
          <div style={{ width: "fit-content" }} onClick={handleClick}>
            <OrderStatusButton
              currentStatus={orderIn.status}
              entityID={orderIn.id}
              onChange={(status: string) => {
                if (status === "COMPLETE") {
                  setInvoiceExist(true);
                }
              }}
            />
          </div>
        </TableCell>
        <TableCell align="right" onClick={() => setOpen((open) => !open)}>
          <span style={{ display: "flex", flexDirection: "row" }}>
            <span>
              <LightTooltip title="More..">
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem(
                      "OpenedOrder",
                      orderIn.id.toString()
                    );
                    history.push(`/ordrestyring/${orderIn.id}`);
                  }}
                  color={"primary"}
                  size="small"
                >
                  <Typography fontWeight={"bold"}>
                    <OpenOrderIcon />
                  </Typography>
                </IconButton>
              </LightTooltip>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "0.2rem",
                paddingRight: "0.5rem",
              }}
            >
              {!order?.message ? (
                <CommentIcon />
              ) : (
                <LightTooltip title={order?.message}>
                  <CommentIconFilled />
                </LightTooltip>
              )}
            </span>
            <span
              style={{
                padding: "0",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span style={{ padding: 0, display: "flex" }}>
                {reformatDate(orderIn.created).substring(0, 10) <= today &&
                reformatDate(orderIn.created).substring(0, 10) >=
                  threeDaysAgo ? (
                  <>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      overlap={"circular"}
                      badgeContent={
                        orderIn.status?.toString().toUpperCase() !== "REVIEW"
                          ? null
                          : "NEW"
                      }
                      color={"primary"}
                      style={{ left: "70%", top: "40%" }}
                    ></Badge>
                    <span>#{orderIn.orderNo}</span>
                  </>
                ) : (
                  <span>#{orderIn.orderNo}</span>
                )}
              </span>
              <span style={{ fontSize: "0.7rem", padding: "0" }}>
                {reformatDate(orderIn.created)}
              </span>
            </span>
          </span>
        </TableCell>
        <TableCell onClick={() => setOpen((open) => !open)}>
          <span style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{ lineHeight: "0", display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  paddingRight: "0.5rem",
                  lineHeight: "0",
                }}
                onClick={handleClick}
              >
                <CopyPhone phone={orderIn.billingAddress.phone} />
              </span>
              <span
                style={{ paddingRight: "0.5rem", lineHeight: "0" }}
                onClick={handleClick}
              >
                <CopyEmail
                  email={orderIn.billingAddress.email}
                  subject={`Ordre: #${orderIn.id}`}
                />
              </span>
            </span>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span>{orderIn.firstName + " " + orderIn.lastName}</span>
              <span style={{ fontSize: "0.7rem" }}>
                {orderIn.billingAddress?.company}
              </span>
            </span>
          </span>
        </TableCell>
        <TableCell align="left" onClick={() => setOpen((open) => !open)}>
          {orderIn.poNumber}
        </TableCell>
        <TableCell align="center" onClick={() => setOpen((open) => !open)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ paddingRight: "0.5rem" }}>
              {orderIn.shippingMethod === "pickup" ? (
                <Tooltip title="Pickup">
                  <PickupIcon />
                </Tooltip>
              ) : orderIn.shippingMethod === "tnt_before_12" ? (
                <Tooltip title="GLS">
                  <GlsIcon />
                </Tooltip>
              ) : orderIn.shippingMethod.startsWith("postnord") ? (
                <Tooltip title="PostNord">
                  <PostNordIcon />
                </Tooltip>
              ) : (
                ""
              )}
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <span style={{}}>{reformatDate(orderIn.leadTimeEndDate)}</span>
              <span
                style={{
                  fontSize: "0.7rem",
                }}
              >{`${orderIn.leadTimeOption} ${orderIn.leadTimeDays}wd`}</span>
            </span>
          </div>
        </TableCell>
        <TableCell align="right" onClick={() => setOpen((open) => !open)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "1em",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ paddingRight: "0.5rem" }}>
                  {formatter.format(
                    (orderIn?.subtotal ?? 0) + (orderIn?.shipping ?? 0)
                  )}
                </span>
                <span style={{ fontSize: "0.7rem" }}>
                  {renderItemCount(orderIn.itemSummary.cnc, "CNC")}
                  {renderItemCount(orderIn.itemSummary.print3d, "3D")}
                  {renderItemCount(orderIn.itemSummary.sheet, "Sheet")}
                  {renderItemCount(orderIn.itemSummary.other, "Other")}
                </span>
              </div>
            </span>

            <div>
              <span>{invoiceButton}</span>
              <span style={{ paddingRight: "0.3rem" }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!order) {
                      apiGetOrder(token, orderIn.id).then((res) => {
                        setOrder(res);
                        setReqProjects(res);
                      });
                    } else {
                      setReqProjects(order);
                    }
                  }}
                >
                  <LightTooltip title="Outsource">
                    <AltRoute color={"info"}></AltRoute>
                  </LightTooltip>
                </IconButton>
              </span>
            </div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > *": {
            background: open ? "WhiteSmoke" : "",
            borderRadius: open ? "0 0 5px 5px" : "",
            borderBottom: open ? "2px solid" + primaryColor[0] : "",
            borderLeft: open ? "2px solid" + primaryColor[0] : "",
            borderRight: open ? "2px solid" + primaryColor[0] : "",
          },
        }}
      >
        <TableCell colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                {cncTableContent}
                {print3dTableContent}
                {sheetTableContent}
                {otherTableContent}
                {feeTableContent}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderRow;
