import React, { FunctionComponent } from "react";
import { useToast } from "contexts/ToastContext";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as EmailIcon } from "../../assets/svg/email.svg";
import { Email } from "@mui/icons-material";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

type EmailProps = {
  email: string;
  subject: string;
  blackIcon?: boolean;
};

const CopyEmail: FunctionComponent<EmailProps> = ({
  email,
  subject,
  blackIcon,
}) => {
  const { addToast } = useToast();
  const mailtoLink = `${email}?subject=${encodeURIComponent(subject)}`;

  const handleCopyEmail = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(email);
    addToast({
      type: "info",
      message: "Email copied to the clipboard",
    });
  };

  const handleEmailIconClick = (e: any) => {
    window.open("mailto:" + mailtoLink, "_self");
  };
  return (
    <LightTooltip
      title={
        <a
          href={"mailto: " + mailtoLink}
          onClick={handleCopyEmail}
          style={{
            color: "#028B81",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {email}
        </a>
      }
    >
      {blackIcon ? (
        <Email onClick={handleEmailIconClick} />
      ) : (
        <EmailIcon
          style={{ cursor: "pointer" }}
          onClick={handleEmailIconClick}
        />
      )}
    </LightTooltip>
  );
};
export default CopyEmail;
