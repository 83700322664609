import {
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { Email, Face } from "@mui/icons-material";
import {
  REGEX_EMAIL,
  REGEX_FIRSTNAME,
  REGEX_LASTNAME,
} from "../../../util/RegExUtil";
import styles from "../../order_management/orderedit_components/Styles/loginPageStyle.module.css";
import {
  AccessEnum,
  CreateUserType,
  UserType,
} from "../../../types/users/UserTypes";
import ErrorCollapse from "components/Generic/ErrorCollapse";

type PropsType = {
  existing?: UserType | null;
  setUser: Function;
  showEmployeeModal: boolean;
  setShowEmployeeModal: Function;
  createUser: Function;
};

const EmployeeModal: FunctionComponent<PropsType> = ({
  existing,
  setUser,
  showEmployeeModal,
  setShowEmployeeModal,
  createUser,
}) => {
  const [first_name, setFirstName] = useState<string>("");
  const [last_name, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [access_level, setAccessLevel] = useState<AccessEnum>(
    AccessEnum.MANAGER
  );
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorNames, setErrorNames] = useState<string[]>([]);

  useEffect(() => {
    if (existing) {
      setFirstName(existing.firstName);
      setLastName(existing.lastName);
      setEmail(existing?.email);
    } else if (existing === null) {
      setFirstName("");
      setLastName("");
      setEmail("");
    }
  }, [existing]);

  const handleSubmit = () => {
    if (!email || !first_name || !last_name) {
      if (!email) errorNames.push("Email er påkrævet");
      if (!first_name) errorNames.push("Fornavn er påkrævet");
      if (!last_name) errorNames.push("Efternavn er påkrævet");
      setErrorOpen(true);
      return;
    }

    const userCreation: CreateUserType = {
      firstName: first_name,
      lastName: last_name,
      email: email,
      accessLevel: access_level,
    };
    createUser(userCreation);
  };

  const handleClose = () => {
    setUser(null);
    setShowEmployeeModal(false);
  };

  return (
    <div>
      <Dialog open={showEmployeeModal} onClose={() => handleClose()}>
        <div className={styles.userModalContainer}>
          <div className={styles.pdLeft}>
            <h3>{"BRUGER OPLYSNINGER"}</h3>
            <span className={styles.requiredText}>{"*krævede felter"}</span>
          </div>
          <div className={styles.pd}>
            <TextField
              label={"Fornavn: "}
              id={"fornavn"}
              required
              fullWidth={false}
              type="text"
              value={first_name}
              error={!REGEX_FIRSTNAME.test(first_name)}
              onChange={(e: any) => setFirstName(e.target.value)}
              variant="standard"
              size="small"
              inputProps={{
                maxLength: 30,
              }}
            />
            <TextField
              label={"Efternavn: "}
              id={"efternavn"}
              required
              fullWidth={false}
              type="text"
              variant="standard"
              size="small"
              value={last_name}
              error={!REGEX_LASTNAME.test(last_name)}
              onChange={(e: any) => setLastName(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={styles.inputAdornment}
                  >
                    <Face className={styles.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 30,
              }}
            />
            <br />
            <br />
            <TextField
              label={"Email: "}
              id="email"
              size="small"
              variant="standard"
              type="email"
              required={true}
              fullWidth={false}
              value={email}
              error={!REGEX_EMAIL.test(email) && email.length > 0}
              helperText={
                !REGEX_EMAIL.test(email) && email.length > 0
                  ? "Invalid email address"
                  : ""
              }
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={styles.inputAdornment}
                  >
                    <Email className={styles.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <h5 className={styles.smallText}>{"Vælg rolle"}</h5>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => {
                // @ts-ignore
                setAccessLevel(e.target.value as keyof typeof AccessEnum);
                // console.log(e.target.value as keyof typeof AccessEnum)
              }}
              defaultValue={access_level.toString()}
            >
              <MenuItem value={AccessEnum.MANAGER}>MANAGER</MenuItem>
              <MenuItem value={AccessEnum.OPERATOR}>OPERATOR</MenuItem>
              <MenuItem value={AccessEnum.UNVERIFIED}>UNVERIFIED</MenuItem>
            </Select>
            <br />
            <div className={styles.flRg}>
              <Button
                color={"error"}
                variant="contained"
                className={styles.mrrg}
                onClick={() => handleClose()}
              >
                Annuller
              </Button>
              <Button variant="contained" onClick={() => handleSubmit()}>
                Opret
              </Button>
            </div>
          </div>
        </div>
        <ErrorCollapse
          open={errorOpen}
          setOpen={setErrorOpen}
          errorNames={errorNames}
          setErrorNames={setErrorNames}
        />
      </Dialog>
    </div>
  );
};

export default EmployeeModal;
