import { useContext, useEffect, useState } from "react";
import OrderTable from "../OrderTable";
import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import { primaryColor } from "../../../assets/colors";
import AppStateContext from "../../../contexts/AppStateContext";
import { useHistory } from "react-router";
import SearchField from "components/SearchField";
import { apiCreateDraftCart } from "../../../util/network/Carts";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import StatusBox from "components/Dashboard/StatusBox";
import { apiGetOrdersSummary } from "util/network/Orders";
import { OrderSummaryType } from "types/OrderTypes";
import { formatter, formatterNoDec } from "util/formatter";

const OrderManagementUI = () => {
  const { token } = useContext(AppStateContext);
  const [orderSummary, setOrderSummary] = useState<OrderSummaryType>();

  const history = useHistory();

  const [loadingCreateDraft, setLoadingCreateDraft] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useStateWithSessionStorage<string>(
    "",
    "orderSearchVal"
  );

  useEffect(() => {
    // Periodically check every minute for new quotes
    if (token) {
      apiGetOrdersSummary(token).then((res) => {
        setOrderSummary(res);
      });
    }
    const interval = setInterval(() => {
      if (token) {
        apiGetOrdersSummary(token).then((res) => {
          setOrderSummary(res);
        });
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [token]);

  return (
    <Grid
      container
      // style={{ padding: "0.5em", paddingTop: "0.5em" }}
    >
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
            justifyContent: "space-between",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;Ordreoversigt
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div>
              <SearchField onChange={setSearchValue} value={searchValue} />
            </div>
            <div style={{ padding: "0 1rem 0 1rem" }}>
              <Button
                sx={{
                  borderRadius: 0,
                  maxHeight: 40,
                  width: "10rem",
                  background: "#E0A900",
                }}
                onClick={(e) => {
                  setLoadingCreateDraft(true);
                  if (token) {
                    apiCreateDraftCart(token).then((res) => {
                      setLoadingCreateDraft(false);
                      localStorage.removeItem("orderEditing");
                      history.push(`/quotes/${res.id}`);
                    });
                  }
                }}
                variant="contained"
              >
                {loadingCreateDraft ? (
                  <CircularProgress
                    //left={-20}
                    //top={10}
                    size={"2em"}
                    style={{
                      margin: "1em",
                      color: primaryColor[0],
                    }}
                  />
                ) : (
                  <h4 color="white">New Order</h4>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Grid>
      {/* <Grid
        item
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        sx={{
          display: "flex",
          padding: "1.4em",
        }}
        xs
        md
        lg
      > */}
      {/* <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={overrideStatus}
                onChange={(val) => {
                  console.log(val.target);
                  setOverrideStatus(val.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Override Status"
          />
        </FormGroup> */}

      {/* {lastDraft && (
            <Button
              sx={{
                borderRadius: 10,
                flexShrink: 0.6,
                fontStyle: "bold",
                marginRight: "1em",
                maxHeight: 50,
                flexWrap: "no-wrap",
              }}
              onClick={resumeDraft}
              variant="outlined"
            >
              <h5 color="white">Fortsæt redigering</h5>
            </Button>
          )} */}

      {/* </Grid> */}
      <Grid
        item
        xs={8}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem 1.5rem 0 1.5rem",
        }}
      >
        <StatusBox
          title={"Active Order Value"}
          amount={formatterNoDec.format(orderSummary?.activeOrdersValue ?? 0)}
        />
        <StatusBox title={"Active"} amount={orderSummary?.activeOrders} />
      </Grid>
      <Grid item xs={12} style={{ padding: "0.5rem 1.5rem 0 1.5rem" }}>
        <OrderTable searchValue={searchValue} />
      </Grid>
    </Grid>
  );
};

export default OrderManagementUI;
