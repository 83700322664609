import { ArrowDropDown, Cancel, CheckCircle } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import AppStateContext from "contexts/AppStateContext";
import { useContext, useMemo, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { RequisitionStatusEnum } from "types/RequisitionTypes";
import { apiUpdateRequisition } from "util/network/Requisitions";

type PropsType = {
  currentStatus: RequisitionStatusEnum;
  entityID: number;
  onChange: (val: string) => void;
  options: string[];
};

const RequisitionStatusSelector: React.FC<PropsType> = ({
  currentStatus,
  entityID,
  onChange,
  options,
}) => {
  const { token } = useContext(AppStateContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<RequisitionStatusEnum>(currentStatus);
  const [anchorEl, setAnchorEl] = useState(null);

  const override = Boolean(sessionStorage.getItem("debug") === "true");

  const open = Boolean(anchorEl);

  // Open menu
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // Close menu without selection
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Open confirmation dialog
  const handleMenuItemClick = (status: RequisitionStatusEnum) => {
    console.log(status);
    setStatus(status);
    setOpenConfirm(true);
    setAnchorEl(null);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const dialogConfirm = () => {
    if (!token) {
      return;
    }
    setOpenConfirm(false);

    apiUpdateRequisition(
      token,
      entityID,
      {
        state: status,
      },
      false
    ).then((res) => {
      onChange(status);
    });
  };

  const dialogCancel = () => {
    setOpenConfirm(false);
    setStatus(currentStatus);
  };

  const menuItems = useMemo(() => {
    return options.map((option, index) => (
      <MenuItem
        key={index}
        selected={status === option}
        onClick={(event: any) => {
          event.stopPropagation();
          const selected =
            RequisitionStatusEnum[option as keyof typeof RequisitionStatusEnum];
          if (selected) handleMenuItemClick(selected);
        }}
      >
        {option}
      </MenuItem>
    ));
  }, [override, status]);

  return (
    <div>
      {openConfirm ? (
        <SweetAlert
          info
          title={`Confirm status change for ${entityID} to ${status.toLowerCase()}`}
          customButtons={
            <>
              <Button
                size={"large"}
                variant={"contained"}
                color={"secondary"}
                startIcon={<Cancel />}
                onClick={() => {
                  dialogCancel();
                }}
              >
                Nej
              </Button>
              <Button
                size={"large"}
                variant={"contained"}
                color={"primary"}
                endIcon={<CheckCircle />}
                onClick={() => {
                  dialogConfirm();
                }}
              >
                Ja
              </Button>
            </>
          }
          onConfirm={() => {}}
        />
      ) : null}
      <Button
        disabled={status === RequisitionStatusEnum.CANCELLED}
        endIcon={
          status !== RequisitionStatusEnum.CANCELLED ? <ArrowDropDown /> : null
        }
        color={"primary"}
        onClick={handleClick}
        style={{
          fontWeight: "bold",
          borderRadius: "5px",
          border:
            status === RequisitionStatusEnum.REQUEST
              ? "1px solid #075E5B"
              : status === RequisitionStatusEnum.RESPONSE
              ? "1px solid #E5CB00"
              : status === RequisitionStatusEnum.PROCESSING
              ? "1px solid #41A8A1"
              : status === RequisitionStatusEnum.PAUSED
              ? "1px solid #848585"
              : status === RequisitionStatusEnum.PICKUP
              ? "1px solid #E1A900"
              : status === RequisitionStatusEnum.RECEIVED
              ? "1px solid #7FBAB9"
              : status === RequisitionStatusEnum.CANCELLED
              ? "1px solid #848585"
              : undefined,
          color:
            status === RequisitionStatusEnum.REQUEST
              ? "#075E5B"
              : status === RequisitionStatusEnum.RESPONSE
              ? "#E5CB00"
              : status === RequisitionStatusEnum.PROCESSING
              ? "#41A8A1"
              : status === RequisitionStatusEnum.PAUSED
              ? "#848585"
              : status === RequisitionStatusEnum.PICKUP
              ? "#E1A900"
              : status === RequisitionStatusEnum.RECEIVED
              ? "#7FBAB9"
              : status === RequisitionStatusEnum.CANCELLED
              ? "#848585"
              : undefined,
          background:
            status === RequisitionStatusEnum.REQUEST
              ? "#e6eeee"
              : status === RequisitionStatusEnum.RESPONSE
              ? "#fcf9e5"
              : status === RequisitionStatusEnum.PROCESSING
              ? "#ecf6f5"
              : status === RequisitionStatusEnum.PAUSED
              ? "#f2f2f2"
              : status === RequisitionStatusEnum.PICKUP
              ? "#fcf6e5"
              : status === RequisitionStatusEnum.RECEIVED
              ? "#f2f8f8"
              : status === RequisitionStatusEnum.CANCELLED
              ? "#f2f2f2"
              : undefined,
        }}
      >
        {status}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default RequisitionStatusSelector;
