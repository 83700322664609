import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomLineGraph from "./CustomLineChart";
import styles from "./chartStyle.module.css";

type SalesDataType = { name: string; value: number };

type SaleProps = {
  completedParts: SalesDataType[];
  completedItems: SalesDataType[];
  completedPartsSum: SalesDataType[];
  currentMonthName?: React.ReactChild;
  value?: string;
  models?: number;
  cncParts?: number;
};

const ProductionReportChart: React.FC<SaleProps> = ({
  completedParts,
  completedItems,
  completedPartsSum,
  currentMonthName,
  value,
  cncParts,
  models,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const totalTabs = 3;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextTabValue = (tabValue + 1) % totalTabs;
      setTabValue(nextTabValue);
    }, 300000);

    return () => {
      clearInterval(intervalId);
    };
  }, [tabValue]);

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={`${styles.tabs}`}
      >
        <Tab
          label={
            <div
              className={`${styles.tab} ${
                tabValue === 0 ? styles.tab__active : ""
              }`}
            >
              <span className={`${styles.tab__name}`}>Value</span>
              <span className={`${styles.tab__number}`}>{value}</span>
            </div>
          }
        />
        <Tab
          label={
            <div
              className={`${styles.tab} ${
                tabValue === 1 ? styles.tab__active : ""
              }`}
            >
              <span className={`${styles.tab__name}`}>Models</span>
              <span className={`${styles.tab__number}`}>{models}</span>
            </div>
          }
        />
        <Tab
          label={
            <div
              className={`${styles.tab} ${
                tabValue === 2 ? styles.tab__active : ""
              }`}
            >
              <span className={`${styles.tab__name}`}>CNC Parts</span>{" "}
              <span className={`${styles.tab__number}`}>{cncParts}</span>
            </div>
          }
        />
      </Tabs>
      <Box>
        {tabValue === 0 && <CustomLineGraph data={completedPartsSum} price />}
        {tabValue === 1 && <CustomLineGraph data={completedItems} />}
        {tabValue === 2 && <CustomLineGraph data={completedParts} />}
      </Box>
    </Box>
  );
};
export default ProductionReportChart;
