import { FunctionComponent, useCallback, useEffect } from "react";
import {
  REGEX_ADDRESS,
  REGEX_CITY,
  REGEX_EMAIL,
  REGEX_FIRMNAME,
  REGEX_FIRSTNAME,
  REGEX_LASTNAME,
  REGEX_EAN,
  REGEX_CVRNUMBER,
  REGEX_ZIPCODE,
} from "../../../util/RegExUtil";
import { Button, Card, InputAdornment, TextField } from "@mui/material";
import {
  BusinessCenter,
  Email,
  Face,
  House,
  LocationCity,
  Phone,
} from "@mui/icons-material";
import stylesCard from "../../../components/Card/css/card.module.css";
import styles from "../orderedit_components/Styles/loginPageStyle.module.css";

type PropsType = {
  shippingCompany: string;
  shippingCvr: string;
  shippingFirstName: string;
  shippingLastName: string;
  shippingAddressName: string;
  shippingZipCode: string;
  shippingCity: string;
  shippingPhone: string;
  shippingEmail: string;
  billingCompany: string;
  billingCvr: string;
  billingFirstName: string;
  billingLastName: string;
  billingAddressName: string;
  billingZipCode: string;
  billingCity: string;
  billingPhone: string;
  billingEmail: string;
  invoiceEmail: string;
  setShippingCompany?: Function;
  setShippingCvr?: Function;
  setShippingFirstName?: Function;
  setShippingLastName?: Function;
  setShippingAddressName?: Function;
  setShippingZipCode?: Function;
  setShippingCity?: Function;
  setShippingPhone?: Function;
  setShippingEmail?: Function;
  setBillingCompany?: Function;
  setBillingCvr?: Function;
  setBillingFirstName?: Function;
  setBillingLastName?: Function;
  setBillingAddressName?: Function;
  setBillingZipCode?: Function;
  setBillingCity?: Function;
  setBillingPhone?: Function;
  setBillingEmail?: Function;
  setInvoiceEmail?: Function;
  poNumber?: string;
  setPoNumber?: Function;
  EANNumber: string;
  setEANNumber?: Function;
  useSameShipping?: boolean;
  setUseSameShipping?: Function;
  disabled?: boolean;
  inputErr: boolean;
  setInputErr: Function;
  paymentMethod?: string;
  fillAddressFields?: Function;
};

const AddressFields: FunctionComponent<PropsType> = ({
  shippingCompany,
  shippingCvr,
  shippingFirstName,
  shippingLastName,
  shippingAddressName,
  shippingZipCode,
  shippingCity,
  shippingPhone,
  shippingEmail,
  billingCompany,
  billingCvr,
  billingFirstName,
  billingLastName,
  billingAddressName,
  billingZipCode,
  billingCity,
  billingPhone,
  billingEmail,
  invoiceEmail,
  setShippingCompany,
  setShippingCvr,
  setShippingFirstName,
  setShippingLastName,
  setShippingAddressName,
  setShippingZipCode,
  setShippingCity,
  setShippingPhone,
  setShippingEmail,
  setBillingCompany,
  setBillingCvr,
  setBillingFirstName,
  setBillingLastName,
  setBillingAddressName,
  setBillingZipCode,
  setBillingCity,
  setBillingPhone,
  setBillingEmail,
  setInvoiceEmail,
  poNumber,
  setPoNumber,
  EANNumber,
  setEANNumber,
  useSameShipping,
  setUseSameShipping,
  disabled,
  setInputErr,
  paymentMethod,
  fillAddressFields,
}) => {
  const formErr = useCallback(() => {
    const isBillingValid =
      REGEX_FIRMNAME.test(billingCompany) &&
      REGEX_FIRSTNAME.test(billingFirstName) &&
      REGEX_EMAIL.test(billingEmail) &&
      REGEX_ADDRESS.test(billingAddressName) &&
      REGEX_CITY.test(billingCity) &&
      (paymentMethod === "ean" ? REGEX_EAN.test(EANNumber) : true);

    if (!useSameShipping) {
      const isShippingValid =
        REGEX_FIRMNAME.test(shippingCompany) &&
        REGEX_FIRSTNAME.test(shippingFirstName) &&
        REGEX_LASTNAME.test(shippingLastName) &&
        REGEX_EMAIL.test(shippingEmail) &&
        REGEX_ADDRESS.test(shippingAddressName) &&
        REGEX_CITY.test(shippingCity) &&
        (paymentMethod === "ean" ? REGEX_EAN.test(EANNumber) : true);
      return !isBillingValid || !isShippingValid;
    } else {
      return !isBillingValid;
    }
  }, [
    billingAddressName,
    billingCity,
    billingCompany,
    billingCvr,
    billingEmail,
    billingFirstName,
    billingLastName,
    billingPhone,
    billingZipCode,
    useSameShipping,
    paymentMethod,
    EANNumber,
    shippingAddressName,
    shippingCity,
    shippingCompany,
    shippingCvr,
    shippingEmail,
    shippingFirstName,
    shippingLastName,
    shippingPhone,
    shippingZipCode,
  ]);

  useEffect(() => {
    setInputErr(formErr());
  }, [formErr, setInputErr]);

  return (
    <Card className={stylesCard.card}>
      <div className={styles.flexRMrg}>
        <span className={styles.draftCardsHeader}>FAKTURERINGSOPLYSNINGER</span>
        <div className={styles.pdLeft1}>
          <Button
            variant="contained"
            onClick={() => fillAddressFields && fillAddressFields()}
          >
            Update address info
          </Button>
        </div>
      </div>
      <div className={styles.mrg}>
        <h3 className={styles.fntWeightBold}>{"Faktureringsadresse"}</h3>
        <h4 className={styles.smallText}>{"*krævede felter"}</h4>

        <form id={"addressSubmit"}>
          <div>
            <div className={styles.flexR}>
              <div className={styles.leftInputContainer}>
                <TextField
                  label={"Firmannavn:"}
                  size="small"
                  id="Firmanavn"
                  required
                  fullWidth={false}
                  onChange={(e) => {
                    if (e.target.value.length < 45 && setBillingCompany) {
                      setBillingCompany(e.target.value);
                    }
                  }}
                  type="text"
                  value={billingCompany}
                  disabled={disabled || !setBillingCompany}
                  error={
                    !REGEX_FIRMNAME.test(billingCompany) &&
                    billingCompany !== ""
                  }
                  helperText={
                    !REGEX_FIRMNAME.test(billingCompany) &&
                    billingCompany !== ""
                      ? "Invalid firm name"
                      : ""
                  }
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </div>
              <div className={styles.rightInputContainer}>
                <TextField
                  label={"CVR: "}
                  id="cvr"
                  size="small"
                  required
                  fullWidth={false}
                  type="text"
                  value={billingCvr}
                  disabled={disabled || !setBillingCvr}
                  onChange={(e: any) => {
                    setBillingCvr && setBillingCvr(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <BusinessCenter
                          className={stylesCard.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                  error={!REGEX_CVRNUMBER.test(billingCvr) && billingCvr !== ""}
                  helperText={
                    !REGEX_CVRNUMBER.test(billingCvr) && billingCvr !== ""
                      ? "Invalid CVR"
                      : ""
                  }
                />
              </div>
            </div>
            <br />
            <div className={styles.flexR}>
              <div className={styles.leftInputContainer}>
                <TextField
                  label={"Fornavn: "}
                  id={"fornavn"}
                  size="small"
                  required
                  fullWidth={false}
                  value={billingFirstName}
                  disabled={disabled || !setBillingFirstName}
                  error={
                    !REGEX_FIRSTNAME.test(billingFirstName) &&
                    billingFirstName !== ""
                  }
                  helperText={
                    !REGEX_FIRSTNAME.test(billingFirstName) &&
                    billingFirstName !== ""
                      ? "Invalid first name"
                      : ""
                  }
                  onChange={(e: any) =>
                    setBillingFirstName && setBillingFirstName(e.target.value)
                  }
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </div>
              <div className={styles.rightInputContainer}>
                <TextField
                  label={"Efternavn: "}
                  size="small"
                  id={"efternavn"}
                  required
                  fullWidth={false}
                  type="text"
                  value={billingLastName}
                  disabled={disabled || !setBillingLastName}
                  error={
                    !REGEX_LASTNAME.test(billingLastName) &&
                    billingLastName !== ""
                  }
                  helperText={
                    !REGEX_LASTNAME.test(billingLastName) &&
                    billingLastName !== ""
                      ? "Invalid last name"
                      : ""
                  }
                  onChange={(e: any) =>
                    setBillingLastName && setBillingLastName(e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={stylesCard.inputAdornment}
                      >
                        <Face className={stylesCard.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </div>
            </div>
            <br />
            <div className={styles.leftInputContainer}>
              <TextField
                label={"Vej: "}
                id="vej"
                size="small"
                fullWidth={false}
                required
                type="address"
                value={billingAddressName}
                disabled={disabled || !setBillingAddressName}
                error={
                  !REGEX_ADDRESS.test(billingAddressName) &&
                  billingAddressName !== ""
                }
                helperText={
                  !REGEX_ADDRESS.test(billingAddressName) &&
                  billingAddressName !== ""
                    ? "Invalid address"
                    : ""
                }
                onChange={(e: any) =>
                  setBillingAddressName && setBillingAddressName(e.target.value)
                }
                inputProps={{
                  maxLength: 400,
                }}
              />
            </div>
            <br />
            <div className={styles.flexR}>
              <div className={styles.leftInputContainer}>
                <TextField
                  label={"Postnummer: "}
                  id="postnummer"
                  size="small"
                  fullWidth={false}
                  required
                  value={billingZipCode}
                  type="text"
                  disabled={disabled || !setBillingZipCode}
                  onChange={(e) =>
                    setBillingZipCode && setBillingZipCode(e.target.value)
                  }
                  InputProps={{
                    className: stylesCard.number,
                  }}
                  error={
                    !REGEX_ZIPCODE.test(billingZipCode) && billingZipCode !== ""
                  }
                  helperText={
                    !REGEX_ZIPCODE.test(billingZipCode) && billingZipCode !== ""
                      ? "Invalid zip code"
                      : ""
                  }
                />
              </div>
              <div className={styles.rightInputContainer}>
                <TextField
                  label={"By: "}
                  id="by"
                  size="small"
                  fullWidth={false}
                  required
                  type="text"
                  value={billingCity}
                  disabled={disabled || !setBillingCity}
                  error={!REGEX_CITY.test(billingCity) && billingCity !== ""}
                  helperText={
                    !REGEX_CITY.test(billingCity) && billingCity !== ""
                      ? "Invalid city"
                      : ""
                  }
                  onChange={(e: any) =>
                    setBillingCity && setBillingCity(e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={stylesCard.inputAdornment}
                      >
                        <LocationCity
                          className={stylesCard.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </div>
            </div>
            <br />
            <div className={styles.flexR}>
              <div className={styles.leftInputContainer}>
                <TextField
                  size="small"
                  label={"Tlf.nr.: "}
                  id="telefonnummer"
                  fullWidth={false}
                  required
                  value={billingPhone}
                  type="text"
                  disabled={disabled || !setBillingPhone}
                  onChange={(e) =>
                    setBillingPhone && setBillingPhone(e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={stylesCard.inputAdornment}
                      >
                        <Phone className={stylesCard.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={styles.rightInputContainer}>
                <TextField
                  size="small"
                  label={"Email: "}
                  id="email"
                  required
                  fullWidth={false}
                  type="email"
                  value={billingEmail}
                  disabled={disabled || !setBillingEmail}
                  error={!REGEX_EMAIL.test(billingEmail) && billingEmail !== ""}
                  helperText={
                    !REGEX_EMAIL.test(billingEmail) && billingEmail !== ""
                      ? "Invalid email address"
                      : ""
                  }
                  onChange={(e) =>
                    setBillingEmail && setBillingEmail(e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={stylesCard.inputAdornment}
                      >
                        <Email className={stylesCard.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <br />
            <div className={styles.flexR}>
              <div className={styles.leftInputContainer}>
                <TextField
                  label={"PO nummer: "}
                  id="PO"
                  size="small"
                  fullWidth={false}
                  value={poNumber}
                  disabled={disabled || !setPoNumber}
                  onChange={(e) =>
                    setPoNumber && setPoNumber(e.target.value.trim())
                  }
                  type="text"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={styles.rightInputContainer}>
                <TextField
                  size="small"
                  label={"Invoice Email: "}
                  id="invoiceemail"
                  fullWidth={false}
                  required
                  type="email"
                  value={invoiceEmail}
                  disabled={disabled || !setInvoiceEmail}
                  error={!REGEX_EMAIL.test(invoiceEmail) && invoiceEmail !== ""}
                  helperText={
                    !REGEX_EMAIL.test(invoiceEmail) && invoiceEmail !== ""
                      ? "Invalid email address"
                      : ""
                  }
                  onChange={(e) =>
                    setInvoiceEmail && setInvoiceEmail(e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={stylesCard.inputAdornment}
                      >
                        <Email className={stylesCard.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          {paymentMethod === "ean" ? (
            <TextField
              size="small"
              label={"GLN nummer (EAN)"}
              id="EAN"
              type="text"
              fullWidth={false}
              required={paymentMethod === "ean"}
              value={EANNumber}
              disabled={disabled || !setEANNumber}
              error={!REGEX_EAN.test(EANNumber) && EANNumber.length > 0}
              helperText={
                !REGEX_EAN.test(EANNumber) && EANNumber.length > 0
                  ? "Invalid EAN Number"
                  : ""
              }
              onChange={(e: any) => {
                if (e.target.value.trim().length < 14)
                  setEANNumber && setEANNumber(e.target.value.trim());
              }}
              inputProps={{
                maxLength: 13,
              }}
            />
          ) : null}
          <br />
          {setUseSameShipping && (
            <>
              <br />
              <label>
                <input
                  type={"checkbox"}
                  checked={useSameShipping}
                  //value={addExtras}
                  disabled={disabled || !setUseSameShipping}
                  onChange={(e) =>
                    setUseSameShipping &&
                    setUseSameShipping(e.currentTarget.checked)
                  }
                />
                {"Benyt samme leveringsaddresse?"}
              </label>
            </>
          )}
          {!useSameShipping && (
            <div>
              <br />
              <h3 className={styles.fntWeightBold}>{"Leveringsadresse"}</h3>
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label={"Firmannavn:"}
                    size="small"
                    id="Firmanavn"
                    required
                    fullWidth={false}
                    value={shippingCompany}
                    disabled={disabled || !setShippingCompany}
                    type="text"
                    error={
                      !REGEX_FIRMNAME.test(shippingCompany) &&
                      shippingCompany.length > 0
                    }
                    helperText={
                      !REGEX_FIRMNAME.test(shippingCompany) &&
                      shippingCompany.length > 0
                        ? "Invalid firm name"
                        : ""
                    }
                    onChange={(e: any) => {
                      if (e.target.value.length < 45) {
                        setShippingCompany &&
                          setShippingCompany(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className={styles.rightInputContainer}>
                  <TextField
                    size="small"
                    label={"CVR: "}
                    id="cvr"
                    type="text"
                    required
                    fullWidth={false}
                    value={shippingCvr}
                    disabled={disabled || !setShippingCvr}
                    error={
                      !REGEX_CVRNUMBER.test(shippingCvr) && shippingCvr !== ""
                    }
                    helperText={
                      !REGEX_CVRNUMBER.test(shippingCvr) && shippingCvr !== ""
                        ? "Invalid CVR"
                        : ""
                    }
                    onChange={(e) =>
                      setShippingCvr && setShippingCvr(e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={stylesCard.inputAdornment}
                        >
                          <BusinessCenter
                            className={stylesCard.inputAdornmentIcon}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <br />
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label={"Fornavn"}
                    size="small"
                    id={"ekstraFornavn"}
                    fullWidth={false}
                    required
                    type="text"
                    value={shippingFirstName}
                    disabled={disabled || !setShippingFirstName}
                    error={
                      !REGEX_FIRSTNAME.test(shippingFirstName) &&
                      shippingFirstName.length > 0
                    }
                    helperText={
                      !REGEX_FIRSTNAME.test(shippingFirstName) &&
                      shippingFirstName.length > 0
                        ? "Invalid first name"
                        : ""
                    }
                    onChange={(e: any) =>
                      setShippingFirstName &&
                      setShippingFirstName(e.target.value)
                    }
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </div>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label={"Efternavn"}
                    size="small"
                    id={"ekstraEfternavn"}
                    required
                    type="text"
                    fullWidth={false}
                    value={shippingLastName}
                    disabled={disabled || !setShippingLastName}
                    onChange={(e) =>
                      setShippingLastName && setShippingLastName(e.target.value)
                    }
                    error={
                      !REGEX_LASTNAME.test(shippingLastName) &&
                      shippingLastName.length > 0
                    }
                    helperText={
                      !REGEX_LASTNAME.test(shippingLastName) &&
                      shippingLastName.length > 0
                        ? "Invalid last name"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={stylesCard.inputAdornment}
                        >
                          <Face className={stylesCard.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </div>
              </div>
              <br />
              <div className={styles.rightInputContainer}>
                <TextField
                  size="small"
                  type="text"
                  label={"Vej"}
                  id="ekstravej"
                  required
                  fullWidth={false}
                  value={shippingAddressName}
                  disabled={disabled || !setShippingAddressName}
                  onChange={(e) =>
                    setShippingAddressName &&
                    setShippingAddressName(e.target.value)
                  }
                  error={
                    !REGEX_ADDRESS.test(shippingAddressName) &&
                    shippingAddressName.length > 0
                  }
                  helperText={
                    !REGEX_ADDRESS.test(shippingAddressName) &&
                    shippingAddressName.length > 0
                      ? "Invalid address"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={stylesCard.inputAdornment}
                      >
                        <House className={stylesCard.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 400,
                  }}
                />
              </div>
              <br />
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    size="small"
                    type="text"
                    label={"Postnummer"}
                    id="ekstraPostnummer"
                    value={shippingZipCode}
                    disabled={disabled || !setShippingZipCode}
                    onChange={(e) =>
                      setShippingZipCode && setShippingZipCode(e.target.value)
                    }
                    error={
                      !REGEX_ZIPCODE.test(shippingZipCode) &&
                      shippingZipCode.length > 0
                    }
                    helperText={
                      !REGEX_ZIPCODE.test(shippingZipCode) &&
                      shippingZipCode.length > 0
                        ? "Invalid zip code"
                        : ""
                    }
                    className={stylesCard.number}
                    required
                    fullWidth={false}
                  />
                </div>
                <div className={styles.rightInputContainer}>
                  <TextField
                    label={"By"}
                    size="small"
                    id="ekstraBy"
                    fullWidth={false}
                    required
                    type="text"
                    value={shippingCity}
                    disabled={disabled || !setShippingCity}
                    onChange={(e) =>
                      setShippingCity && setShippingCity(e.target.value)
                    }
                    error={
                      shippingCity !== "" && !REGEX_CITY.test(shippingCity)
                    }
                    helperText={
                      shippingCity !== "" && !REGEX_CITY.test(shippingCity)
                        ? "Invalid city name"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={stylesCard.inputAdornment}
                        >
                          <LocationCity
                            className={stylesCard.inputAdornmentIcon}
                          />
                        </InputAdornment>
                      ),
                      inputProps: {
                        maxLength: 50,
                      },
                    }}
                  />
                </div>
              </div>
              <br />
              <div className={styles.flexR}>
                <div className={styles.leftInputContainer}>
                  <TextField
                    label={"Telefonnummer"}
                    id="ekstraTelefon"
                    size="small"
                    type="text"
                    fullWidth={false}
                    required
                    value={shippingPhone}
                    disabled={disabled || !setShippingPhone}
                    onChange={(e) =>
                      setShippingPhone && setShippingPhone(e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={stylesCard.inputAdornment}
                        >
                          <Phone className={stylesCard.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={styles.rightInputContainer}>
                  <TextField
                    label={"Email"}
                    type="email"
                    id="ekstraEmail"
                    size="small"
                    fullWidth={false}
                    required
                    value={shippingEmail}
                    disabled={disabled || !setShippingEmail}
                    onChange={(e) =>
                      setShippingEmail && setShippingEmail(e.target.value)
                    }
                    error={
                      !REGEX_EMAIL.test(shippingEmail) &&
                      shippingEmail.length > 0
                    }
                    helperText={
                      !REGEX_EMAIL.test(shippingEmail) &&
                      shippingEmail.length > 0
                        ? "Invalid email address"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={stylesCard.inputAdornment}
                        >
                          <Email className={stylesCard.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </Card>
  );
};

export default AddressFields;
