import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import {
  ArrowBack,
  Calculate,
  Cancel,
  Check,
  Dangerous,
  Warning,
} from "@mui/icons-material";
import "./layout/StockManagementUI.css";
import { primaryColor } from "../../assets/colors";
import Barcode from "react-barcode";
import fakePOMData from "./FakePOMData";
import fakeAluminiumData from "./FakeAluminiumData";
import SweetAlert from "react-bootstrap-sweetalert";

const StockTable = ({ material, goBack }) => {
  const startValue = 15000;
  const [editStock, setEditStock] = useState(false);
  const [prevValue, setPrevValue] = useState(startValue);
  const [fakeValue, setFakevalue] = useState(startValue);
  const [criticalSupply, setCriticalSupply] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  const justNumbers = (string) => {
    let numsStr = string.replace(/[^0-9]/g, "");
    return parseInt(numsStr);
  };

  const handleCancelStock = () => {
    setFakevalue(prevValue);
    setEditStock(false);
  };

  const handleSubmitStock = () => {
    setPrevValue(fakeValue);
    setEditStock(false);
  };

  const handleCounterChange = (e) => {
    if (e.target.value > 0) {
      setFakevalue(e.target.value);
    } else setFakevalue(1);
  };

  const checkForCriticalSupply = () => {
    if (material.startsWith("ALU")) {
      const criticalAlu = fakeAluminiumData.filter(
        (row) => justNumbers(row.Maengde) <= 2000
      );
      setCriticalSupply(criticalAlu);
    } else {
      const criticalPOM = fakePOMData.filter((row) => row.Maengde <= 1000);
      setCriticalSupply(criticalPOM);
    }
  };

  useEffect(() => {
    checkForCriticalSupply();
  }, [material]);

  useEffect(() => {
    if (criticalSupply.length > 0) {
      setOpenAlert(true);
    }
  }, [criticalSupply]);

  return (
    <div>
      {openAlert ? (
        <SweetAlert
          warning
          title={"Mangel på følgende materialer:"}
          customButtons={
            <>
              <Button
                size={"large"}
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                OK
              </Button>
            </>
          }
          onConfirm={null}
        >
          <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
            {criticalSupply.map((supply) => (
              <li>
                <Typography variant={"body1"}>
                  {supply.Varenummer.toString()}
                </Typography>
              </li>
            ))}
          </ul>
        </SweetAlert>
      ) : null}
      <div>
        <Button
          onClick={() => goBack()}
          color={"primary"}
          size={"small"}
          startIcon={<ArrowBack />}
        >
          TILBAGE TIL OVERSIGT
        </Button>
        <div className={"titleContainer"}>
          <h1 className={"title"}>
            Lagerbeholdning for&nbsp;
            <span style={{ color: primaryColor[0] }}>{material}</span>
          </h1>
          {!editStock ? (
            <Button
              style={{ marginLeft: "auto" }}
              onClick={() => setEditStock(!editStock)}
              variant={"contained"}
              size={"medium"}
              color={"info"}
              startIcon={<Calculate />}
            >
              {"LAGEROPTÆLLING"}
            </Button>
          ) : (
            <>
              <Button
                style={{ marginLeft: "auto" }}
                onClick={handleSubmitStock}
                variant={"contained"}
                size={"medium"}
                color={"primary"}
                startIcon={<Check />}
              >
                {"BEKRÆFT OPTÆLLING"}
              </Button>
              <Button
                style={{ marginLeft: "15px" }}
                onClick={handleCancelStock}
                variant={"contained"}
                size={"medium"}
                color={"secondary"}
                endIcon={<Cancel />}
              >
                {"ANNULER OPTÆLLING"}
              </Button>
            </>
          )}
        </div>
      </div>
      <hr color={primaryColor[0]} />
      <Paper>
        <TableContainer style={{ maxHeight: "75vh" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: primaryColor[0],
              }}
            >
              <TableRow>
                <TableCell align="left">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Varenummer
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Type
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Tykkelse
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Bredde
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Beskrivelse
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Mængde i mm
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Status
                  </Typography>
                </TableCell>
                <TableCell width={"100"} align="center">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Stregkode
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {material.startsWith("ALU")
                ? fakeAluminiumData.map((row, index) => (
                    <TableRow className={"tableRow"} key={index}>
                      <TableCell align="left">{row.Varenummer}</TableCell>
                      <TableCell align="center">{material}</TableCell>
                      <TableCell align="center">{row.Tykkelse}</TableCell>
                      <TableCell align="center">{row.Bredde}</TableCell>
                      <TableCell align="center">{row.Beskrivelse}</TableCell>
                      <TableCell align="center">
                        {!editStock ? (
                          row.Maengde
                        ) : (
                          <input
                            type="number"
                            id={index.toString()}
                            name="Antal"
                            min="0"
                            max="99999"
                            value={justNumbers(row.Maengde)}
                            onChange={(e) => handleCounterChange(e)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {justNumbers(row.Maengde) > 2000 ? (
                          <Check color={"primary"} />
                        ) : justNumbers(row.Maengde) <= 2000 &&
                          justNumbers(row.Maengde) > 1000 ? (
                          <Warning color={"warning"} />
                        ) : (
                          <Dangerous
                            className={"iconWarning"}
                            color={"secondary"}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Barcode
                          textMargin={0}
                          margin={0}
                          width={1}
                          height={25}
                          value={row.Stregkode}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : material.startsWith("POM")
                ? fakePOMData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.Varenummer}</TableCell>
                      <TableCell align="left">{material}</TableCell>
                      <TableCell align="left">{row.Tykkelse}</TableCell>
                      <TableCell align="left">{row.Bredde}</TableCell>
                      <TableCell align="center">{row.Beskrivelse}</TableCell>
                      <TableCell align="center">
                        {!editStock ? (
                          row.Maengde + "mm"
                        ) : (
                          <input
                            type="number"
                            id={index.toString()}
                            name="Antal"
                            min="0"
                            max="99999"
                            value={row.Maengde}
                            onChange={(e) => handleCounterChange(e)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.Maengde > 1000 ? (
                          <Check color={"primary"} />
                        ) : row.Maengde <= 1000 && row.Maengde > 500 ? (
                          <Warning color={"warning"} />
                        ) : (
                          <Dangerous
                            className={"iconWarning"}
                            color={"secondary"}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Barcode width={2} height={25} value={row.Stregkode} />
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default StockTable;
