import { TableCell, TableRow, Tooltip } from "@mui/material";
import PreviewButton from "components/Generic/PreviewButton";
import AppStateContext from "contexts/AppStateContext";
import {
  FunctionComponent,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { Description, DoDisturb, Person } from "@mui/icons-material";
import ThreadDisplay from "../../components/OrderView/ThreadDisplay";
import { OrderItemType } from "types/OrderTypes";
import {
  checkColorStep,
  fetchPDFFile,
  fetchSTEPFile,
  fetchThreadFile,
} from "util/FileDownload";
import { dangerColor } from "assets/colors";
import { ReactComponent as PdfIcon } from "../../assets/svg/pdfFile.svg";
import { ReactComponent as StepIcon } from "../../assets/svg/stepFile.svg";
import { ReactComponent as ThreadsIcon } from "../../assets/svg/threadFile.svg";
import {
  ProductionTaskStateEnum,
  ProductionTaskType,
  ProductionVMType,
} from "types/ProductionType";
import Popup from "components/ChatBox/Popup";
import { apiGetConversation } from "util/network/Messages";
import { apiCheckDXFFile, apiGetDXFFile } from "util/network/Products";
import { apiGetProduction } from "util/network/Productions";
import {
  CNCProductDetailsType,
  ProductTypeEnum,
  SheetProductDetailsType,
} from "types/products/ProductCommandsType";

type PropsType = {
  item: OrderItemType;
  data: any;
  production?: ProductionVMType;
};

const OrderConfigRow: FunctionComponent<PropsType> = ({
  item,
  data,
  production,
}) => {
  const { token, user } = useContext(AppStateContext);

  const [pdfExists, setPdfExists] = useState<boolean | null>();
  const [colorStepExist, setColorStepExist] = useState<boolean>();
  const [activeTask, setActiveTask] = useState<ProductionTaskType>();
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  const [isLastMessage, setIsLastMessage] = useState<boolean>(false);
  const [dxfFileExists, setDxfFileExists] = useState<boolean>(false);
  const handleButtonClickInternal = () => {
    setIsNewMessage(false);
    setShowInternalChat((prev) => !prev);
  };

  useEffect(() => {
    const checkIfNewMessage = () => {
      apiGetConversation(item.productId + "internal", token)
        .then((res) => {
          if (res.lastMessage) setIsLastMessage(true);
          if (
            res.lastMessage &&
            res.lastMessage?.senderId !== user!.id.toString() &&
            !res.lastMessage?.readBy.includes(user!.id.toString())
          )
            setIsNewMessage(true);
          else setIsNewMessage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    checkIfNewMessage();
    if (
      item.product.productType == ProductTypeEnum.CNC ||
      item.product.productType == ProductTypeEnum.SHEET
    ) {
      const details = item.product.details as
        | CNCProductDetailsType
        | SheetProductDetailsType;
      setPdfExists(details?.blueprint ? true : false);
    } else {
      setPdfExists(null);
    }
  }, [item.productId, token, user]);

  const details = item.product.details as CNCProductDetailsType;

  useEffect(() => {
    if (production != undefined) {
      apiGetProduction(token, production.id).then((data) => {
        // Find the first task that is not done
        const task = data.tasks.find(
          (task) => task.state !== ProductionTaskStateEnum.DONE
        );
        if (task) {
          setActiveTask(task);
        } else {
          const doneTask = data.tasks[data.tasks.length - 1];
          setActiveTask(doneTask);
        }
      });
    }

    data &&
      checkColorStep(token, data.modelId).then((res) => {
        if (res === 200) {
          setColorStepExist(true);
        } else {
          setColorStepExist(false);
        }
      });
    data &&
      apiCheckDXFFile(token, data.modelId).then((res) => {
        setDxfFileExists(res);
      });
  }, [data, production]);

  return (
    <TableRow key={item.id}>
      <TableCell
        align={"left"}
        style={{
          padding: 0,
          maxWidth: "90px",
          paddingLeft: "0.5rem",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            padding: "0.5rem",
            textTransform: "uppercase",
            border:
              activeTask?.state === ProductionTaskStateEnum.TODO
                ? "1px solid #474848"
                : activeTask?.state === ProductionTaskStateEnum.ACTIVE
                ? "1px solid #E0A900"
                : activeTask?.state === ProductionTaskStateEnum.DONE
                ? "1px solid #007474"
                : undefined,
            color:
              activeTask?.state === ProductionTaskStateEnum.TODO
                ? "1px solid #474848"
                : activeTask?.state === ProductionTaskStateEnum.ACTIVE
                ? "1px solid #E0A900"
                : activeTask?.state === ProductionTaskStateEnum.DONE
                ? "1px solid #007474"
                : undefined,
            background:
              activeTask?.state === ProductionTaskStateEnum.TODO
                ? "rgba(71, 72, 72, 0.1)"
                : activeTask?.state === ProductionTaskStateEnum.ACTIVE
                ? "rgba(224, 169, 0, 0.1)"
                : activeTask?.state === ProductionTaskStateEnum.DONE
                ? "rgba(0, 116, 116, 0.1)"
                : undefined,

            borderRadius: "5px",
          }}
        >
          {activeTask?.description ?? activeTask?.name ?? "No task"}
        </span>

        {/* {task && (
          <AssignTask
            initTask={task}
            assignShow={postProcessState !== ProductionTaskStateEnum.TODO}
            disabled={postProcessState !== ProductionTaskStateEnum.TODO}
          />
        )} */}
      </TableCell>

      <TableCell
        style={{
          padding: 0,
          width: "300px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        align={"left"}
      >
        <span style={{ display: "flex", flexDirection: "row" }}>
          <span>
            {data ? (
              <PreviewButton
                productId={item.productId}
                modelId={data.modelId}
                size={"small"}
              />
            ) : (
              "Intet preview"
            )}
          </span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <span style={{}}>#{item.productId}</span>
            <span
              style={{
                fontSize: "0.7rem",
              }}
            >
              {item.name}
            </span>
          </span>
        </span>
      </TableCell>

      {/* <TableCell align="left">
        {data ? (
          <Tooltip
            title={<img src={preview} alt={"Preview"} width={"250px"} />}
            placement={"right-start"}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Button
              onClick={() => {
                setProductId(data.modelId);
                setShowThreeDModal(true);
              }}
            >
              {item.name} ({item.product.productType})
            </Button>
          </Tooltip>
        ) : (
          <p>
            {item.name} ({item.product.productType})
          </p>
        )}
      </TableCell> */}
      <TableCell align="center">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "0.7rem",
          }}
        >
          {item.product.productType == ProductTypeEnum.CNC ? (
            <>
              <span>
                {details.tolerance === 0
                  ? "ISO 2768-medium"
                  : details.tolerance === 1
                  ? "ISO 2768-fine"
                  : ""}
              </span>
              <span>
                {details.surfaceQuality === 0
                  ? "Standard Ra 1.6"
                  : details.surfaceQuality === 1
                  ? "Fine Ra 0.8"
                  : ""}
              </span>
              <span>
                {details.materialCertificate == true
                  ? "Certificate"
                  : details.materialCertificate == false
                  ? "-"
                  : ""}
              </span>
            </>
          ) : item.product.productType == ProductTypeEnum.SHEET ? (
            details.materialCertificate == true ? (
              "Certificate"
            ) : details.materialCertificate == false ? (
              "-"
            ) : (
              ""
            )
          ) : null}
        </div>
      </TableCell>
      <TableCell align="left" style={{ padding: 0 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            {data?.customMaterial
              ? data.customMaterial.name
              : data.standardMaterial
              ? data.standardMaterial.name
              : data.material ?? "Loading..."}
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            {data.model
              ? (data.model.dimX?.toFixed(1) ?? "?") +
                " x " +
                (data.model.dimY?.toFixed(1) ?? "?") +
                " x " +
                (data.model.dimZ?.toFixed(1) ?? "?")
              : "Loading..."}
          </span>
        </span>
      </TableCell>
      <TableCell align="left" style={{ padding: 0 }}>
        {data?.customFinish
          ? data.customFinish.name
          : data?.finish
          ? data.finish.length > 1
            ? data.finish.filter((i: string) => i !== "standard").join("+")
            : data.finish
          : "Loading..."}
      </TableCell>
      <TableCell align="right" style={{ padding: 0 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ paddingRight: "0.5rem" }}>
            {data ? (
              <p
                className={"downloadLink"}
                onClick={() =>
                  colorStepExist
                    ? fetchThreadFile(token, item.product.name, data.modelId)
                    : fetchSTEPFile(token, item.product.name, data.modelId)
                }
              >
                <StepIcon style={{ fill: "#474848", cursor: "pointer" }} />
              </p>
            ) : (
              <StepIcon style={{ fill: "#EBEBEB" }} />
            )}
          </span>
          <span style={{ paddingRight: "0.5rem" }}>
            {data ? (
              <p
                className={"downloadLink"}
                onClick={() =>
                  apiGetDXFFile(token, `${data.model.name}.dxf`, data.model.id)
                }
              >
                <Suspense fallback={<p>Loading...</p>}>
                  {dxfFileExists ? (
                    <Description
                      style={{ fill: "#E0A900", cursor: "pointer" }}
                    />
                  ) : dxfFileExists === false ? (
                    <Description style={{ fill: "#EBEBEB" }} />
                  ) : (
                    <p>Loading...</p>
                  )}
                </Suspense>
              </p>
            ) : (
              <DoDisturb sx={{ color: dangerColor[0] }} />
            )}
          </span>
          <span style={{ paddingRight: "0.5rem" }}>
            {pdfExists !== null &&
              (data ? (
                <p
                  className={"downloadLink"}
                  onClick={() =>
                    fetchPDFFile(token, item.product.name, data.productId)
                  }
                >
                  <Suspense fallback={<p>Loading...</p>}>
                    {pdfExists ? (
                      <PdfIcon style={{ fill: "#E0A900", cursor: "pointer" }} />
                    ) : pdfExists === false ? (
                      <PdfIcon style={{ fill: "#EBEBEB" }} />
                    ) : (
                      <p>Loading...</p>
                    )}
                  </Suspense>
                </p>
              ) : (
                <DoDisturb sx={{ color: dangerColor[0] }} />
              ))}
          </span>

          <span style={{ paddingRight: "0.5rem" }}>
            {data && data.threads ? (
              data.threads?.length > 0 ? (
                <div>
                  <Tooltip
                    title={
                      <div style={{ backgroundColor: "white" }}>
                        <ThreadDisplay specs={data.threads} />
                      </div>
                    }
                  >
                    <ThreadsIcon style={{ fill: "#E0A900" }} />
                  </Tooltip>
                </div>
              ) : (
                <ThreadsIcon style={{ fill: "#EBEBEB" }} />
              )
            ) : (
              "-"
            )}
          </span>
          <Tooltip title={"Internal chat"} onClick={handleButtonClickInternal}>
            <span>
              <Person
                sx={{
                  color: isLastMessage || data?.comment ? "#FFC01F" : "#EBEBEB",
                  cursor: "pointer",
                }}
              />
            </span>
          </Tooltip>
          <div style={{ position: "absolute" }}>
            {showInternalChat && (
              <Popup
                customerComment={data?.comment}
                customer={item.product.author!}
                productId={item.product.id}
                selectedParticipant={"employee"}
                onClose={handleButtonClickInternal}
              />
            )}
          </div>
        </span>
      </TableCell>
      <TableCell align="center" style={{ padding: 0 }}>
        {item.quantity}pcs
      </TableCell>
      <TableCell align="right" style={{ padding: 0, paddingRight: "0.5rem" }}>
        <span>{item.price}kr</span>
        <br />
        <span style={{ fontSize: "0.7rem" }}>{item.unitPrice}kr</span>
      </TableCell>
    </TableRow>
  );
};

export default OrderConfigRow;
