import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import SearchField from "components/SearchField";
import RequisitionComponent from "components/Requisition/RequisitionComponent";
import { RequisitionStatusEnum, RequisitionType } from "types/RequisitionTypes";
import OutsourcingTable from "./OutsourcingTable";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";

type ParamsType = {
  id?: string;
};

// Outsourcing management UI
const OutsourcingManagementUI = () => {
  const { id } = useParams<ParamsType>(); // Extract the ID from the URL
  console.log(id)
  const [searchValue, setSearchValue] = useState(id || "");
  const [statusFilter, setStatusFilter] = useStateWithSessionStorage(
    [
      RequisitionStatusEnum.REQUEST,
      RequisitionStatusEnum.RESPONSE,
      RequisitionStatusEnum.PROCESSING,
      RequisitionStatusEnum.PICKUP,
    ],
    "status"
  );
  const [showRequisition, setShowRequisition] = useState(false);
  const [requisitionEdit, _setRequisitionEdit] = useState<RequisitionType>();
  const [refresh, setRefresh] = useState(false);

  const setRequisitionEdit = (requisition: RequisitionType) => {
    _setRequisitionEdit(requisition);
    setShowRequisition(true);
  };

  // Handle filter change
  const handleFilterChange = (name: string) => {
    const statusName = name as RequisitionStatusEnum;
    console.log(name);
    if (name === "ALLE") {
      if (statusFilter.length == Object.values(RequisitionStatusEnum).length) {
        setStatusFilter([]);
      } else {
        setStatusFilter([
          RequisitionStatusEnum.REQUEST,
          RequisitionStatusEnum.RESPONSE,
          RequisitionStatusEnum.PROCESSING,
          RequisitionStatusEnum.PAUSED,
          RequisitionStatusEnum.PICKUP,
          RequisitionStatusEnum.RECEIVED,
          RequisitionStatusEnum.CANCELLED,
        ]);
      }
    } else
      setStatusFilter(
        statusFilter.includes(statusName)
          ? statusFilter.filter((item) => item !== statusName)
          : [...statusFilter, statusName]
      );
  };
  // Handle close requisition
  const closeRequisition = () => {
    setShowRequisition(false);
    setRefresh(true);
    _setRequisitionEdit(undefined);
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        open={showRequisition ? true : false}
        onClose={() => closeRequisition()}
      >
        {showRequisition ? (
          <RequisitionComponent
            closeRequisition={closeRequisition}
            oldRequisition={requisitionEdit}
            prodType={requisitionEdit?.orderId ? "order" : "cart"}
          />
        ) : null}
      </Dialog>
      <Grid container>
        <Grid item xs={12}>
          {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
              justifyContent: "space-between",
              background: "var(--header)",
              color: "#fff",
              height: "6vh",
              paddingTop: "20px",
            }}
          >
            <Typography
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
              fontWeight={"bold"}
              variant={"h6"}
            >
              &nbsp;Sourcing overview
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "stretch",
              }}
            >
              <div style={{ paddingRight: "1rem" }}>
                <SearchField onChange={setSearchValue} value={searchValue} />
              </div>
              <button
                style={{
                  // borderRadius: 10,
                  maxHeight: 40,
                  padding: "0.5rem",
                  background: "var(--mainYellow)",
                  color: "white",
                  border: "none",
                  // fontSize: "16px",
                  textTransform: "uppercase",
                  paddingLeft: "0.5rem",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
                onClick={() => setShowRequisition(true)}
              >
                New Requisition
              </button>
            </div>
          </div>

          {/* </div> */}
        </Grid>

        <Grid
          item
          direction={"row"}
          sx={{
            display: "flex",
            marginBottom: "10px",
            paddingLeft: "1.5rem",
            paddingTop: "1rem",
          }}
          xs
          md
          lg
          // style={{ , paddingTop: "0.5em" }}
        >
          <Button
            sx={{
              "&:hover": {
                backgroundColor: "#fff",
                color: "#696969 ",
                border: "1px solid #696969",
              },
              color:
                statusFilter.length ==
                Object.values(RequisitionStatusEnum).length
                  ? "white"
                  : "#696969",
              backgroundColor:
                statusFilter.length ==
                Object.values(RequisitionStatusEnum).length
                  ? "#696969"
                  : null,
              margin: "10px",
              border: "1px solid #696969",
            }}
            variant="outlined"
            onClick={() => handleFilterChange("ALLE")}
          >
            Alle
          </Button>
          {Object.values(RequisitionStatusEnum).map((status) => (
            <Button
              sx={{
                "&:hover": {
                  background: statusFilter.includes(status)
                    ? "#fff"
                    : status === RequisitionStatusEnum.REQUEST
                    ? "#075E5B"
                    : status === RequisitionStatusEnum.RESPONSE
                    ? "#E5CB00"
                    : status === RequisitionStatusEnum.PROCESSING
                    ? "#41A8A1"
                    : status === RequisitionStatusEnum.PAUSED
                    ? "#848585"
                    : status === RequisitionStatusEnum.PICKUP
                    ? "#E1A900"
                    : status === RequisitionStatusEnum.RECEIVED
                    ? "#7FBAB9"
                    : status === RequisitionStatusEnum.CANCELLED
                    ? "#848585"
                    : undefined,
                  color:
                    statusFilter.includes(status) &&
                    status === RequisitionStatusEnum.REQUEST
                      ? "#075E5B"
                      : statusFilter.includes(status) &&
                        status === RequisitionStatusEnum.RESPONSE
                      ? "#E5CB00"
                      : statusFilter.includes(status) &&
                        status === RequisitionStatusEnum.PROCESSING
                      ? "#41A8A1"
                      : statusFilter.includes(status) &&
                        status === RequisitionStatusEnum.PAUSED
                      ? "#848585"
                      : statusFilter.includes(status) &&
                        status === RequisitionStatusEnum.PICKUP
                      ? "#E1A900"
                      : statusFilter.includes(status) &&
                        status === RequisitionStatusEnum.RECEIVED
                      ? "#7FBAB9"
                      : statusFilter.includes(status) &&
                        status === RequisitionStatusEnum.CANCELLED
                      ? "#848585"
                      : "#fff",
                },
                color: statusFilter.includes(status)
                  ? "#fff"
                  : status === RequisitionStatusEnum.REQUEST
                  ? "#075E5B"
                  : status === RequisitionStatusEnum.RESPONSE
                  ? "#E5CB00"
                  : status === RequisitionStatusEnum.PROCESSING
                  ? "#41A8A1"
                  : status === RequisitionStatusEnum.PAUSED
                  ? "#848585"
                  : status === RequisitionStatusEnum.PICKUP
                  ? "#E1A900"
                  : status === RequisitionStatusEnum.RECEIVED
                  ? "#7FBAB9"
                  : status === RequisitionStatusEnum.CANCELLED
                  ? "#848585"
                  : undefined,
                background:
                  statusFilter.includes(status) &&
                  status === RequisitionStatusEnum.REQUEST
                    ? "#075E5B"
                    : statusFilter.includes(status) &&
                      status === RequisitionStatusEnum.RESPONSE
                    ? "#E5CB00"
                    : statusFilter.includes(status) &&
                      status === RequisitionStatusEnum.PROCESSING
                    ? "#41A8A1"
                    : statusFilter.includes(status) &&
                      status === RequisitionStatusEnum.PAUSED
                    ? "#848585"
                    : statusFilter.includes(status) &&
                      status === RequisitionStatusEnum.PICKUP
                    ? "#E1A900"
                    : statusFilter.includes(status) &&
                      status === RequisitionStatusEnum.RECEIVED
                    ? "#7FBAB9"
                    : statusFilter.includes(status) &&
                      status === RequisitionStatusEnum.CANCELLED
                    ? "#848585"
                    : status === RequisitionStatusEnum.REQUEST
                    ? "#e6eeee"
                    : status === RequisitionStatusEnum.RESPONSE
                    ? "#fcf9e5"
                    : status === RequisitionStatusEnum.PROCESSING
                    ? "#ecf6f5"
                    : status === RequisitionStatusEnum.PAUSED
                    ? "#f2f2f2"
                    : status === RequisitionStatusEnum.PICKUP
                    ? "#fcf6e5"
                    : status === RequisitionStatusEnum.RECEIVED
                    ? "#f2f8f8"
                    : status === RequisitionStatusEnum.CANCELLED
                    ? "#f2f2f2"
                    : null,
                border:
                  status === RequisitionStatusEnum.REQUEST
                    ? "1px solid #075E5B !important"
                    : status === RequisitionStatusEnum.RESPONSE
                    ? "1px solid #E5CB00 !important"
                    : status === RequisitionStatusEnum.PROCESSING
                    ? "1px solid #41A8A1 !important"
                    : status === RequisitionStatusEnum.PAUSED
                    ? "1px solid #848585 !important"
                    : status === RequisitionStatusEnum.PICKUP
                    ? "1px solid #E1A900 !important"
                    : status === RequisitionStatusEnum.RECEIVED
                    ? "1px solid #7FBAB9 !important"
                    : status === RequisitionStatusEnum.CANCELLED
                    ? "1px solid #848585 !important"
                    : undefined,
                margin: "10px",
              }}
              variant="outlined"
              onClick={() => handleFilterChange(status)}
            >
              {status}
            </Button>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: "1.5rem",
          }}
        >
          <OutsourcingTable
            refresh={refresh}
            setRefresh={setRefresh}
            setRequisitionEdit={setRequisitionEdit}
            searchValue={searchValue}
            statusFilter={statusFilter}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OutsourcingManagementUI;
