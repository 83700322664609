import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  Channel,
  GridItemProps,
  Program,
  useEpg,
  Epg,
  Layout,
} from "@nessprim/planby";
import { fetchPlansResources } from "./helpers";
import { epgChannels } from "./helpers/epg-data/epg-channels";

// Import theme
import { theme } from "./helpers/theme";

// ----- Please uncomment the following lines to see the custom components -----
// Import components
import {
  CustomTimeline,
  ChannelItem,
  Line,
  LiveTime,
  ProgramItemView,
  ProgramItemEdit,
} from "./components";

function PlanByEditor() {
  const todayDateString = new Date().toISOString().split("T")[0];
  const [viewDate, setViewDate] = React.useState<Date>(new Date());
  const ViewDateString = viewDate.toISOString().split("T")[0];
  const startDateTime = new Date(
    viewDate.getFullYear(),
    viewDate.getMonth(),
    viewDate.getDate(),
    7,
    0,
    0
  );
  const endDateTime = new Date(
    viewDate.getFullYear(),
    viewDate.getMonth(),
    viewDate.getDate(),
    20,
    0,
    0
  );

  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [epg, setEpg] = React.useState<Program[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const channelsData = React.useMemo(() => channels, [channels]);
  const epgData = React.useMemo(() => epg, [epg]);

  const { getEpgProps, getLayoutProps, getLayoutData } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: 2400,
    startDate: startDateTime.toISOString(),
    endDate: endDateTime.toISOString(),
    isVerticalMode: false,
    sidebarWidth: 100,
    itemHeight: 50,
    channelMapKey: "id",
    programChannelMapKey: "machine",
    isBaseTimeFormat: false,
    isResize: true,
    overlap: {
      enabled: true,
      mode: "stack",
      layerOverlapLevel: 0.4,
    },
    dnd: { enabled: true, mode: "multi-rows" },
    grid: {
      enabled: true,
      //onGridItemClick: (props: GridItemProps) => alert(JSON.stringify(props)),
      hoverHighlight: false,
    },
    theme,
  });

  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    setChannels([
      ...(epgChannels as unknown as Channel[]),
      {
        id: "UNPLANNED",
        type: "UNPLANNED",
        title: "UNPLANNED",
      } as unknown as Channel,
    ]);
    setEpg((await fetchPlansResources(viewDate)) as unknown as Program[]);
    setIsLoading(false);
  }, [viewDate]);

  React.useEffect(() => {
    handleFetchResources();
  }, [handleFetchResources]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    setViewDate(newDate);
  };

  return (
    <div>
      <input
        type="date"
        id="start-date"
        name="start-date"
        value={ViewDateString}
        min={todayDateString}
        onChange={handleDateChange}
      />

      <div style={{ width: "99%" }}>
        <Epg isLoading={isLoading} {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            // ----- Please uncomment the following lines to see the custom components -----
            // renderLine={(props) => <Line {...props} />}
            renderCurrentTime={(props) => <LiveTime {...props} />}
            renderTimeline={(props) => <CustomTimeline {...props} />}
            renderProgram={({ program, ...rest }) => (
              <ProgramItemEdit
                key={`${program.data.channelUuid}-${program.data.id}`}
                program={program}
                {...rest}
              />
            )}
            renderChannel={({ channel, ...rest }) => (
              <ChannelItem key={channel.uuid} channel={channel} {...rest} />
            )}
          />
        </Epg>
      </div>
    </div>
  );
}

export default PlanByEditor;
