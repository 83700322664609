import { Button } from "@mui/material";
import AppStateContext from "contexts/AppStateContext";
import { FunctionComponent, useContext } from "react";
import { API_URL } from "util/network/common";
import LazyLoad from "react-lazyload";

type PropsType = {
  productId: number;
  modelId: number;
  size?: string;
  picture?: string;
};
const PreviewButton: FunctionComponent<PropsType> = ({
  productId,
  modelId,
  size,
  picture,
}) => {
  const { token, setShowThreeDModal, setProductId } =
    useContext(AppStateContext);

  return (
    <Button onClick={(e) => e.stopPropagation()} style={{ padding: "0.2rem" }}>
      <LazyLoad once>
        <img
          width={size == "small" ? "50px" : "100px"}
          onClick={() => {
            setProductId(productId);
            setShowThreeDModal(true);
          }}
          src={picture || `${API_URL}/v1/ecommerce/models/${modelId}/preview`}
          alt="preview"
        ></img>
      </LazyLoad>
    </Button>
  );
};

export default PreviewButton;
