import React, { useEffect, useRef, useState } from "react";
import styles from "./customBorderlessSelector.module.css";
export interface OptionType {
  value: string | number;
  [key: string]: any;
}

type PropsType = {
  options: OptionType[];
  renderOption?: (option: OptionType) => JSX.Element;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedValue: string | number;
  disabled?: boolean;
};

const CustomBorderlessSelect: React.FC<PropsType> = ({
  options,
  renderOption,
  onChange,
  selectedValue,
  disabled,
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [selectWidth, setSelectWidth] = useState<string>("auto");

  useEffect(() => {
    adjustWidth();
  }, [selectedValue]);

  const adjustWidth = () => {
    if (selectRef.current) {
      const selectedOption =
        selectRef.current.options[selectRef.current.selectedIndex];
      const tempOption = document.createElement("option");
      tempOption.textContent = selectedOption.textContent;
      tempOption.style.display = "inline";
      tempOption.style.position = "absolute";
      tempOption.style.visibility = "hidden";
      document.body.appendChild(tempOption);

      const buffer = 25;
      setSelectWidth(`${tempOption.offsetWidth + buffer}px`);
      document.body.removeChild(tempOption);
    }
  };

  renderOption && console.log(renderOption({ value: "1", label: "test" }));

  return (
    <div>
      <select
        disabled={disabled}
        onChange={(e) => {
          adjustWidth();
          if (onChange) {
            onChange(e);
          }
        }}
        name=""
        id=""
        ref={selectRef}
        style={{
          width: selectWidth,
        }}
        value={selectedValue}
        className={styles.borderlessSelect}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {renderOption ? renderOption(option) : option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
export default CustomBorderlessSelect;
