import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Paper,
  IconButton,
  Tooltip,
  TablePagination,
  Skeleton,
  TableSortLabel,
} from "@mui/material";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { apiGetCustomers, apiSearchUsers } from "../../util/network/Users";
import AppStateContext from "../../contexts/AppStateContext";
import CustomerRow from "./CustomerRow";
import RefreshIcon from "@mui/icons-material/Refresh";
import { UserType } from "types/users/UserTypes";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";

type PropsType = {
  setExisting: Function;
  searchVal?: string;
  setSearchVal?: Function;
  doRefresh?: React.MutableRefObject<any>;
};

const CustomerTable: FunctionComponent<PropsType> = ({
  setExisting,
  searchVal,
  setSearchVal,
  doRefresh,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserType[]>([]);
  const { token } = useContext(AppStateContext);
  const [page, setPage] = useStateWithSessionStorage<number>(0, "customerPage");
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useStateWithSessionStorage<number>(
    10,
    "customerPageSize"
  );
  const [userCheck, setUserCheck] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<string>("last_action_date");
  const [sortAsc, setSortAsc] = useState<boolean>(false);

  const doSort = (key: string) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(false);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(+event.target.value);
  };

  useEffect(() => {
    if (users) {
      if (users?.length > 0) {
        setUserCheck(true);
      } else if (!users || users.length === 0) {
        setUserCheck(false);
      }
    }
  }, [users]);

  useEffect(() => {
    // Loading while fetching users
    if (users) {
      if (users?.length > 0) {
        setLoading(false);
      } else if (!users || users.length === 0) {
        setLoading(true);
        refreshUsers();
      }
    }
  }, [userCheck]);

  useEffect(() => {
    setLoading(true);
    refreshUsers();
  }, [searchVal, page, pageSize, sortKey, sortAsc, doRefresh]);

  const refreshUsers = () => {
    if (token) {
      setLoading(true);
      if (searchVal) {
        apiSearchUsers(token, searchVal)
          .then((res) => {
            setUsers(res);
            setTotal(res.length);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        apiGetCustomers(
          token,
          page + 1,
          pageSize,
          sortKey,
          sortAsc ? "asc" : "desc"
        )
          .then((res) => {
            setUsers(res.items);
            setTotal(res.total);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (doRefresh) {
      doRefresh.current = refreshUsers;
    }
    refreshUsers();
  }, [searchVal, page, pageSize, sortKey, sortAsc, doRefresh]);

  const filteredUsers = useMemo(() => {
    if (searchVal == "") {
      return users
        .slice(page * pageSize, page * pageSize + pageSize)
        .map((customer: any, index: number) => {
          return (
            <CustomerRow
              key={customer.id}
              setExisting={setExisting}
              customer={customer}
            />
          );
        });
    } else {
      return users
        .slice(page * pageSize, page * pageSize + pageSize)
        .map((customer: UserType, index: number) => {
          return (
            <CustomerRow
              key={customer.id}
              setExisting={setExisting}
              customer={customer}
            />
          );
        });
    }
  }, [token, users, searchVal, page, pageSize, setExisting, sortAsc, sortKey]);

  useEffect(() => {
    if (searchVal != "") setPage(0);
  }, [searchVal]);

  return (
    <Paper>
      <TableContainer>
        {users ? (
          <Table>
            <TableHead
              sx={{
                background: "var(--header)",
              }}
            >
              <TableRow>
                <TableCell align="left">
                  <TableSortLabel
                    onClick={() => {
                      doSort("last_action_date");
                    }}
                    active={sortKey === "last_action_date"}
                    disabled={loading}
                    direction={sortAsc ? "asc" : "desc"}
                  >
                    <Typography
                      color={"white"}
                      fontWeight={"bold"}
                      variant={"body1"}
                    >
                      Last action
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" sx={{ paddingLeft: "20px" }}>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    onClick={() => {
                      doSort("created");
                    }}
                    active={sortKey === "created"}
                    disabled={loading}
                    direction={sortAsc ? "asc" : "desc"}
                  >
                    <Typography
                      color={"white"}
                      fontWeight={"bold"}
                      variant={"body1"}
                    >
                      Created
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Phone
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Company
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Address
                  </Typography>
                </TableCell>

                <TableCell>
                  <Tooltip title="Genindlæs">
                    <IconButton
                      sx={{ color: "white" }}
                      onClick={() => refreshUsers()}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading && users.length === 0 ? (
              <TableBody>
                {Array.from({ length: pageSize }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={30}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : users.length > 0 ? (
              <>
                <TableBody>{filteredUsers}</TableBody>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={total}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    No customer found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        ) : (
          <LinearProgress />
        )}
      </TableContainer>
      {users ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Paper>
  );
};

export default CustomerTable;
