import { FunctionComponent, useContext, useState } from "react";
import { Dialog, Grid } from "@mui/material";
import CalculatorRow from "./CalculatorRow";
import CalculatorModal from "./CalculatorModal";
import { CartItemType } from "types/CartTypes";
import { useToast } from "contexts/ToastContext";
import styles from "../Styles/loginPageStyle.module.css";

type PropsType = {
  item: CartItemType;
  onPriceChange: (newPrice: number) => void;
  isOpen: boolean;
  handleClose: (open: boolean) => void;
};

const CalculatorDialog: FunctionComponent<PropsType> = ({
  item,
  onPriceChange,
  isOpen,
  handleClose,
}) => {
  const { addToast } = useToast();
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const handlePriceChange = (newPrice: number) => {
    setTotalPrice(newPrice);
  };
  const handleDialogClose = () => {
    handleClose(false);
    addToast({
      type: "warning",
      message: (
        <>
          Price changes were <strong>not saved</strong>.
        </>
      ),
    });
  };
  const handleSave = () => {
    onPriceChange(totalPrice);
    handleClose(false);
    addToast({
      type: "success",
      message: (
        <>
          New price was <strong>saved</strong> successfully.
        </>
      ),
    });
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "1050px",
            },
          },
        }}
        open={isOpen}
        onClose={() => handleClose(false)}
      >
        <h4 className={styles.calcBoldTitle}>{"PRICE CALCULATOR"}</h4>
        <div className={styles.pd2}>
          <Grid
            item
            direction={"row"}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={8} style={{ width: "450px" }}>
              <CalculatorRow item={item} onPriceChange={handlePriceChange} />
            </Grid>
            <Grid item xs={4}>
              <CalculatorModal
                item={item}
                totalPrice={totalPrice}
                onSave={handleSave}
                handleClose={handleDialogClose}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default CalculatorDialog;
