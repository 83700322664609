import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import AppStateContext from "contexts/AppStateContext";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { ProductionListType, ProductionVMType } from "types/ProductionType";
import { apiGetProductions } from "util/network/Productions";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import HeaderRow from "../HeaderRow";
import MillingRow from "./MillingRow";
import { MachineType } from "types/MachineType";
import { apiGetMachines } from "util/network/Machine";

type PropsType = {
  selection: string;
  searchValue?: string;
  orderId?: number;
  machinesIn?: MachineType[];
};
const MillingTable: React.FC<PropsType> = ({
  selection,
  searchValue,
  orderId,
  machinesIn,
}) => {
  const { token } = useContext(AppStateContext);
  const [fetchTimeStamp, setFetchTimeStamp] = useState<number>(0);
  const [testUrl, setTestUrl] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>(
    15,
    "millPageSize"
  );
  const queryClient = useQueryClient();
  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");
  const [sortKey, setSortKey] = useStateWithLocalStorage<
    "deadline_date" | "order_id" | "priority"
  >("deadline_date", "productionSortKey");
  const [sortDirection, setSortDirection] = useStateWithLocalStorage<
    "asc" | "desc"
  >("asc", "productionSortDirection");

  const [machines, setMachines] = useState<MachineType[]>(machinesIn ?? []);

  // const getMaterialProductions = (
  //   page: number,
  //   pageSize: number,
  //   selection: string,
  //   sortKey: "deadline_date" | "order_id" | "priority",
  //   sortDirection: "asc" | "desc",
  //   search?: string
  // ) => {
  //   return apiGetProductions(
  //     token,
  //     page,
  //     pageSize,
  //     `${selection == "ready" && "production=ACTIVE"}&status=0`,
  //     sortKey,
  //     sortDirection,
  //     search,
  //     selection == "coming" ? "production" : undefined,
  //     selection == "all" ? "production=ACTIVE;production=TODO" : undefined,
  //     "description=Material"
  //   ).then((res) => {
  //     setFetchTimeStamp(Date.now());
  //     return res;
  //   });
  // };

  // const getMillingProductions = (
  //   page: number,
  //   pageSize: number,
  //   selection: string,
  //   sortKey: "deadline_date" | "order_id" | "priority",
  //   sortDirection: "asc" | "desc",
  //   search?: string
  // ) => {
  //   return apiGetProductions(
  //     token,
  //     page,
  //     pageSize,
  //     `${selection == "ready" && "production=ACTIVE"}&status=0`,
  //     sortKey,
  //     sortDirection,
  //     search,
  //     selection == "coming" ? "production" : undefined,
  //     selection == "all" ? "production=ACTIVE;production=TODO" : undefined,
  //     "description=Milling"
  //   ).then((res) => {
  //     setFetchTimeStamp(Date.now());
  //     return res;
  //   });
  // };

  useEffect(() => {
    if (machinesIn) {
      setMachines(machinesIn);
    } else {
      apiGetMachines(token).then((res) => {
        setMachines(res);
      });
    }
  }, [queryClient, machinesIn, token]);

  const getAllProductions = (
    page: number,
    pageSize: number,
    selection: string,
    sortKey: "deadline_date" | "order_id" | "priority",
    sortDirection: "asc" | "desc",
    search?: string
  ) => {
    return apiGetProductions(
      token,
      page,
      pageSize,
      `${selection == "ready" && "production=ACTIVE"}&status=0`,
      sortKey,
      sortDirection,
      search,
      selection == "coming" ? "production" : undefined,
      selection == "all" ? "production=ACTIVE;production=TODO" : undefined
    ).then((res) => {
      setFetchTimeStamp(Date.now());
      return res;
    });
  };

  const { data } = useQuery<ProductionListType, any>(
    [
      "production",
      page,
      pageSize,
      selection,
      sortKey,
      sortDirection,
      searchValue,
    ],
    () =>
      getAllProductions(
        page,
        pageSize,
        selection,
        sortKey,
        sortDirection,
        searchValue
      ),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
    }
  );

  // const { data: data1 } = useQuery<ProductionListType, any>(
  //   [
  //     "material",
  //     page,
  //     pageSize,
  //     selection,
  //     sortKey,
  //     sortDirection,
  //     searchValue,
  //   ],
  //   () =>
  //     getMaterialProductions(
  //       page,
  //       pageSize,
  //       selection,
  //       sortKey,
  //       sortDirection,
  //       searchValue
  //     ),
  //   {
  //     keepPreviousData: true,
  //     staleTime: 5 * 60 * 1000, // 5 minutes
  //     cacheTime: 30 * 60 * 1000, // 30 minutes
  //   }
  // );

  // const { data: data2 } = useQuery<ProductionListType, any>(
  //   ["milling", page, pageSize, selection, sortKey, sortDirection, searchValue],
  //   () =>
  //     getMillingProductions(
  //       page,
  //       pageSize,
  //       selection,
  //       sortKey,
  //       sortDirection,
  //       searchValue
  //     ),
  //   {
  //     keepPreviousData: true,
  //     staleTime: 5 * 60 * 1000, // 5 minutes
  //     cacheTime: 30 * 60 * 1000, // 30 minutes
  //   }
  // );

  // const data = useMemo(() => {
  //   if (data1 && data2) {
  //     return {
  //       items: [...data1.items, ...data2.items],
  //       page: page,
  //       pageSize: pageSize,
  //       total: Number(data1.total) + Number(data2.total),
  //     };
  //   }
  //   return null;
  // }, [data1, data2]);

  const filteredData = useMemo(() => {
    if (!data) return [];

    const filteredItems = data.items.filter((project) => {
      if (project.status === 1) return false;

      const millingTasks = project.tasks.filter(
        (task) => task.description && task.description.includes("Milling")
      );

      return (
        millingTasks.length > 0 &&
        millingTasks.some((task) => task.state !== "DONE")
      );
    });

    return filteredItems;
  }, [data]);

  const paginatedData = useMemo(() => {
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, page, pageSize]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const projectMemo = useMemo(() => {
    let oldId = 0;
    const rows = data?.items.map((project) => {
      const groupByOrderId = data?.items.reduce((acc, item) => {
        if (!acc[item.orderId]) acc[item.orderId] = [];
        acc[item.orderId].push(item);
        return acc;
      }, {} as Record<number, ProductionVMType[]>);

      if (
        project.orderId &&
        project.orderId !== oldId &&
        orderId === undefined
      ) {
        oldId = project.orderId;

        const itemsInGroup = groupByOrderId[project.orderId];
        const allMillTasksDoneInGroup = itemsInGroup.every((item) =>
          item.tasks.every(
            (task) => task.name !== "milling" || task.state === "DONE"
          )
        );
        return (
          <React.Fragment key={project.id}>
            {/* {!allMillTasksDoneInGroup && ( */}
            <HeaderRow
              key={`${project.orderId}`}
              project={project}
              productions={data}
              mill
            />
            {/* )} */}
            <Suspense
              fallback={
                <TableRow>
                  <TableCell colSpan={11}>
                    <Skeleton variant="rectangular" height={55} />
                  </TableCell>
                </TableRow>
              }
            >
              <MillingRow
                key={`${project.id}${project.modified}${fetchTimeStamp}`}
                project={project}
                machines={machines}
              />
            </Suspense>
          </React.Fragment>
        );
      } else
        return (
          <Suspense
            key={project.id}
            fallback={
              <TableRow>
                <TableCell colSpan={11}>
                  <Skeleton variant="rectangular" height={55} />
                </TableCell>
              </TableRow>
            }
          >
            <MillingRow
              key={`${project.id}${project.modified}${fetchTimeStamp}`}
              project={project}
              machines={machines}
            />
          </Suspense>
        );
    });
    return rows;
  }, [data, fetchTimeStamp, orderId, machines]);

  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>{projectMemo}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 50]}
        component="div"
        count={data?.total ?? 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default MillingTable;
