import { OrderType, SimpleOrderType } from "types/OrderTypes";
import Modal from "./Modal";
import { useContext, useState } from "react";
import { apiCreateInvoice } from "util/network/Orders";
import AppStateContext from "contexts/AppStateContext";
import { Button } from "@mui/material";

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (created: boolean) => void;
  order: SimpleOrderType | OrderType;
};

const InvoiceModal: React.FC<PropsType> = ({
  isOpen,
  onClose,
  onSubmit,
  order,
}) => {
  const [sendEmail, setSendEmail] = useState<boolean>(false);

  const { token } = useContext(AppStateContext);

  const sendInvoice = () => {
    apiCreateInvoice(token, order.id, sendEmail).then((res) => {
      if (res.ok) onSubmit(true);
      else onSubmit(false);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2
        style={{
          color: "var(--black)",
        }}
      >
        Create invoice for {order.orderNo}?
      </h2>

      <div>
        <span
          style={{
            color: "var(--black)",
          }}
        >
          Send out email:
        </span>
        <input
          type="checkbox"
          onChange={(e) => {
            setSendEmail(e.target.checked);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginTop: "1rem",
        }}
      >
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          color="error"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            sendInvoice();
            onClose();
          }}
          variant="contained"
          color="info"
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default InvoiceModal;
