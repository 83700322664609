import { LeadOptionsType } from "./CheckoutTypes";
import { UserType } from "./users/UserTypes";
import { AddressType } from "./AddressTypes";
import { ProductVMType } from "./products/ProductCommandsType";

export enum OrderStatusEnum {
  QUOTE = "QUOTE",
  REVIEW = "REVIEW",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  UNPAID = "UNPAID",
}

export type OrderItemType = {
  id: number;
  created: string;
  modified: string;
  sku: string;
  productId: number;
  product: ProductVMType;
  orderId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  unitTax: number;
  price: number;
  tax: number;
  weight: number;
};

export type SimpleOrderItemType = {
  id: number;
  created: string;
  modified: string;
  sku: string;
  productId: number;
  product: ProductVMType;
  orderId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  unitTax: number;
  price: number;
  tax: number;
  weight: number;
};

export type OrderType = {
  id: number;
  created: string;
  modified: string;
  orderNo: string;
  quoteId: number;
  status: OrderStatusEnum;
  author: UserType;
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  fees: number;
  discount: number;
  shippingTax: number;
  shipping: number;
  subtotalTax: number;
  subtotal: number;
  totalTax: number;
  total: number;
  billingAddress: AddressType;
  shippingAddress: AddressType;
  couponCode?: string;
  paymentMethod: string;
  shippingMethod: string;
  invoiceId?: string;
  items: OrderItemType[];
  leadTimeOption: LeadOptionsType;
  leadTimeDays: number;
  leadTimeStartDate: string;
  leadTimeEndDate: string;
  weight: number;
  invoiceEmail: string;
  eanNumber: string;
  poNumber: string;
  modified_by: string;
  created_by: string;
};

export type SimpleOrderType = {
  id: number;
  created: string;
  modified: string;
  orderNo: string;
  quoteId: number;
  status: OrderStatusEnum;
  authorId: number;
  firstName: string;
  lastName: string;
  email: string;
  fees: number;
  discount: number;
  shippingTax: number;
  shipping: number;
  subtotalTax: number;
  subtotal: number;
  totalTax: number;
  total: number;
  billingAddress: AddressType;
  shippingAddress: AddressType;
  couponCode?: string;
  paymentMethod: string;
  shippingMethod: string;
  invoiceId?: string;
  items: SimpleOrderItemType[];
  itemSummary: {
    cnc: number;
    print3d: number;
    sheet: number;
    other: number;
  };
  leadTimeOption: LeadOptionsType;
  leadTimeDays: number;
  leadTimeStartDate: string;
  leadTimeEndDate: string;
  weight: number;
  invoiceEmail: string;
  eanNumber: string;
  poNumber: string;
  modified_by: string;
  created_by: string;
};

export type OrderListType = {
  items: SimpleOrderType[];
  page: number;
  pageSize: number;
  total: number;
};

export type OrderSummaryType = {
  activeOrders: number;
  activeOrdersValue: number;
};
