import { FetchError } from "exceptions/exceptions";
import {
  CartItemType,
  CartListType,
  CartStatusEnum,
  CartType,
  PaymentMethodsType,
  ShippingMethodType,
  UpdateCartInfoCommandType,
  CartsSummaryType,
  UpdateCartItemCommandType,
} from "types/CartTypes";
import { AddressType } from "types/AddressTypes";
import { OrderType } from "types/OrderTypes";
import { LeadOptionsType } from "types/CheckoutTypes";
import { API_URL } from "./common";
import { LeadTimeSelectorType } from "../../types/ConfigTypes";

const ENDPOINT = `${API_URL}/v1/ecommerce/carts`;

export const apiGetCarts = (
  token: string,
  page: number,
  pageSize: number,
  sortKey: string = "id",
  sortDirection: "asc" | "desc" = "desc",
  status?: CartStatusEnum[],
  search?: string,
  userId?: number,
  customersOnly?: boolean
): Promise<CartListType> => {
  return fetch(
    `${ENDPOINT}?page=${page}&pageSize=${pageSize}&sortKey=${sortKey}&sortDirection=${sortDirection}${
      search ? "&search=" + search : ""
    }${status ? "&status=" + status.join("+") : ""}
    ${userId ? "&userId=" + userId : ""}
    ${customersOnly ? "&customersOnly=" + customersOnly : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    //console.log(res);
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiDeleteQuote = (token: string, id: number): Promise<boolean> => {
  return fetch(`${ENDPOINT}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
};

export const apiGetCart = (
  token: string,
  cartID: number,
  signal?: AbortSignal
): Promise<CartType> => {
  return fetch(`${ENDPOINT}/${cartID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal: signal,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetCartItems = (
  token: string,
  cartID: number
): Promise<CartItemType[]> => {
  return fetch(`${ENDPOINT}/${cartID}/items`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateCart = (
  token: string,
  customerId?: number
): Promise<CartType> => {
  return fetch(`${ENDPOINT}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: customerId ? JSON.stringify({ customerId: customerId }) : undefined,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateDraftCart = (token: string): Promise<CartType> => {
  return fetch(`${ENDPOINT}/draft`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

/**
 *
 * @param token
 * @param cartId Any existing cart, wether locked, open or any other status it can have, can be used to create a new cart with with the same data as this.
 *               If you need to create a cart from an order, then the cartId should be the quoteId found on the order.
 * @returns The object of the new cart created.
 */
export const apiCreateDraftCartFromCart = (
  token: string,
  cartId: number
): Promise<CartType> => {
  return fetch(`${ENDPOINT}/draft/${cartId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiLockCart = (
  token: string,
  cartID: number
): Promise<boolean> => {
  return fetch(`${ENDPOINT}/${cartID}/lock`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.ok;
  });
};

export const apiUnlockCart = (
  token: string,
  cartID: number
): Promise<boolean> => {
  return fetch(`${ENDPOINT}/${cartID}/unlock`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.ok;
  });
};

export const apiUnlockCartForEditing = (
  token: string,
  cartID: number
): Promise<Boolean> => {
  return fetch(`${ENDPOINT}/${cartID}/unlock`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiAddCartItem = (
  token: string,
  cartID: number,
  productId: number,
  quantity?: number
): Promise<CartItemType> => {
  const body = JSON.stringify({
    productId: productId,
    quantity: quantity,
  });
  return fetch(`${ENDPOINT}/${cartID}/items`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiUpdateCartItem = (
  token: string,
  cartID: number,
  cartItemID: number,
  command: UpdateCartItemCommandType
): Promise<CartItemType> => {
  const body = JSON.stringify(command);
  return fetch(`${ENDPOINT}/${cartID}/items/${cartItemID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiRemoveCartItem = (
  token: string,
  cartID: number,
  cartItemID: number
): Promise<boolean> => {
  return fetch(`${ENDPOINT}/${cartID}/items/${cartItemID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiClearCartItems = (
  token: string,
  cartId: number
): Promise<boolean> => {
  return fetch(`${ENDPOINT}/${cartId}/items`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
};

export const apiGetShippingMethods = (
  token: string,
  cartID: number
): Promise<ShippingMethodType[]> => {
  return fetch(`${ENDPOINT}/${cartID}/shippingMethods`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetPaymentMethods = (
  token: string,
  cartID: number
): Promise<PaymentMethodsType[]> => {
  return fetch(`${ENDPOINT}/${cartID}/paymentMethods`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetCartLeadTime = (
  token: string,
  cartID: number
): Promise<LeadTimeSelectorType> => {
  return fetch(`${ENDPOINT}/${cartID}/leadtime`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => data.options);
};

export const apiUpdateCartLeadTime = (
  token: string,
  cartID: number,
  leadtime: LeadOptionsType,
  date?: string
): Promise<CartType> => {
  const body = JSON.stringify({ leadtime: leadtime, date: date });
  return fetch(`${ENDPOINT}/${cartID}/leadtime`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    return res.json();
  });
};

export const apiUpdateCartInfo = (
  token: string,
  cartID: number,
  command: UpdateCartInfoCommandType
): Promise<CartType> => {
  const body = JSON.stringify(command);
  return fetch(`${ENDPOINT}/${cartID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiRequestReview = (
  token: string,
  cartID: number
): Promise<CartType> => {
  return fetch(`${ENDPOINT}/${cartID}/request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
};

export const apiSubmitReview = (
  token: string,
  cartID: number
): Promise<CartType> => {
  return fetch(`${ENDPOINT}/${cartID}/request`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    return res.json();
  });
};

export const apiConfirmOrder = (
  token: string,
  cartId: number,
  paymentMethod: string,
  eanNumber?: string
): Promise<OrderType> => {
  const body = JSON.stringify({
    paymentMethod: paymentMethod,
    eanNumber: eanNumber,
  });
  return fetch(`${ENDPOINT}/${cartId}/order`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then(async (res) => {
    if (res.ok) return res.json();
    else throw new FetchError(await res.text(), res.status);
  });
};

export const apiGetCartsSummary = (
  token: string
): Promise<CartsSummaryType> => {
  return fetch(`${ENDPOINT}/summary`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    //console.log(res);
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
