import React, { useEffect, useState } from 'react';
import { fetchToolEvents, ToolEvent, Tool } from './ToolsAPI'; // Import the fetchToolsData function
import { format } from 'date-fns';

const ToolEventList: React.FC<{ tool: Tool }> = ({ tool }) => {
  const [events, setEvents] = useState<ToolEvent[]>([]);

  useEffect(() => {
    // Fetch tools data when the component mounts
    async function fetchData() {
          const data = await fetchToolEvents(tool.id);
          setEvents(data);
      }
  
      fetchData();
  }, [tool]);


  return (
    <div>
      <h2>T{tool.number} Events</h2>
      <table>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Event</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={index}>
              <td>{format(event.timestamp, "yyyy")}</td>
              <td>{event.event}</td>
              <td>{event.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ToolEventList;