import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Route, Switch } from "react-router";
import OrderManagementUI from "../pages/order_management/layout/OrderManagementUI";
import MachineManagementUI from "../pages/machine_management/layout/MachineManagementUI";
import StockManagementUI from "../pages/stock_management/layout/StockManagementUI";
import CustomerManagementUI from "../pages/customer_management/layout/CustomerManagementUI";
import OrderView from "../pages/order_management/OrderView";
import {
  BadgeOutlined,
  Build,
  DashboardCustomize,
  HighQuality,
  Logout,
  Store,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory, Redirect, useLocation } from "react-router";

import ToolList from "tooldb/src/tools/ToolList";
import PlanByViewer from "tooldb/src/planby/PlanByViewer";
import JobList from "tooldb/src/jobs/JobList";
import PlanByEditor from "tooldb/src/planby/PlanByEditor";
import CNCPlanList from "tooldb/src/plans/CNCPlanList";

import { infoColor, whiteColor } from "../assets/colors";
import "./DashboardLayout.css";
import { Button, Grid, Skeleton, Tooltip } from "@mui/material";
import ProjectManagementUI from "../pages/project_management/layout/ProjectManagementUI";
import QuoteManagementUI from "pages/quote_management/layout/QuoteManagementUI";
import DraftEdit from "../pages/order_management/DraftEdit";
import { useContext, useEffect, useState } from "react";
import LoadingModal from "../components/Modals/LoadingModal";
import { Finishes, Materials } from "util/MaterialUtils";
import ThreeDModal from "components/Modals/ThreeDModal";
import EmployeeManagementUI from "../pages/employee_management/layout/EmployeeManagementUI";
import LogOutDialog from "../pages/authPages/LogOutDialog";
import AppStateContext from "../contexts/AppStateContext";
import styles from "./DashboardLayout.module.css";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { ReactComponent as LogoIcon } from "../assets/svg/logoBlack.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { apiGetMe } from "util/network/Users";
import PartnerManagementUI from "pages/partner_management/layout/PartnerManagementUI";
import OutsourcingManagmentUI from "pages/outsourcing_management/OutsourcingManagementUI";
import Dashboard from "company_overview/dashboard/Dashboard";
import SettingsUI from "pages/system_management/layout/SettingsUI";
import NewOrderBadge from "components/Badge/NewOrderBadge";
import NewSourcingBadge from "components/Badge/NewSourcingBadge";
import CustomerBasketsUI from "pages/customer_management/customer_baskets/layout/CustomerBasketsUI";
import { ReactComponent as PartnersIcon } from "../assets/svg/handshake-outline.svg";
import ReviewQuoteBadge from "components/Badge/ReviewQuoteBadge";
import CamUI from "pages/tasks_management/camPage/CamUI";
import ReviewUI from "pages/tasks_management/reviewPage/ReviewUI";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import GlobalSearch from "components/GlobalSearch/GlobalSearch";
import MaterialCutUI from "../pages/tasks_management/materialPage/MaterialCutUI.tsx";
import MillingUI from "../pages/tasks_management/millingPage/MillingUI.tsx";
import ShippingUI from "pages/tasks_management/shippingPage/ShippingUI.tsx";
import QualityControlUI from "pages/tasks_management/qcPage/QualityControlUI.tsx";

const drawerWidth = 240;

//  THE BELOW STYLING IS FROM MUI AND REQUIRED FOR THE LOOKS OF THE DRAWER! - https://mui.com/components/drawers/
const openedMixin = (theme) => ({
  background: infoColor[0],
  color: whiteColor,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  background: infoColor[0],
  color: whiteColor,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: "-8px",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
// END OF MUI DRAWER STYLING!

const DashboardLayout = () => {
  const { setShowLogoutModal, token, logUserOut, checkUserValid, employees } =
    useContext(AppStateContext);
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [openItem, setOpenItem] = useState(
    localStorage.getItem("openItem") || ""
  );
  let userRole = "admin";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [lastVisited, setLastVisited] = useState(() => {
    const storedTimestamp = localStorage.getItem("lastVisitedOrder");
    if (storedTimestamp) return { ORDER: parseInt(storedTimestamp, 10) };
    return { ORDER: null };
  });
  const [lastVisitedQuote, setLastVisitedQuote] = useState(() => {
    const storedTimestamp = localStorage.getItem("lastVisitedQuote");
    if (storedTimestamp) return { QUOTE: parseInt(storedTimestamp, 10) };
    return { QUOTE: null };
  });
  const [lastVisitedProd, setLastVisitedProd] = useState(() => {
    const storedTimestamp = localStorage.getItem("lastVisitedProd");
    if (storedTimestamp) return { PRODUCTION: parseInt(storedTimestamp, 10) };
    return { PRODUCTION: null };
  });
  const [searchValue, setSearchValue] = useStateWithSessionStorage(
    "",
    "globalSearchVal"
  );

  useEffect(() => {
    localStorage.setItem("openItem", openItem);
  }, [openItem]);

  // userRole="user"

  //Hvis navne skal ændres, gøres det her
  const list = [
    // { name: "OVERVIEW", path: "/dashboard" },
    { name: "DASHBOARD", path: "/dashboard" },
    {
      name: "SALES",
      pages: [
        { name: "ORDER", path: "/ordrestyring" },
        { name: "QUOTES", path: "/quotes" },
        { name: "BASKETS", path: "/baskets" },
        { name: "CUSTOMER", path: "/kunder" },
      ],
    },
    {
      name: "MANUFACTURING",
      pages: [
        { name: "OVERVIEW", path: "/produktion" },
        { name: "REVIEW", path: "/review" },
        { name: "CAM", path: "/cam" },
        { name: "PRODUCTION", path: "/allProdction" },
        { name: "MILLING", path: "/milling" },
        { name: "QC", path: "/qc" },
        { name: "SHIPPING", path: "/shipping" },
        { name: "PLANNING", path: "/planning" },
      ],
    },
    {
      name: "EQUIPMENT",
      pages: [
        { name: "TOOLS", path: "/tools" },
        { name: "HISTORY", path: "/history" },
      ],
    },
    {
      name: "OUTSOURCING",
      pages: [
        { name: "REQUISITIONS", path: "/outsourcing" },
        { name: "PARTNERS", path: "/partnere" },
      ],
    },
    {
      name: "SETTINGS",
      pages: [
        { name: "MASKINER", path: "/maskine" },
        { name: "BRUGERE", path: "/brugere" },
        { name: "SYSTEM", path: "/settings" },
      ],
    },

    // { name: "Lager", path: "/lagerstyring" },
    // { name: "Værktøj", path: "/værktøj" },
    // { name: "Kvalitet", path: "/kvalitetskontrol" },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!token || token === "") {
      logUserOut();
    }
    checkUserValid();
  }, [token]);

  useEffect(() => {
    Materials.loadMaterials();
    Finishes.loadFinishes();
  }, []);

  useEffect(() => {
    apiGetMe(token)
      .then((data) => {
        setFirstName(
          data.firstName ||
            (employees.items[0] && employees.items[0].firstName) ||
            ""
        );
        setLastName(
          data.lastName ||
            (employees.items[0] && employees.items[0].lastName) ||
            ""
        );
        setEmail(
          data.email || (employees.items[0] && employees.items[0].email) || ""
        );
        setCompany(
          data.defaultBillingAddress.company ||
            (employees.items[0] &&
              employees.items[0].defaultBillingAddress.company) ||
            ""
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  const iconSwitch = (index) => {
    switch (index) {
      case 0:
        return <DashboardIcon className={"listIcon"} />;
      case 1:
        return <Store className={"listIcon"} />;
      case 2:
        return <Build className={"listIcon"} />;
      case 3:
        return <PrecisionManufacturingIcon className={"listIcon"} />;
      case 4:
        return <PartnersIcon className={"listIcon"} />;
      case 5:
        return <SettingsIcon className={"listIcon"} />;
      case 6:
        return <HighQuality className={"listIcon"} />;
      // case 6:
      //   return <DashboardCustomize className={"listIcon"} />;
      // case 7:
      //   return <PersonIcon className={"listIcon"} />;
      // case 8:
      //   return <PrecisionManufacturingIcon className={"listIcon"} />;
      // case 9:
      //   return <BadgeOutlined className={"listIcon"} />;
      default:
        return <AssignmentTurnedInIcon className={"listIcon"} />;
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <ThreeDModal />
      <LoadingModal />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            background: "#000",
            justifyContent: open ? "flex-end" : "center",
          }}
        >
          {open ? (
            <>
              <span style={{ position: "relative", right: "15%" }}>
                <LogoIcon />
              </span>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon className={"listIcon"} />
              </IconButton>
            </>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <div
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            paddingLeft: "1rem",
            paddingTop: "2rem",
            paddingBottom: "0.5rem",
          }}
        >
          <span
            style={{
              textTransform: "uppercase",
              borderRadius: "20px",
              height: "fit-content",
              background: "var(--header)",
              padding: "0.5rem",
              fontWeight: "bold",
            }}
          >
            {email ? (
              `${email.substring(0, 3)}`
            ) : (
              <Skeleton
                variant="circular"
                width={22}
                height={22}
                sx={{ bgcolor: "grey.900" }}
              />
            )}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              paddingLeft: "1rem",
            }}
          >
            <span style={{ fontSize: "1.2rem" }}>
              {firstName && lastName ? (
                `${firstName} ${lastName}`
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={20}
                  sx={{ bgcolor: "grey.900" }}
                />
              )}
            </span>
            <span style={{ fontSize: "0.8rem" }}>
              {" "}
              {company ? (
                `${company}`
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ bgcolor: "grey.900", fontSize: "1rem" }}
                />
              )}
            </span>
          </div>
        </div>
        <div style={{ background: "#000" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "0.5rem",
            }}
          >
            <GlobalSearch />
          </div>
        </div>
        <List
          style={{ background: "#000" }}
          // onMouseEnter={() => setHover(item.name)}
          className={`${styles.item}`}
        >
          {list.map((item, index) => (
            <div key={item.name}>
              {item.pages ? (
                // Render dropdown with subpages
                <div>
                  <ListItem
                    onClick={() => {
                      if (open === item.name) {
                        setOpen(true);
                        setOpenItem(true);
                      } else {
                        setOpen(item.name);
                        setOpenItem(item.name);
                      }
                    }}
                    className={`${styles.item}`}
                    style={{ boxShadow: "0 1px 3px #075E5B" }}
                  >
                    <ListItemIcon style={{ minWidth: "36px" }}>
                      {iconSwitch(index)}
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={item.name}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                    {open === item.name ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open === item.name} timeout="auto">
                    <List component="div" disablePadding>
                      {open &&
                        item.pages.map((page) => {
                          return (
                            <ListItem
                              className={`${styles.item}`}
                              key={page.name}
                              onClick={() => {
                                history.push(page.path);
                                if (page.name === "ORDER") {
                                  const currentTimestamp = Date.now();
                                  setLastVisited({ ORDER: currentTimestamp });
                                  localStorage.setItem(
                                    "lastVisitedOrder",
                                    currentTimestamp.toString()
                                  );
                                } else if (page.name === "QUOTES") {
                                  const currentTimestamp = Date.now();
                                  setLastVisitedQuote({
                                    QUOTE: currentTimestamp,
                                  });
                                  localStorage.setItem(
                                    "lastVisitedQuote",
                                    currentTimestamp.toString()
                                  );
                                } else if (page.name === "PRODUCTION") {
                                  const currentTimestamp = Date.now();
                                  setLastVisitedProd({
                                    PRODUCTION: currentTimestamp,
                                  });
                                  localStorage.setItem(
                                    "lastVisitedProd",
                                    currentTimestamp.toString()
                                  );
                                }
                              }}
                              style={{
                                background:
                                  location.pathname === page.path
                                    ? "var(--header)"
                                    : null,
                                boxShadow: "0 0px 2px #075E5B",
                              }}
                            >
                              <ListItemText
                                style={{
                                  paddingLeft: "1.5rem",
                                  cursor: "pointer",
                                }}
                                primary={page.name}
                              />
                              {page.name === "ORDER" ? (
                                <NewOrderBadge
                                  newOrder={location.pathname === page.path}
                                  lastVisited={lastVisited.ORDER}
                                  setLastVisited={setLastVisited}
                                />
                              ) : page.name === "QUOTES" ? (
                                <ReviewQuoteBadge />
                              ) : // ) : page.name === "PRODUCTION" ? (
                              //   <NewProductionBadge
                              //     newProd={location.pathname === page.path}
                              //     lastVisitedProd={lastVisitedProd.PRODUCTION}
                              //     setLastVisitedProd={setLastVisitedProd}
                              //   />
                              page.name === "REQUISITIONS" ? (
                                <NewSourcingBadge
                                  newReq={location.pathname === page.path}
                                />
                              ) : (
                                ""
                              )}
                            </ListItem>
                          );
                        })}
                    </List>
                  </Collapse>
                </div>
              ) : (
                // Render single page
                <ListItem
                  onClick={() => history.push(openItem)}
                  style={{
                    background:
                      location.pathname === item.path ? "var(--header)" : null,
                    boxShadow: "0 1px 3px #075E5B",
                  }}
                  className={`${styles.item}`}
                >
                  <ListItemIcon style={{ minWidth: "36px" }}>
                    {iconSwitch(index)}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={item.name}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </ListItem>
              )}
            </div>
          ))}
        </List>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end",
            background: "#000",
          }}
        >
          <Button
            variant="contained"
            style={{
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #E02C4F",
              borderRadius: "0",
            }}
            onClick={() => setShowLogoutModal(true)}
            fullWidth
          >
            <ListItemIcon style={{ minWidth: "36px", color: "#fff" }}>
              <Logout />
            </ListItemIcon>
            {open && <span>SIGN OUT</span>}
          </Button>
        </div>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 0, width: `calc(100% - ${drawerWidth}px)` }}
      >
        <Grid container>
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/ordrestyring/:id" component={OrderView} />
            <Route path="/ordrestyring" component={OrderManagementUI} />
            <Route path="/maskine" component={MachineManagementUI} />
            <Route path="/produktion" component={ProjectManagementUI} />
            <Route path="/review" component={ReviewUI} />
            <Route path="/cam" component={CamUI} />
            <Route path="/allProdction" component={MaterialCutUI} />
            <Route path="/milling" component={MillingUI} />
            <Route path="/qc" component={QualityControlUI} />
            <Route path="/shipping" component={ShippingUI} />
            <Route path="/outsourcing/:id" component={OutsourcingManagmentUI} />
            <Route path="/outsourcing" component={OutsourcingManagmentUI} />
            <Route path="/lagerstyring" component={StockManagementUI} />
            <Route path="/tools" component={ToolList} />
            <Route
              path="/history"
              component={() => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <PlanByViewer />
                  <JobList />
                </div>
              )}
            />
            <Route
              path="/planning"
              component={() => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <PlanByEditor />
                  <CNCPlanList />
                </div>
              )}
            />
            {/* <Route path="/kvalitetskontrol" component={QualityManagementUI} /> */}
            <Route path="/quotes/:id" component={DraftEdit} />
            <Route path="/quotes" component={QuoteManagementUI} />
            <Route path="/baskets" component={CustomerBasketsUI} />
            <Route path="/kunder" component={CustomerManagementUI} />
            <Route path="/settings" component={SettingsUI} />
            <Route
              path="/brugere"
              component={
                userRole === "admin"
                  ? () => <EmployeeManagementUI />
                  : () => <div>Du har ikke adgang til bruger siden</div>
              }
            />
            <Route
              path="/partnere"
              component={
                userRole === "admin"
                  ? () => <PartnerManagementUI />
                  : () => <div>Du har ikke adgang til denne side</div>
              }
            />
            <Route path={"*"}>
              <Redirect to={"/dashboard"} />
            </Route>
          </Switch>
        </Grid>
      </Box>
      <LogOutDialog />
    </Box>
  );
};

export default DashboardLayout;
