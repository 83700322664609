import { FetchError } from "exceptions/exceptions";
import { API_URL } from "./common";

const ENDPOINT = `${API_URL}/v1/ecommerce/auth`;

export const apiLogin = (
  username: string,
  password: string
): Promise<string> => {
  const body = JSON.stringify({
    username: username,
    password: password,
  });
  return fetch(`${ENDPOINT}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiRequestPasswordReset = (email: string): Promise<string> => {
  const body = JSON.stringify({
    email: email,
  });
  return fetch(`${ENDPOINT}/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
