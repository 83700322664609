import { TableCell, Tooltip, TableRow } from "@mui/material";
import { Description, DoDisturb, Person } from "@mui/icons-material";
import {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import styles from "../../pages/order_management/orderedit_components/Styles/cartTableRowStyle.module.css";
import AppStateContext from "../../contexts/AppStateContext";
import { Finishes } from "../../util/MaterialUtils";
import { SelectedThreadsInfoType } from "../../types/RenderTypes";
import { DFMErrorType } from "../../types/NetworkTypes";
import ThreadDisplay from "./ThreadDisplay";
import { dangerColor } from "../../assets/colors";
import PreviewButton from "components/Generic/PreviewButton";
import { OrderItemType, OrderType } from "types/OrderTypes";
import {
  apiCheckDXFFile,
  apiGetDXFFile,
  apiGetPreviewImage,
  apiUpdateProduct,
} from "util/network/Products";
import ModelSelector from "pages/order_management/orderedit_components/ModelSelector";
import { apiUploadModelFile } from "util/network/Models";
import BlueprintSelector from "pages/order_management/orderedit_components/BlueprintSelector";
import {
  CNCProductDetailsType,
  ManufacturedProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
  SheetProductDetailsType,
} from "types/products/ProductCommandsType";
import { apiGetOrder, apiGetOrderItem } from "util/network/Orders";
import { ReactComponent as ThreadIcon } from "../../assets/svg/threadFile.svg";
import { useToast } from "contexts/ToastContext";
import { apiGetConversation } from "util/network/Messages";
import Popup from "components/ChatBox/Popup";

type PropsType = {
  item: OrderItemType;
  loading: boolean;
  setLoading: Function;
  checkall?: boolean;
  checked?: boolean;
  dfmwarning?: boolean;
  validDFM?: boolean;
  dfmErrors?: DFMErrorType;
};

const ManufacturedCartTableRowView: FunctionComponent<PropsType> = ({
  item,
  loading,
  setLoading,
  checkall,
  dfmwarning,
  dfmErrors,
}) => {
  const { strings, token, user } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [code, setCode] = useState<"dk" | "gb">(strings.code);
  const [colorStepExist, setColorStepExist] = useState<boolean>();
  const [details, setDetails] = useState<
    CNCProductDetailsType | Print3DProductDetailsType | SheetProductDetailsType
  >();
  const detailsCnc = item.product.details as CNCProductDetailsType;
  const [modelId, setModelId] = useState<number>();
  const [finish, setFinish] = useState<string[]>();
  const [material, setMaterial] = useState<string>();
  const [threads, setThreads] = useState<SelectedThreadsInfoType[]>([]);
  const [comment, setComment] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(item.name);
  const [blueprint, setBlueprint] = useState<string | null>(null);
  const [dxfFileExists, setDxfFileExists] = useState<boolean>(false);

  const [materialText, setMaterialText] = useState<string>("");
  const [finishText, setFinishText] = useState<string>("");
  const [preview, setPreview] = useState<string>();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [itemData, setItemData] = useState<OrderType>();
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  const [isLastMessage, setIsLastMessage] = useState<boolean>(false);

  const handleButtonClickInternal = () => {
    setIsNewMessage(false);
    setShowInternalChat((prev) => !prev);
  };

  useEffect(() => {
    const checkIfNewMessage = () => {
      apiGetConversation(item.productId + "internal", token)
        .then((res) => {
          if (res.lastMessage) setIsLastMessage(true);
          if (
            res.lastMessage &&
            res.lastMessage?.senderId !== user!.id.toString() &&
            !res.lastMessage?.readBy.includes(user!.id.toString())
          )
            setIsNewMessage(true);
          else setIsNewMessage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    checkIfNewMessage();
  }, [item.productId, token, user]);

  useEffect(() => {
    setMaterialText(material ?? "");
    Finishes.loadFinishes().then((res) => {
      setFinishText(
        finish
          ? finish?.length > 1
            ? finish?.filter((i) => i !== "standard").join("+")
            : finish.join("+")
          : ""
      );
    });
  }, [finish, material]);

  useEffect(() => {
    const details = {
      ...item.product.details,
      finish: (
        (item.product.details as ManufacturedProductDetailsType)?.finish as any
      ).split(","),
    } as ManufacturedProductDetailsType;
    setDetails(details);
    setModelId(details.modelId);
    setFinish(
      details.customFinish
        ? [details.customFinish.name]
        : details.finish ?? ["Missing"]
    );
    setMaterial(
      details.customMaterial
        ? details.customMaterial.name
        : details.standardMaterial?.name ?? details.material
    );
    setThreads(details.threads);
    setComment(details.comment);
    if (item.product.productType === ProductTypeEnum.CNC) {
      setBlueprint((details as CNCProductDetailsType).blueprint);
    } else if (item.product.productType === ProductTypeEnum.SHEET) {
      setBlueprint((details as SheetProductDetailsType).blueprint);
    }
    if (
      item.product.productType === ProductTypeEnum.SHEET &&
      details.model.status != "Bad"
    ) {
      apiCheckDXFFile(token, details.modelId).then((res) => {
        setDxfFileExists(res);
      });
    }
  }, [item]);

  useEffect(() => {
    if (details?.threads && details?.threads?.length > 0) {
      setColorStepExist(true);
    } else {
      setColorStepExist(false);
    }
  });

  useEffect(() => {
    setCode(strings.code);
  }, [strings.code]);

  useEffect(() => {
    refresh();
  }, [strings, modelId]);

  const refresh = () => {
    if (modelId) {
      apiGetOrderItem(token, item.id).then((res) => {
        if (res) {
          setName(res.name);
        }
      });
      apiGetPreviewImage(token, modelId).then((res) => {
        setPreview(res);
        setLoading(false);
      });
    }
  };

  const previewPicture = useMemo(() => {
    return modelId ? (
      <PreviewButton
        productId={item.productId}
        modelId={modelId}
        picture={preview}
        size={"small"}
      />
    ) : (
      <div className={styles.previewImage} />
    );
  }, [preview, modelId]);

  const handleUpdateModel = (id: number, file: File) => {
    setLoading(true);
    apiUploadModelFile(token, file, ProductTypeEnum.CNC).then((res) => {
      if (res.filesAdded > 1) {
        addToast({
          type: "error",
          message:
            "The uploaded file contained more than one solid. Please upload a file with only one solid.",
        });
        return;
      }
      let command = {};
      if (item.product.productType === ProductTypeEnum.CNC) {
        command = {
          quantity: item.quantity,
          threads: [],
          blueprint: blueprint,
          comment: comment,
          modelId: res.filesList[0].id,
        };
      } else if (item.product.productType === ProductTypeEnum.PRINT3D) {
        command = {
          quantity: item.quantity,
          threads: [],
          comment: comment,
          modelId: res.filesList[0].id,
        };
      } else if (item.product.productType === ProductTypeEnum.SHEET) {
        command = {
          quantity: item.quantity,
          threads: [],
          blueprint: blueprint,
          comment: comment,
          modelId: res.filesList[0].id,
        };
      }
      apiUpdateProduct(token, item.product.id, command).then((res) => {
        addToast({
          type: "success",
          message: (
            <>
              New model was <strong>updated</strong> successfully.
            </>
          ),
        });
        refresh();
      });
    });
  };

  useEffect(() => {
    apiGetOrder(token, item.orderId).then((data) => {
      if (data.id) {
        setItemData(data);
      }
    });
  }, [token, item.orderId]);

  return (
    <TableRow key={item.sku} className={styles.cellsNoWrap}>
      <TableCell align="left">{itemData?.status ?? "Loading..."}</TableCell>
      <TableCell align="left">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>{previewPicture}</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>#{item.productId}</span>
            <span style={{ fontSize: "0.75rem" }}>
              {name} ({item.product.productType})
            </span>
          </div>
        </div>
      </TableCell>
      <TableCell align="left">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "0.7rem",
          }}
        >
          {item.product.productType == ProductTypeEnum.CNC ? (
            <>
              <span>
                {detailsCnc.tolerance === 0
                  ? "ISO 2768-medium"
                  : detailsCnc.tolerance === 1
                  ? "ISO 2768-fine"
                  : ""}
              </span>
              <span>
                {detailsCnc.surfaceQuality === 0
                  ? "Standard Ra 1.6"
                  : detailsCnc.surfaceQuality === 1
                  ? "Fine Ra 0.8"
                  : ""}
              </span>
              <span>
                {detailsCnc.materialCertificate == true
                  ? "Certificate"
                  : detailsCnc.materialCertificate == false
                  ? "-"
                  : ""}
              </span>
            </>
          ) : item.product.productType == ProductTypeEnum.SHEET ? (
            detailsCnc.materialCertificate == true ? (
              "Certificate"
            ) : detailsCnc.materialCertificate == false ? (
              "-"
            ) : (
              ""
            )
          ) : null}
        </div>
      </TableCell>
      <TableCell align="left">
        <div>
          <div style={{ textTransform: "uppercase" }}>{materialText}</div>
          <div style={{ fontSize: "0.75rem" }}>
            {details?.model
              ? (details?.model.dimX?.toFixed(1) ?? "?") +
                " x " +
                (details?.model.dimY?.toFixed(1) ?? "?") +
                " x " +
                (details?.model.dimZ?.toFixed(1) ?? "?")
              : "Loading..."}
          </div>
        </div>
      </TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {finishText}
      </TableCell>
      <TableCell align="center" style={{ padding: 0 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <ModelSelector
              id={modelId}
              name={name ?? "Name missing"}
              colorFileExist={colorStepExist}
              handleUpdateModel={handleUpdateModel}
            />
          </div>
          <div style={{ paddingTop: "0.5rem" }}>
            {details ? (
              <div
                className={"downloadLink"}
                onClick={() =>
                  apiGetDXFFile(token, `${name}.dxf`, details.model.id)
                }
              >
                <Tooltip
                  title={
                    dxfFileExists
                      ? "Download DXF file"
                      : "No DXF file found, click to generate"
                  }
                >
                  <Description
                    style={{
                      fill: dxfFileExists ? "#E0A900" : "#EBEBEB",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <DoDisturb sx={{ color: dangerColor[0] }} />
            )}
          </div>
          <div>
            <BlueprintSelector
              id={item.productId}
              name={blueprint}
              pdfExists={blueprint !== null}
              isOrder={true}
              onUpdated={(newName) => {
                setBlueprint(newName);
              }}
            />
          </div>
          <div style={{ paddingTop: "0.5rem" }}>
            {threads ? (
              threads?.length > 0 ? (
                <div>
                  <Tooltip
                    title={
                      <div style={{ backgroundColor: "white" }}>
                        <ThreadDisplay specs={threads} />{" "}
                      </div>
                    }
                  >
                    <ThreadIcon
                      style={{ fill: "#E0A900", cursor: "pointer" }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <ThreadIcon style={{ fill: "#EBEBEB" }} />
              )
            ) : (
              <DoDisturb sx={{ color: dangerColor[0] }} />
            )}
          </div>
          <div style={{ paddingLeft: "1rem", paddingTop: "0.5rem" }}>
            <Tooltip
              title={"Internal chat"}
              onClick={handleButtonClickInternal}
            >
              <span>
                <Person
                  sx={{
                    color:
                      isLastMessage || details?.comment ? "#FFC01F" : "#EBEBEB",
                    cursor: "pointer",
                  }}
                />
              </span>
            </Tooltip>
            {showInternalChat && (
              <Popup
                customerComment={details?.comment}
                customer={item.product.author!}
                productId={item.product.id}
                selectedParticipant={"employee"}
                onClose={handleButtonClickInternal}
              />
            )}
          </div>
        </div>
      </TableCell>
      {/* <TableCell>
        <DFMErrors dfmErrors={dataDFM} />
      </TableCell> */}
      <TableCell align="center">{item.quantity}pcs</TableCell>
      <TableCell align="right">
        <div>
          <div style={{ fontSize: "1rem", padding: "none" }}>
            {item.price} kr
          </div>
          <div style={{ fontSize: "0.7rem", padding: "none" }}>
            {item.unitPrice} kr/pcs
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ManufacturedCartTableRowView;
