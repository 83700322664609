import React, { useContext, useEffect, useState } from "react";
import {
  ProductionTaskEnum,
  ProductionTaskStateEnum,
  ProductionTaskType,
  ProductionVMType,
  UpdateProductionTaskType,
} from "types/ProductionType";
import TaskTableRow from "../TaskTableRow";
import PartStateButton from "components/Buttons/PartStateButton";
import { ProductionPartState } from "../camPage/CamRow";
import { apiUpdateProductionTask } from "util/network/Productions";
import AppStateContext from "contexts/AppStateContext";
import { useToast } from "contexts/ToastContext";

type PropsType = {
  project: ProductionVMType;
};

const ReviewRow: React.FC<PropsType> = ({ project }) => {
  const { token } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [tasks, setTasks] = useState<ProductionTaskType[]>(project.tasks);
  const [taskStatus, setTaskStatus] = useState<ProductionPartState>({
    type: undefined,
    state: undefined,
  });

  useEffect(() => {
    const reviewTask = project.tasks.find((tsk) => tsk.name === "review");

    if (reviewTask && reviewTask.state === ProductionTaskStateEnum.ACTIVE) {
      setTaskStatus({
        type: ProductionTaskEnum.REVIEW,
        state: ProductionTaskStateEnum.ACTIVE,
      });
    } else if (
      reviewTask &&
      reviewTask.state === ProductionTaskStateEnum.TODO
    ) {
      setTaskStatus({
        type: ProductionTaskEnum.REVIEW,
        state: ProductionTaskStateEnum.TODO,
      });
    } else {
      setTaskStatus({ type: undefined, state: undefined });
    }
  }, [project.tasks]);

  const handleTaskUpdate = (changes: Partial<ProductionTaskType>) => {
    if (project) {
      const task = tasks.find((task) => task.id === changes.id);
      if (!task) {
        return;
      }
      const updatedTask = {
        ...task,
        ...changes,
      } as ProductionTaskType;

      setTasks((prevTasks) => {
        return prevTasks.map((prevTask) => {
          if (prevTask.id === changes.id) {
            return {
              ...prevTask,
              ...changes,
            };
          } else if (changes.state) {
            if (
              changes.state === ProductionTaskStateEnum.DONE ||
              changes.state === ProductionTaskStateEnum.ACTIVE
            ) {
              if (prevTask.priority < updatedTask.priority) {
                return {
                  ...prevTask,
                  state: ProductionTaskStateEnum.DONE,
                };
              } else {
                return prevTask;
              }
            } else if (updatedTask.state === ProductionTaskStateEnum.TODO) {
              if (prevTask.priority > updatedTask.priority) {
                return {
                  ...prevTask,
                  state: ProductionTaskStateEnum.TODO,
                };
              } else {
                return prevTask;
              }
            } else {
              return prevTask;
            }
          } else {
            return prevTask;
          }
        });
      });
      let id = Number(changes.id);
      if (isNaN(id)) {
        return;
      }
      const command = {
        id: id,
        name: changes.name,
        description: changes.description,
        state: changes.state,
        priority: changes.priority,
        progress: changes.progress,
        progressMax: changes.progressMax,
        deadline: changes.deadline,
        leadTime: changes.leadTime,
        workTime: changes.workTime,
        users: changes.users?.map((user) => user.id),
        machines: changes.machines?.map((machine) => machine.id),
      } as UpdateProductionTaskType;
      apiUpdateProductionTask(token, id, command)
        .then((res) => {
          if (res) {
            addToast({
              type: "success",
              message: "Task updated",
              time: 1000,
            });
            if (updatedTask.state === ProductionTaskStateEnum.DONE) {
              const tasksDone = tasks.every(
                (task) =>
                  task.state === ProductionTaskStateEnum.DONE ||
                  task.id === updatedTask.id
              );
            }
          }
        })
        .catch((error) => {
          addToast({
            type: "error",
            message: "Failed to update task",
            keep: true,
          });
          console.error("Failed to update task:", error);
        });
    }
  };

  const getTaskToDisplay = () => {
    const reviewTaskIndex = project.tasks.findIndex(
      (tsk) => tsk.name === "review" || tsk.description?.includes("review")
    );

    if (reviewTaskIndex === -1) {
      return null;
    }

    const reviewTask = project.tasks[reviewTaskIndex];

    if (reviewTask.state === ProductionTaskStateEnum.DONE) {
      return null;
    }

    for (let i = 0; i < reviewTaskIndex; i++) {
      const task = project.tasks[i];
      if (
        task.state === ProductionTaskStateEnum.TODO ||
        task.state === ProductionTaskStateEnum.ACTIVE
      ) {
        return task;
      }
    }

    if (
      reviewTask.state === ProductionTaskStateEnum.TODO ||
      reviewTask.state === ProductionTaskStateEnum.ACTIVE
    ) {
      return reviewTask;
    }

    return null;
  };

  const taskToDisplay = getTaskToDisplay();

  return (
    <>
      {taskToDisplay && (
        <TaskTableRow
          task={taskToDisplay}
          project={project}
          buttonComponent={
            <PartStateButton
              key={taskStatus.state}
              type={taskStatus.type}
              state={taskToDisplay.state}
              project={project}
              onChange={handleTaskUpdate}
              task={taskToDisplay}
              title={taskToDisplay.description ?? taskToDisplay.name}
            />
          }
          review
          taskStatus={taskStatus}
        />
      )}
    </>
  );
};

export default ReviewRow;
