import AppStateContext from "contexts/AppStateContext";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { OrderType, SimpleOrderType } from "types/OrderTypes";
import { apiGetOrder, apiGetOrders } from "util/network/Orders";
import styles from "./globalSearch.module.css";
import { API_URL } from "util/network/common";
import { ManufacturedProductDetailsType } from "types/products/ProductCommandsType";
import SearchField from "components/SearchField";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const GlobalSearch = () => {
  const { token, setShowThreeDModal, setProductId, showThreeDModal } =
    useContext(AppStateContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [orders, setOrders] = useState<SimpleOrderType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [modelIds, setModelIds] = useState<OrderType[]>([]);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const history = useHistory();

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setOrders([]);
      setDropdownVisible(false);
      setLoading(false);
      return;
    }

    setLoading(true);
    apiGetOrders(token, 1, 10, "id", undefined, undefined, searchTerm)
      .then((res) => {
        setOrders(res.items);
        setDropdownVisible(true);
      })
      .catch((error) => {
        console.error("Failed to fetch orders:", error);
        setLoading(false);
      });
  }, [searchTerm, token]);

  useEffect(() => {
    if (orders.length === 0) {
      setDropdownVisible(false);
      return;
    }

    setLoading(true);
    Promise.all(
      orders.map((order) =>
        apiGetOrder(token, order.id)
          .then((res) => res)
          .catch((err) => {
            console.error("Failed to fetch order details:", err);
            return null;
          })
      )
    )
      .then((results) => {
        const filteredResults = results.filter(
          (result) => result !== null
        ) as OrderType[];
        setModelIds(filteredResults);
        setDropdownVisible(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orders, token]);

  const handleSelectOrder = (orderId: number) => {
    history.push(`/ordrestyring/${orderId}`);
    if (searchTerm == "") setDropdownVisible(false);
    setSearchTerm("");
  };

  const isExactMatch = (searchTerm: string, id: number): boolean => {
    return searchTerm === id.toString();
  };

  useEffect(() => {
    if (dropdownVisible && focusedIndex !== null && dropdownRef.current) {
      const items = dropdownRef.current.children;
      if (items[focusedIndex]) {
        (items[focusedIndex] as HTMLElement).focus();
      }
    }
  }, [focusedIndex, dropdownVisible]);

  const openModal = (productId: number) => {
    console.log("Opening modal for product ID:", productId);
    setProductId(productId);
    setShowThreeDModal(true);
    setFocusedIndex(null);
    setDropdownVisible(false);
    if (!showThreeDModal) setDropdownVisible(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (
        focusedIndex !== null &&
        focusedIndex >= 0 &&
        focusedIndex < modelIds.length
      ) {
        const order = modelIds[focusedIndex];
        const matchingItem = order.items.find((item) =>
          isExactMatch(searchTerm, item.productId)
        );
        if (matchingItem) {
          openModal(matchingItem.productId);
          handleSelectOrder(matchingItem.orderId);
        } else {
          handleSelectOrder(order.id);
        }
        setDropdownVisible(false);
      } else {
        const searchTerm = e.currentTarget.value;
        const matching = modelIds.filter((order) =>
          order.items.some((item) => item.name.includes(searchTerm))
        );
        setModelIds(matching.slice(0, 6));
        setDropdownVisible(true);
      }
    } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      let newIndex = focusedIndex !== null ? focusedIndex : -1;
      newIndex = e.key === "ArrowDown" ? newIndex + 1 : newIndex - 1;

      if (newIndex >= 0 && newIndex < modelIds.length) {
        setFocusedIndex(newIndex);
      }
    }
  };

  const skeletonLoaders = Array.from({ length: 8 }, (_, index) => (
    <li key={index} className={styles.dropdownItemLoader}>
      <Skeleton variant="rectangular" width={184} height={37} />
    </li>
  ));

  return (
    <div className={styles.searchContainer}>
      <SearchField
        value={searchTerm}
        onChange={(value) => {
          if (value.startsWith("#")) {
            value = value.slice(1);
          }
          setSearchTerm(value);
          if (value == "") {
            setDropdownVisible(false);
          }
        }}
        placeholder="Search..."
        onFocus={() => {
          if (searchTerm !== "" || modelIds.length > 0) {
            setDropdownVisible(true);
          }
        }}
        onKeyDown={handleKeyDown}
      />
      {dropdownVisible && (
        <ul className={styles.dropdown}>
          {loading ? (
            skeletonLoaders
          ) : modelIds.length > 0 ? (
            modelIds.map((order, index) => {
              const matchingItem = order.items.find((item) =>
                isExactMatch(searchTerm, item.productId)
              );
              if (matchingItem) {
                return (
                  <li
                    key={matchingItem.productId}
                    onClick={() => openModal(matchingItem.productId)}
                    tabIndex={0}
                    className={`${styles.dropdownItem} ${
                      index === focusedIndex ? styles.selected : ""
                    }`}
                  >
                    <div className={`${styles.flexR} ${styles.pd05}`}>
                      <img
                        width="40px"
                        src={`${API_URL}/v1/ecommerce/models/${
                          (
                            matchingItem.product
                              .details as ManufacturedProductDetailsType
                          ).modelId
                        }/preview`}
                      />
                      <span>
                        #{matchingItem.productId} {order.billingAddress.company}
                      </span>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li
                    key={order.id}
                    onClick={() => handleSelectOrder(order.id)}
                    tabIndex={-1}
                    className={`${styles.dropdownItem} ${
                      index === focusedIndex ? styles.selected : ""
                    }`}
                  >
                    <div className={`${styles.pd05} ${styles.flexR}`}>
                      <span className={styles.orderId}>#{order.orderNo}</span>
                      <div className={styles.pdL05}>
                        {order.billingAddress.company}
                      </div>
                    </div>
                  </li>
                );
              }
            })
          ) : (
            <li className={styles.dropdownNoItem}>
              <span className={styles.noItem}>No items found</span>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
export default GlobalSearch;
