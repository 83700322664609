import React, { useEffect, useState } from "react";
import styles from "./quantitySelector.module.css";
import { ProductionTaskType, ProductionVMType } from "types/ProductionType";
import { ManufacturedProductDetailsType } from "types/products/ProductCommandsType";

type RowProps = {
  isDisabled?: boolean;
  updateTasks: (
    taskId: string,
    updatedTasks: Partial<ProductionTaskType>
  ) => void;
  task: Partial<ProductionTaskType>;
  project?: ProductionVMType;
};

const QuantitySelector: React.FC<RowProps> = ({
  isDisabled,
  updateTasks,
  task,
  project,
}) => {
  const [quantity, setQuantity] = useState<number>(task.progress || 0);
  const details =
    project && (project.product.details as ManufacturedProductDetailsType);
  const [maxQuantity, setMaxQuantity] = useState<number>(
    details?.quantity || 1
  );

  const incrementQuantity = () => {
    setQuantity((prev) => {
      const newQuantity = prev + 1;
      if (task.id) {
        updateTasks(task.id, { progress: newQuantity });
      }
      return newQuantity;
    });
  };

  const decrementQuantity = () => {
    setQuantity((prev) => {
      const newQuantity = prev > 0 ? prev - 1 : prev;
      if (task.id) {
        updateTasks(task.id, { progress: newQuantity });
      }
      return newQuantity;
    });
  };

  const incrementMaxQuantity = () => {
    setMaxQuantity((prevMax) => {
      const newMaxQuantity = prevMax + 1;
      if (task.id) {
        updateTasks(task.id, { progressMax: newMaxQuantity });
      }
      return newMaxQuantity;
    });
  };

  const decrementMaxQuantity = () => {
    setMaxQuantity((prevMax) => {
      const newMaxQuantity = prevMax - 1;
      if (task.id) {
        updateTasks(task.id, { progressMax: newMaxQuantity });
      }
      return newMaxQuantity;
    });
  };

  useEffect(() => {
    setQuantity(task.progress || 0);
    setMaxQuantity(details?.quantity || 1);
  }, [task, details]);

  return (
    <div className={styles.quantitySelector}>
      <div className={styles.controls}>
        {!isDisabled && (
          <button
            onClick={incrementQuantity}
            className={styles.increase_button}
          >
            +
          </button>
        )}
        {!isDisabled && (
          <button
            onClick={decrementQuantity}
            className={styles.decrease_button}
          >
            -
          </button>
        )}
      </div>
      <div className={styles.inputGroup}>
        <input
          value={quantity}
          onChange={(event) => {
            const value = parseInt(event.target.value, 10);
            if (!isNaN(value) && value >= 0) {
              setQuantity(value);
              if (task.id) {
                updateTasks(task.id, { progress: value });
              }
            }
          }}
          type="number"
          className={styles.quantityInput}
          disabled={isDisabled}
        />
        <span className={styles.separator}>/</span>
        <input
          value={maxQuantity}
          type="number"
          className={styles.maxQuantityInput}
          disabled={isDisabled}
          onChange={(event: any) => {
            const value = parseInt(event.target.value, 10);
            setQuantity(value);
            if (!isNaN(value) && task.id) {
              updateTasks(task.id, { progress: value });
            }
          }}
        />
        <div className={styles.controls}>
          {!isDisabled && (
            <button
              onClick={incrementMaxQuantity}
              className={styles.increase_button}
            >
              +
            </button>
          )}
          {!isDisabled && (
            <button
              onClick={decrementMaxQuantity}
              className={styles.decrease_button}
            >
              -
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuantitySelector;
