import React from "react";
import { useMediaQuery } from "react-responsive";
import { Channel, GridItemProps, Program, useEpg, Epg, Layout } from "@nessprim/planby";
import { fetchJobsResources } from "./helpers";

// Import theme
import { theme } from "./helpers/theme";

// ----- Please uncomment the following lines to see the custom components -----
// Import components
import {
  CustomTimeline,
  ChannelItem,
  Line,
  LiveTime,
  ProgramItemView,
} from "./components";

function PlanByViewer() {
  const todayDateString = new Date().toISOString().split('T')[0];
  const [viewDate, setViewDate] = React.useState<Date>(new Date());
  const ViewDateString = viewDate.toISOString().split('T')[0];
  const startDateTime = new Date(viewDate.getFullYear(), viewDate.getMonth(), viewDate.getDate(), 7, 0, 0);
  const endDateTime = new Date(viewDate.getFullYear(), viewDate.getMonth(), viewDate.getDate(), 20, 0, 0);

  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [epg, setEpg] = React.useState<Program[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const channelsData = React.useMemo(() => channels, [channels]);
  const epgData = React.useMemo(() => epg, [epg]);

  const { getEpgProps, getLayoutProps, getLayoutData } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: 2400,
    startDate: startDateTime.toISOString(),
    endDate: endDateTime.toISOString(),
    isVerticalMode: false,
    sidebarWidth: 100,
    itemHeight: 50,
    channelMapKey: "id",
    programChannelMapKey: "machine",
    isBaseTimeFormat: false,
    isResize: false,
    overlap: {
      enabled: true,
      mode: "stack",
      layerOverlapLevel: 0.4,
    },
    dnd: { enabled: false, mode: "multi-rows" },
    grid: {
      enabled: true,
      //onGridItemClick: (props: GridItemProps) => alert(JSON.stringify(props)),
      hoverHighlight: false,
    },
    theme,
  });

  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    // const channels = epgChannels;
    const { epg, channels } = (await fetchJobsResources(viewDate)) as {
      epg: Program[];
      channels: Channel[];
    };
    setEpg(epg);
    setChannels(channels);
    setIsLoading(false);
  }, [viewDate]);

  React.useEffect(() => {
    handleFetchResources();
  }, [handleFetchResources]);


  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    setViewDate(newDate);
  };

  return (
    <div>
      <input type="date" id="start-date" name="start-date" value={ViewDateString} max={todayDateString} onChange={handleDateChange} />

      <div style={{ height: "200px", width: "99%" }}>
        <Epg isLoading={isLoading} {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            // ----- Please uncomment the following lines to see the custom components -----
            // renderLine={(props) => <Line {...props} />}
            renderCurrentTime={(props) => <LiveTime {...props} />}
            renderTimeline={(props) => <CustomTimeline {...props} />}
            renderProgram={({ program, ...rest }) => (
              <ProgramItemView
                key={`${program.data.channelUuid}-${program.data.id}`}
                program={program}
                {...rest}
              />
            )}
            renderChannel={({ channel, ...rest }) => (
              <ChannelItem key={channel.uuid} channel={channel} {...rest} />
            )}
          />
        </Epg>
      </div>
    </div>
  );
}

export default PlanByViewer;
