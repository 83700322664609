import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { formatter } from "../../../util/formatter";
import { FunctionComponent } from "react";

type PropsType = {
  discount?: number;
  coupon_code?: string;
  subtotal: number;
  subtotal_tax: number;
  shipping: number;
  shipping_tax: number;
  total: number;
  total_tax: number;
  fees?: number;
  disabled?: boolean;
  orderWeight: number;
};
const AmountTable: FunctionComponent<PropsType> = ({
  discount,
  coupon_code,
  subtotal,
  subtotal_tax,
  shipping,
  shipping_tax,
  total,
  total_tax,
  fees,
  disabled,
  orderWeight,
}) => {
  return (
    <Table sx={{ border: 0 }} size="small">
      <TableBody>
        {fees != undefined && (
          <TableRow>
            <TableCell>Fees</TableCell>
            <TableCell align="right">{formatter.format(fees ?? 0)}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Shipping</TableCell>
          <TableCell align="right">{formatter.format(shipping)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Weight</TableCell>
          <TableCell align="right">{orderWeight.toFixed(1)}kg</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ height: 0.1 }}>Rabat</TableCell>
          <TableCell align="right">{formatter.format(discount ?? 0)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Subtotal excl. VAT</TableCell>
          <TableCell align="right">{formatter.format(total)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>VAT (25%)</TableCell>
          <TableCell align="right">
            {formatter.format(total_tax ?? 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight={"bold"}>Samlet beløb inkl. moms</Typography>
          </TableCell>
          <TableCell align="right" style={{ fontWeight: "bold" }}>
            {formatter.format(total + total_tax)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default AmountTable;
