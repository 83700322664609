import { Checkbox, CircularProgress } from "@mui/material";
import React from "react";
import styles from "./StatusBox.module.css";

type StatusProps = {
  title: string;
  amount?: number | string;
  machineTasks?: string | number;
  smallText?: string;
  checked?: boolean;
  onChange?: (event: any) => void;
  disabled?: boolean;
  name?: string;
};

const StatusBox: React.FC<StatusProps> = ({
  title,
  amount,
  machineTasks,
  smallText,
  checked,
  onChange,
  disabled,
  name,
}) => {
  const isLoading = amount === undefined || amount === null;
  return (
    <>
      <div
        style={{
          paddingBottom: "1rem",
          paddingRight: "1rem",
        }}
      >
        <div
          className={`${styles.container} ${
            onChange ? styles.containerClickable : ""
          } ${checked ? styles.active : ""}`}
          onClick={() => {
            if (onChange) {
              onChange({
                target: {
                  name: name,
                  checked: !checked,
                },
              });
            }
          }}
        >
          <div
            style={{
              color: "#000",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#474949" }}>{title}</span>
            {isLoading && !machineTasks ? (
              <span style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size="1rem" />
              </span>
            ) : (
              <span>{amount}</span>
            )}
            <span style={{ textAlign: "center" }}>{machineTasks}</span>
            <span
              style={{
                textAlign: "center",
                fontSize: "0.6rem",
                fontWeight: "normal",
              }}
            >
              {smallText}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default StatusBox;
