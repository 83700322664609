import { TableCell, TableRow, Tooltip } from "@mui/material";
import PreviewButton from "components/Generic/PreviewButton";
import AppStateContext from "contexts/AppStateContext";
import {
  FunctionComponent,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { DoDisturb, Person } from "@mui/icons-material";
import {
  checkColorStep,
  fetchPDFFile,
  fetchSTEPFile,
  fetchThreadFile,
} from "util/FileDownload";
import { dangerColor } from "assets/colors";
import { ReactComponent as PdfIcon } from "../../../assets/svg/pdfFile.svg";
import { ReactComponent as StepIcon } from "../../../assets/svg/stepFile.svg";
import { ReactComponent as ThreadsIcon } from "../../../assets/svg/threadFile.svg";
import { CartItemType } from "types/CartTypes";
import {
  CNCProductDetailsType,
  Print3DProductDetailsType,
  ProductTypeEnum,
  SheetProductDetailsType,
} from "types/products/ProductCommandsType";
import Popup from "components/ChatBox/Popup";
import { apiGetConversation } from "util/network/Messages";
import ThreadDisplay from "components/OrderView/ThreadDisplay";

type PropsType = {
  item: CartItemType;
};

const BasketConfigRow: FunctionComponent<PropsType> = ({ item }) => {
  const { token, user } = useContext(AppStateContext);
  const [pdfExists, setPdfExists] = useState<boolean>();
  const [colorStepExist, setColorStepExist] = useState<boolean>();

  const [details, setDetails] = useState<
    CNCProductDetailsType | Print3DProductDetailsType | SheetProductDetailsType
  >();
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  const [isLastMessage, setIsLastMessage] = useState<boolean>(false);
  const handleButtonClickInternal = () => {
    setIsNewMessage(false);
    setShowInternalChat((prev) => !prev);
  };

  useEffect(() => {
    const checkIfNewMessage = () => {
      apiGetConversation(item.product.id + "internal", token)
        .then((res) => {
          if (res.lastMessage) setIsLastMessage(true);
          if (
            res.lastMessage &&
            res.lastMessage?.senderId !== user!.id.toString() &&
            !res.lastMessage?.readBy.includes(user!.id.toString())
          )
            setIsNewMessage(true);
          else setIsNewMessage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    checkIfNewMessage();
  }, [item.product.id, token, showInternalChat]);

  useEffect(() => {
    const det = {
      ...item.product.details,
      finish: (item.product.details as any).finish.split(","),
    } as
      | CNCProductDetailsType
      | Print3DProductDetailsType
      | SheetProductDetailsType;
    setDetails(det);

    if (
      item.product.productType === ProductTypeEnum.CNC ||
      item.product.productType === ProductTypeEnum.SHEET
    ) {
      const tempDetails = det as CNCProductDetailsType;
      if (tempDetails.blueprint) {
        setPdfExists(true);
      } else {
        setPdfExists(false);
      }
    } else {
      setPdfExists(false);
    }

    checkColorStep(token, det.modelId).then((res) => {
      if (res === 200) {
        setColorStepExist(true);
      } else {
        setColorStepExist(false);
      }
    });
  }, [item, token]);

  return (
    <TableRow key={item.id}>
      <TableCell
        style={{
          padding: 0,
          width: "30px",
        }}
        align={"left"}
      >
        <span style={{ display: "flex", flexDirection: "row" }}>
          <span>
            {details ? (
              <PreviewButton
                productId={item.product.id}
                modelId={details.modelId}
                size={"small"}
              />
            ) : (
              "Intet preview"
            )}
          </span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <span style={{}}>#{item.product.id}</span>
            <span
              style={{
                fontSize: "0.7rem",
              }}
            >
              {item.name}
            </span>
          </span>
        </span>
      </TableCell>
      <TableCell></TableCell>
      <TableCell align="left" style={{ padding: 0 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            {details?.customMaterial?.name ??
              details?.standardMaterial?.name ??
              details?.material}
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            {details?.model
              ? (details.model.dimX?.toFixed(1) ?? "?") +
                " x " +
                (details.model.dimY?.toFixed(1) ?? "?") +
                " x " +
                (details.model.dimZ?.toFixed(1) ?? "?")
              : "Loading..."}
          </span>
        </span>
      </TableCell>
      <TableCell align="left" style={{ padding: 0 }}>
        {details?.customFinish
          ? details.customFinish.name
          : details?.finish && details.finish.length > 1
          ? details.finish.filter((i: string) => i !== "standard").join("+")
          : details?.finish ?? "Loading..."}
      </TableCell>
      <TableCell align="right" style={{ padding: 0 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ paddingRight: "0.5rem" }}>
            {details ? (
              <p
                className={"downloadLink"}
                onClick={() =>
                  colorStepExist
                    ? fetchThreadFile(token, item.product.name, details.modelId)
                    : fetchSTEPFile(token, item.product.name, details.modelId)
                }
              >
                <StepIcon style={{ fill: "#474848", cursor: "pointer" }} />
              </p>
            ) : (
              <StepIcon style={{ fill: "#EBEBEB" }} />
            )}
          </span>
          <span style={{ paddingRight: "0.5rem" }}>
            {details ? (
              <p
                className={"downloadLink"}
                onClick={() =>
                  fetchPDFFile(token, item.product.name, details.productId)
                }
              >
                <Suspense fallback={<p>Loading...</p>}>
                  {pdfExists ? (
                    <PdfIcon style={{ fill: "#E0A900", cursor: "pointer" }} />
                  ) : pdfExists === false ? (
                    <PdfIcon style={{ fill: "#EBEBEB" }} />
                  ) : (
                    <p>Loading...</p>
                  )}
                </Suspense>
              </p>
            ) : (
              <DoDisturb sx={{ color: dangerColor[0] }} />
            )}
          </span>
          <span style={{ paddingRight: "0.5rem" }}>
            {details && details.threads ? (
              details.threads?.length > 0 ? (
                <div>
                  <Tooltip
                    title={
                      <div style={{ backgroundColor: "white" }}>
                        <ThreadDisplay specs={details.threads} />
                      </div>
                    }
                  >
                    <ThreadsIcon style={{ fill: "#E0A900" }} />
                  </Tooltip>
                </div>
              ) : (
                <ThreadsIcon style={{ fill: "#EBEBEB" }} />
              )
            ) : (
              "-"
            )}
          </span>
          <Tooltip title={"Internal chat"} onClick={handleButtonClickInternal}>
            <span>
              <Person
                sx={{
                  color:
                    isLastMessage || details?.comment ? "#FFC01F" : "#474848",
                  cursor: "pointer",
                }}
              />
            </span>
          </Tooltip>
          <div style={{ position: "absolute" }}>
            {showInternalChat && (
              <Popup
                customerComment={details?.comment}
                customer={item.product.author!}
                productId={item.product.id}
                selectedParticipant={"employee"}
                onClose={handleButtonClickInternal}
              />
            )}
          </div>
        </span>
      </TableCell>
      <TableCell align="center" style={{ padding: 0 }}>
        {item.quantity}pcs
      </TableCell>
      <TableCell align="right" style={{ padding: 0, paddingRight: "0.5rem" }}>
        <span>{item.total}kr</span>
        <br />
        <span style={{ fontSize: "0.7rem" }}>{item.pricePerUnit}kr</span>
      </TableCell>
    </TableRow>
  );
};
export default BasketConfigRow;
