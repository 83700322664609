import { FetchError } from "./exceptions";
import { API_URL } from "./network/common";

//Returns colored step
export const fetchThreadFile = (
  token: string,
  name: string,
  modelId: number
) => {
  const vaerksOptionsBlob = {
    headers: {
      "Content-Type": "application/octet-stream",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(
    `${API_URL}/v1/ecommerce/models/${modelId}/file/colored`,
    vaerksOptionsBlob
  )
    .then((response) => {
      if (response.ok) {
        response.blob().then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + "_COLORS.step");

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          document.body.removeChild(link);
        });
      } else throw new FetchError(response.statusText, response.status);
    })

    .catch((e) => window.alert(e));
};

//Returns original STEP
export const fetchSTEPFile = (token: string, name: string, modelId: number) => {
  const vaerksOptionsBlob = {
    headers: {
      "Content-Type": "application/octet-stream",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };
  fetch(`${API_URL}/v1/ecommerce/models/${modelId}/file`, vaerksOptionsBlob)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((e) => window.alert(e));
};

export const checkColorStep = (token: string, modelId: number) => {
  return fetch(`${API_URL}/v1/ecommerce/models/${modelId}/file/colored`, {
    method: "HEAD",
    headers: {
      "Content-Type": "application/pdf",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.status);
};

export const fetchPDFFile = (
  token: string,
  name: string,
  productId: number,
  noExtension?: boolean
) => {
  fetch(`${API_URL}/v1/ecommerce/products/${productId}/blueprint`, {
    headers: {
      "Content-Type": "application/pdf",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      if (blob.size > 100) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        const saveName = noExtension ? name : name + ".pdf";
        link.setAttribute("download", saveName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else window.alert("No blueprint was found for this product.");
    })
    .catch((e) => window.alert(e));
};

export const checkPDFFile = (token: string, productId: number) => {
  return fetch(`${API_URL}/v1/ecommerce/products/${productId}/blueprint`, {
    method: "HEAD",
    headers: {
      "Content-Type": "application/pdf",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.status);
};

export const downloadModelJson = (token: string, name: string, id: number) => {
  return fetch(`${API_URL}/v1/ecommerce/models/${id}/file/json`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((e) => window.alert(e));
};
