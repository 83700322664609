import React, { useEffect, useState } from 'react';
import { fetchJobDatas, JobData, Job } from './JobsAPI';
import { formatDistance, differenceInSeconds} from 'date-fns';

const formatDuration = (durationInSeconds: number)  => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  return formattedDuration;
}

const JobDataList: React.FC<{ job: Job }> = ({ job }) => {
  const [jobData, setJobData] = useState<JobData[]>([]);

  useEffect(() => {
      // Fetch jobs data when the component mounts
      async function fetchData() {
        const data = await fetchJobDatas(job.id);
        setJobData(data);
      }
  
      fetchData();
  }, [job]);


  return (
    <div data-thinking={true}>
      <h2>Data for job {job.id}: {job.file} on {job.machine}</h2>
      <table>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Line</th>
            <th>Tool</th>
            <th>Load</th>
            <th>Max Load</th>
            <th>Speed</th>
            <th>Override</th>
          </tr>
        </thead>
        <tbody>
          {jobData.map((jobData, index) => (
            <tr key={index}>
              <td>{formatDuration(differenceInSeconds(jobData.timestamp, job.start))}</td>
              <td>{jobData.line}</td>
              <td>T{jobData.tool}</td>
              <td>{jobData.load}%</td>
              <td>{jobData.maxLoad}%</td>
              <td>{jobData.speed} RPM</td>
              <td>{jobData.override}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );  
};

export default JobDataList;