const fakeAluminiumData = [
  {
    "Varenummer": "ALU5083-80-25",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "ALU5083 80mm x 25mm",
    "Maengde": "1845 mm",
    "Stregkode": "ALU5083-80-25"
  },
  {
    "Varenummer": "ALU5083-80-50",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "ALU5083 80mm x 50mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-80-50"
  },
  {
    "Varenummer": "ALU5083-80-75",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "ALU5083 80mm x 75mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-80-75"
  },
  {
    "Varenummer": "ALU5083-80-100",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "ALU5083 80mm x 100mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-80-100"
  },
  {
    "Varenummer": "ALU5083-80-150",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "ALU5083 80mm x 150mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-80-150"
  },
  {
    "Varenummer": "ALU5083-80-200",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "ALU5083 80mm x 200mm",
    "Maengde": "1060 mm",
    "Stregkode": "ALU5083-80-200"
  },
  {
    "Varenummer": "ALU5083-80-250",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "ALU5083 80mm x 250mm",
    "Maengde": "1425 mm",
    "Stregkode": "ALU5083-80-250"
  },
  {
    "Varenummer": "ALU5083-80-300",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "ALU5083 80mm x 300mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-80-300"
  },
  {
    "Varenummer": "ALU5083-80-350",
    "Type": "ALU5083",
    "Tykkelse": "80 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "ALU5083 80mm x 350mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-80-350"
  },
  {
    "Varenummer": "ALU5083-60-25",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "ALU5083 60mm x 25mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-25"
  },
  {
    "Varenummer": "ALU5083-60-50",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "ALU5083 60mm x 50mm",
    "Maengde": "1400 mm",
    "Stregkode": "ALU5083-60-50"
  },
  {
    "Varenummer": "ALU5083-60-75",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "ALU5083 60mm x 75mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-75"
  },
  {
    "Varenummer": "ALU5083-60-100",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "ALU5083 60mm x 100mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-100"
  },
  {
    "Varenummer": "ALU5083-60-150",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "ALU5083 60mm x 150mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-150"
  },
  {
    "Varenummer": "ALU5083-60-200",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "ALU5083 60mm x 200mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-200"
  },
  {
    "Varenummer": "ALU5083-60-250",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "ALU5083 60mm x 250mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-250"
  },
  {
    "Varenummer": "ALU5083-60-300",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "ALU5083 60mm x 300mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-300"
  },
  {
    "Varenummer": "ALU5083-60-350",
    "Type": "ALU5083",
    "Tykkelse": "60 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "ALU5083 60mm x 350mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-60-350"
  },
  {
    "Varenummer": "ALU5083-40-25",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "ALU5083 40mm x 25mm",
    "Maengde": "1970 mm",
    "Stregkode": "ALU5083-40-25"
  },
  {
    "Varenummer": "ALU5083-40-50",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "ALU5083 40mm x 50mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-40-50"
  },
  {
    "Varenummer": "ALU5083-40-75",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "ALU5083 40mm x 75mm",
    "Maengde": "2870 mm",
    "Stregkode": "ALU5083-40-75"
  },
  {
    "Varenummer": "ALU5083-40-100",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "ALU5083 40mm x 100mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-40-100"
  },
  {
    "Varenummer": "ALU5083-40-150",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "ALU5083 40mm x 150mm",
    "Maengde": "1240 mm",
    "Stregkode": "ALU5083-40-150"
  },
  {
    "Varenummer": "ALU5083-40-200",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "ALU5083 40mm x 200mm",
    "Maengde": "3390 mm",
    "Stregkode": "ALU5083-40-200"
  },
  {
    "Varenummer": "ALU5083-40-250",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "ALU5083 40mm x 250mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-40-250"
  },
  {
    "Varenummer": "ALU5083-40-300",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "ALU5083 40mm x 300mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-40-300"
  },
  {
    "Varenummer": "ALU5083-40-350",
    "Type": "ALU5083",
    "Tykkelse": "40 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "ALU5083 40mm x 350mm",
    "Maengde": "1245 mm",
    "Stregkode": "ALU5083-40-350"
  },
  {
    "Varenummer": "ALU5083-20-25",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "25 mm",
    "Beskrivelse": "ALU5083 20mm x 25mm",
    "Maengde": "910 mm",
    "Stregkode": "ALU5083-20-25"
  },
  {
    "Varenummer": "ALU5083-20-50",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "50 mm",
    "Beskrivelse": "ALU5083 20mm x 50mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-20-50"
  },
  {
    "Varenummer": "ALU5083-20-75",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "75 mm",
    "Beskrivelse": "ALU5083 20mm x 75mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-20-75"
  },
  {
    "Varenummer": "ALU5083-20-100",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "100 mm",
    "Beskrivelse": "ALU5083 20mm x 100mm",
    "Maengde": "3262 mm",
    "Stregkode": "ALU5083-20-100"
  },
  {
    "Varenummer": "ALU5083-20-150",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "150 mm",
    "Beskrivelse": "ALU5083 20mm x 150mm",
    "Maengde": "2890 mm",
    "Stregkode": "ALU5083-20-150"
  },
  {
    "Varenummer": "ALU5083-20-200",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "200 mm",
    "Beskrivelse": "ALU5083 20mm x 200mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-20-200"
  },
  {
    "Varenummer": "ALU5083-20-250",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "250 mm",
    "Beskrivelse": "ALU5083 20mm x 250mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-20-250"
  },
  {
    "Varenummer": "ALU5083-20-300",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "300 mm",
    "Beskrivelse": "ALU5083 20mm x 300mm",
    "Maengde": "3220 mm",
    "Stregkode": "ALU5083-20-300"
  },
  {
    "Varenummer": "ALU5083-20-350",
    "Type": "ALU5083",
    "Tykkelse": "20 mm",
    "Bredde": "350 mm",
    "Beskrivelse": "ALU5083 20mm x 350mm",
    "Maengde": "1610 mm",
    "Stregkode": "ALU5083-20-350"
  }
];

export default fakeAluminiumData