import { Typography } from "@mui/material";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ErrorType, DFMInfoType } from "../../../types/RenderTypes";
import LoaderComponent from "../../LoaderComponent/LoaderComponent";
import DFMControls from "./DFMControls";

type PropsType = {
  dfmInfo?: DFMInfoType;
  selectedError?: ErrorType;
  setSelectedError: Function;
};

const DFMContainer: FunctionComponent<PropsType> = ({
  dfmInfo,
  selectedError,
  setSelectedError,
}) => {
  const [uploadText, setUploadText] = useState<string>("Analyzing");
  const [dfmLoading, setDfmLoading] = useState<boolean>(true);

  const [showControls, setShowControls] = useState<boolean>(false);

  const onUpload = (number: number) => {
    if (number > 5 && number <= 12) setUploadText("Analyzing geometry");
    else if (number > 13 && number <= 55) setUploadText("Analyzing weaknesses");
    else if (number > 56) setUploadText(" Finishing analysis");
    else if (number >= 99) setUploadText("Done!");
  };

  useEffect(() => {
    if (dfmInfo !== undefined) {
      setDfmLoading(false);
    }
  }, [dfmInfo]);

  const loaderMemo = useMemo(() => {
    return (
      <LoaderComponent
        isLoading={dfmLoading}
        isDone={() => setShowControls(true)}
        onChange={onUpload}
      />
    );
  }, [dfmLoading]);

  return (
    <div
      style={{
        position: "absolute",
        top: "1em",
        left: "1em",
        borderRadius: "4px",
      }}
    >
      {!dfmLoading && showControls && dfmInfo ? (
        <DFMControls
          selectedError={selectedError}
          setSelectedError={setSelectedError}
          dfmInfo={dfmInfo}
        />
      ) : (
        <div
          style={{
            display: "flex",
            margin: "0 1em 0 1em",
            alignItems: "center",
          }}
        >
          <Typography
            component="h2"
            style={{ display: "flex", margin: "1em", alignItems: "center" }}
          >
            {loaderMemo}
          </Typography>
          <Typography component="h2">{uploadText}</Typography>
        </div>
      )}
    </div>
  );
};

export default DFMContainer;
