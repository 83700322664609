import React, { useContext, useReducer, useState } from "react";
import styles from "./CreateOtherProductModal.module.css";
import { apiCreateProduct } from "util/network/Products";
import AppStateContext from "contexts/AppStateContext";
import {
  CreateOtherProductType,
  ProductTypeEnum,
  ProductVMType,
} from "types/products/ProductCommandsType";
import { FileResourceType } from "types/FileResourceTypes";
import { apiDeleteFileResource } from "util/network/FileResources";
import ListReducer from "util/ListReducer";
import { ListReducerEnum } from "util/ListReducer";
import Modal from "components/Modals/Modal";
import FileResourceList from "components/FileResourceList";

type CreateOtherProductModalProps = {
  onSubmit: (result: ProductVMType) => void;
  onCancel: () => void;
  disabled?: boolean;
};

const CreateOtherProductModal: React.FC<CreateOtherProductModalProps> = ({
  onSubmit,
  onCancel,
  disabled = false,
}) => {
  const { token } = useContext(AppStateContext);
  const [name, setName] = useState("");
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const [description, setDescription] = useState("");
  const [fileResources, fileResourcesDispath] = useReducer(
    ListReducer<FileResourceType>("id"),
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!token) return;

    setIsLoading(true);

    try {
      const command: CreateOtherProductType = {
        name: name,
        qty: Number(qty),
        price: Number(price),
        weight: Number(weight),
        description: description,
        fileResources: fileResources.map((x) => x.id),
        productType: ProductTypeEnum.OTHER,
      };

      const result = await apiCreateProduct(token, command);

      onSubmit(result);
      setIsLoading(false);
      setIsModalOpen(false);
      setName("");
      setQty("");
      setPrice("");
      setWeight("");
      setDescription("");
      fileResourcesDispath({
        type: ListReducerEnum.CLEAR,
        value: [],
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleDeleteFileResource = (id: number) => {
    const fileResource = fileResources.find((x) => x.id === id);
    if (!fileResource) return;
    fileResourcesDispath({ type: ListReducerEnum.REMOVE, value: fileResource });
    apiDeleteFileResource(token, id).catch((error) => {
      console.log(error);
      fileResourcesDispath({
        type: ListReducerEnum.ADD,
        value: fileResource,
      });
    });
  };

  return (
    <>
      <button
        className={styles.openModalButton}
        onClick={() => setIsModalOpen(true)}
        disabled={disabled}
      >
        + Create Other Product
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          fileResourcesDispath({
            type: ListReducerEnum.CLEAR,
            value: [],
          });
          setIsModalOpen(false);
        }}
      >
        <div className={styles.modalHeader}>
          <h2>Create Other Product</h2>
        </div>
        <form onSubmit={handleSubmit} id="otherProductForm">
          <div className={styles.modalBody}>
            <div className={styles.formGroup}>
              <label>Name:</label>
              <input
                type="text"
                className={styles.inputField}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Qty:</label>
              <input
                type="number"
                className={styles.inputField}
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Price:</label>
              <input
                type="number"
                className={styles.inputField}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Weight:</label>
              <input
                type="number"
                className={styles.inputField}
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Description:</label>
              <textarea
                className={`${styles.inputField} ${styles.textarea}`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div
            style={{
              maxHeight: "15em",
              overflow: "auto",
            }}
          >
            <FileResourceList
              resources={fileResources}
              allowEdit
              onChange={(resources) => {
                fileResourcesDispath({
                  type: ListReducerEnum.SET,
                  value: resources,
                });
                return Promise.resolve(true);
              }}
            />
          </div>
          <div className={styles.modalFooter}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={() => {
                onCancel();
                fileResourcesDispath({
                  type: ListReducerEnum.CLEAR,
                  value: [],
                });
                setIsModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={styles.createButton}
              form="otherProductForm"
              // onClick={handleCloseModal}
            >
              {isLoading ? "Creating..." : "Create"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateOtherProductModal;
