import { useState } from "react";
import MaterialCard from "../MaterialCard";
import { Grid, Typography } from "@mui/material";
import { infoColor, primaryColor } from "../../../assets/colors";
import Aluminium from "../../../assets/img/alu.png";
import PANylon from "../../../assets/img/pa_nylon.png";
import POMC from "../../../assets/img/pom-c.jpg";
import POMH from "../../../assets/img/pom-h.jpg";
import BRASS58 from "../../../assets/img/brass58_2.png";
import PTFE from "../../../assets/img/PTFE.png";
import PEEK from "../../../assets/img/PEEK.png";
import StockTable from "../StockTable";

const StockManagementUI = () => {
  const [material, setMaterial] = useState(null);

  const setActiveMaterial = (material) => {
    if (material.startsWith("ALU")) {
      setMaterial(material);
    } else setMaterial("POM-C");
  };

  const resetMaterial = () => {
    setMaterial(null);
  };

  return (
    <Grid justifyContent={"center"} container spacing={3}>
      {!material ? (
        <>
          <Grid item xs={12}>
            <Typography fontWeight={"bold"} variant={"h4"}>
              <span style={{ color: primaryColor[0], fontWeight: "bold" }}>
                Materialeoversigt
              </span>
              <span style={{ color: infoColor[0] }}>
                &nbsp;-&nbsp;Vælg for detaljeret lagerbeholdning
              </span>
            </Typography>
            <hr style={{ marginBottom: "-10px" }} color={primaryColor[0]} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"aluminium 5083"}
              status={"low"}
              imageResource={Aluminium}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"aluminium 6082"}
              imageResource={Aluminium}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"aluminium 7075"}
              imageResource={Aluminium}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"hvid POM-C"}
              status={"critical"}
              imageResource={POMC}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"sort POM-C"}
              imageResource={POMH}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"PA-NYLON"}
              status={"critical"}
              imageResource={PANylon}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"PEEK-NATURAL"}
              imageResource={PEEK}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"PTFE-WHITE"}
              imageResource={PTFE}
              openMaterial={setActiveMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MaterialCard
              material={"MESSING 58"}
              imageResource={BRASS58}
              openMaterial={setActiveMaterial}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <StockTable material={material} goBack={resetMaterial} />
        </Grid>
      )}
    </Grid>
  );
};

export default StockManagementUI;
