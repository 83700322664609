import React, { useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import DraggableTaskRow from "./DraggableTaskRow";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import styles from "./css/draggableTable.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  CreateProductionTaskType,
  ProductionTaskType,
  ProductionVMType,
} from "types/ProductionType";
import { MachineType } from "types/MachineType";

export type TaskTemplatesType = {
  [key: string]: CreateProductionTaskType;
};

type DraggableBoxProps = {
  isDisabled?: boolean;
  machines?: MachineType[];
  tasks: Partial<ProductionTaskType>[];
  updateTasks: (
    taskId: string,
    updatedTasks: Partial<ProductionTaskType>
  ) => void;
  templates?: TaskTemplatesType;
  setNewTask: (template: CreateProductionTaskType) => void;
  handleTaskDelete: (taskId: string) => void;
  deletedTasks: string[];
  project?: ProductionVMType;
};

const DraggableTable: React.FC<DraggableBoxProps> = ({
  isDisabled,
  machines,
  tasks,
  updateTasks,
  templates,
  setNewTask,
  handleTaskDelete,
  deletedTasks,
  project,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const sortedTasks = useMemo(() => {
    return [...tasks].sort((a, b) => a.priority! - b.priority!);
  }, [tasks]);

  // after deletion
  const visibleTasks = sortedTasks.filter(
    (task) => task.id !== undefined && !deletedTasks.includes(task.id)
  );

  const handleDragEnd = (result: DropResult) => {
    if (isDisabled) return;
    if (!result.destination) return;

    const reorderedRows = Array.from(
      [...tasks].sort((a, b) => a.priority! - b.priority!)
    );
    const [movedRow] = reorderedRows.splice(result.source.index, 1);
    console.log("remf, ", movedRow);
    reorderedRows.splice(result.destination.index, 0, movedRow);
    console.log("result", result);
    console.log("reorder: ", reorderedRows);
    const updatedTasks: Partial<ProductionTaskType>[] = reorderedRows.map(
      (task, index) => {
        console.log("index", index);
        return {
          ...task,
          priority: index,
        };
      }
    );
    updatedTasks.forEach((updateTask) => {
      updateTasks(updateTask.id!, { priority: updateTask.priority });
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="rows">
          {(provided) => (
            <TableContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ overflow: "hidden" }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.hd__typo} colSpan={2}>
                      Tasks
                    </TableCell>
                    <TableCell className={styles.hd__typo} align="center">
                      State
                    </TableCell>
                    <TableCell className={styles.hd__typo} align="left">
                      Assigned
                    </TableCell>
                    <TableCell className={styles.hd__typo}>Work Time</TableCell>
                    <TableCell
                      className={styles.hd__typo}
                      style={{ paddingRight: "0.5rem" }}
                    >
                      LeadTime
                    </TableCell>
                    <TableCell className={styles.hd__typo} align="center">
                      Deadline
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleTasks.map((task, index) => {
                    const taskId = task.id
                      ? task.id.toString()
                      : `temp-${index}`;
                    return (
                      <Draggable
                        key={taskId}
                        draggableId={taskId}
                        index={index}
                        isDragDisabled={isDisabled}
                      >
                        {(provided) => (
                          <DraggableTaskRow
                            task={task}
                            provided={provided}
                            index={index}
                            machines={machines}
                            updateTasks={updateTasks}
                            onDelete={() => handleTaskDelete(task.id!)}
                            project={project}
                            templates={templates}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
      {isDisabled ? (
        ""
      ) : (
        <>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleClick}
            className={styles.add_button}
            sx={{
              borderRadius: "0.125rem",
              background: "#83aead",
              "&:hover": {
                color: "#fff",
                background: "#075E5B !important",
              },
            }}
          >
            Add Task
          </Button>
          <Menu
            id="add-task-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {templates !== undefined &&
              Object.values(templates).map((template, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setNewTask(template);
                      handleClose();
                    }}
                  >
                    {template.description ?? template.name}
                  </MenuItem>
                );
              })}
          </Menu>
        </>
      )}
    </>
  );
};

export default DraggableTable;
