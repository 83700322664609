import { Fragment, FunctionComponent } from "react";
import styles from "./ResultRowStyle.module.css";
import { UserType } from "../../../../types/users/UserTypes";

type ResultPropsType = {
  info?: UserType;
  onClick?: () => void;
};

const ResultRow: FunctionComponent<ResultPropsType> = ({ info, onClick }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      {info ? (
        <>
          <div>
            {info.firstName} {info.lastName}
            {info.defaultBillingAddress &&
            // info.defaultBillingAddress?.length > 0 &&
            info.defaultBillingAddress.company
              ? ` - ${info.defaultBillingAddress.company}`
              : ""}
          </div>
          {`Email: ${info.email}`}
        </>
      ) : (
        "Ingen resultater"
      )}
    </div>
  );
};

export default ResultRow;
