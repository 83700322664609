export const epgChannels = [
  {
    id: "M3",
    type: "DVF-5000",
    title: "M3",
  },
  {
    id: "M4",
    type: "DVF-5000",
    title: "M4",
  }
];
