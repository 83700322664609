import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ArrowDropDown } from "@mui/icons-material";
import AppStateContext from "../../contexts/AppStateContext";
import { CartStatusEnum, CartType } from "types/CartTypes";
import Textarea from "@mui/material/TextField";
import {
  apiGetRequisitions,
  apiUpdateRequisition,
} from "util/network/Requisitions";
import { RequisitionStatusEnum } from "types/RequisitionTypes";
import { useToast } from "contexts/ToastContext";
import { apiDeleteQuote, apiUpdateCartInfo } from "util/network/Carts";
import Modal from "components/Modals/Modal";
import { Checkbox } from "@mui/material";

type PropsType = {
  currentStatus: CartStatusEnum;
  entityID: number;
  onChange: (val?: CartType) => void;
  message: string;
};

enum Resources {
  REQUISITION = 1,
}

const QuoteStatusButton: FunctionComponent<PropsType> = ({
  currentStatus,
  entityID,
  onChange,
  message,
}) => {
  const { token } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<CartStatusEnum>(currentStatus);
  const [anchorEl, setAnchorEl] = useState(null);
  const [requisitionsToUpdate, setRequisitionsToUpdate] = useState<number[]>(
    []
  );
  const [selectedResources, setSelectedResources] = useState<Resources[]>([]);
  const override = Boolean(sessionStorage.getItem("debug") === "true");
  const [lostMessage, setLostMessage] = useState<string>(message);

  const open = Boolean(anchorEl);

  // Open menu
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  // Close menu without selection
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (status: CartStatusEnum) => {
    setStatus(status);
    apiGetRequisitions(
      token,
      1,
      100,
      undefined,
      undefined,
      undefined,
      undefined,
      `quoteId=${entityID}`
    ).then((res) => {
      setRequisitionsToUpdate(
        res.items
          .filter(
            (req) =>
              req.state !== RequisitionStatusEnum.CANCELLED &&
              req.state !== RequisitionStatusEnum.RECEIVED
          )
          .map((req) => req.id)
      );
    });
    setShowModal(true);
    setAnchorEl(null);
  };

  const dialogCancel = () => {
    setShowModal(false);
    setStatus(currentStatus);
  };

  const confirmUpdate = () => {
    if (!token) {
      return;
    }
    let promise = null;
    if (status === CartStatusEnum.DELETED) {
      promise = apiDeleteQuote(token, entityID);
    } else {
      promise = apiUpdateCartInfo(token, entityID, {
        status: status as CartStatusEnum,
        internalComment: lostMessage,
      }).then((res) => {
        onChange(res);
        return res;
      });
    }

    promise
      .then((res) => {
        addToast({
          type: "success",
          message: "Quote updated successfully",
        });
        if (selectedResources.includes(Resources.REQUISITION)) {
          Promise.all(
            requisitionsToUpdate.map((requisitionId) =>
              apiUpdateRequisition(
                token,
                requisitionId,
                {
                  state:
                    status == CartStatusEnum.DELETED ||
                    status == CartStatusEnum.LOST
                      ? RequisitionStatusEnum.CANCELLED
                      : RequisitionStatusEnum.RECEIVED,
                },
                false
              )
            )
          ).then((res) => {
            addToast({
              type: "success",
              message: (
                <>
                  Requisitions were updated to <strong>{status}</strong>.
                </>
              ),
            });
          });
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          message: (
            <>
              Failed to update order status to <strong>{status}</strong>.
            </>
          ),
        });
      });
    setShowModal(false);
  };

  const statusOptions = (currStatus: CartStatusEnum, ovride?: boolean) => {
    if (ovride) {
      return [...Object.values(CartStatusEnum)];
    }
    switch (currStatus) {
      case CartStatusEnum.DRAFT:
        return [
          CartStatusEnum.LOCKED,
          CartStatusEnum.LOST,
          CartStatusEnum.REVIEW,
          CartStatusEnum.DELETED,
        ];
      case CartStatusEnum.LOCKED:
        return [
          CartStatusEnum.LOST,
          CartStatusEnum.REVIEW,
          CartStatusEnum.DRAFT,
          CartStatusEnum.DELETED,
        ];
      case CartStatusEnum.REVIEW:
        return [
          CartStatusEnum.LOST,
          CartStatusEnum.LOCKED,
          CartStatusEnum.DRAFT,
          CartStatusEnum.DELETED,
        ];
      default:
        return [CartStatusEnum.DELETED];
    }
  };

  const menuItems = useMemo(() => {
    const possibleStatus = statusOptions(status, override);
    if (possibleStatus.length === 0) {
      return null;
    }
    return possibleStatus
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .map((option, index) =>
        option !== CartStatusEnum.LOCKED ? (
          <MenuItem
            key={index}
            selected={status === option}
            onClick={(event: any) => handleMenuItemClick(option)}
          >
            {option}
          </MenuItem>
        ) : (
          <MenuItem
            key={index}
            selected={status === option}
            onClick={(event: any) => handleMenuItemClick(option)}
          >
            QUOTE
          </MenuItem>
        )
      );
  }, [override, status]);

  const selectableRow = (type: Resources, text: string) => {
    return (
      <tr key={type}>
        <td>{text}</td>
        <td>
          <Checkbox
            onChange={() => {
              if (selectedResources.includes(type)) {
                setSelectedResources(
                  selectedResources.filter((t) => t !== type)
                );
              } else {
                setSelectedResources([...selectedResources, type]);
              }
            }}
          />
        </td>
      </tr>
    );
  };

  /**
   * The purpose of the modal is to show the user a modal, where each row in the table is the type of resource which will/can be affected from this order update.
   * For now this includes productions and requisitions.
   * @returns A modal containing a list of the resources that can be affected from this update.
   */
  const updateableItemsModal = (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
      <h1>
        Update quote #{entityID} to{" "}
        {status !== CartStatusEnum.LOCKED ? status : "QUOTE"}
      </h1>
      {(status == CartStatusEnum.DELETED || status == CartStatusEnum.LOST) &&
      requisitionsToUpdate.length > 0 ? (
        <>
          <h2>The following resources will be affected from this update:</h2>
          <table>
            <tbody>
              {selectableRow(
                Resources.REQUISITION,
                `Requisitions (${requisitionsToUpdate.length}) will be set to cancelled`
              )}
            </tbody>
          </table>
        </>
      ) : null}
      {status !== CartStatusEnum.DELETED && (
        <Textarea
          rows={4}
          multiline
          label="Internal comment"
          placeholder="Reason for ending the quote"
          variant="outlined"
          value={lostMessage}
          onChange={(e: any) => setLostMessage(e.target.value)}
          style={{ width: "100%" }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          marginTop: "1em",
        }}
      >
        <Button variant="contained" color="error" onClick={dialogCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={confirmUpdate}>
          Confirm
        </Button>
      </div>
    </Modal>
  );

  const disabledStates = [CartStatusEnum.DELETED];

  return (
    <div>
      {updateableItemsModal}
      <Button
        disabled={disabledStates.includes(status)}
        endIcon={disabledStates.includes(status) || <ArrowDropDown />}
        onClick={handleClick}
        style={{
          fontWeight: "bold",
          border:
            status == CartStatusEnum.DRAFT
              ? "1px solid #474848"
              : status == CartStatusEnum.LOCKED
              ? "1px solid #E0A900"
              : status == CartStatusEnum.REVIEW
              ? "1px solid #007474"
              : status == CartStatusEnum.LOST
              ? "1px solid #f44336"
              : undefined,
          color:
            status == CartStatusEnum.DRAFT
              ? "#474848"
              : status == CartStatusEnum.LOCKED
              ? "#E0A900"
              : status == CartStatusEnum.REVIEW
              ? "#007474"
              : status == CartStatusEnum.LOST
              ? "#f44336"
              : undefined,
          background:
            status == CartStatusEnum.DRAFT
              ? "rgba(71, 72, 72, 0.1)"
              : status == CartStatusEnum.LOCKED
              ? "rgba(224, 169, 0, 0.1)"
              : status == CartStatusEnum.REVIEW
              ? "rgba(0, 116, 116, 0.1)"
              : status == CartStatusEnum.LOST
              ? "rgba(71, 72, 72, 0.1)"
              : undefined,
          borderRadius: "5px",
        }}
      >
        {status === CartStatusEnum.LOCKED ? "QUOTE" : status}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems}
      </Menu>
    </div>
  );
};

export default QuoteStatusButton;
