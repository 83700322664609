import { Button, Menu, MenuItem } from "@mui/material";
import { FunctionComponent, useEffect, useState, useContext } from "react";
import styles from "../../../assets/jss/material-dashboard-pro-react/creativeTim/views/selectorStyle.module.css";
import { ArrowDropDown } from "@mui/icons-material";
import { FinishType } from "../../../types/NetworkTypes";
import AppStateContext from "contexts/AppStateContext";
import { fetchPublic } from "util/network/common";

type PropsType = {
  material: string;
  currentSel: string[];
  onChange: Function;
  disabled?: boolean;
  finishFile?: string;
};

const FinishSelector: FunctionComponent<PropsType> = ({
  material,
  currentSel,
  onChange,
  disabled,
  finishFile,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFinishes, setSelectedFinishes] = useState<string[]>(
    currentSel ?? []
  );
  const [options, setOptions] = useState<FinishType[]>([]);
  const { strings } = useContext(AppStateContext);

  useEffect(() => {
    fetchPublic(finishFile ? `/${finishFile}` : "/cncFinish.json").then(
      (res) => {
        setOptions(res);
      }
    );
  }, [finishFile]);

  useEffect(() => {
    setSelectedFinishes(currentSel);
  }, [currentSel]);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event: any, cat: FinishType, id: string) => {
    let newSelectedFinish = [...selectedFinishes];
    if (newSelectedFinish.includes(id)) {
      // If it is, remove it
      newSelectedFinish = newSelectedFinish.filter((i) => i !== id);
    } else {
      // Check if any of the variants are selected
      const selected = cat.variants.find((i) =>
        newSelectedFinish.includes(i.id)
      );
      if (selected) {
        // If they are, remove them
        newSelectedFinish = newSelectedFinish.filter((i) => i !== selected.id);
      }
      newSelectedFinish = [...newSelectedFinish, id];
    }
    setSelectedFinishes(newSelectedFinish);
    onChange(newSelectedFinish);
    handleClose();
  };

  return (
    <>
      <Button
        className={styles.selector}
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
        disabled={disabled}
      >
        <p
          style={{
            overflowWrap: "break-word",
            whiteSpace: "break-spaces",
          }}
        >
          {selectedFinishes.length > 1
            ? selectedFinishes.filter((i) => i !== "standard").join("+")
            : selectedFinishes}
        </p>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, key) => {
          let disabled = false;
          if (material !== "custom" && option.id !== "custom") {
            if (!option.compatibleMaterials.includes(material)) {
              return null;
            }
            const selected = option.variants.find((i) =>
              selectedFinishes.includes(i.id)
            );
            if (!selected) {
              selectedFinishes.forEach((fin) => {
                // console.log(fin);
                let finCat = options.find((i) =>
                  i.variants.find((j) => j.id === fin)
                );
                if (finCat) {
                  // console.log(option);
                  if (
                    option.compatibleFinishes !== undefined &&
                    !option.compatibleFinishes.includes(finCat?.id ?? "")
                  ) {
                    disabled = true;
                  }
                }
              });
            }
          }
          return [
            option.variants.length > 1 && (
              <MenuItem key={key} disabled>
                {option.title[strings.code]}
              </MenuItem>
            ),
            ...option.variants.map((variant, key) => (
              <MenuItem
                key={key}
                selected={selectedFinishes.includes(variant.id)}
                onClick={(event) =>
                  handleMenuItemClick(event, option, variant.id)
                }
                disabled={disabled}
              >
                {variant[strings.code].finishTitle}
              </MenuItem>
            )),
          ];
        })}
      </Menu>
    </>
  );
};

export default FinishSelector;
