// Author: Erik s195397
import { TableRow, TableCell, Tooltip } from "@mui/material";
import { FunctionComponent } from "react";
import { RequisitionItemType } from "types/RequisitionTypes";
import AppStateContext from "contexts/AppStateContext";
import { useContext, useEffect, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DoDisturb } from "@mui/icons-material";
import {
  checkColorStep,
  fetchPDFFile,
  fetchSTEPFile,
  fetchThreadFile,
} from "util/FileDownload";
import { dangerColor } from "assets/colors";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import PreviewButton from "components/Generic/PreviewButton";
import { ReactComponent as CommentIcon } from "../../../assets/svg/commentFile.svg";
import ThreadDisplay from "components/OrderView/ThreadDisplay";
import { ReactComponent as ThreadsIcon } from "../../../assets/svg/threadFile.svg";

type PropsType = {
  item: RequisitionItemType;
  data: any;
};

// Requisition item row
const RequisitionItemRow: FunctionComponent<PropsType> = ({ item, data }) => {
  const { token, strings, setShowThreeDModal, setProductId } =
    useContext(AppStateContext);
  const [pdfExists, setPdfExists] = useState<boolean>();
  const [colorStepExist, setColorStepExist] = useState<boolean>();
  const [preview, setPreview] = useState<string>();
  useEffect(() => {
    if (data?.blueprint) {
      setPdfExists(true);
    } else {
      setPdfExists(false);
    }
    data &&
      checkColorStep(token, data.modelId).then((res) => {
        if (res === 200) {
          setColorStepExist(true);
        } else {
          setColorStepExist(false);
        }
      });
  }, [data]);

  return (
    <TableRow key={item.id} sx={{ padding: "0px" }}>
      <TableCell align="left" sx={{ padding: "0px" }}>
        <span style={{ display: "flex", flexDirection: "row" }}>
          {data ? (
            <PreviewButton
              productId={data.productId}
              modelId={data.modelId}
              size={"small"}
            />
          ) : (
            "Intet preview"
          )}
          <span style={{ display: "flex", flexDirection: "column" }}>
            <span style={{}}>#{item.product.id}</span>
            <span
              style={{
                fontSize: "0.7rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.product.name}
            </span>
          </span>
        </span>
      </TableCell>

      <TableCell align="left" style={{ padding: "0px" }}>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            {data?.customMaterial
              ? data.customMaterial.name
              : data.standardMaterial
              ? data.standardMaterial.name
              : data.material ?? "Loading..."}
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            {data.model
              ? (data.model.dimX?.toFixed(1) ?? "?") +
                " x " +
                (data.model.dimY?.toFixed(1) ?? "?") +
                " x " +
                (data.model.dimZ?.toFixed(1) ?? "?")
              : "Loading..."}
          </span>
        </span>
      </TableCell>

      <TableCell align="left" sx={{ padding: "0px" }}>
        <span style={{ display: "flex", flexDirection: "row" }}>
          <span style={{ display: "flex", alignItems: "center" }}>
            {data ? (
              <p
                className={"downloadLink"}
                onClick={() =>
                  colorStepExist
                    ? fetchThreadFile(token, item.product.name, data.modelId)
                    : fetchSTEPFile(token, item.product.name, data.modelId)
                }
              >
                <ThreeDRotationIcon />
              </p>
            ) : (
              <DoDisturb sx={{ color: dangerColor[0] }} />
            )}
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "0.3rem",
              paddingLeft: "0.2rem",
            }}
          >
            {pdfExists ? (
              <p
                className={"downloadLink"}
                onClick={() => {
                  fetchPDFFile(token, item.product.name, data.productId);
                }}
              >
                <PictureAsPdfIcon />
              </p>
            ) : (
              <DoDisturb sx={{ color: dangerColor[0] }} />
            )}
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "0.3rem",
              paddingLeft: "0.2rem",
            }}
          >
            {data && data.comment ? (
              <Tooltip title={data.comment ? data.comment : "Ingen kommentar"}>
                <CommentIcon style={{ fill: "#E0A900" }} />
              </Tooltip>
            ) : (
              <Tooltip title={"Ingen kommentar"}>
                <CommentIcon style={{ fill: "#EBEBEB" }} />
              </Tooltip>
            )}
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "0.3rem",
              paddingLeft: "0.2rem",
            }}
          >
            {data && data.threads ? (
              data.threads?.length > 0 ? (
                <Tooltip
                  title={
                    <div style={{ backgroundColor: "white" }}>
                      <ThreadDisplay specs={data.threads} />
                    </div>
                  }
                >
                  <ThreadsIcon style={{ fill: "#E0A900" }} />
                </Tooltip>
              ) : (
                <ThreadsIcon style={{ fill: "#EBEBEB" }} />
              )
            ) : (
              "-"
            )}
          </span>
        </span>
      </TableCell>
      <TableCell align="center">
        {(item.orderItem ? item.orderItem.quantity : item.quoteItem?.quantity) +
          "pcs"}
      </TableCell>
      {/* <TableCell align="center">{item.price + " kr"}</TableCell> */}
    </TableRow>
  );
};

export default RequisitionItemRow;
