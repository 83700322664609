// JobsAPI.ts

import { API_URL } from "../network/Common";
import { Tool } from "../tools/ToolsAPI";

// Define the endpoint URL where you'll fetch the data (replace with your actual API URL)
const baseURL = `${API_URL}/v1/tooldb/jobs`;

// Define a type for the job data
export interface Job {
  id: number;
  machine: string;
  startTime: string;
  start: Date;
  endTime: string;
  end: Date;
  item: number;
  file: string;
  tools: Tool[];
}

// Sample job data (replace with your actual data)
const sampleJobs: Job[] = [
  {
    id: 1,
    machine: "Machine1",
    startTime: "2023-10-20T08:00:00",
    start: new Date("2023-10-20T08:00:00"),
    endTime: "2023-10-20T16:00:00",
    end: new Date("2023-10-20T16:00:00"),
    item: 1001,
    file: "JobFile1",
    tools: [],
  },
  // Add more job data as needed
];

// Function to fetch jobs data from the API
export async function fetchJobs(
  searchString?: string,
  dateStart?: string,
  dateEnd?: string
) {
  try {
    // Generate api call
    let url = baseURL;
    if (searchString) {
      url += `?search_string=${encodeURIComponent(searchString)}`;
    }
    if (dateStart) {
      url += `${searchString ? "&" : "?"}startTime=${encodeURIComponent(
        dateStart
      )}`;
    }
    if (dateEnd) {
      url += `${
        searchString || dateStart ? "&" : "?"
      }endTime=${encodeURIComponent(dateEnd)}`;
    }

    // Fetch results
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    data.forEach((job: Job) => {
      job.start = new Date(job.startTime);
      job.end = new Date(job.endTime);
    });

    return data;
  } catch (error) {
    console.error("Error fetching jobs data:", error);
    throw error;
  }
}

// Define a type for the job event data
export interface JobData {
  id: number;
  job: number;
  timestamp: Date;
  line: number;
  tool: number;
  load: number;
  maxLoad: number;
  speed: number;
  override: number;
}

// Sample job event data (replace with your actual data)
const sampleJobEvents: JobData[] = [
  {
    id: 1,
    job: 1234,
    timestamp: new Date("2023-10-20T09:00:00"),
    line: 1,
    tool: 101,
    load: 50,
    maxLoad: 100,
    speed: 200,
    override: 1,
  },
  // Add more job events as needed
];

// Function to fetch job events data from the API by job ID
export async function fetchJobDatas(jobId: number): Promise<JobData[]> {
  try {
    const response = await fetch(`${baseURL}/${jobId}/data`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    data.forEach((jobData: JobData) => {
      jobData.timestamp = new Date(jobData.timestamp);
    });

    return data;
  } catch (error) {
    console.error("Error fetching job events data:", error);
    throw error;
  }
}
