import { useContext, useState } from "react";
import styles from "../ModelUpload/css/modelUploadStyles.module.css";
import { Document, Page, pdfjs } from "react-pdf";
//@ts-ignore
import { IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { API_URL } from "../../util/network/common";
import AppStateContext from "../../contexts/AppStateContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PropsType = {
  productId: number;
};

const PDFViewer: React.FC<PropsType> = ({ productId }) => {
  const { token } = useContext(AppStateContext);

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { height, width } = useWindowDimensions();

  const url = `${API_URL}/v1/ecommerce/products/${productId}/blueprint`;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    console.log("PDF LOADED");
    setNumPages(numPages);
  }

  return (
    <div>
      {/*@ts-ignore*/}
      <Document
        file={{
          url: url,
          httpHeaders: {
            Authorization: `Bearer ${token}`,
          },
        }}
        error={"Ingen arbejdstegning uploadet"}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => {
          console.log("PDF ERROR");
        }}
      >
        {/*@ts-ignore*/}
        <Page
          pageNumber={pageNumber}
          //height={height * 0.8}
          width={width * 0.45}
          loading={<div className={styles.pdfTemp} />}
        />
      </Document>
      <div className={styles.pdfNavigation}>
        <IconButton
          onClick={() => setPageNumber(pageNumber - 1)}
          disabled={pageNumber === 1}
          color={"primary"}
        >
          <ArrowBack />
        </IconButton>
        <p className={styles.pdfPageText}>
          Side <b>{pageNumber}</b> af <b>{numPages}</b>
        </p>
        <IconButton
          onClick={() => setPageNumber(pageNumber + 1)}
          disabled={pageNumber === numPages}
          color={"primary"}
        >
          <ArrowForward />
        </IconButton>
      </div>
    </div>
  );
};

export default PDFViewer;
