import { Grid, TextField, Typography, LinearProgress } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import styles from "./productionTasks.module.css";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { ReactComponent as StepIcon } from "../../assets/svg/stepFile.svg";
import DraggableTable from "components/DraggableTasks/DraggableTable";
import DoneIcon from "@mui/icons-material/Done";
import DraggableButton from "components/CustomButtons/DraggableButton";
import {
  UpdateCNCProductType,
  ProductTypeEnum,
  ManufacturedProductDetailsType,
} from "types/products/ProductCommandsType";
import {
  apiCreateProduction,
  apiCreateProductionTask,
  apiDeleteProductionTask,
  apiGetProduction,
  apiUpdateProduction,
} from "util/network/Productions";
import AppStateContext from "contexts/AppStateContext";
import {
  apiCreateProduct,
  apiGetPreviewImage,
  apiUpdateProduct,
} from "util/network/Products";
import {
  CreateProductionTaskType,
  ProductionTaskType,
  ProductionVMType,
  UpdateProductionTaskType,
} from "types/ProductionType";
import MatSelector from "pages/order_management/orderedit_components/MatSelector";
import {
  CustomFinishType,
  CustomMaterialType,
} from "types/products/ProductOptionsType";
import CustomMaterialSelector from "pages/order_management/orderedit_components/CustomMaterialSelector";
import PreviewButton from "components/Generic/PreviewButton";
import CustomFinishSelector from "pages/order_management/orderedit_components/CustomFinishSelector";
import FinishSelector from "pages/order_management/orderedit_components/FinishSelector";
import { UserType } from "types/users/UserTypes";
import { MachineType } from "types/MachineType";
import { fetchPublic } from "util/network/common";
import { apiGetModel, apiUploadModelFile } from "util/network/Models";
import { useToast } from "contexts/ToastContext";
import { SelectedThreadsInfoType } from "types/RenderTypes";
import { ModelType } from "types/ModelTypes";

export type TaskTemplatesType = {
  [key: string]: CreateProductionTaskType;
};

type ModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  onSave: () => void;
  project?: ProductionVMType;
  machines?: MachineType[];
};

const ProductionTasks: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  project,
  machines,
  onSave,
}) => {
  const { token } = useContext(AppStateContext);
  const [production, setProduction] = useState<ProductionVMType>();
  const [model, setModel] = useState<ModelType>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [material, setMaterial] = useState<string | undefined>();
  const [customMaterial, setCustomMaterial] = useState<
    CustomMaterialType | undefined
  >();
  const [finish, setFinish] = useState<string[] | undefined>();
  const [customFinish, setCustomFinish] = useState<
    CustomFinishType | undefined
  >();
  const [previewImage, setPreviewImage] = useState<string>();
  const [itemAmount, setItemAmount] = useState<number>(1);
  const [updatedOrderId, setUpdatedOrderId] = useState<number>();
  const [comment, setComment] = useState<string>("");
  const [threads, setThreads] = useState<SelectedThreadsInfoType[]>([]);
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplatesType>();
  const [fetchError, setFetchError] = useState(null);
  const [productId, setProductId] = useState<number>();
  const [tasks, setTasks] = useState<Partial<ProductionTaskType>[]>([]);
  const [customer, _setCustomer] = useState<UserType>();
  const { addToast } = useToast();
  const [deletedTasks, setDeletedTasks] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);

  const reset = () => {
    setIsDisabled(true);
    setProductId(undefined);
    setModel(undefined);
    setMaterial(undefined);
    setFinish(undefined);
    setCustomMaterial(undefined);
    setCustomFinish(undefined);
    setComment("");
    setItemAmount(1);
  };

  // Init details
  useEffect(() => {
    if (project) {
      setProductId(project.productId);
      const details = project.product.details as ManufacturedProductDetailsType;
      setModel(details.model);
      setMaterial(details.standardMaterial?.name);
      setFinish((details.finish as any)?.split(","));
      if (details.customMaterial) setCustomMaterial(details?.customMaterial);
      if (details.customFinish) setCustomFinish(details?.customFinish);
      setComment(details?.comment ?? "");
      setItemAmount(details?.quantity ?? 1);
      setThreads(details?.threads ?? []);
    } else {
      setIsDisabled(false);
    }

    return () => {
      reset();
    };
  }, [project]);

  useEffect(() => {
    if (token && model)
      apiGetPreviewImage(token, model.id).then((img) => {
        if (img) setPreviewImage(img);
      });
  }, [token, model]);

  const updateFinish = useCallback((finish: string[]) => {
    if (finish.includes("custom")) {
      setCustomFinish({
        name: "",
        description: "",
      });
    } else {
      setFinish(finish);
    }
  }, []);

  const handleFileUpload = (file: File, type: ProductTypeEnum) => {
    setModelLoading(true);

    apiUploadModelFile(token, file, type)
      .then((res) => {
        if (res.filesAdded > 1) {
          setModelLoading(false);
          addToast({
            type: "error",
            message:
              "The model you added consists of more than one solid. Please upload a single solid model.",
            keep: false,
          });
          return;
        } else if (res.filesAdded === 0) {
          setModelLoading(false);
          addToast({
            type: "error",
            message:
              "The model you added is not a solid. Please upload a solid model.",
            keep: false,
          });
          return;
        } else if (res.filesAdded === 1) {
          setModelLoading(false);
          addToast({
            type: "success",
            message: "Model uploaded successfully",
            keep: false,
          });

          apiGetModel(token, res.filesList[0].id).then((model) => {
            setModel(model);
            apiCreateProduct(token, {
              modelId: model.id,
              productType: ProductTypeEnum.CNC,
              qty: 1,
              material: "alu-6082",
              finish: ["standard"],
              blueprint: null,
              comment: null,
              threads: [],
            }).then((product) => {
              setProductId(product.id);
            });
          });
        }
      })
      .catch((err) => {
        setModelLoading(false);
        addToast({
          type: "error",
          message:
            "Something went wrong with the upload. Check the file and try again\n Error code: " +
            err,
          keep: false,
        });
      });
  };

  const handleSave = () => {
    if (production) {
      const deleteTasksPromises = deletedTasks.map((taskId) => {
        return apiDeleteProductionTask(token, Number(taskId));
      });

      const newTasks = tasks.filter((task) => String(task.id).includes("T"));
      const updatedTasks = tasks
        .filter((task) => !String(task.id).includes("T"))
        .map((task) => {
          return {
            id: Number(task.id),
            priority: task.priority,
            name: task.name,
            description: task.description,
            state: task.state,
            progress: task.progress,
            progressMax: task.progressMax,
            workTime: task.workTime,
            leadTime: task.leadTime,
            deadline: task.deadline,
            users: task.users?.map((user) => user.id),
            machines: task.machines?.map((mch) => mch.id),
          } as UpdateProductionTaskType;
        });
      const body = {
        customerId: production.customerId,
        deadline: production.deadlineDate,
        orderId:
          updatedOrderId !== undefined ? updatedOrderId : production.orderId,
        priority: production.priority,
        outsourced: production.outsourced,
        status: production.status,
        tasks: updatedTasks,
      };
      apiUpdateProduction(token, production.id, body)
        .then((updatedProd) => {
          const updatedProductDetails: UpdateCNCProductType = {
            quantity: itemAmount,
            blueprint: null,
            comment: comment,
            threads: threads,
            material: customMaterial ? undefined : material,
            customMaterial: customMaterial
              ? {
                  name: customMaterial.name,
                  density: customMaterial.density,
                  description: customMaterial.description,
                  customerProvides: customMaterial.customerProvides,
                }
              : undefined,
            finish: customFinish ? undefined : finish,
            customFinish: customFinish
              ? {
                  name: customFinish.name,
                  description: customFinish.description,
                }
              : undefined,
          };
          addToast({
            type: "success",
            message: "Task updated successfully",
            keep: false,
          });
          apiUpdateProduct(token, production?.productId, updatedProductDetails)
            .then((data) => {})
            .catch((error) => {
              console.error("Failed to update product:", error);
              addToast({
                type: "error",
                message: "Task was not updated",
                keep: false,
              });
            });
          return Promise.all([
            ...deleteTasksPromises,
            ...newTasks.map((task) => {
              const newTask: CreateProductionTaskType = {
                name: task.name!,
                productionId: updatedProd.id,
                description: task.description,
                priority: task.priority,
                deadline: task.deadline,
                leadTime: task.leadTime,
                workTime: task.workTime,
                progressMax: task.progressMax,
                users: task.users?.map((user) => user.id),
                machines: task.machines?.map((mch) => mch.id),
                state: task.state,
                allowUsers: task.allowUsers,
                allowMachines: task.allowMachines,
                allowWorkTime: task.allowWorkTime,
                allowLeadTime: task.allowLeadTime,
                allowProgress: task.allowProgress,
              };

              return apiCreateProductionTask(token, newTask)
                .then((response) => {
                  setProduction((prevProduction) => {
                    if (prevProduction && response) {
                      return {
                        ...prevProduction,
                        tasks: response.tasks,
                      };
                    }
                    return prevProduction;
                  });
                })
                .catch((error) => {
                  console.error("Error creating new task", error);
                });
            }),
          ]).then((res) => {
            return apiGetProduction(token, updatedProd.id).then((res) => {
              setDeletedTasks([]);
              setProduction(res);
              setTasks(res.tasks);
              if (onSave) onSave();

              return res;
            });
          });
        })
        .catch((error) => {
          console.error("Failed to update task:", error);
        });
    }

    reset();
    onClose();
  };

  // Create a new production
  const handleCreateNew = () => {
    if (productId && taskTemplates) {
      const deadline = new Date();

      const placeholderUser = {
        id: 0,
        firstName: "Placeholder",
        lastName: "User",
      };
      const formattedDeadline = deadline.toISOString().split("T")[0];
      const taskNames = ["CAM", "Material", "Milling", "QC"];
      const defaultTasks = Object.values(taskTemplates)
        .filter((task) => {
          const description = task?.description;
          return description ? taskNames.includes(description) : false;
        })
        .map((template, index) => ({
          ...template,
          id: undefined,
          productionId: undefined,
          // users: template.users
          //   ? template.users.map(() => placeholderUser)
          //   : [], // Convert users to placeholder user
          // machines: template.machines
          //   ? template.machines.map((machineId) => ({
          //       id: machineId,
          //       name: "Machine Name",
          //       label: "Machine Label",
          //     }))
          //   : [],
        }));

      // const mergedTasks = defaultTasks.map((defaultTask, index) => {
      //   const changedTask = tasks.find((task) => task.id === defaultTask.id);
      //   return changedTask ? { ...defaultTask, ...changedTask } : defaultTask;
      // });

      const newProductionData = {
        productId: productId,
        deadline: formattedDeadline,
        orderId: updatedOrderId,
        outsourced: false,
        tasks: defaultTasks,
      };
      apiCreateProduction(token, newProductionData)
        .then((newProduction) => {
          setProduction(newProduction);
          setTasks(newProduction.tasks || []);
          reset();
          onClose();
          onSave && onSave();
          addToast({
            type: "success",
            message: "Production created successfully",
            keep: false,
          });
        })
        .catch((error) => {
          console.error("Failed to create new production:", error);
        });
    }
  };

  // const handleCreateNew = () => {
  //   if (productId && taskTemplates) {
  //     const deadline = new Date();

  //     const placeholderUser = {
  //       id: 0,
  //       firstName: "Placeholder",
  //       lastName: "User",
  //     };
  //     const formattedDeadline = deadline.toISOString().split("T")[0];
  //     const taskNames = ["CAM", "Material", "Milling", "QC"];
  //     const defaultTasks = Object.values(taskTemplates)
  //       .filter((task) => {
  //         const description = task?.description;
  //         return description ? taskNames.includes(description) : false;
  //       })
  //       .map((template, index) => ({
  //         ...template,
  //         id: undefined,
  //         productionId: undefined,
  //         users: template.users
  //           ? template.users.map(() => placeholderUser)
  //           : [], // Convert users to placeholder user
  //         machines: template.machines
  //           ? template.machines.map((machineId) => ({
  //               id: machineId,
  //               name: "Machine Name",
  //               label: "Machine Label",
  //             }))
  //           : [], // Convert machines to the correct type
  //       }));

  //     // Combine default tasks and existing tasks
  //     const allTasks = [...defaultTasks, ...tasks];

  //     // Create the production without tasks initially
  //     const newProductionData = {
  //       productId: productId,
  //       deadline: formattedDeadline,
  //       orderId: updatedOrderId,
  //       outsourced: false,
  //       tasks: defaultTasks,
  //     };

  //     apiCreateProduction(token, newProductionData)
  //       .then((newProduction) => {
  //         const createTaskPromises = allTasks.map((task) => {
  //           const newTask: CreateProductionTaskType = {
  //             name: task.name!,
  //             productionId: newProduction.id,
  //             description: task.description,
  //             priority: task.priority,
  //             deadline: task.deadline,
  //             leadTime: task.leadTime,
  //             workTime: task.workTime,
  //             progressMax: task.progressMax,
  //             users: task.users?.map((user) => user.id),
  //             machines: task.machines?.map((mch) => mch.id),
  //             state: task.state,
  //             allowUsers: task.allowUsers,
  //             allowMachines: task.allowMachines,
  //             allowWorkTime: task.allowWorkTime,
  //             allowLeadTime: task.allowLeadTime,
  //             allowProgress: task.allowProgress,
  //           };

  //           return apiCreateProductionTask(token, newTask).then((response) => {
  //             if (response && response.tasks) {
  //               return response.tasks;
  //             } else {
  //               console.error("Invalid task response:", response);
  //               return null;
  //             }
  //           });
  //         });

  //         return Promise.all(createTaskPromises).then((taskResponses) => {
  //           const newTasks = taskResponses
  //             .flat()
  //             .filter((task): task is ProductionTaskType => task !== null);
  //           setProduction({
  //             ...newProduction,
  //             tasks: newTasks,
  //           });
  //           setTasks(newTasks);
  //           reset();
  //           onClose();
  //           onSave && onSave();
  //           addToast({
  //             type: "success",
  //             message: "Production created successfully",
  //             keep: false,
  //           });
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Failed to create new production:", error);
  //       });
  //   }
  // };

  const handleTaskChange = (
    taskId: string,
    updatedTaskData: Partial<ProductionTaskType>
  ) => {
    setTasks((prevTasks) =>
      prevTasks!.map((task) =>
        task.id === taskId ? { ...task, ...updatedTaskData } : task
      )
    );
  };

  const handleTaskDelete = (taskId: string) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
    setDeletedTasks((prevTasksToDelete) => [...prevTasksToDelete, taskId]);
  };

  useEffect(() => {
    fetchPublic("/tasksTemplates.json")
      .then((data) => {
        setTaskTemplates(data);
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
        setFetchError(error);
      });
  }, []);

  useEffect(() => {
    if (project && isOpen) {
      setLoading(true);
      apiGetProduction(token, project.id).then((production) => {
        setProduction(production);
        setTasks(production.tasks);
        setLoading(false);
        setUpdatedOrderId(production.orderId);
      });
    }
  }, [token, project?.id, isOpen]);

  const handleOrderIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOrderId = parseInt(event.target.value);
    if (!isNaN(newOrderId)) {
      setUpdatedOrderId(newOrderId);
    }
  };

  const handleItemAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newItemAmount = parseInt(event.target.value, 10);
    if (!isNaN(newItemAmount)) {
      setItemAmount(newItemAmount);
    }
  };

  const handleCreateNewTask = (template: CreateProductionTaskType) => {
    const tempId = Math.round((Math.random() + 1) * 100000);
    const newTask = {
      ...(template as unknown as Partial<ProductionTaskType>),

      id: `T${tempId}`,
      priority: tasks.length,
      productionId: production?.id,
    };
    setTasks((prev) => [...prev, newTask]);
  };

  return (
    <Grid item xs={12}>
      <div className={styles.container}>
        <div className={styles.container__header}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant={"h6"}
              style={{
                textTransform: "uppercase",
                color: "#fff",
                paddingLeft: "1rem",
              }}
            >
              &nbsp;{" "}
              {project
                ? `Production #${project?.productId
                    .toString()
                    .padStart(4, "0")}`
                : "New CNC Production"}
              &nbsp;
            </Typography>
          </div>
          <div className={styles.pd_rg__1}>
            <button
              onClick={project ? handleSave : handleCreateNew}
              className={`${styles.btn} ${styles.btn__green}`}
            >
              <DoneIcon /> &nbsp; &nbsp; &nbsp;
              <span>{project ? "Save" : "Create"}</span>
            </button>
          </div>
        </div>
        <div className={styles.pd__05}>
          {isDisabled ? (
            ""
          ) : (
            <DraggableButton
              accept={".step, .STEP, .stp, .STP"}
              buttonProps={{
                sx: {
                  backgroundColor: "var(--buttonYellow)",
                },
              }}
              onFilesDropped={(files) => {
                files.forEach((file) => {
                  handleFileUpload(file, ProductTypeEnum.CNC);
                });
              }}
            >
              <FileUploadIcon /> Model
            </DraggableButton>
          )}
          {modelLoading ? (
            <div className={styles.pd_top__2}>
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <div className={styles.container_row}>
            <div className={styles.pd_top__2}>
              <div className={styles.container__product}>
                {model ? (
                  <PreviewButton
                    key={`${model.id}${model.modified}`}
                    productId={project?.productId ?? 0}
                    modelId={model.id}
                    size={"small"}
                  />
                ) : (
                  ""
                )}
                <div className={styles.container__product_info}>
                  <div className={styles.container__product_details}>
                    <div>#{project?.productId}</div>
                    <div className={styles.product_details__file}>
                      {model && `${model?.name}`}
                    </div>
                  </div>
                  <div className={styles.container__icons}>
                    <StepIcon style={{ fill: "#474848", cursor: "pointer" }} />
                  </div>
                </div>
              </div>
              <div className={styles.pd_top__05}>
                <div className={styles.container__input_fields}>
                  <div className={styles.input_field__separation}>
                    <TextField
                      id="outlined-size-small"
                      value={String(updatedOrderId)}
                      label="Order ID"
                      onChange={handleOrderIdChange}
                      size="small"
                      sx={{ width: "5rem" }}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.container__input_fields} ${styles.pd_top__05}`}
                >
                  <div className={styles.input_field__separation}>
                    <TextField
                      id="outlined-size-small"
                      value={itemAmount}
                      size="small"
                      label="Quantity"
                      onChange={handleItemAmountChange}
                      sx={{ width: "5rem" }}
                    />
                  </div>
                  <div
                    className={`${styles.input_field__separation} ${styles.container__input_fields}`}
                  >
                    {customMaterial !== undefined ? (
                      <CustomMaterialSelector
                        value={customMaterial}
                        onChange={(val: CustomMaterialType | null) => {
                          if (val) {
                            setCustomMaterial(val);
                          } else {
                            setCustomMaterial(undefined);
                          }
                        }}
                      />
                    ) : (
                      <MatSelector
                        currentSel={material ?? "alu-6082"}
                        onChange={(mat: string) => {
                          if (mat === "custom") {
                            setCustomMaterial({
                              name: "",
                              description: "",
                              customerProvides: false,
                              density: 0,
                            });
                          } else {
                            setMaterial(mat);
                            setFinish(["standard"]);
                          }
                        }}
                      />
                    )}

                    {customFinish !== undefined ? (
                      <CustomFinishSelector
                        value={customFinish}
                        onChange={(val: CustomFinishType | null) => {
                          if (val) {
                            setCustomFinish(val);
                          } else {
                            setCustomFinish(undefined);
                          }
                        }}
                      />
                    ) : (
                      <>
                        <FinishSelector
                          material={customMaterial ? "custom" : material ?? ""}
                          currentSel={finish ?? ["standard"]}
                          onChange={updateFinish}
                        />
                      </>
                    )}
                  </div>{" "}
                </div>
                <div
                  className={`${styles.container__input_fields} ${styles.pd_top__05}`}
                >
                  <div className={styles.input_field__separation}>
                    <TextField
                      value={comment}
                      onChange={(e: any) => setComment(e.target.value)}
                      size="small"
                      placeholder="Comment"
                      id="standard-textarea"
                      multiline
                      rows={2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                src={previewImage}
                alt={project?.product.name}
                className={`${styles.img__preview}`}
                id="previewImg"
              />
            </div>
          </div>
          <div className={styles.pd_top__2}>
            <div className={styles.container__title}>
              <div className={styles.title}>Tasks</div>
            </div>
            {loading ? (
              <div className={styles.pd_top__2}>
                <LinearProgress />
              </div>
            ) : (
              tasks && (
                <DraggableTable
                  machines={machines}
                  tasks={tasks}
                  updateTasks={handleTaskChange}
                  templates={taskTemplates}
                  setNewTask={(template: CreateProductionTaskType) =>
                    handleCreateNewTask(template)
                  }
                  handleTaskDelete={handleTaskDelete}
                  deletedTasks={deletedTasks}
                  project={project}
                />
              )
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
};
export default ProductionTasks;
