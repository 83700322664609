import { Button, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { Materials } from "../../../util/MaterialUtils";
import { ArrowDropDown, Close } from "@mui/icons-material";
import { MaterialType } from "../../../types/NetworkTypes";
import { fetchPublic } from "util/network/common";

type PropsType = {
  currentSel: any;
  disabled?: boolean;
  onChange: Function;
  custom?: boolean;
  materialFile?: string;
};

const MatSelector: FunctionComponent<PropsType> = ({
  currentSel,
  disabled,
  onChange,
  custom,
  materialFile,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState<string>(currentSel);
  const [options, setOptions] = useState<MaterialType[]>([]);

  useEffect(() => {
    setSelected(currentSel);
  }, [currentSel]);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: any, id: string) => {
    if (id !== selected) {
      setSelected(id);
      setAnchorEl(null);
      onChange(id);
      console.log("Material selected in MatSelector:", id);
    }
  };

  useEffect(() => {
    Materials.loadMaterials();
    fetchPublic(materialFile ? `/${materialFile}` : "/cncMaterials.json").then(
      (res) => {
        setOptions(res);
      }
    );
  }, [materialFile]);

  return (
    <>
      {custom ? (
        <TextField
          value={selected}
          sx={{ m: 1, width: "19ch" }}
          onChange={(e) => {
            onChange(e.target.value);
            setSelected(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={(e) => {
                  onChange("disable");
                }}
              >
                <Close />
              </IconButton>
            ),
          }}
          disabled={disabled}
        />
      ) : (
        <>
          <Button
            endIcon={<ArrowDropDown />}
            onClick={handleClick}
            style={{ fontWeight: "bold" }}
            disabled={disabled}
          >
            {selected}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onChange={() => console.log(selected)}
          >
            {options.map((option, key) => [
              <MenuItem key={key} disabled>
                {option.title.gb}
              </MenuItem>,
              ...option.variants.map((variant, key) => (
                <MenuItem
                  key={key}
                  selected={variant.id == selected}
                  onClick={(event: any) =>
                    handleMenuItemClick(event, variant.id)
                  }
                >
                  {variant.gb.materialTitle}
                </MenuItem>
              )),
            ])}
          </Menu>
        </>
      )}
    </>
  );
};

export default MatSelector;
