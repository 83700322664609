import React from "react";
import styles from "./css/filterButton.module.css";

type StatusProps = {
  title: string;
  checked?: boolean;
  onChange?: (event: any) => void;
  name?: string;
};

const FilterButton: React.FC<StatusProps> = ({
  title,
  checked,
  onChange,
  name,
}) => {
  return (
    <>
      <div
        style={{
          paddingBottom: "1rem",
          paddingRight: "1rem",
        }}
      >
        <div
          className={`${styles.container} ${
            onChange ? styles.containerClickable : ""
          } ${checked ? styles.active : ""}`}
          onClick={() => {
            if (onChange) {
              onChange({
                target: {
                  name: name,
                  checked: !checked,
                },
              });
            }
          }}
        >
          {title}
        </div>
      </div>
    </>
  );
};
export default FilterButton;
