// Author: Magnus s195401 & Erik s195397
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import AppStateContext from "contexts/AppStateContext";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserType } from "types/users/UserTypes";
import PartnerRow from "./PartnerRow";
import RefreshIcon from "@mui/icons-material/Refresh";
import { apiGetPartners } from "util/network/Partners";
import { PartnerType } from "types/partners/PartnerTypes";

type PropsType = {
  setExisting: Function;
  setShowPartnerModal: (show: boolean) => void;
  setShowDeleteUser: Function;
  refreshTrigger: number;
};
// Partner Table
const PartnerTable: FunctionComponent<PropsType> = ({
  setExisting,
  setShowPartnerModal,
  setShowDeleteUser,
  refreshTrigger,
}) => {
  const headerText = { color: "white", fontWeight: "bold", variant: "body1" };

  const [loading, setLoading] = useState<boolean>(false);
  const [partners, setPartners] = useState<PartnerType[]>([]);
  const { token } = useContext(AppStateContext);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [userCheck, setUserCheck] = useState<boolean>(false);

  // Set page
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  // Set page size
  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(+event.target.value);
  };

  // Check if there are any users
  useEffect(() => {
    if (partners) {
      if (partners?.length > 0) {
        setUserCheck(true);
      } else if (!partners || partners.length === 0) {
        setUserCheck(false);
      }
    }
  }, [partners]);

  useEffect(() => {
    refreshPartners();
  }, [token, refreshTrigger]);

  useEffect(() => {
    // Loading while fetching users
    if (partners) {
      if (partners?.length > 0) {
        setLoading(false);
      } else if (!partners || partners.length === 0) {
        setLoading(true);
        refreshPartners();
      }
    }
  }, [userCheck]);

  // Refresh partners
  const refreshPartners = () => {
    if (token) {
      apiGetPartners(token, page, pageSize).then((res) => {
        setPartners(res.items);
        setTotal(res.total);
      });
    }
  };
  // Map partners
  const mapPartners = useMemo(() => {
    return partners?.map((partner) => {
      return (
        <PartnerRow
          partner={partner}
          setExisting={setExisting}
          setShowPartnerModal={setShowPartnerModal}
          setShowDeleteUser={setShowDeleteUser}
        />
      );
    });
  }, [partners]);
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              background: "var(--header)",
            }}
          >
            <TableRow>
              <TableCell align="left" sx={{ paddingLeft: "20px" }}>
                <Typography
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Phone.nr.
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Company
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Address
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                ></Typography>
              </TableCell>
              <TableCell>
                <Tooltip title="Genindlæs">
                  <IconButton
                    sx={{ color: "white" }}
                    onClick={() => refreshPartners()}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{mapPartners}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={0}
        rowsPerPage={10}
        page={1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PartnerTable;
