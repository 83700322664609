import { FetchError } from "../exceptions";
import { CreateUserType, UserListType, UserType } from "types/users/UserTypes";
import { API_URL } from "./common";
import { UserUpdateType } from "../../types/users/UserCommandsType";
import { CartType } from "types/CartTypes";
import { EventLogsListType } from "types/EventLogTypes";

const ENDPOINT = `${API_URL}/v1/ecommerce/users`;

export const apiGetUser = (token: string, id: number): Promise<UserType> => {
  return fetch(`${ENDPOINT}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetMe = (token: string): Promise<UserType> => {
  return fetch(`${ENDPOINT}/me`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetCustomers = (
  token: string,
  page: number,
  pageSize: number,
  sortKey: string = "id",
  sortDirection: "asc" | "desc" = "desc"
): Promise<UserListType> => {
  return fetch(
    `${ENDPOINT}/customers?page=${page}&pageSize=${pageSize}&sortKey=${sortKey}&sortDirection=${sortDirection}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetEmployees = (
  token: string,
  page: number,
  pageSize: number,
  sortKey: string = "id",
  sortDirection: "asc" | "desc" = "desc"
): Promise<UserListType> => {
  return fetch(
    `${ENDPOINT}/employees?page=${page}&pageSize=${pageSize}&sortKey=${sortKey}&sortDirection=${sortDirection}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiSearchUsers = (
  token: string,
  value: string
): Promise<UserType[]> => {
  return fetch(`${API_URL}/v1/ecommerce/users/search?value=${value}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiCreateUser = (token: string, customer: CreateUserType) => {
  const body = JSON.stringify(customer);
  return fetch(`${API_URL}/v1/ecommerce/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    return res.json();
  });
};

export const apiDeleteUser = (token: string, userId: number): Promise<any> => {
  return fetch(`${ENDPOINT}/${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    console.log(res);
    if (res.ok) {
      return res.json;
    }
  });
};

export const apiUpdateUser = (
  token: string,
  userId: number,
  customer: UserUpdateType
): Promise<any> => {
  const body = JSON.stringify(customer);
  return fetch(`${API_URL}/v1/ecommerce/users/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetUserCart = (
  token: string,
  id: number
): Promise<CartType[]> => {
  return fetch(`${API_URL}/v1/ecommerce/users/${id}/carts`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};

export const apiGetUserEvents = (
  token: string,
  userId: number,
  page: number,
  pageSize: number,
  sortKey: string = "id",
  sortDirection: "asc" | "desc" = "desc",
  dateFrom?: string,
  dateTo?: string
): Promise<EventLogsListType> => {
  return fetch(
    `${API_URL}/v1/ecommerce/users/${userId}/events?page=${page}&pageSize=${pageSize}&sortKey=${sortKey}&sortDirection=${sortDirection}${
      dateFrom ? `&dateFrom=${dateFrom}` : ""
    }${dateTo ? `&dateTo=${dateTo}` : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    if (res.ok) return res.json();
    else throw new FetchError(res.statusText, res.status);
  });
};
