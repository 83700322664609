import React from "react";
import styles from "./tolSurfCertDisplay.module.css";
import { ReactComponent as MediumIcon } from "../../assets/svg/Medium.svg";
import { ReactComponent as Fineicon } from "../../assets/svg/Fine.svg";
import { ReactComponent as SurfaceStandard } from "../../assets/svg/1.6µm.svg";
import { ReactComponent as Surface } from "../../assets/svg/0.8µm.svg";
import { ReactComponent as Certificate } from "../../assets/svg/certificate.svg";
import { ReactComponent as CertificateYes } from "../../assets/svg/certificateYes.svg";
import { CNCProductDetailsType } from "types/products/ProductCommandsType";

type PropsTypes = {
  details: CNCProductDetailsType;
  showTol?: boolean;
  showSurf?: boolean;
  showCert?: boolean;
};

const TolSurfCertDisplay: React.FC<PropsTypes> = ({
  details,
  showTol,
  showSurf,
  showCert,
}) => {
  return (
    <div className={styles.container}>
      {showTol && (
        <div className={`${styles.iconContainer} ${styles.pdRg}`}>
          <span className={styles.smallTitle}>Tolerance</span>
          {(details.tolerance === 0 && <MediumIcon />) ||
            (details.tolerance === 1 && <Fineicon />)}
        </div>
      )}
      <div className={styles.algnCenter}>
        {showSurf && (
          <div className={`${styles.iconContainer} ${styles.pdRg}`}>
            <span className={styles.smallTitle}>Surface</span>
            {(details.surfaceQuality === 0 && <SurfaceStandard />) ||
              (details.surfaceQuality === 1 && <Surface />)}
          </div>
        )}
        {showCert && (
          <div className={styles.iconContainer}>
            {(details.materialCertificate == true && <CertificateYes />) ||
              (details.materialCertificate == false && <Certificate />)}
          </div>
        )}
      </div>
    </div>
  );
};
export default TolSurfCertDisplay;
