import { Button, IconButton, TextField } from "@mui/material";
import ExpandingContainer from "components/ExpandingContainer";
import { useEffect, useState } from "react";
import { CustomFinishType } from "types/products/ProductOptionsType";
import { Close } from "@mui/icons-material";

type PropsType = {
  value: CustomFinishType;
  onChange: (value: CustomFinishType | null) => void;
  disabled?: boolean;
};

const CustomFinishSelector: React.FC<PropsType> = ({
  value,
  onChange,
  disabled,
}) => {
  const [name, setName] = useState<string>(value.name);
  const [description, setDescription] = useState<string>(value.description);

  const [open, setOpen] = useState<boolean>(false);

  const makeChange = () => {
    const change = {
      name: name,
      description: description,
    };
    onChange(change);
  };

  useEffect(() => {
    setName(value.name);
    setDescription(value.description);
  }, [value]);

  return (
    <ExpandingContainer
      open={open}
      setOpen={(_open: boolean) => {
        if (!_open) {
          makeChange();
        }
        setOpen(_open);
      }}
      primaryElement={
        <TextField
          value={name}
          sx={{ m: 1, minWidth: "12em" }}
          onChange={(e: any) => {
            console.log(e.target.value);
            setName(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={(e) => {
                  onChange(null);
                }}
              >
                <Close />
              </IconButton>
            ),
          }}
          disabled={disabled}
        />
      }
      expandingElements={[
        <TextField
          sx={{ m: 1 }}
          label={"Description"}
          multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        ></TextField>,
        <Button
          sx={{ m: 1, width: "19ch" }}
          variant="contained"
          onClick={() => {
            makeChange();
            setOpen(false);
          }}
        >
          UPDATE
        </Button>,
      ]}
    />
  );
};

export default CustomFinishSelector;
