import React, { useEffect, useState } from "react";
import { ProductionTaskType, ProductionVMType } from "types/ProductionType";
import styles from "../../pages/tasks_management/css/tasksPages.module.css";
import { ManufacturedProductDetailsType } from "types/products/ProductCommandsType";

type PropsType = {
  task: ProductionTaskType;
  onChange?: (task: Partial<ProductionTaskType>) => void;
  project?: ProductionVMType;
};

const TaskQuantitySelector: React.FC<PropsType> = ({
  task,
  onChange,
  project,
}) => {
  const [progress, setProgress] = useState(task.progress);
  const [quantity, setQuantity] = useState<number>(task.progress || 0);
  const details =
    project && (project.product.details as ManufacturedProductDetailsType);
  const [maxQuantity, setMaxQuantity] = useState<number>(
    details?.quantity || 1
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    } else if (value > task.progressMax) {
      value = task.progressMax;
    }
    setProgress(value);
    if (value !== task.progress) {
      if (onChange) {
        onChange({
          id: task.id,
          progress: value,
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setProgress(value === "" ? 0 : parseInt(value, 10));
  };

  useEffect(() => {
    if (details) {
      setMaxQuantity(details.quantity);
    }
  }, [details]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <input
        type="number"
        value={progress}
        onChange={(e) => {
          let value = parseInt(e.target.value);
          if (isNaN(value)) {
            value = 0;
          }
          if (value < 0) {
            value = 0;
          } else if (value > task.progressMax) {
            value = task.progressMax;
          }
          setProgress(value);
        }}
        onClick={(e) => e.stopPropagation()}
        onBlur={(e) => {
          let value = parseInt(e.target.value);
          if (isNaN(value)) {
            value = 0;
          }
          if (value < 0) {
            value = 0;
          } else if (value > task.progressMax) {
            value = task.progressMax;
          }
          setProgress(value);
          if (value !== task.progress) {
            if (onChange)
              onChange({
                id: task.id,
                progress: value,
              });
          }
        }}
        style={{
          width: "2.5em",
          textAlign: "center",
          borderRadius: "1px",
        }}
      />
      <span>
        <span className={styles.quantityNum}> /{details?.quantity}</span>
        <span className={styles.smallText}>pcs</span>
      </span>
    </div>
  );
};
export default TaskQuantitySelector;
