import { Button, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import EmployeeTable from "../EmployeeTable";
import AppStateContext from "../../../contexts/AppStateContext";
import EmployeeModal from "../employee_edit_components/EmployeeModal";
import { CreateUserType, UserType } from "../../../types/users/UserTypes";
import DeleteUser from "../employee_edit_components/DeleteUser";
import EditEmployeeModal from "../employee_edit_components/EditEmployeeModal";
import {
  apiCreateUser,
  apiDeleteUser,
  apiUpdateUser,
} from "util/network/Users";
import { UserUpdateType } from "types/users/UserCommandsType";
import { useToast } from "contexts/ToastContext";

const EmployeeManagementUI = () => {
  const [existing, setExisting] = useState<UserType | null>();
  const { token, refreshEmployees } = useContext(AppStateContext);
  const { addToast } = useToast();

  const [showDeleteUser, setShowDeleteUser] = useState<boolean>(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
  const [editEmployeeModal, setEditEmployeeModal] = useState<boolean>(false);

  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

  const handleDeleteEmployee = (id: number) => {
    apiDeleteUser(token, id)
      .then((res) => {
        console.log(res);
        addToast({
          type: "success",
          message: (
            <>
              Employee was <strong>deleted</strong> successfully.
            </>
          ),
        });
        setShowDeleteUser(false);
        setRefreshTrigger((trigger) => trigger + 1);
      })

      .catch((e) => {
        console.log(e);
      });
  };
  const handleCreateEmployee = (user: CreateUserType) => {
    apiCreateUser(token, user).then((res) => {
      if (!res.message) {
        addToast({
          type: "success",
          message: (
            <>
              New employee was <strong>created</strong> successfully.
            </>
          ),
        });
        setShowEmployeeModal(false);
        setRefreshTrigger((trigger) => trigger + 1);
      } else if (res.message) {
        addToast({
          type: "error",
          message: "Der skete en fejl ved oprettelse: \n" + res.message,
          keep: true,
        });
      }
    });
  };
  const handleEditEmployee = (id: number, changes: UserUpdateType) => {
    apiUpdateUser(token, id, changes).then((res) => {
      if (!res.message) {
        setEditEmployeeModal(false);
        addToast({
          type: "success",
          message: (
            <>
              Employee was <strong>edited</strong> successfully.
            </>
          ),
        });
        refreshEmployees();
        setRefreshTrigger((trigger) => trigger + 1);
      } else if (res.message) {
        addToast({
          type: "error",
          message: "Der skete en fejl ved redigere: \n" + res.message,
          keep: true,
        });
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "10px",
            background: "var(--header)",
            color: "#fff",
            height: "6vh",
            paddingTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontWeight={"bold"}
            variant={"h6"}
            style={{
              textTransform: "uppercase",
              color: "#fff",
              paddingLeft: "1rem",
            }}
          >
            &nbsp;Brugeroversigt
          </Typography>
          <Button
            sx={{
              borderRadius: 0,
              maxHeight: 40,
              width: "10rem",
              background: "#E0A900",
              color: "#fff",
            }}
            onClick={() => setShowEmployeeModal(true)}
          >
            Opret ny bruger
          </Button>
        </div>
      </Grid>
      <Grid
        item
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        xs={2}
      >
        <EmployeeModal
          // existing={existing}
          setUser={setExisting}
          showEmployeeModal={showEmployeeModal}
          setShowEmployeeModal={setShowEmployeeModal}
          createUser={handleCreateEmployee}
        />
        <DeleteUser
          existing={existing}
          setUser={setExisting}
          deleteUser={handleDeleteEmployee}
          showDeleteUser={showDeleteUser}
          setShowDeleteUser={setShowDeleteUser}
        />
        <EditEmployeeModal
          editEmployeeModal={editEmployeeModal}
          setEditEmployeeModal={setEditEmployeeModal}
          setUser={setExisting}
          existing={existing}
          editEmployee={handleEditEmployee}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: "2rem 1.5rem 0 1.5rem" }}>
        <EmployeeTable
          setExisting={setExisting}
          setEditEmployeeModal={setEditEmployeeModal}
          setShowDeleteUser={setShowDeleteUser}
          refreshTrigger={refreshTrigger}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeManagementUI;
