import { Table, TableCell, TableRow, Tooltip } from "@mui/material";
import PreviewButton from "components/Generic/PreviewButton";
import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./css/tasksPages.module.css";
import {
  fetchPDFFile,
  fetchSTEPFile,
  fetchThreadFile,
} from "util/FileDownload";
import { ReactComponent as StepIcon } from "../../assets/svg/stepFile.svg";
import { ReactComponent as PdfIcon } from "../../assets/svg/pdfFile.svg";
import { ReactComponent as ThreadsIcon } from "../../assets/svg/threadFile.svg";
import { ReactComponent as SetupSheetIcon } from "../../assets/svg/setupSheet.svg";
import AssignPersonMachine from "pages/project_management/AssignPersonMachine";
import TaskDeadline from "components/CustomTasksComponents/TaskDeadline";
import {
  apiGetProduction,
  apiGetProductionDatasheet,
} from "util/network/Productions";
import TolSurfCertDisplay from "components/TolSurfCertDisplay/TolSurfCertDisplay";
import { Person } from "@mui/icons-material";
import ThreadDisplay from "components/OrderView/ThreadDisplay";
import Popup from "components/ChatBox/Popup";
import { ProductionTaskType, ProductionVMType } from "types/ProductionType";
import AppStateContext from "contexts/AppStateContext";
import {
  CNCProductDetailsType,
  ProductTypeEnum,
  ProductVMType,
} from "types/products/ProductCommandsType";
import { UserType } from "types/users/UserTypes";
import { ProductionPartState } from "./camPage/CamRow";
import { ModelDataType } from "types/RenderTypes";
import { apiGetUser } from "util/network/Users";
import { apiGetOrder, apiGetOrderItem } from "util/network/Orders";
import { useToast } from "contexts/ToastContext";
import { apiGetModelInfo } from "util/network/Products";
import { OrderItemType, OrderType } from "types/OrderTypes";
import { ReactComponent as OrderMsgIcon } from "../../assets/svg/orderMessage.svg";
import { ReactComponent as OrderMsgYelIcon } from "../../assets/svg/orderMessageYellow.svg";
import QuantitySelector from "components/QuantitySelector/QuantitySelector";
import { MachineType } from "types/MachineType";
import TaskQuantitySelector from "components/TaskQuantitySelector.tsx/TaskQuantitySelector";

type PropsType = {
  task: ProductionTaskType;
  project: ProductionVMType;
  buttonComponent: React.ReactNode;
  taskStatus: ProductionPartState;
  cam?: boolean;
  review?: boolean;
  cut?: boolean;
  mill?: boolean;
  qc?: boolean;
  shipping?: boolean;
  machines?: MachineType[];
  onChange?: (task: Partial<ProductionTaskType>) => void;
};

const TaskTableRow: React.FC<PropsType> = ({
  project,
  buttonComponent,
  taskStatus,
  cam,
  review,
  cut,
  mill,
  qc,
  shipping,
  machines,
  task,
  onChange,
}) => {
  const { token } = useContext(AppStateContext);
  const { addToast } = useToast();
  const [details, setDetails] = useState<CNCProductDetailsType>({
    ...(project.product.details as CNCProductDetailsType),
    finish: (project.product.details as any).finish.split(","),
  });
  const detailsCnc = project.product.details as CNCProductDetailsType;
  const [colorStepExist, setColorStepExist] = useState<boolean>();
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  const [isLastMessage, setIsLastMessage] = useState<boolean>(false);
  const [customer, setCustomer] = useState<UserType>();
  const [leadTimeDate, setLeadTimeDate] = useState<Date>(
    new Date(project.deadlineDate)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [daysLeft, setDaysLeft] = useState<number>();
  const [tasks, setTasks] = useState<ProductionTaskType[]>(project.tasks);
  const [product, setProduct] = useState<ProductVMType>(project.product);
  const [dxfFileExists, setDxfFileExists] = useState<boolean>(false);
  const [modelInfo, setModelInfo] = useState<ModelDataType>();
  const [order, setOrder] = useState<OrderType>();
  const [item, setItem] = useState<OrderItemType>();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedMachineId, setSelectedMachineId] = useState<string | null>(
    null
  );

  const material = details?.customMaterial ? `${details.customMaterial.name}` : details?.standardMaterial?.name ?? details?.material;
  const finish = details?.customFinish ? `${details?.customFinish.name}` : details?.finish || [];

  const formatFinish = (finish: string): string => {
    return finish.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/-+/g, "-");
  };
  const formattedFinishes = Array.isArray(finish) ? finish.map(formatFinish).join(" ") : formatFinish(finish || "");

  const opacityStyle = cam
    ? taskStatus.type == "review" && taskStatus.state == "TODO"
    : cut
    ? taskStatus.type == "cam" && taskStatus.state == "TODO"
    : null;

  const handleButtonClickInternal = () => {
    if (customer) {
      setIsNewMessage(false);
      setShowInternalChat((prev) => !prev);
    } else {
      if (token) {
        apiGetUser(token, project.product.authorId).then((data) => {
          setCustomer(data);
          setIsNewMessage(false);
          setShowInternalChat((prev) => !prev);
        });
      }
    }
  };

  useEffect(() => {
    if (details) {
      if (details.threads && details.threads.length > 0) {
        setColorStepExist(true);
      } else {
        setColorStepExist(false);
      }
    }
  }, [details]);

  const cncItems = useMemo(() => {
    return order?.items.filter(
      (i) => i.product.productType == ProductTypeEnum.CNC
    );
  }, [order]);

  useEffect(() => {
    const fetchProductionData = async () => {
      try {
        const productionRes = await apiGetProduction(token, project.id);
        setProduct(productionRes.product);
        const productDetails = {
          ...(productionRes.product.details as CNCProductDetailsType),
          finish: (productionRes.product.details as any).finish.split(","),
        };
        setDetails(productDetails);
        setTasks(productionRes.tasks);

        const modelInfoData = await apiGetModelInfo(
          token,
          productDetails.modelId
        );
        setModelInfo(modelInfoData);

        // Check if orderId is valid before proceeding
        if (!project.orderId) {
          console.warn("Order ID is null or undefined. Skipping order fetching.");
          return; // Exit the function early
        }

        const orderRes = await apiGetOrder(token, project.orderId);
        setOrder(orderRes);

        const cncItemsData = orderRes?.items.filter(
          (i) => i.product.productType === ProductTypeEnum.CNC
        );

        if (cncItemsData && cncItemsData.length > 0) {
          const singleItem = cncItemsData[0];
          const itemData = await apiGetOrderItem(token, singleItem.id);
          setItem(itemData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductionData();
  }, [token, project.id, project.orderId]);

  const handleTaskChange = (
    taskId: string,
    updatedTaskData: Partial<ProductionTaskType>
  ) => {
    setTasks((prevTasks) =>
      prevTasks!.map((task) =>
        task.id === taskId ? { ...task, ...updatedTaskData } : task
      )
    );

    console.log("update task: ", updatedTaskData);
  };

  const formatWorkTime = (workTime: number) => {
    const hours = Math.floor(workTime);
    const minutes = Math.round((workTime - hours) * 60);
    return `${hours} h ${minutes} min`;
  };

  return (
    <TableRow key={details && details.modelId}>
      <TableCell align="left" className={style.modelCell}>
        <div className={style.flexRow}>
          {details ? (
            <PreviewButton
              productId={project.productId}
              modelId={details.modelId}
              size="small"
            />
          ) : (
            ""
          )}
          <Tooltip title={project.product.name || ""}>
            <div
              className={style.modelContainer}
              style={{ opacity: opacityStyle ? 0.4 : "" }}
            >
              <span className={style.modelId}>#{project.productId} &nbsp;</span>
              <span className={style.modelName}>{project.product.name}</span>
            </div>
          </Tooltip>
        </div>
      </TableCell>
      {!mill && (
        <TableCell
          align="left"
          className={`${style.pd0} ${style.noBrdBottom}`}
          style={{ opacity: opacityStyle ? 0.4 : "" }}
        >
          <span className={style.quantityNum}>
            {details && details.quantity}
          </span>
          <span className={style.smallText}>pcs</span>
        </TableCell>
      )}

      <TableCell
        className={`${style.pd0} ${style.noBrdBottom}`}
        style={{ opacity: opacityStyle ? 0.4 : "" }}
      >
        <div className={style.dimContainer}>
          <span className={style.matText}>{material}</span>
          {!cut && (
            <span className={style.smallText}>
              {modelInfo
                ? `${modelInfo.bboxDx?.toFixed(0) ?? "?"} x ${
                    modelInfo.bboxDy?.toFixed(0) ?? "?"
                  } x ${modelInfo.bboxDz?.toFixed(0) ?? "?"}`
                : "Loading..."}
            </span>
          )}
        </div>
      </TableCell>
      {!cut && !shipping && !mill && (
        <TableCell
          align="right"
          className={`${style.pd0} ${style.noBrdBottom}`}
          style={{ opacity: opacityStyle ? 0.4 : "" }}
        >
          <div className={style.iconsContainer}>
            <div className={style.iconContainer}>
              {details ? (
                <Tooltip
                  title={`Download: ${details?.model && details.model.name}`}
                >
                  <span
                    className="downloadLink"
                    onClick={() =>
                      colorStepExist
                        ? fetchThreadFile(
                            token,
                            project.product.name,
                            details.modelId
                          )
                        : fetchSTEPFile(
                            token,
                            project.product.name,
                            details.modelId
                          )
                    }
                  >
                    <StepIcon className={style.stepIcon} />
                  </span>
                </Tooltip>
              ) : (
                <StepIcon className={style.stepIconNoModel} />
              )}
            </div>
            <div className={style.iconContainer}>
              {project.product.productType === "CNC" &&
              (project.product.details as CNCProductDetailsType).blueprint ? (
                <Tooltip title={`Download: ${(project.product.details as CNCProductDetailsType).blueprint}`}>
                  <span onClick={() => fetchPDFFile(token, project.product?.name, project.productId)}>
                    <PdfIcon className={style.activeIcon} />
                  </span>
                </Tooltip>
              ) : (
                <PdfIcon className={style.notActiveIcon} />
              )}
            </div>
            <div className={style.iconContainer}>
              {details?.threads ? (
                details.threads.length > 0 ? (
                  <Tooltip
                    title={
                      <div className={style.threadTooltipBkgr}>
                        <ThreadDisplay specs={details.threads} />
                      </div>
                    }
                  >
                    <ThreadsIcon className={style.activeIcon} />
                  </Tooltip>
                ) : (
                  <ThreadsIcon className={style.notActiveIcon} />
                )
              ) : (
                "-"
              )}
            </div>
            <Tooltip title="Internal chat" onClick={handleButtonClickInternal}>
              <span>
                <Person className={style.personIcon} />
              </span>
            </Tooltip>
            <div className={style.chatPopup}>
              {customer && showInternalChat && (
                <Popup
                  customerComment={details?.comment}
                  customer={customer}
                  productId={project.productId}
                  selectedParticipant="employee"
                  onClose={handleButtonClickInternal}
                />
              )}
            </div>
          </div>
        </TableCell>
      )}
      {cut && (
        <TableCell
          className={`${style.pd0} ${style.noBrdBottom}`}
          style={{ opacity: opacityStyle ? 0.4 : "" }}
        >
          <div className={style.dimContainer}>
            <span className={style.titleText}>
              {" "}
              {modelInfo
                ? `${modelInfo.bboxDx?.toFixed(0) ?? "?"} x ${
                    modelInfo.bboxDy?.toFixed(0) ?? "?"
                  } x ${modelInfo.bboxDz?.toFixed(0) ?? "?"}`
                : "Loading..."}
            </span>
            {/* <span className={style.smallText}>stock?</span> */}
          </div>
        </TableCell>
      )}
      <TableCell
        className={`${style.pd0} ${style.noBrdBottom}`}
        style={{ opacity: opacityStyle ? 0.4 : "" }}
      >
        <TolSurfCertDisplay
          details={project.product.details as CNCProductDetailsType}
          showCert={review ?? cam ?? cut ?? qc ?? shipping}
          showTol={review ?? cam ?? qc ?? mill}
          showSurf={review ?? cam ?? qc ?? mill}
        />
      </TableCell>
      {shipping && <TableCell colSpan={4}></TableCell>}
      {!cut && !shipping && !mill && (
        <TableCell
          className={`${style.pd0} ${style.noBrdBottom}`}
          align="left"
          style={{ opacity: opacityStyle ? 0.4 : "" }}
        >
          <span className={style.finishTitle}>{formattedFinishes}</span>
        </TableCell>
      )}
      {cut && <TableCell colSpan={2} />}
      {(cut || mill) && (
        <TableCell
          align="right"
          className={`${style.pd0} ${style.noBrdBottom}`}
          style={{ opacity: opacityStyle ? 0.4 : "" }}
        >
          {/* <div className={style.flexRow}> */}
          {!order?.message ? (
            <OrderMsgIcon />
          ) : (
            <Tooltip title={order?.message}>
              <OrderMsgYelIcon />
            </Tooltip>
          )}
          {/* </div> */}
        </TableCell>
      )}
      {mill && (
        <TableCell align="right" colSpan={2}>
          <span
            className={style.flexRow}
            key={`${task.productionId},${task.id}`}
          >
            {tasks
              .sort((a, b) => {
                return a.priority - b.priority;
              })
              .map((taskItem, index) => {
                return (
                  <div key={taskItem.id}>
                    <AssignPersonMachine
                      taskId={task.id}
                      initTask={task}
                      isHover={false}
                      machines={machines}
                    />
                  </div>
                );
              })}
          </span>
        </TableCell>
      )}
      {mill && (
        <TableCell>
          <span className={style.titleText}>
            {task.workTime ? "~" + formatWorkTime(task.workTime) : ""}
          </span>
        </TableCell>
      )}
      {mill && (
        <TableCell>
          {task.allowProgress ? (
            <TaskQuantitySelector
              task={task}
              onChange={onChange}
              project={project}
            />
          ) : (
            ""
          )}
        </TableCell>
      )}

      {cam && (
        <TableCell
          className={`${style.pd0} ${style.noBrdBottom}`}
          align="right"
        >
          <div className={style.iconContainer}>
            <Tooltip title="Setup Sheet">
              <span
                onClick={() =>
                  apiGetProductionDatasheet(
                    token,
                    project.id,
                    project.productId
                  )
                }
              >
                <SetupSheetIcon className={style.stepIcon} />
              </span>
            </Tooltip>
          </div>
        </TableCell>
      )}
      {review && (
        <>
          <TableCell
            colSpan={2}
            className={`${style.pd0} ${style.noBrdBottom}`}
            align="right"
          >
            <span className={style.flexC}>
              <span className={style.finishTitle}>{item?.price} DKK</span>
              <span className={style.smallText}>{item?.unitPrice} /pcs</span>
            </span>
          </TableCell>
        </>
      )}
      {cam && (
        <TableCell
          className={`${style.pd0} ${style.noBrdBottom}`}
          align="center"
          style={{ opacity: opacityStyle ? 0.4 : "" }}
        >
          <div className={style.assignContainer}>
            {tasks
              .filter((task) => task.name === taskStatus.type)
              .map((task) => (
                <TaskDeadline
                  key={task.id}
                  task={task}
                  numberStyle
                  letterStyle
                />
              ))}
            <span className={style.flexRow}>
              {/* {tasks
                .filter(
                  (task) =>
                    task.users &&
                    ((task.name === "cam" && taskStatus.type === "cam") ||
                      (task.name === "review" && taskStatus.type === "review"))
                )
                .map((task) => ( */}
              <AssignPersonMachine
                key={task.id}
                initTask={task}
                taskId={task.id}
                isHover
                machines={machines}
              />
              {/* ))} */}
              {/* <AssignToTask
                key={task.id}
                initTask={task}
                taskId={task.id}
                isHover
                machines={machines}
              /> */}
            </span>
          </div>
        </TableCell>
      )}
      {qc && (
        <TableCell
          className={`${style.pd0} ${style.noBrdBottom}`}
          align="center"
          style={{ opacity: opacityStyle ? 0.4 : "" }}
          colSpan={2}
        >
          {tasks
            .filter((task) => task.name === taskStatus.type)
            .map((task) => (
              <TaskDeadline key={task.id} task={task} numberStyle letterStyle />
            ))}
        </TableCell>
      )}
      <TableCell align="right" className={`${style.pd0} ${style.noBrdBottom}`}>
        {buttonComponent}
      </TableCell>
    </TableRow>
  );
};
export default TaskTableRow;
