import { FinishType, MaterialType } from "../types/NetworkTypes";
import { fetchPublic } from "./network/common";

export class Materials {
  static categories: MaterialType[];

  static loadMaterials(): Promise<MaterialType[]> {
    if (Materials.categories === undefined) {
      Materials.categories = [];
      // Load finish data from json
      return Promise.all([
        fetchPublic("/cncMaterials.json").then((res: any) => {
          Materials.categories.push(...res);
        }),
        fetchPublic("/print3DMaterials.json").then((res: any) => {
          Materials.categories.push(...res);
        }),
      ]).then(() => Materials.categories);
    } else {
      return Promise.resolve(Materials.categories);
    }
  }

  // Function to get material object from materialCategories
  static getById(materialId: string) {
    if (Materials.categories && materialId) {
      for (const materialCategory of Materials.categories) {
        for (const materialItem of materialCategory.variants) {
          if (materialItem.id === materialId) {
            return materialItem;
          }
        }
      }
    }
    return null;
  }
}

export class Finishes {
  static categories: FinishType[];

  static loadFinishes() {
    if (Finishes.categories === undefined) {
      Finishes.categories = [];
      // Load finish data from json
      return Promise.all([
        fetchPublic("/cncFinish.json").then((res: FinishType[]) => {
          Finishes.categories.push(...res);
        }),
        fetchPublic("/print3DFinish.json").then((res: FinishType[]) => {
          Finishes.categories.push(...res);
        }),
      ]).then(() => Finishes);
    } else {
      return Promise.resolve(Finishes);
    }
  }

  // Function to get finish object from FinishData
  static getById(finishId: string) {
    if (Finishes.categories !== undefined && finishId) {
      for (const finishCategory of Finishes.categories) {
        for (const finishItem of finishCategory.variants) {
          if (finishItem.id === finishId) {
            return finishItem;
          }
        }
      }
    }
    return null;
  }
}
