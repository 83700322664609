const primaryColor = [
  "#00b28d",
  "#58e5bd",
  "#439c7e",
  "#008A80",
  "#00b28d",
  "#00b28d",
  "#00b28d",
];
const magentoColor = "#ef672f";

const warningColor = [
  "#ff9800",
  "#ffa726",
  "#fb8c00",
  "#ffa21a",
  "#f57c00",
  "#faf2cc",
  "#fcf8e3",
];

const dangerColor = [
  "#f44336",
  "#ef5350",
  "#e53935",
  "#ff7961",
  "#d32f2f",
  "#ebcccc",
  "#f2dede",
];
const successColor = [
  "#4caf50",
  "#66bb6a",
  "#43a047",
  "#5cb860",
  "#388e3c",
  "#d0e9c6",
  "#dff0d8",
];

const LEDColor = [];

const whiteColor = "#FFF";

const infoColor = ["#607d8b", "#607d8b", "#607d8b", "#26A1F3", "#AF26F3"];

export {
  primaryColor,
  warningColor,
  magentoColor,
  dangerColor,
  successColor,
  infoColor,
  whiteColor,
};
