import { Dialog, CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import AppStateContext from "../../contexts/AppStateContext";

type PropsType = {
  show?: boolean;
}

const LoadingModal: React.FC<PropsType> = ({
  show
}) => {
  const { showLoadingModal, setShowLoadingModal } = useContext(AppStateContext);
  return (
    <Dialog
      open={show ?? showLoadingModal}
      //onBackdropClick={() => setShowLoadingModal(false)}
      maxWidth="lg"
    >
      <div
        style={{
          height: "10em",
          width: "15em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "4em",
        }}
      >
        <CircularProgress
          size={"8em"}
          style={{
            color: "info",
          }}
        />
        <br />
        <h3>{"Loader..."}</h3>
      </div>
    </Dialog>
  );
};

export default LoadingModal;
