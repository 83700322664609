import React, { FunctionComponent } from "react";
import { useToast } from "contexts/ToastContext";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

type UserProps = {
  user: string;
};

const CopyUser: FunctionComponent<UserProps> = ({ user }) => {
  const { addToast } = useToast();

  const handleCopyUser = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(user);
    addToast({
      type: "info",
      message: "User name and lastname copied to the clipboard",
    });
  };
  return (
    <LightTooltip
      title={
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#028B81",
          }}
          onClick={handleCopyUser}
        >
          {user}
        </span>
      }
    >
      <PersonIcon style={{ cursor: "pointer" }} onClick={handleCopyUser} />
    </LightTooltip>
  );
};
export default CopyUser;
