import { FunctionComponent, useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Paper,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Typography,
  Skeleton,
} from "@mui/material";
import "../../../styles/global.css";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import { CartStatusEnum, SimpleCartType } from "types/CartTypes";
import { apiGetCarts } from "util/network/Carts";
import AppStateContext from "contexts/AppStateContext";
import BasketRow from "./BasketRow";
import { primaryColor } from "../../../assets/colors";

type PropsType = {
  searchValue: string;
};

const BasketTable: FunctionComponent<PropsType> = ({ searchValue }) => {
  const { token } = useContext(AppStateContext);
  const [page, setPage] = useStateWithSessionStorage<number>(0, "basketPage");
  const [pageSize, setPageSize] = useStateWithSessionStorage<number>(
    25,
    "basketPageSize"
  );
  const [total, setTotal] = useState<number>(0);
  const [statusFilter, setStatusFilter] = useStateWithLocalStorage(
    [CartStatusEnum.OPEN],
    "basketStateFilter"
  );
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<string>("modified");
  const [baskets, setBaskets] = useState<SimpleCartType[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchCarts = (): Promise<void> => {
    if (token) {
      return apiGetCarts(
        token,
        page + 1,
        pageSize,
        sortKey,
        sortAsc ? "asc" : "desc",
        statusFilter,
        searchValue,
        undefined,
        true
      )
        .then((res) => {
          setTotal(res.total);
          setBaskets(res.items);
        })
        .catch((err) => {
          console.error("Error retrieving orders", err);
          setBaskets([]);
        });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    fetchCarts();
    const intervalId = setInterval(fetchCarts, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [token, page, pageSize, sortKey, sortAsc, statusFilter, searchValue]);

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    // Loading while fetching orders
    setLoading(true);
    fetchCarts().then(() => setLoading(false));
  }, [page, pageSize, sortKey, sortAsc, statusFilter, searchValue]);

  const doSort = (key: string) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(false);
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer style={{ paddingBottom: "1rem" }}>
        <Table
          stickyHeader
          padding={"none"}
          sx={{ color: primaryColor[0] }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead
            sx={{
              backgroundColor: "var(--header-2)",
            }}
          >
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  backgroundColor: "var(--header-2)",
                  paddingLeft: "1rem",
                  padding: "1rem",
                }}
              >
                <Typography
                  fontSize={"1.1em"}
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  State
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  paddingLeft: "0.5em",
                  backgroundColor: "var(--header-2)",
                }}
              >
                <TableSortLabel>
                  <Typography
                    fontSize={"1.1em"}
                    color={"white"}
                    fontWeight={"bold"}
                    variant={"body1"}
                  >
                    Customer
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "var(--header-2)" }}
              >
                <Typography
                  fontSize={"1.1em"}
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Shipping
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  backgroundColor: "var(--header-2)",
                  paddingRight: "0.8rem",
                }}
              >
                <Typography
                  fontSize={"1.1em"}
                  color={"white"}
                  fontWeight={"bold"}
                  variant={"body1"}
                >
                  Value
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableBody>
              {Array.from({ length: pageSize }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={8} style={{ padding: "0.5rem" }}>
                    <Skeleton key={index} variant="rectangular" height={30} />{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {baskets.map((basket) => {
                return (
                  <BasketRow
                    key={basket.id}
                    basket={basket}
                    refresh={() => fetchCarts().then(() => setLoading(false))}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 100]}
        component="div"
        count={total}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default BasketTable;
