import React, { useEffect, useState } from "react";
import styles from "../styles/Table.module.css";
import { fetchJobs, Job } from "./JobsAPI"; // Import the fetchJobsData function
import JobDataList from "./JobDataList"; // Import the JobEventList component
import { format, differenceInSeconds, isSameDay } from "date-fns";

// Formats time from seconds
const formatTime = (seconds: number): string => {
  if (seconds < 60) {
    return `${seconds}s`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${remainingMinutes}m`;
  }
};

const JobList: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const [searchDate, setSearchDate] = useState<string>("");
  const [searchDateStart, setSearchDateStart] = useState<string>("");
  const [searchDateEnd, setSearchDateEnd] = useState<string>("");

  useEffect(() => {
    // Fetch jobs data when the component mounts
    async function fetchData() {
      try {
        const data = await fetchJobs(
          searchString,
          searchDateStart,
          searchDateEnd
        );
        setJobs(data);
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [searchString, searchDateStart, searchDateEnd]);

  const handleRowClick = (job: Job) => {
    if (selectedJob && selectedJob.id == job.id) setSelectedJob(null);
    else setSelectedJob(job);
  };

  const handleTableClick = () => {
    // Deselect the job when clicking anywhere in the table body
    setSelectedJob(null);
  };

  const filteredJobs = jobs.filter((job) => {
    if (!searchDate) return true;
    return isSameDay(new Date(job.start), new Date(searchDate));
  });

  return (
    <div>
      <h1>Job List</h1>
      <input
        type="text"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        placeholder="Search..."
      />
      <input
        type="date"
        value={searchDateStart}
        onChange={(e) => setSearchDateStart(e.target.value)}
        placeholder="Start Date"
      />
      <input
        type="date"
        value={searchDateEnd}
        onChange={(e) => setSearchDateEnd(e.target.value)}
        placeholder="End Date"
      />
      <table className={styles.tooldb__table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Machine</th>
            <th>Start</th>
            <th>Duration</th>
            <th>Item</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr key={job.id} onClick={() => handleRowClick(job)}>
              <td>{job.id}</td>
              <td>{job.machine}</td>
              <td>{format(job.start, "HH:mm, d MMM yyyy")}</td>
              <td>{formatTime(differenceInSeconds(job.end, job.start))}</td>
              <td>{job.item}</td>
              <td>{job.file}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedJob && (
        <div>
          <JobDataList job={selectedJob} />
        </div>
      )}
    </div>
  );
};

export default JobList;
