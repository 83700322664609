import {
  TableCell,
  Tooltip,
  TableRow,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FunctionComponent, useEffect, useState, useContext } from "react";
import { formatter } from "../../../util/TextUtil";
import styles from "./Styles/cartTableRowStyle.module.css";
import AppStateContext from "../../../contexts/AppStateContext";
import { CartItemType, UpdateCartItemCommandType } from "types/CartTypes";
import { LeadOptionsType } from "types/CheckoutTypes";
import { useToast } from "contexts/ToastContext";
import React from "react";
import { apiUpdateCartItem } from "util/network/Carts";

type PropsType = {
  cartId: number;
  item: CartItemType;
  leadTime: LeadOptionsType;
  deleteItem: Function;
  onChange: (input: CartItemType) => void;
};

const FeeItemTableRow: FunctionComponent<PropsType> = React.memo(
  ({ cartId, item, leadTime, deleteItem, onChange }) => {
    const { strings, token } = useContext(AppStateContext);
    const { addToast } = useToast();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [priceStandard, setPriceStandard] = useState<number>(
      item.product.priceStandard
    );
    const [priceExpress, setPriceExpress] = useState<number>(
      item.product.priceExpress
    );
    const [priceEconomic, setPriceEconomic] = useState<number>(
      item.product.priceEconomic
    );
    const [price, setPrice] = useState<number>(item.pricePerUnit);
    const [total, setTotal] = useState<number>(item.total);
    const [priceText, setPriceText] = useState<string>(
      String(item.pricePerUnit)
    );
    const [priceOverridden, setPriceOverridden] = useState<boolean>(
      item.priceOverridden
    );
    const [showProductPrice, setShowProductPrice] = useState<boolean>(false);

    const updateItem = (input: UpdateCartItemCommandType) => {
      setDisabled(true);
      apiUpdateCartItem(token, cartId, item.id, input)
        .then((res) => {
          setPrice(res.pricePerUnit);
          setTotal(res.total);
          onChange(res);
          return true;
        })
        .catch((err) => {
          addToast({
            type: "error",
            message: "Could not update item correctly in cart" + err,
            keep: true,
          });
          return false;
        })
        .finally(() => {
          setDisabled(false);
        });
    };

    const updatePrice = (amount: string) => {
      let newPrice = amount.trim();
      if (newPrice === String(price)) return;
      if (newPrice === "") {
        let resetPrice = priceStandard;
        if (leadTime === "EXPRESS") {
          resetPrice = priceExpress;
        } else if (leadTime === "ECONOMIC") {
          resetPrice = priceEconomic;
        }
        setPriceText(String(resetPrice));
        setPriceOverridden(false);
        updateItem({ pricePerUnit: -1 });
        return;
      }
      newPrice = newPrice.replace(",", ".");
      let floatPrice = parseFloat(newPrice);
      if (isNaN(floatPrice)) {
        //setCustomPrice(String(item.pricePerUnit ?? 0));
        return;
      }
      floatPrice = parseFloat(floatPrice.toFixed(2));
      setPriceText(String(floatPrice));
      setPriceOverridden(true);
      updateItem({
        pricePerUnit: floatPrice,
      });
    };

    useEffect(() => {
      setPrice(item.pricePerUnit);
    }, [item.pricePerUnit]);

    // This useEffect is used to reset the custom price when the lead time changes
    useEffect(() => {
      let productPrice = null;
      if (leadTime === "EXPRESS") {
        productPrice = item.product.priceExpress;
      } else if (leadTime === "STANDARD") {
        productPrice = item.product.priceStandard;
      } else if (leadTime === "ECONOMIC") {
        productPrice = item.product.priceEconomic;
      }
      if (productPrice && !priceOverridden) {
        setPrice(productPrice);
        setPriceText(String(item.pricePerUnit));
        setTotal(item.total);
      }
    }, [leadTime]);

    const splitFee = item.product.name.split("_");
    console.log(item.product.name);
    const feeType = splitFee[0];
    const extraFee = splitFee[1];
    let feeName = `${strings[feeType]}` ?? item.product.name;
    if (extraFee) {
      feeName += ` (${extraFee})`;
    }

    return (
      <>
        <TableRow key={item.id} className={styles.cellsNoWrap}>
          <TableCell align="left" colSpan={2}>
            {feeName}
          </TableCell>
          <TableCell></TableCell>
          <TableCell
            align="left"
            className={styles.cellItemDescription}
            colSpan={4}
          >
            {strings[`${feeType}Description`] ?? item.product.name}
          </TableCell>
          <TableCell>
            <TextField
              value={showProductPrice ? item.product.priceStandard : priceText}
              onChange={(e) => {
                setPriceText(e.target.value);
              }}
              onBlur={(e) => updatePrice(e.target.value)}
              id="outlined-start-adornment"
              sx={{ m: 1, width: "19ch" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kr.</InputAdornment>
                ),
              }}
              // disabled={disabled || showProductPrice}
            />
            <ButtonGroup
              sx={{ marginTop: 1 }}
              //variant="contained"
              orientation="vertical"
              size="small"
              aria-label="outlined primary button group"
            >
              <Tooltip
                title={<h5>Press and hold for original price</h5>}
                placement="bottom"
              >
                <span>
                  <Button
                    aria-label={"Calculate"}
                    color={showProductPrice ? "info" : "primary"}
                    onMouseDown={() => {
                      setShowProductPrice(true);
                    }}
                    onMouseUp={() => {
                      setShowProductPrice(false);
                    }}
                    disabled={!priceOverridden}
                  >
                    <VisibilityIcon fontSize={"small"} />
                  </Button>
                </span>
              </Tooltip>
            </ButtonGroup>
          </TableCell>
          <TableCell>{formatter.format(total ?? 0)}</TableCell>

          {/* Actions Cell */}
          <TableCell>
            {/* Delete Button */}
            <Tooltip
              title={<h5>SLET</h5>}
              placement="bottom"
              sx={{ float: "right" }}
            >
              <IconButton
                aria-label={"Delete"}
                color={"secondary"}
                onClick={() => deleteItem(item)}
                disabled={true}
              >
                <Delete fontSize={"large"} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </>
    );
  }
);

export default FeeItemTableRow;
