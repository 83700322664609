import AppStateContext from "contexts/AppStateContext";
import useStateWithLocalStorage from "hooks/UseStateWithLocalStorage";
import useStateWithSessionStorage from "hooks/UseStateWithSessionStorage";
import React, { Suspense, useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ProductionListType } from "types/ProductionType";
import { apiGetProductions } from "util/network/Productions";
import HeaderRow from "../HeaderRow";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import ReviewRow from "./ReviewRow";

type PropsType = {
  selection: string;
  searchValue?: string;
  orderId?: number;
};

const ReviewTable: React.FC<PropsType> = ({
  selection,
  searchValue,
  orderId,
}) => {
  const { token } = useContext(AppStateContext);
  const [fetchTimeStamp, setFetchTimeStamp] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>(
    15,
    "reviewPageSize"
  );
  const [debug, setDebug] = useStateWithSessionStorage(false, "debug");
  const [sortKey, setSortKey] = useStateWithLocalStorage<
    "deadline_date" | "order_id" | "priority"
  >("deadline_date", "reviewSortKey");
  const [sortDirection, setSortDirection] = useStateWithLocalStorage<
    "asc" | "desc"
  >("asc", "reviewSortDirection");

  const getAllProductions = (
    page: number,
    pageSize: number,
    selection: string,
    sortKey: "deadline_date" | "order_id" | "priority",
    sortDirection: "asc" | "desc",
    search?: string
  ) => {
    return apiGetProductions(
      token,
      page,
      pageSize,
      `${selection == "ready" && "review=ACTIVE"}&status=0`,
      sortKey,
      sortDirection,
      search,
      undefined,
      selection == "all" ? "review=ACTIVE;review=TODO" : undefined
    ).then((res) => {
      setFetchTimeStamp(Date.now());
      return res;
    });
  };

  const { data } = useQuery<ProductionListType, any>(
    [
      "productions",
      page,
      pageSize,
      selection,
      sortKey,
      sortDirection,
      searchValue,
      orderId,
    ],
    () =>
      getAllProductions(
        page,
        pageSize,
        selection,
        sortKey,
        sortDirection,
        searchValue
      ),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
    }
  );

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const projectMemo = useMemo(() => {
    let oldId = 0;
    const rows = data?.items.map((project) => {
      if (
        project.orderId &&
        project.orderId !== oldId &&
        orderId === undefined
      ) {
        oldId = project.orderId;

        return (
          <React.Fragment key={project.id}>
            <HeaderRow
              key={`${project.orderId}`}
              project={project}
              productions={data}
              review
            />
            <Suspense
              fallback={
                <TableRow>
                  <TableCell colSpan={11}>
                    <Skeleton variant="rectangular" height={55} />
                  </TableCell>
                </TableRow>
              }
            >
              <ReviewRow
                key={`${project.id}${project.modified}${fetchTimeStamp}`}
                project={project}
              />
            </Suspense>
          </React.Fragment>
        );
      } else
        return (
          <Suspense
            key={project.id}
            fallback={
              <TableRow>
                <TableCell colSpan={11}>
                  <Skeleton variant="rectangular" height={55} />
                </TableCell>
              </TableRow>
            }
          >
            <ReviewRow
              key={`${project.id}${project.modified}${fetchTimeStamp}`}
              project={project}
            />
          </Suspense>
        );
    });
    return rows;
  }, [data, fetchTimeStamp, orderId]);

  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>{projectMemo}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 50]}
        component="div"
        count={data?.total ?? 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default ReviewTable;
