import "./App.css";
import DashboardLayout from "./dashboard_layout/DashboardLayout";
import LoginPage from "./pages/authPages/LoginPage";
import { Grid } from "@mui/material";
import { Route, Switch } from "react-router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      <div className="container">
        <Grid container>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/">
              <DashboardLayout />
            </Route>
          </Switch>
        </Grid>
      </div>
    </LocalizationProvider>
  );
}

export default App;
